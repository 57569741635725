import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetBranchWorkingHoursQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetBranchWorkingHoursQuery = { __typename?: 'Query', branch?: { __typename?: 'Branch', workingHours?: Array<{ __typename?: 'WorkingHour', day?: Types.DayOfWeekEnum | null, openTimeRanges?: Array<{ __typename?: 'TimeRange', closeTime?: any | null, openTime?: any | null } | null> | null } | null> | null } | null };


export const GetBranchWorkingHoursDocument = gql`
    query getBranchWorkingHours($id: ID!) {
  branch(id: $id) {
    workingHours {
      day
      openTimeRanges {
        closeTime
        openTime
      }
    }
  }
}
    `;

/**
 * __useGetBranchWorkingHoursQuery__
 *
 * To run a query within a React component, call `useGetBranchWorkingHoursQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBranchWorkingHoursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBranchWorkingHoursQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBranchWorkingHoursQuery(baseOptions: Apollo.QueryHookOptions<GetBranchWorkingHoursQuery, GetBranchWorkingHoursQueryVariables> & ({ variables: GetBranchWorkingHoursQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBranchWorkingHoursQuery, GetBranchWorkingHoursQueryVariables>(GetBranchWorkingHoursDocument, options);
      }
export function useGetBranchWorkingHoursLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBranchWorkingHoursQuery, GetBranchWorkingHoursQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBranchWorkingHoursQuery, GetBranchWorkingHoursQueryVariables>(GetBranchWorkingHoursDocument, options);
        }
export function useGetBranchWorkingHoursSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetBranchWorkingHoursQuery, GetBranchWorkingHoursQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBranchWorkingHoursQuery, GetBranchWorkingHoursQueryVariables>(GetBranchWorkingHoursDocument, options);
        }
export type GetBranchWorkingHoursQueryHookResult = ReturnType<typeof useGetBranchWorkingHoursQuery>;
export type GetBranchWorkingHoursLazyQueryHookResult = ReturnType<typeof useGetBranchWorkingHoursLazyQuery>;
export type GetBranchWorkingHoursSuspenseQueryHookResult = ReturnType<typeof useGetBranchWorkingHoursSuspenseQuery>;
export type GetBranchWorkingHoursQueryResult = Apollo.QueryResult<GetBranchWorkingHoursQuery, GetBranchWorkingHoursQueryVariables>;