import { PatientHealthParameter } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, IconButton, MuiPlayCircleIcon } from "@toolkit/ui";
import { useMemo } from "react";

type PatientHealthParameterHistoryAudioColumnsProps = {
  selectedItem?: PatientHealthParameter;
  onChange: (value: PatientHealthParameter) => void;
};

export const usePatientHealthParameterHistoryAudioColumns = (
  props: PatientHealthParameterHistoryAudioColumnsProps
): CustomTableColumnProps<PatientHealthParameter>[] => {
  const { selectedItem, onChange } = props;

  const { t } = useTranslation("provider");

  return useMemo(() => {
    return [
      {
        key: "source",
        header: t("Source"),
        accessor: "source",
      },
      {
        key: "date",
        header: t("Date"),
        accessor: "createdDate",
      },
      {
        key: "play",
        header: t("Play"),
        accessor: item => {
          return (
            <IconButton disabled={item?.valueAudioFile === selectedItem?.valueAudioFile} onClick={() => onChange(item)}>
              <MuiPlayCircleIcon color={"primary"} />
            </IconButton>
          );
        },
      },
    ];
  }, [onChange, selectedItem?.valueAudioFile, t]);
};
