import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PatientHealthParametersQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.PatientHealthParameterFilterInput>;
  sortBy?: Types.InputMaybe<Types.PatientHealthParameterSortingInput>;
}>;


export type PatientHealthParametersQuery = { __typename?: 'Query', patientHealthParameters?: { __typename?: 'PatientHealthParameterCountableConnection', edges: Array<{ __typename?: 'PatientHealthParameterCountableEdge', node: { __typename?: 'PatientHealthParameter', id: string, deviceName?: string | null, source?: Types.Source | null, createdDate?: any | null, valueBoolean?: boolean | null, valueNumber?: any | null, valueString?: string | null, valueNumericList?: Array<any | null> | null, valueAudioFile?: string | null, valueVideoFile?: string | null, patientId?: string | null } }>, pageInfo: { __typename?: 'H_PageInfo', endCursor?: string | null, startCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean } } | null };


export const PatientHealthParametersDocument = gql`
    query PatientHealthParameters($first: Int, $last: Int, $before: String, $after: String, $filter: PatientHealthParameterFilterInput, $sortBy: PatientHealthParameterSortingInput) {
  patientHealthParameters(
    first: $first
    last: $last
    before: $before
    after: $after
    filter: $filter
    sortBy: $sortBy
  ) {
    edges {
      node {
        id
        deviceName
        source
        createdDate
        valueBoolean
        valueNumber
        valueString
        valueNumericList
        valueAudioFile
        valueVideoFile
        patientId
      }
    }
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    `;

/**
 * __usePatientHealthParametersQuery__
 *
 * To run a query within a React component, call `usePatientHealthParametersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientHealthParametersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientHealthParametersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function usePatientHealthParametersQuery(baseOptions?: Apollo.QueryHookOptions<PatientHealthParametersQuery, PatientHealthParametersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatientHealthParametersQuery, PatientHealthParametersQueryVariables>(PatientHealthParametersDocument, options);
      }
export function usePatientHealthParametersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientHealthParametersQuery, PatientHealthParametersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatientHealthParametersQuery, PatientHealthParametersQueryVariables>(PatientHealthParametersDocument, options);
        }
export function usePatientHealthParametersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PatientHealthParametersQuery, PatientHealthParametersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PatientHealthParametersQuery, PatientHealthParametersQueryVariables>(PatientHealthParametersDocument, options);
        }
export type PatientHealthParametersQueryHookResult = ReturnType<typeof usePatientHealthParametersQuery>;
export type PatientHealthParametersLazyQueryHookResult = ReturnType<typeof usePatientHealthParametersLazyQuery>;
export type PatientHealthParametersSuspenseQueryHookResult = ReturnType<typeof usePatientHealthParametersSuspenseQuery>;
export type PatientHealthParametersQueryResult = Apollo.QueryResult<PatientHealthParametersQuery, PatientHealthParametersQueryVariables>;