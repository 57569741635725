import { PermissionEnum } from "@/schema/types";
import { FeatureEnum, IsFeatureEnabledType } from "@health/domains";
import { hasPermission } from "@toolkit/core";
import { TFunction } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import { ProductOrdersScreen } from "./ProductOrders";

const baseRoute = "/product-orders";

export const settingsPaths = {
  indexPage: {
    route: "",
    get fullPath() {
      return `${baseRoute}${this.route}`;
    },
  },
};

export const productOrdersRoutes: (props: {
  navigate: NavigateFunction;
  t: TFunction;
  isFeatureEnabled: IsFeatureEnabledType;
}) => RouteItem = ({ navigate, t, isFeatureEnabled }): RouteItem => {
  const isProductItemHidden = !(isFeatureEnabled([FeatureEnum.Marketplace]) && hasPermission(PermissionEnum.ManageProducts));

  return {
    id: "productOrders",
    text: t("Product Orders", { ns: "provider" }),
    route: baseRoute,
    fullPath: settingsPaths.indexPage.fullPath,
    element: <ProductOrdersScreen />,
    hidden: isProductItemHidden,
    onClick: (route: string) => navigate(route),
  };
};
