import { AppRoleTypes, Branch } from "@/schema/types";
import { BranchesAutocomplete } from "@health/autocompletes";
import { combineErrors, formGirdBreakPoints, formGirdSpacing } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController, Grid, createAutocompleteOption, useAutocompleteCustomHook } from "@toolkit/ui";
import { useBranchesAutocompleteOptionsQuery } from "pages/HealthProviderManagement/User-Management/gql";
import { FC, useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";

export const Branches: FC<{ defaultValues; errors }> = ({ defaultValues, errors }) => {
  const { t } = useTranslation("provider");
  const {
    control,
    watch,
    setValue,
    formState: { errors: reactFormError },
  } = useFormContext();
  const appRole = watch("appRole");
  const branchesValues = defaultValues?.branches;
  const formBranchesValues = watch("branches");
  const formDefaultBranchValue = watch("defaultBranch");
  const isDefaultValueSet = useRef<boolean>(false);

  useEffect(() => {
    if (defaultValues?.appRole === AppRoleTypes.User) {
      setValue("branches", branchesValues);
    }
    if (defaultValues?.defaultBranch) {
      setValue("defaultBranch", createAutocompleteOption(defaultValues?.defaultBranch, "id", "name"));
    }
  }, [defaultValues, setValue, branchesValues]);

  useEffect(() => {
    if (appRole?.value) {
      if (!isDefaultValueSet.current) {
        isDefaultValueSet.current = true;
      } else if (appRole?.value !== defaultValues?.appRole) {
        setValue("branches", []);
        setValue("defaultBranch", null);
      }
    }
  }, [appRole, defaultValues?.appRole, setValue]);

  useEffect(() => {
    if (isDefaultValueSet.current && formDefaultBranchValue?.value?.id && appRole?.value !== AppRoleTypes.Admin) {
      const defaultBranchExists = formBranchesValues?.find(branch => branch?.id === formDefaultBranchValue?.value?.id);
      if (!defaultBranchExists) {
        setValue("defaultBranch", null);
      }
    }
  }, [formBranchesValues, formDefaultBranchValue, setValue]);

  const combinedErrors = combineErrors(reactFormError, errors);
  const {
    data: branchesData,
    isLoading: isBranchLoading,
    onFetchMoreData,
    refetch: refetchBranches,
  } = useAutocompleteCustomHook({
    useAutocompleteControllerQuery: useBranchesAutocompleteOptionsQuery,
    input: {
      first: 10,
      after: null,
      before: null,
      last: null,
    },
    dataAccessor: "branches",
  });

  const handleBranchesSearch = (filter: string) => {
    refetchBranches({
      filter: {
        name_Icontains: filter,
      },
    });
  };

  const branches = branchesData?.branches?.edges?.map(branch => branch?.node) || ([] as Branch[]);
  const pageInfo = branchesData?.branches?.pageInfo;

  return (
    <>
      <Grid container spacing={formGirdSpacing}>
        {(appRole?.value === AppRoleTypes.User || (defaultValues?.appRole == AppRoleTypes.User && !appRole)) && (
          <Grid item {...formGirdBreakPoints}>
            <AutocompleteController
              ControllerProps={{
                name: "branches",
                control: control,
              }}
              multiple
              loading={isBranchLoading}
              hasMore={Boolean(pageInfo?.hasNextPage)}
              fetchMoreData={onFetchMoreData}
              TextFieldProps={{
                error: Boolean(combinedErrors?.branches?.type),
                helperText: t(combinedErrors?.branches?.type && t("Required")),
                label: t("Branches"),
              }}
              getOptionLabel={option => option?.name}
              options={branches || []}
              isOptionEqualToValue={(option, value) => option?.id === value.id}
              onSearchInput={handleBranchesSearch}
            />
          </Grid>
        )}

        <Grid item {...formGirdBreakPoints}>
          <BranchesAutocomplete
            name='defaultBranch'
            label={t("Default Branch")}
            disabled={!formBranchesValues?.length && appRole?.value === AppRoleTypes.User}
            filter={{
              vendors: defaultValues?.vendor ? [defaultValues?.vendor?.id] : [],
              ids: formBranchesValues?.map(branch => branch.id) || [],
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};
