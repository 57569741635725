import { pickLocalizedValue } from "@toolkit/i18n";
import { Card, ExpandIcon, IconButton } from "@toolkit/ui";
import { Children, cloneElement, FC, isValidElement, ReactElement } from "react";
import { useOrdersCardStyles } from "./OrdersCard.styles";
import { OrdersCardProps } from "./OrdersCard.types";
import { OrdersDialog } from "./OrdersDialog";
import { isOpenOrderCardDialog } from "./utils";
import { useOpenState } from "@toolkit/core";

export const OrdersCard: FC<OrdersCardProps> = ({ children, hasExpand = false, color }) => {
  const { open, handleToggle: handleToggleState } = useOpenState();

  const { classes } = useOrdersCardStyles({ color });

  const handleToggle = () => {
    isOpenOrderCardDialog(!open);
    handleToggleState();
  };

  const ChildrenComponent = Children.map(children, child => {
    if (isValidElement(child)) {
      return cloneElement(child as ReactElement, { open });
    }
  });

  return (
    <Card className={`${classes.card} ${classes.border}`}>
      {hasExpand && (
        <IconButton onClick={handleToggle} className={pickLocalizedValue(classes.icon, `${classes.icon} ${classes.iconRtl}`)}>
          <ExpandIcon />
        </IconButton>
      )}

      {open && (
        <OrdersDialog open={open} color={color} onToggle={handleToggle}>
          {ChildrenComponent}
        </OrdersDialog>
      )}

      {children}
    </Card>
  );
};
