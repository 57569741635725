import { useOrderActionStyles } from "@/pages/GlobalOrders/Orders/actions/OrderAction.styles";
import { MedicationsMappingDialogProps } from "@/pages/GlobalOrders/Orders/cards/types";
import { useTranslation } from "@toolkit/i18n";
import { Button, Checkbox, CircularProgress, CustomDialog, FormControlLabel, TextField, Tooltip, Typography } from "@toolkit/ui";
import { FC, Fragment, useState } from "react";
import { useOpenState } from "@toolkit/core";

export const MedicationsMappingDialog: FC<MedicationsMappingDialogProps> = props => {
  const { isLoading, buttonLabel, medications, onSubmit: handleAccept } = props;

  const [acceptedMedications, setAcceptedMedications] = useState<any[]>([]);

  const { t } = useTranslation("provider");
  const { open, handleToggle: handleToggleState } = useOpenState();

  const { classes } = useOrderActionStyles();

  const handleSelectedValue = (e, id) => {
    if (acceptedMedications?.find(item => item.id === id)) {
      setAcceptedMedications(prev => prev?.filter(item => item?.id !== id));
    } else {
      const item = medications?.find(item => item?.id === id);
      setAcceptedMedications(prev => [...prev, item]);
    }
  };

  const handleToggle = () => {
    setAcceptedMedications([]);
    handleToggleState();
  };

  const handleConfirm = () => {
    handleToggle();
    if (!!medications?.length && !acceptedMedications?.length) return;

    const inputs = acceptedMedications?.map(item => ({
      id: item?.id,
      tradeCode: item?.tradeCode,
      quantity: item?.quantity,
    }));
    handleAccept(inputs);
  };

  const handleOptionsFiled = (e, id) => {
    const values = acceptedMedications?.map(item => (item.id === id ? { ...item, quantity: e.target.value } : item));
    setAcceptedMedications([...values]);
  };

  const buttonLabels = buttonLabel === t("View & Accept");
  const isDisabled = !acceptedMedications?.length || isLoading;

  return (
    <CustomDialog
      type='base'
      open={open}
      button={
        <Tooltip title={buttonLabel!}>
          <Button
            className={!buttonLabels ? classes.outlinedAccept : classes.accept}
            variant={buttonLabels ? "contained" : "outlined"}
            onClick={handleToggle}
          >
            {buttonLabel}
          </Button>
        </Tooltip>
      }
      DialogTitleProps={{
        title: medications?.length ? t("Medications") : t("Products"),
        onClose: handleToggle,
      }}
      onClose={handleToggle}
      DialogActionsProps={{
        children: (
          <Tooltip title={acceptedMedications?.length === 0 ? t("Please select all medications") : ""}>
            <span>
              <Button startIcon={isLoading && <CircularProgress size={12} />} disabled={isDisabled} onClick={handleConfirm}>
                {t("Confirm")}
              </Button>
            </span>
          </Tooltip>
        ),
      }}
    >
      {medications?.length ? (
        <>
          {medications?.map(medication => {
            const currentItem = acceptedMedications.find(item => item?.id === medication?.id)!;

            return (
              <Fragment key={medication?.id}>
                <FormControlLabel
                  control={<Checkbox checked={!!currentItem} onChange={e => handleSelectedValue(e, medication?.id)} />}
                  label={
                    <Typography>
                      <strong>{t("Trade Name")}</strong> : {medication?.display! || medication?.code!} <strong>{t("Quantity")}</strong> :
                      {!medication?.quantity ? (
                        <TextField
                          sx={{
                            marginInline: 2,
                            minWidth: 230,
                          }}
                          type='number'
                          value={!currentItem?.quantity || 0}
                          label={t("Value")}
                          onChange={e => handleOptionsFiled(e, medication?.id)}
                        />
                      ) : (
                        medication?.quantity
                      )}
                    </Typography>
                  }
                />
              </Fragment>
            );
          })}
        </>
      ) : undefined}
    </CustomDialog>
  );
};
