import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProviderMedicalFormUpdateMutationVariables = Types.Exact<{
  updateMedicalFormId: Types.Scalars['ID']['input'];
  input: Types.MedicalFormInput;
}>;


export type ProviderMedicalFormUpdateMutation = { __typename?: 'Mutation', updateMedicalForm?: { __typename?: 'MedicalForm', errors?: Array<{ __typename?: 'MedicalFormGraphqlError', errorType?: Types.ErrorType | null, message?: string | null, field?: string | null } | null> | null } | null };


export const ProviderMedicalFormUpdateDocument = gql`
    mutation ProviderMedicalFormUpdate($updateMedicalFormId: ID!, $input: MedicalFormInput!) {
  updateMedicalForm(id: $updateMedicalFormId, input: $input) {
    errors {
      errorType
      message
      field
    }
  }
}
    `;
export type ProviderMedicalFormUpdateMutationFn = Apollo.MutationFunction<ProviderMedicalFormUpdateMutation, ProviderMedicalFormUpdateMutationVariables>;

/**
 * __useProviderMedicalFormUpdateMutation__
 *
 * To run a mutation, you first call `useProviderMedicalFormUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProviderMedicalFormUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [providerMedicalFormUpdateMutation, { data, loading, error }] = useProviderMedicalFormUpdateMutation({
 *   variables: {
 *      updateMedicalFormId: // value for 'updateMedicalFormId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProviderMedicalFormUpdateMutation(baseOptions?: Apollo.MutationHookOptions<ProviderMedicalFormUpdateMutation, ProviderMedicalFormUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProviderMedicalFormUpdateMutation, ProviderMedicalFormUpdateMutationVariables>(ProviderMedicalFormUpdateDocument, options);
      }
export type ProviderMedicalFormUpdateMutationHookResult = ReturnType<typeof useProviderMedicalFormUpdateMutation>;
export type ProviderMedicalFormUpdateMutationResult = Apollo.MutationResult<ProviderMedicalFormUpdateMutation>;
export type ProviderMedicalFormUpdateMutationOptions = Apollo.BaseMutationOptions<ProviderMedicalFormUpdateMutation, ProviderMedicalFormUpdateMutationVariables>;