import { PermissionEnum } from "@/schema/types";
import { hasPermission } from "@toolkit/core";
import { TFunction } from "@toolkit/i18n";
import { marketplaceRoute } from "pages/Marketplace/constants/marketplace-paths.constants";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import { HealthPackagesList } from "./List";
import EditPackage from "./PackageForm/PackageEdit/PackageEditPage.component";
import NewPackage from "./PackageForm/PackageNew/PackageNewPage.component";

const healthPackagesManagement = `${marketplaceRoute}/health-packages`;
export const healthPackagesManagementPaths = {
  indexPage: {
    route: "",
    get fullPath() {
      return healthPackagesManagement + this.route;
    },
  },
  edit: {
    route: "edit/:id",
    get fullPath() {
      return healthPackagesManagement + "/" + this.route;
    },
  },
  addNew: {
    route: "new",
    get fullPath() {
      return healthPackagesManagement + "/" + this.route;
    },
  },
};

export const healthPackagesRoutes: (props: { navigate: NavigateFunction; t: TFunction; enabled: boolean }) => RouteItem = ({
  navigate,
  t,
  enabled,
}): RouteItem => {
  return {
    id: "health-packages",
    text: t("Health Packages", { ns: "provider" }),
    route: healthPackagesManagement,
    isProhibited: !(enabled && hasPermission(PermissionEnum.ManageHealthPackages)),
    subItems: [
      {
        id: healthPackagesManagementPaths.indexPage.fullPath,
        route: healthPackagesManagementPaths.indexPage.route,
        fullPath: healthPackagesManagementPaths.indexPage.fullPath,
        text: t("Packages Management", { ns: "provider" }),
        element: <HealthPackagesList />,
        onClick: route => navigate(route),
      },
      {
        id: healthPackagesManagementPaths.addNew.fullPath,
        text: "",
        route: healthPackagesManagementPaths.addNew.route,
        fullPath: healthPackagesManagementPaths.addNew.fullPath,
        element: <NewPackage />,
        onClick: route => navigate(route),
        hidden: true,
      },
      {
        id: healthPackagesManagementPaths.edit.fullPath,
        route: healthPackagesManagementPaths.edit.route,
        fullPath: healthPackagesManagementPaths.edit.fullPath,
        text: "",
        element: <EditPackage />,
        onClick: route => navigate(route),
        hidden: true,
      },
    ],
  };
};
