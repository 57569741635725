import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPatientIdByHealthProgramMemberQueryVariables = Types.Exact<{
  healthProgramMemberId: Types.Scalars['ID']['input'];
}>;


export type GetPatientIdByHealthProgramMemberQuery = { __typename?: 'Query', healthProgramMember?: { __typename?: 'HealthProgramMember', patient?: { __typename?: 'Patient', id: string, user?: { __typename?: 'User', id: string } | null } | null } | null };


export const GetPatientIdByHealthProgramMemberDocument = gql`
    query getPatientIdByHealthProgramMember($healthProgramMemberId: ID!) {
  healthProgramMember(id: $healthProgramMemberId) {
    patient {
      id
      user {
        id
      }
    }
  }
}
    `;

/**
 * __useGetPatientIdByHealthProgramMemberQuery__
 *
 * To run a query within a React component, call `useGetPatientIdByHealthProgramMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientIdByHealthProgramMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientIdByHealthProgramMemberQuery({
 *   variables: {
 *      healthProgramMemberId: // value for 'healthProgramMemberId'
 *   },
 * });
 */
export function useGetPatientIdByHealthProgramMemberQuery(baseOptions: Apollo.QueryHookOptions<GetPatientIdByHealthProgramMemberQuery, GetPatientIdByHealthProgramMemberQueryVariables> & ({ variables: GetPatientIdByHealthProgramMemberQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPatientIdByHealthProgramMemberQuery, GetPatientIdByHealthProgramMemberQueryVariables>(GetPatientIdByHealthProgramMemberDocument, options);
      }
export function useGetPatientIdByHealthProgramMemberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPatientIdByHealthProgramMemberQuery, GetPatientIdByHealthProgramMemberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPatientIdByHealthProgramMemberQuery, GetPatientIdByHealthProgramMemberQueryVariables>(GetPatientIdByHealthProgramMemberDocument, options);
        }
export function useGetPatientIdByHealthProgramMemberSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPatientIdByHealthProgramMemberQuery, GetPatientIdByHealthProgramMemberQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPatientIdByHealthProgramMemberQuery, GetPatientIdByHealthProgramMemberQueryVariables>(GetPatientIdByHealthProgramMemberDocument, options);
        }
export type GetPatientIdByHealthProgramMemberQueryHookResult = ReturnType<typeof useGetPatientIdByHealthProgramMemberQuery>;
export type GetPatientIdByHealthProgramMemberLazyQueryHookResult = ReturnType<typeof useGetPatientIdByHealthProgramMemberLazyQuery>;
export type GetPatientIdByHealthProgramMemberSuspenseQueryHookResult = ReturnType<typeof useGetPatientIdByHealthProgramMemberSuspenseQuery>;
export type GetPatientIdByHealthProgramMemberQueryResult = Apollo.QueryResult<GetPatientIdByHealthProgramMemberQuery, GetPatientIdByHealthProgramMemberQueryVariables>;