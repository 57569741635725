import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProviderGuidedCareHealthProgramTeamsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  filter?: Types.InputMaybe<Types.ProviderGuidedCareHealthProgramTeamFilterInput>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type ProviderGuidedCareHealthProgramTeamsQuery = { __typename?: 'Query', providerGuidedCareHealthProgramTeams?: { __typename?: 'ProviderGuidedCareHealthProgramTeamCountableConnection', edges: Array<{ __typename?: 'ProviderGuidedCareHealthProgramTeamCountableEdge', node: { __typename?: 'ProviderGuidedCareHealthProgramTeam', id: string, name?: string | null } }>, pageInfo: { __typename?: 'H_PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } } | null };


export const ProviderGuidedCareHealthProgramTeamsDocument = gql`
    query providerGuidedCareHealthProgramTeams($first: Int, $filter: ProviderGuidedCareHealthProgramTeamFilterInput, $after: String, $before: String, $last: Int) {
  providerGuidedCareHealthProgramTeams(
    first: $first
    filter: $filter
    after: $after
    before: $before
    last: $last
  ) {
    edges {
      node {
        id
        name
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
}
    `;

/**
 * __useProviderGuidedCareHealthProgramTeamsQuery__
 *
 * To run a query within a React component, call `useProviderGuidedCareHealthProgramTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProviderGuidedCareHealthProgramTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProviderGuidedCareHealthProgramTeamsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      filter: // value for 'filter'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useProviderGuidedCareHealthProgramTeamsQuery(baseOptions?: Apollo.QueryHookOptions<ProviderGuidedCareHealthProgramTeamsQuery, ProviderGuidedCareHealthProgramTeamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProviderGuidedCareHealthProgramTeamsQuery, ProviderGuidedCareHealthProgramTeamsQueryVariables>(ProviderGuidedCareHealthProgramTeamsDocument, options);
      }
export function useProviderGuidedCareHealthProgramTeamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProviderGuidedCareHealthProgramTeamsQuery, ProviderGuidedCareHealthProgramTeamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProviderGuidedCareHealthProgramTeamsQuery, ProviderGuidedCareHealthProgramTeamsQueryVariables>(ProviderGuidedCareHealthProgramTeamsDocument, options);
        }
export function useProviderGuidedCareHealthProgramTeamsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ProviderGuidedCareHealthProgramTeamsQuery, ProviderGuidedCareHealthProgramTeamsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProviderGuidedCareHealthProgramTeamsQuery, ProviderGuidedCareHealthProgramTeamsQueryVariables>(ProviderGuidedCareHealthProgramTeamsDocument, options);
        }
export type ProviderGuidedCareHealthProgramTeamsQueryHookResult = ReturnType<typeof useProviderGuidedCareHealthProgramTeamsQuery>;
export type ProviderGuidedCareHealthProgramTeamsLazyQueryHookResult = ReturnType<typeof useProviderGuidedCareHealthProgramTeamsLazyQuery>;
export type ProviderGuidedCareHealthProgramTeamsSuspenseQueryHookResult = ReturnType<typeof useProviderGuidedCareHealthProgramTeamsSuspenseQuery>;
export type ProviderGuidedCareHealthProgramTeamsQueryResult = Apollo.QueryResult<ProviderGuidedCareHealthProgramTeamsQuery, ProviderGuidedCareHealthProgramTeamsQueryVariables>;