import { Maybe, QuestionAnswerExtended, QuestionType } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, Checkbox, CustomDialog, FormControlLabel, FormGroup, Radio, RadioGroup, Typography, useTheme } from "@toolkit/ui";
import moment from "moment";
import { FC, Fragment, ReactNode } from "react";
import { MedicalFormInfo } from "./MedicalFormInfo";

type HealthInsuranceSatisfactionProps = {
  isOpen: boolean;
  date: string;
  label: string;
  numberOfQuestions: number;
  classificationName: string;
  onToggle: (e?) => void;
  questionAnswers: Maybe<QuestionAnswerExtended>[];
  footerComponent?: ReactNode;
};

export const HealthInsuranceSatisfaction: FC<HealthInsuranceSatisfactionProps> = props => {
  const { isOpen, date, label, numberOfQuestions, classificationName, questionAnswers, footerComponent, onToggle: handleToggle } = props;

  const { t } = useTranslation("provider");
  const theme = useTheme();
  const booleanOptions = [
    {
      value: true,
      name: t("Yes"),
    },
    {
      value: false,
      name: t("No"),
    },
  ];

  return (
    <CustomDialog
      type='base'
      maxWidth='md'
      open={isOpen}
      keepMounted={false}
      DialogTitleProps={{
        onClose: handleToggle,
        title: label,
      }}
      DialogActionsProps={{ children: footerComponent || <Button onClick={handleToggle}>{t("Done")}</Button> }}
    >
      <Box display='flex' justifyContent='space-between'>
        <MedicalFormInfo title={t("Fulfillment Date")} subTitle={moment(date).format("DD MMM YYYY")} />
        <MedicalFormInfo title={t("Questions")} subTitle={numberOfQuestions || 0} />
        <MedicalFormInfo isRow title={t("Status")} subTitle={classificationName || "-"} />
      </Box>
      <Box>
        {questionAnswers?.length ? (
          questionAnswers?.map(item => (
            <Fragment key={item?.id}>
              <Box
                my={1}
                sx={{
                  borderRadius: 1,
                  background: theme.palette.background.default,
                }}
              >
                <Typography
                  fontSize={theme.mixins.fonts.fontSize.md}
                  p={2}
                  fontWeight={theme.mixins.fonts.fontWeight.medium}
                  color={theme.palette.primary.main}
                >
                  {item?.question?.question}
                </Typography>
              </Box>
              {item?.question?.questionType === QuestionType.Boolean && (
                <RadioGroup defaultValue={item?.booleanAnswer}>
                  {booleanOptions?.map(option => (
                    <FormControlLabel
                      disabled
                      value={option.value}
                      key={option?.name}
                      control={<Radio />}
                      label={<Box sx={{ color: theme.palette.primary.main }}>{option.name}</Box>}
                    />
                  ))}
                </RadioGroup>
              )}
              {item?.question?.questionType === QuestionType.SingleChoice && (
                <RadioGroup defaultValue={item?.singleChoiceAnswer}>
                  {item?.question?.options?.map(option => (
                    <FormControlLabel
                      disabled
                      value={option}
                      key={option}
                      control={<Radio />}
                      label={<Box sx={{ color: theme.palette.primary.main }}>{option}</Box>}
                    />
                  ))}
                </RadioGroup>
              )}
              {item?.question?.questionType === QuestionType.MultiChoice && (
                <FormGroup>
                  {item?.question?.options?.map(option => {
                    const checked = item?.multipleChoiceAnswer?.includes(option);
                    return (
                      <FormControlLabel
                        disabled
                        key={option}
                        control={<Checkbox defaultChecked={checked} checked={checked} />}
                        label={option!}
                      />
                    );
                  })}
                </FormGroup>
              )}
              {item?.question?.questionType === QuestionType.String && <Typography>{item?.stringAnswer}</Typography>}
            </Fragment>
          ))
        ) : (
          <Typography>{t("No data available")}</Typography>
        )}
      </Box>
    </CustomDialog>
  );
};
