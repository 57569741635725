import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
export type PatientProfileFragmentFragment = { __typename?: 'Patient', id: string, firstName?: string | null, lastName?: string | null, gender?: string | null, dateOfBirth?: any | null, contactNumber?: string | null, nationality?: string | null, activeHealthProgramMembers?: Array<{ __typename?: 'HealthProgramMember', id: string, nationalId?: string | null, isDefault?: boolean | null, payer?: { __typename?: 'Payer', id: string, name: string } | null } | null> | null, user?: { __typename?: 'User', id: string, photo?: string | null, preferredLanguage?: { __typename?: 'Language', code: string, display: string, displayAr?: string | null, id: string } | null } | null, bodyComposition?: { __typename?: 'HealthProgramMemberBodyCompositionBasic', bmi?: number | null, sleep?: number | null, smoking?: number | null, weightInKG?: number | null, heightInCM?: number | null, exercise?: boolean | null, dietType?: Types.DietType | null, alcohol?: boolean | null } | null, bodyCompositions?: { __typename?: 'HealthProgramMemberBodyCompositionCountableConnection', totalCount?: number | null, edges: Array<{ __typename?: 'HealthProgramMemberBodyCompositionCountableEdge', node: { __typename?: 'HealthProgramMemberBodyComposition', modified?: any | null, bmi?: number | null, sleep?: number | null, smoking?: number | null, weightInKG?: number | null, heightInCM?: number | null, exercise?: boolean | null, dietType?: Types.DietType | null, alcohol?: boolean | null } }> } | null };

export const PatientProfileFragmentFragmentDoc = gql`
    fragment PatientProfileFragment on Patient {
  id
  firstName
  lastName
  gender
  dateOfBirth
  contactNumber
  nationality
  activeHealthProgramMembers {
    id
    nationalId
    isDefault
    payer {
      id
      name
    }
  }
  user {
    id
    photo
    preferredLanguage {
      code
      display
      displayAr
      id
    }
  }
  bodyComposition {
    bmi
    sleep
    smoking
    weightInKG
    heightInCM
    exercise
    dietType
    alcohol
  }
  bodyCompositions(first: 20) {
    totalCount
    edges {
      node {
        modified
        bmi
        sleep
        smoking
        weightInKG
        heightInCM
        exercise
        dietType
        alcohol
      }
    }
  }
}
    `;