import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProviderSettingsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ProviderSettingsQuery = { __typename?: 'Query', providerSettings?: { __typename?: 'ProviderSettingsCountableConnection', edges: Array<{ __typename?: 'ProviderSettingsCountableEdge', node: { __typename?: 'ProviderSettings', outPatientJourneySupported?: boolean | null, paymentAfterConsultation?: boolean | null, appointmentReminderTimeoutSeconds?: number | null, appointmentConfirmationTimeoutSeconds?: number | null } }> } | null };


export const ProviderSettingsDocument = gql`
    query ProviderSettings {
  providerSettings {
    edges {
      node {
        outPatientJourneySupported
        paymentAfterConsultation
        appointmentReminderTimeoutSeconds
        appointmentConfirmationTimeoutSeconds
      }
    }
  }
}
    `;

/**
 * __useProviderSettingsQuery__
 *
 * To run a query within a React component, call `useProviderSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProviderSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProviderSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProviderSettingsQuery(baseOptions?: Apollo.QueryHookOptions<ProviderSettingsQuery, ProviderSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProviderSettingsQuery, ProviderSettingsQueryVariables>(ProviderSettingsDocument, options);
      }
export function useProviderSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProviderSettingsQuery, ProviderSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProviderSettingsQuery, ProviderSettingsQueryVariables>(ProviderSettingsDocument, options);
        }
export function useProviderSettingsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ProviderSettingsQuery, ProviderSettingsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProviderSettingsQuery, ProviderSettingsQueryVariables>(ProviderSettingsDocument, options);
        }
export type ProviderSettingsQueryHookResult = ReturnType<typeof useProviderSettingsQuery>;
export type ProviderSettingsLazyQueryHookResult = ReturnType<typeof useProviderSettingsLazyQuery>;
export type ProviderSettingsSuspenseQueryHookResult = ReturnType<typeof useProviderSettingsSuspenseQuery>;
export type ProviderSettingsQueryResult = Apollo.QueryResult<ProviderSettingsQuery, ProviderSettingsQueryVariables>;