import { CodeSystemCode, ServiceDetails } from "@/schema/types";
import { SystemCodeAutocomplete } from "@health/autocompletes";
import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, FormTextField, Grid, IconButton, PlusIcon, RemoveIcon } from "@toolkit/ui";
import { forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle } from "react";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { convertAppointmentServiceDetailsToFormValues, createAppointmentServiceDetailsDefaultValue } from "../../utils";
import {
  appointmentServiceDetailsUpsertFormDefaultValues,
  appointmentServiceDetailsUpsertFormSchema,
  IAppointmentServiceDetailsUpsertFormValues,
} from "./AppointmentServiceDetailsUpsertFormSchema";

export type IAppointmentServiceDetailsUpsertFormEvent = {
  type: "UPDATE";
  payload: {
    appointmentId: string;
    values: IAppointmentServiceDetailsUpsertFormValues;
  };
};

type AppointmentServiceDetailsUpsertFormProps = {
  appointmentId: string;
  serviceDetails: ServiceDetails[];
  onChange: (event: IAppointmentServiceDetailsUpsertFormEvent) => void;
};

export type AppointmentServiceDetailsUpsertFormRef = {
  getForm: () => UseFormReturn<IAppointmentServiceDetailsUpsertFormValues>;
  submit: () => void;
};

const AppointmentServiceDetailsUpsertFormForwardRef: ForwardRefRenderFunction<
  AppointmentServiceDetailsUpsertFormRef,
  AppointmentServiceDetailsUpsertFormProps
> = (props, ref) => {
  const { appointmentId, serviceDetails, onChange } = props;

  const { t } = useTranslation("provider");

  const form = useCustomForm<IAppointmentServiceDetailsUpsertFormValues>({
    defaultValues: appointmentServiceDetailsUpsertFormDefaultValues,
    schema: appointmentServiceDetailsUpsertFormSchema,
  });

  const { watch, handleSubmit, setValue } = form;

  const items = watch("items");

  const onAddServiceClick = () => {
    setValue("items", [...items, createAppointmentServiceDetailsDefaultValue()]);
  };

  const onRemoveIconClick = (selectedIndex: number) => {
    const filteredItems = items.filter((_, index) => index !== selectedIndex);
    setValue("items", filteredItems);
  };

  const onSubmit = (values: IAppointmentServiceDetailsUpsertFormValues) => {
    onChange({
      type: "UPDATE",
      payload: { appointmentId, values },
    });
  };

  useImperativeHandle(ref, () => ({
    getForm: () => form,
    submit: handleSubmit(onSubmit),
  }));

  useEffect(() => {
    if (serviceDetails?.length) {
      const _serviceDetails = convertAppointmentServiceDetailsToFormValues(serviceDetails);
      setValue("items", _serviceDetails);
    } else {
      setValue("items", [createAppointmentServiceDetailsDefaultValue()]);
    }
  }, [serviceDetails]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display={"flex"} justifyContent={"flex-end"} marginBottom={2}>
              <Button startIcon={<PlusIcon />} onClick={onAddServiceClick}>
                {t("Add Service")}
              </Button>
            </Box>
          </Grid>

          {items?.map((item, index) => (
            <Grid key={item?.id} item xs={12}>
              <Grid container spacing={2} alignItems={"center"}>
                <Grid item xs={6}>
                  <SystemCodeAutocomplete
                    name={`items.${index}.service`}
                    label={t("Service")}
                    isCodeHidden
                    filter={{ codeSystemCode: CodeSystemCode.Diagnosis }}
                  />
                </Grid>

                <Grid item xs={5}>
                  <FormTextField name={`items.${index}.standard`} label={t("Standard")} />
                </Grid>

                <Grid item xs={1}>
                  <IconButton disabled={items?.length === 1} onClick={() => onRemoveIconClick(index)}>
                    <RemoveIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </form>
    </FormProvider>
  );
};

export const AppointmentServiceDetailsUpsertForm = forwardRef(AppointmentServiceDetailsUpsertFormForwardRef);
