import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CopyVisitQueryVariables = Types.Exact<{
  visitId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type CopyVisitQuery = { __typename?: 'Query', vitalSigns?: { __typename?: 'HealthParameterConnection', edges?: Array<{ __typename?: 'HealthParameterEdge', node?: { __typename?: 'HealthParameter', code?: string | null, display?: string | null, arabicDisplay?: string | null, chartType?: Types.ChartType | null, unit?: { __typename?: 'FieldUnit', display?: string | null, arabicDisplay?: string | null } | null } | null } | null> | null } | null, visit?: { __typename?: 'Visit', id: string, doctorNote?: string | null, rads?: Array<{ __typename?: 'Rad', id: string, code?: string | null, fileName?: string | null } | null> | null, labs?: Array<{ __typename?: 'Lab', id: string, code?: string | null, result?: string | null, resultReceived?: boolean | null, display?: string | null } | null> | null, vitalSigns?: Array<{ __typename?: 'PatientHealthParameter', id: string, hpCode?: string | null, deviceName?: string | null, source?: Types.Source | null, deviceSerial?: string | null, valueNumber?: any | null } | null> | null, visitDiagnoses?: Array<{ __typename?: 'VisitDiagnosis', id: string, code: string, display?: string | null, visitDiagnosisType: Types.VisitDiagnosisType } | null> | null, visitPrescriptions?: Array<{ __typename?: 'VisitPrescriptionTransaction', id: string, referenceId?: string | null, type?: string | null } | null> | null } | null };


export const CopyVisitDocument = gql`
    query copyVisit($visitId: ID) {
  vitalSigns: getHealthParameters(
    first: 10
    filter: {isActive: true, categoryCode: "vital_signs"}
  ) {
    edges {
      node {
        code
        display
        arabicDisplay
        chartType
        unit {
          display
          arabicDisplay
        }
      }
    }
  }
  visit(id: $visitId) {
    id
    doctorNote
    rads {
      id
      code
      fileName
    }
    labs {
      id
      code
      result
      resultReceived
      display
    }
    vitalSigns {
      id
      hpCode
      deviceName
      source
      deviceSerial
      valueNumber
    }
    visitDiagnoses {
      id
      code
      display
      visitDiagnosisType
    }
    visitPrescriptions {
      id
      referenceId
      type
    }
  }
}
    `;

/**
 * __useCopyVisitQuery__
 *
 * To run a query within a React component, call `useCopyVisitQuery` and pass it any options that fit your needs.
 * When your component renders, `useCopyVisitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCopyVisitQuery({
 *   variables: {
 *      visitId: // value for 'visitId'
 *   },
 * });
 */
export function useCopyVisitQuery(baseOptions?: Apollo.QueryHookOptions<CopyVisitQuery, CopyVisitQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CopyVisitQuery, CopyVisitQueryVariables>(CopyVisitDocument, options);
      }
export function useCopyVisitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CopyVisitQuery, CopyVisitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CopyVisitQuery, CopyVisitQueryVariables>(CopyVisitDocument, options);
        }
export function useCopyVisitSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CopyVisitQuery, CopyVisitQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CopyVisitQuery, CopyVisitQueryVariables>(CopyVisitDocument, options);
        }
export type CopyVisitQueryHookResult = ReturnType<typeof useCopyVisitQuery>;
export type CopyVisitLazyQueryHookResult = ReturnType<typeof useCopyVisitLazyQuery>;
export type CopyVisitSuspenseQueryHookResult = ReturnType<typeof useCopyVisitSuspenseQuery>;
export type CopyVisitQueryResult = Apollo.QueryResult<CopyVisitQuery, CopyVisitQueryVariables>;