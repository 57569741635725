import { Typography, useTheme } from "@toolkit/ui";
import { FC } from "react";

type OptimaValidationInfoHeaderProps = {
  title: string;
};

export const OptimaValidationInfoHeader: FC<OptimaValidationInfoHeaderProps> = props => {
  const { title } = props;
  const theme = useTheme();

  return (
    <Typography
      my={3}
      fontSize={theme.mixins.fonts.fontSize.xl}
      fontWeight={theme.mixins.fonts.fontWeight.medium}
      sx={{
        backgroundColor: theme.palette.gray.light,
        padding: 1,
        borderRadius: 1,
      }}
    >
      {title}
    </Typography>
  );
};
