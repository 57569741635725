import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OptimaValidationRequestsListQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.OptimaValidationRequestFilterInput>;
  sortBy?: Types.InputMaybe<Types.OptimaValidationRequestsSortingInput>;
}>;


export type OptimaValidationRequestsListQuery = { __typename?: 'Query', optimaValidationRequests?: { __typename?: 'OptimaValidationRequestsConnection', edges: Array<{ __typename?: 'OptimaValidationRequestsEdge', node: { __typename?: 'OptimaValidationRequest', id: string, visitId?: string | null, patientId?: string | null, response?: string | null, request?: string | null, edits?: Array<{ __typename?: 'OptimaEdit', id: string } | null> | null, items?: Array<{ __typename?: 'OptimaItem', id: string, percentageOfAcceptance?: number | null, edits?: Array<{ __typename?: 'OptimaItemEdit', id: string } | null> | null } | null> | null } }> } | null };


export const OptimaValidationRequestsListDocument = gql`
    query OptimaValidationRequestsList($first: Int, $last: Int, $after: String, $before: String, $filter: OptimaValidationRequestFilterInput, $sortBy: OptimaValidationRequestsSortingInput) {
  optimaValidationRequests(
    first: $first
    last: $last
    after: $after
    before: $before
    filter: $filter
    sortBy: $sortBy
  ) {
    edges {
      node {
        id
        visitId
        patientId
        edits {
          id
        }
        items {
          id
          percentageOfAcceptance
          edits {
            id
          }
        }
        response
        request
      }
    }
  }
}
    `;

/**
 * __useOptimaValidationRequestsListQuery__
 *
 * To run a query within a React component, call `useOptimaValidationRequestsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOptimaValidationRequestsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOptimaValidationRequestsListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useOptimaValidationRequestsListQuery(baseOptions?: Apollo.QueryHookOptions<OptimaValidationRequestsListQuery, OptimaValidationRequestsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OptimaValidationRequestsListQuery, OptimaValidationRequestsListQueryVariables>(OptimaValidationRequestsListDocument, options);
      }
export function useOptimaValidationRequestsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OptimaValidationRequestsListQuery, OptimaValidationRequestsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OptimaValidationRequestsListQuery, OptimaValidationRequestsListQueryVariables>(OptimaValidationRequestsListDocument, options);
        }
export function useOptimaValidationRequestsListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OptimaValidationRequestsListQuery, OptimaValidationRequestsListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OptimaValidationRequestsListQuery, OptimaValidationRequestsListQueryVariables>(OptimaValidationRequestsListDocument, options);
        }
export type OptimaValidationRequestsListQueryHookResult = ReturnType<typeof useOptimaValidationRequestsListQuery>;
export type OptimaValidationRequestsListLazyQueryHookResult = ReturnType<typeof useOptimaValidationRequestsListLazyQuery>;
export type OptimaValidationRequestsListSuspenseQueryHookResult = ReturnType<typeof useOptimaValidationRequestsListSuspenseQuery>;
export type OptimaValidationRequestsListQueryResult = Apollo.QueryResult<OptimaValidationRequestsListQuery, OptimaValidationRequestsListQueryVariables>;