import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VisitCallEndByDoctorMutationVariables = Types.Exact<{
  visitId: Types.Scalars['ID']['input'];
}>;


export type VisitCallEndByDoctorMutation = { __typename?: 'Mutation', visitCallEnd?: { __typename?: 'VisitCallEnd', success?: boolean | null, visitErrors?: Array<{ __typename?: 'H_EntityError', message?: string | null, field?: string | null, code: Types.H_EntityErrorCode }> | null } | null };


export const VisitCallEndByDoctorDocument = gql`
    mutation visitCallEndByDoctor($visitId: ID!) {
  visitCallEnd(visitId: $visitId) {
    success
    visitErrors {
      message
      field
      code
    }
  }
}
    `;
export type VisitCallEndByDoctorMutationFn = Apollo.MutationFunction<VisitCallEndByDoctorMutation, VisitCallEndByDoctorMutationVariables>;

/**
 * __useVisitCallEndByDoctorMutation__
 *
 * To run a mutation, you first call `useVisitCallEndByDoctorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVisitCallEndByDoctorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [visitCallEndByDoctorMutation, { data, loading, error }] = useVisitCallEndByDoctorMutation({
 *   variables: {
 *      visitId: // value for 'visitId'
 *   },
 * });
 */
export function useVisitCallEndByDoctorMutation(baseOptions?: Apollo.MutationHookOptions<VisitCallEndByDoctorMutation, VisitCallEndByDoctorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VisitCallEndByDoctorMutation, VisitCallEndByDoctorMutationVariables>(VisitCallEndByDoctorDocument, options);
      }
export type VisitCallEndByDoctorMutationHookResult = ReturnType<typeof useVisitCallEndByDoctorMutation>;
export type VisitCallEndByDoctorMutationResult = Apollo.MutationResult<VisitCallEndByDoctorMutation>;
export type VisitCallEndByDoctorMutationOptions = Apollo.BaseMutationOptions<VisitCallEndByDoctorMutation, VisitCallEndByDoctorMutationVariables>;