import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EvaluatePatientHealthConditionsMutationVariables = Types.Exact<{
  patientId: Types.Scalars['ID']['input'];
}>;


export type EvaluatePatientHealthConditionsMutation = { __typename?: 'Mutation', evaluatePatientHealthConditions?: Array<{ __typename?: 'PatientEvaluatedHealthCondition', chronicDiseaseCode?: string | null, evaluatedHealthCondition?: { __typename?: 'HealthCondition', id?: string | null, code?: string | null, type?: Types.HealthConditionType | null, display?: string | null, isActive?: boolean | null, fields?: Array<{ __typename?: 'HealthParameter', id?: string | null, type?: Types.TemplateFieldType | null, code?: string | null, display?: string | null, chartType?: Types.ChartType | null, referenceRange?: string | null, maxNormalRangeValue?: number | null, minNormalRangeValue?: number | null, visibleForPatientOverview?: boolean | null, unit?: { __typename?: 'FieldUnit', id?: string | null, display?: string | null } | null } | null> | null } | null } | null> | null };


export const EvaluatePatientHealthConditionsDocument = gql`
    mutation EvaluatePatientHealthConditions($patientId: ID!) {
  evaluatePatientHealthConditions(patientId: $patientId) {
    chronicDiseaseCode
    evaluatedHealthCondition {
      id
      code
      type
      display
      isActive
      fields {
        id
        type
        code
        display
        chartType
        unit {
          id
          display
        }
        referenceRange
        maxNormalRangeValue
        minNormalRangeValue
        visibleForPatientOverview
      }
    }
  }
}
    `;
export type EvaluatePatientHealthConditionsMutationFn = Apollo.MutationFunction<EvaluatePatientHealthConditionsMutation, EvaluatePatientHealthConditionsMutationVariables>;

/**
 * __useEvaluatePatientHealthConditionsMutation__
 *
 * To run a mutation, you first call `useEvaluatePatientHealthConditionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEvaluatePatientHealthConditionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [evaluatePatientHealthConditionsMutation, { data, loading, error }] = useEvaluatePatientHealthConditionsMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useEvaluatePatientHealthConditionsMutation(baseOptions?: Apollo.MutationHookOptions<EvaluatePatientHealthConditionsMutation, EvaluatePatientHealthConditionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EvaluatePatientHealthConditionsMutation, EvaluatePatientHealthConditionsMutationVariables>(EvaluatePatientHealthConditionsDocument, options);
      }
export type EvaluatePatientHealthConditionsMutationHookResult = ReturnType<typeof useEvaluatePatientHealthConditionsMutation>;
export type EvaluatePatientHealthConditionsMutationResult = Apollo.MutationResult<EvaluatePatientHealthConditionsMutation>;
export type EvaluatePatientHealthConditionsMutationOptions = Apollo.BaseMutationOptions<EvaluatePatientHealthConditionsMutation, EvaluatePatientHealthConditionsMutationVariables>;