import { GuidedCareActivityType, TemplateInterventionActivityEventItem } from "@/schema/types";

export type IPatientGuidedCareProgram = {
  id: string;
  name: string;
};

export type ITemplateInterventionActivityItem = TemplateInterventionActivityEventItem & {
  templateActivityType: GuidedCareActivityType;
  isDeleted: boolean;
};

export enum GuidedCareJourneyInterventionsActivitiesStep {
  INTERVENTIONS = 0,
  ACTIVITIES = 1,
}
