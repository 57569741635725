import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetDoctorAppointmentRejectionReasonsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetDoctorAppointmentRejectionReasonsQuery = { __typename?: 'Query', rejectionReasons?: Array<{ __typename?: 'H_RejectionReason', id: string, text?: string | null, text_ar?: string | null } | null> | null };


export const GetDoctorAppointmentRejectionReasonsDocument = gql`
    query getDoctorAppointmentRejectionReasons {
  rejectionReasons(filter: {actor: DOCTOR, affectedType: APPOINTMENT}) {
    id
    text
    text_ar
  }
}
    `;

/**
 * __useGetDoctorAppointmentRejectionReasonsQuery__
 *
 * To run a query within a React component, call `useGetDoctorAppointmentRejectionReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDoctorAppointmentRejectionReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDoctorAppointmentRejectionReasonsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDoctorAppointmentRejectionReasonsQuery(baseOptions?: Apollo.QueryHookOptions<GetDoctorAppointmentRejectionReasonsQuery, GetDoctorAppointmentRejectionReasonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDoctorAppointmentRejectionReasonsQuery, GetDoctorAppointmentRejectionReasonsQueryVariables>(GetDoctorAppointmentRejectionReasonsDocument, options);
      }
export function useGetDoctorAppointmentRejectionReasonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDoctorAppointmentRejectionReasonsQuery, GetDoctorAppointmentRejectionReasonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDoctorAppointmentRejectionReasonsQuery, GetDoctorAppointmentRejectionReasonsQueryVariables>(GetDoctorAppointmentRejectionReasonsDocument, options);
        }
export function useGetDoctorAppointmentRejectionReasonsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetDoctorAppointmentRejectionReasonsQuery, GetDoctorAppointmentRejectionReasonsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDoctorAppointmentRejectionReasonsQuery, GetDoctorAppointmentRejectionReasonsQueryVariables>(GetDoctorAppointmentRejectionReasonsDocument, options);
        }
export type GetDoctorAppointmentRejectionReasonsQueryHookResult = ReturnType<typeof useGetDoctorAppointmentRejectionReasonsQuery>;
export type GetDoctorAppointmentRejectionReasonsLazyQueryHookResult = ReturnType<typeof useGetDoctorAppointmentRejectionReasonsLazyQuery>;
export type GetDoctorAppointmentRejectionReasonsSuspenseQueryHookResult = ReturnType<typeof useGetDoctorAppointmentRejectionReasonsSuspenseQuery>;
export type GetDoctorAppointmentRejectionReasonsQueryResult = Apollo.QueryResult<GetDoctorAppointmentRejectionReasonsQuery, GetDoctorAppointmentRejectionReasonsQueryVariables>;