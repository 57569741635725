import { useTranslation } from "@toolkit/i18n";
export const ChatTitle = ({ value }) => {
    var _a, _b;
    const { t } = useTranslation();
    switch (value === null || value === void 0 ? void 0 : value.__typename) {
        case "ChatGroup":
            return (_a = value.name) !== null && _a !== void 0 ? _a : t("Chat Group");
        case "AnonymousChatGroup":
            return (_b = value.title) !== null && _b !== void 0 ? _b : t("Anonymous Chat Group");
        default:
            return null;
    }
};
