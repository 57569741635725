import { FC } from "react";
import { useChatMessagesStyles } from "./ChatMessages.styles";
import { Box, Card, Divider, EmptyCard, IconButton, MuiArrowBackIcon, Toolbar, Typography } from "@toolkit/ui";
import { ChatAvatar, ChatInput, ChatList, ChatStatus, ChatTitle } from "@health/chat";
import { useTranslation } from "@toolkit/i18n";
import { ChatGroup } from "@/schema/types";

type ChatMessagesProps = {
  onClose: () => void;
  chatGroup: ChatGroup | undefined;
};

export const ChatMessages: FC<ChatMessagesProps> = ({ chatGroup, onClose }) => {
  const { t } = useTranslation("provider");
  const { classes } = useChatMessagesStyles();

  if (!chatGroup) {
    return (
      <Card className={classes.root}>
        <Box height={"100%"} display='flex' alignItems='center'>
          <EmptyCard title={t("No conversation selected")} message={t("Please select a conversation to start chatting")} />
        </Box>
      </Card>
    );
  }

  return (
    <Card className={classes.root}>
      {chatGroup && (
        <Toolbar className={classes.header}>
          <IconButton sx={{ mr: 2 }} size='small' onClick={onClose}>
            <MuiArrowBackIcon />
          </IconButton>
          <Box className={classes.headerText}>
            <Box sx={{ mr: 2 }}>
              <ChatAvatar value={chatGroup} size={40} />
            </Box>
            <Box sx={{ flex: 1, minWidth: 0 }}>
              <Typography className={classes.title}>
                <ChatTitle value={chatGroup} />
              </Typography>
              <Typography className={classes.subtitle} variant='body2'>
                <ChatStatus value={chatGroup} />
              </Typography>
            </Box>
          </Box>
        </Toolbar>
      )}
      <Divider />
      <ChatList id={chatGroup.id} />
      <ChatInput id={chatGroup.id} />
    </Card>
  );
};
