import { useCustomForm, useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Button, CircularProgress, CustomDialog, Grid, Tooltip } from "@toolkit/ui";
import { FC, Fragment } from "react";
import { FormProvider } from "react-hook-form";
import { useOrderActionStyles } from "../../actions/OrderAction.styles";
import { MedicationsMappingDialogContentWrapper } from "../../cards/MedicationsMappingDialogContentWrapper";
import { MedicationsMappingDialogProps } from "../../cards/types";
import { useGetTradeDrugQuantityQuery } from "../../gql";
import { AcceptOrderForm } from "../ErxOrderAccept/AcceptOrderForm";
import { AcceptOrderFormDefaultValues, AcceptOrderFormSchema, IAcceptOrderFormValues } from "./AcceptOrderFormSchema";

export const OrdereRxAcceptDialog: FC<MedicationsMappingDialogProps> = ({
  isLoading,
  medications,
  prescriptionId,
  onSubmit: handleAccept,
}) => {
  const { classes } = useOrderActionStyles();
  const { t } = useTranslation("vendor");
  const { open, handleToggle } = useOpenState();

  const { data, loading } = useGetTradeDrugQuantityQuery({
    fetchPolicy: "no-cache",
    variables: {
      prescriptionId: prescriptionId!,
    },
  });

  const tradeDrugs = data?.getTradeDrugQuantity?.map(item => {
    const medication = medications?.find(medication => medication?.code === item?.code);
    return {
      ...item,
      id: medication?.id,
      display: medication?.display,
      tradeDrugs: item?.tradeDrugs,
    };
  });

  const form = useCustomForm<IAcceptOrderFormValues>({
    defaultValues: AcceptOrderFormDefaultValues,
    schema: AcceptOrderFormSchema,
    mode: "onChange",
  });

  const { watch, handleSubmit } = form;

  const acceptedMedications =
    watch("acceptedMedications")
      ?.filter(item => item && item.tradeCode && item.quantity !== undefined)
      ?.map(item => ({ ...item, quantity: Number(item.quantity), tradeCode: item?.tradeCode })) || [];

  const hasInvalidQuantity = acceptedMedications.find(currentItem => currentItem?.quantity < 0 || Number(currentItem.quantity) === 0);

  const handleConfirm = () => {
    handleToggle();
    handleAccept(acceptedMedications);
  };

  const acceptButtonLabel = t("View & Accept");

  return (
    <FormProvider {...form}>
      <form>
        <CustomDialog
          type='base'
          open={open}
          button={
            <Tooltip title={tradeDrugs?.length ? acceptButtonLabel : t("No medications to accept")}>
              <Button
                className={classes.accept}
                variant={acceptButtonLabel ? "contained" : "outlined"}
                disabled={!tradeDrugs?.length}
                onClick={handleToggle}
              >
                {acceptButtonLabel}
              </Button>
            </Tooltip>
          }
          DialogTitleProps={{
            title: t("Medications"),
            onClose: handleToggle,
          }}
          onClose={handleToggle}
          DialogActionsProps={{
            children: (
              <Button
                type='submit'
                startIcon={isLoading && <CircularProgress size={12} />}
                disabled={Boolean(!acceptedMedications?.length) || Boolean(hasInvalidQuantity) || isLoading}
                onClick={handleSubmit(handleConfirm)}
              >
                {t("Confirm")}
              </Button>
            ),
          }}
        >
          <MedicationsMappingDialogContentWrapper loading={loading} isEmpty={!tradeDrugs?.length} errors=''>
            <Grid container spacing={2} justifyContent='center'>
              {Boolean(tradeDrugs?.length) &&
                tradeDrugs?.map((drug, index) => {
                  return (
                    <Fragment key={drug?.id}>
                      <AcceptOrderForm index={index} id={drug?.id!} genericName={drug?.display!} tradeDrugs={drug?.tradeDrugs!} />
                    </Fragment>
                  );
                })}
            </Grid>
          </MedicationsMappingDialogContentWrapper>
        </CustomDialog>
      </form>
    </FormProvider>
  );
};
