import { GenericDrugProperties, Maybe } from "@/schema/types";
import { useTheme } from "@toolkit/ui";

export const useDrugIdentification = () => {
  const { palette } = useTheme();
  const getGenericDrugProperties = (genericDrugProperties: Maybe<GenericDrugProperties> | undefined) =>
    genericDrugProperties ? Object.keys(genericDrugProperties).find(i => genericDrugProperties[i] === true) : "";

  const handleMedication = (medication: string | undefined) => {
    switch (medication) {
      case "isLASA":
        return { color: palette.primary.main, weight: "bold" };
      case "isHighAlert":
        return { color: palette.warning.main, weight: "inherit" };
      case "isHazardous":
        return { color: palette.yellow.main, weight: "inherit" };
      default:
        return { color: palette.primary.main, weight: "inherit" };
    }
  };

  return {
    getGenericDrugProperties,
    handleMedication,
  };
};
