import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WaitingListAppointmentsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.VisitFilterInput>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  sortBy?: Types.InputMaybe<Types.VisitSortingInput>;
}>;


export type WaitingListAppointmentsQuery = { __typename?: 'Query', visits?: { __typename?: 'VisitCountableConnection', edges: Array<{ __typename?: 'VisitCountableEdge', node: { __typename?: 'Visit', id: string, consumerJoined?: boolean | null, consumerJoinedDate?: any | null, consumerLeft?: boolean | null, doctorJoined?: boolean | null, doctorJoinedDate?: any | null, doctorLeft?: boolean | null, type?: Types.VisitType | null, status?: Types.VisitStatus | null, callId?: any | null, chatId?: any | null, appointment?: { __typename?: 'Appointment', id: string, status?: Types.AppointmentStatus | null, requestedTimeSlots?: Array<string | null> | null } | null, doctor?: { __typename?: 'Doctor', id: string, appointmentSlotTimePeriod: number, user?: { __typename?: 'User', fullName?: string | null, id: string, photo?: string | null } | null } | null, patientUser?: { __typename?: 'User', fullName?: string | null, id: string } | null } }>, pageInfo: { __typename?: 'H_PageInfo', startCursor?: string | null, hasPreviousPage: boolean, hasNextPage: boolean, endCursor?: string | null } } | null };


export const WaitingListAppointmentsDocument = gql`
    query waitingListAppointments($filter: VisitFilterInput, $after: String, $before: String, $first: Int, $last: Int, $sortBy: VisitSortingInput) {
  visits(
    filter: $filter
    after: $after
    before: $before
    first: $first
    last: $last
    sortBy: $sortBy
  ) {
    edges {
      node {
        id
        consumerJoined
        consumerJoinedDate
        consumerLeft
        doctorJoined
        doctorJoinedDate
        doctorLeft
        type
        appointment {
          id
          status
          requestedTimeSlots
        }
        doctor {
          id
          user {
            fullName
            id
            photo
          }
          appointmentSlotTimePeriod
        }
        patientUser {
          fullName
          id
        }
        status
        callId
        chatId
      }
    }
    pageInfo {
      startCursor
      hasPreviousPage
      hasNextPage
      endCursor
    }
  }
}
    `;

/**
 * __useWaitingListAppointmentsQuery__
 *
 * To run a query within a React component, call `useWaitingListAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWaitingListAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWaitingListAppointmentsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useWaitingListAppointmentsQuery(baseOptions?: Apollo.QueryHookOptions<WaitingListAppointmentsQuery, WaitingListAppointmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WaitingListAppointmentsQuery, WaitingListAppointmentsQueryVariables>(WaitingListAppointmentsDocument, options);
      }
export function useWaitingListAppointmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WaitingListAppointmentsQuery, WaitingListAppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WaitingListAppointmentsQuery, WaitingListAppointmentsQueryVariables>(WaitingListAppointmentsDocument, options);
        }
export function useWaitingListAppointmentsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<WaitingListAppointmentsQuery, WaitingListAppointmentsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WaitingListAppointmentsQuery, WaitingListAppointmentsQueryVariables>(WaitingListAppointmentsDocument, options);
        }
export type WaitingListAppointmentsQueryHookResult = ReturnType<typeof useWaitingListAppointmentsQuery>;
export type WaitingListAppointmentsLazyQueryHookResult = ReturnType<typeof useWaitingListAppointmentsLazyQuery>;
export type WaitingListAppointmentsSuspenseQueryHookResult = ReturnType<typeof useWaitingListAppointmentsSuspenseQuery>;
export type WaitingListAppointmentsQueryResult = Apollo.QueryResult<WaitingListAppointmentsQuery, WaitingListAppointmentsQueryVariables>;