import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GuidedCareTemplateInterventionPatientHealthParameterListQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.PatientHealthParameterFilterInput>;
  sortBy?: Types.InputMaybe<Types.PatientHealthParameterSortingInput>;
}>;


export type GuidedCareTemplateInterventionPatientHealthParameterListQuery = { __typename?: 'Query', patientHealthParameters?: { __typename?: 'PatientHealthParameterCountableConnection', pageInfo: { __typename?: 'H_PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'PatientHealthParameterCountableEdge', node: { __typename?: 'PatientHealthParameter', id: string, valueNumber?: any | null } }> } | null };


export const GuidedCareTemplateInterventionPatientHealthParameterListDocument = gql`
    query GuidedCareTemplateInterventionPatientHealthParameterList($first: Int, $last: Int, $before: String, $after: String, $filter: PatientHealthParameterFilterInput, $sortBy: PatientHealthParameterSortingInput) {
  patientHealthParameters(
    first: $first
    last: $last
    before: $before
    after: $after
    filter: $filter
    sortBy: $sortBy
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        valueNumber
      }
    }
  }
}
    `;

/**
 * __useGuidedCareTemplateInterventionPatientHealthParameterListQuery__
 *
 * To run a query within a React component, call `useGuidedCareTemplateInterventionPatientHealthParameterListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuidedCareTemplateInterventionPatientHealthParameterListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuidedCareTemplateInterventionPatientHealthParameterListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useGuidedCareTemplateInterventionPatientHealthParameterListQuery(baseOptions?: Apollo.QueryHookOptions<GuidedCareTemplateInterventionPatientHealthParameterListQuery, GuidedCareTemplateInterventionPatientHealthParameterListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GuidedCareTemplateInterventionPatientHealthParameterListQuery, GuidedCareTemplateInterventionPatientHealthParameterListQueryVariables>(GuidedCareTemplateInterventionPatientHealthParameterListDocument, options);
      }
export function useGuidedCareTemplateInterventionPatientHealthParameterListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GuidedCareTemplateInterventionPatientHealthParameterListQuery, GuidedCareTemplateInterventionPatientHealthParameterListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GuidedCareTemplateInterventionPatientHealthParameterListQuery, GuidedCareTemplateInterventionPatientHealthParameterListQueryVariables>(GuidedCareTemplateInterventionPatientHealthParameterListDocument, options);
        }
export function useGuidedCareTemplateInterventionPatientHealthParameterListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GuidedCareTemplateInterventionPatientHealthParameterListQuery, GuidedCareTemplateInterventionPatientHealthParameterListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GuidedCareTemplateInterventionPatientHealthParameterListQuery, GuidedCareTemplateInterventionPatientHealthParameterListQueryVariables>(GuidedCareTemplateInterventionPatientHealthParameterListDocument, options);
        }
export type GuidedCareTemplateInterventionPatientHealthParameterListQueryHookResult = ReturnType<typeof useGuidedCareTemplateInterventionPatientHealthParameterListQuery>;
export type GuidedCareTemplateInterventionPatientHealthParameterListLazyQueryHookResult = ReturnType<typeof useGuidedCareTemplateInterventionPatientHealthParameterListLazyQuery>;
export type GuidedCareTemplateInterventionPatientHealthParameterListSuspenseQueryHookResult = ReturnType<typeof useGuidedCareTemplateInterventionPatientHealthParameterListSuspenseQuery>;
export type GuidedCareTemplateInterventionPatientHealthParameterListQueryResult = Apollo.QueryResult<GuidedCareTemplateInterventionPatientHealthParameterListQuery, GuidedCareTemplateInterventionPatientHealthParameterListQueryVariables>;