import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VisitSummaryUpdateMutationVariables = Types.Exact<{
  input: Types.VisitSummaryInput;
  id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type VisitSummaryUpdateMutation = { __typename?: 'Mutation', visitSummaryUpdate?: { __typename?: 'VisitSummaryCRUD', visitSummary?: { __typename?: 'VisitSummary', doctorNotes?: string | null, isProblemSolved?: boolean | null, visit?: { __typename?: 'Visit', id: string, status?: Types.VisitStatus | null } | null } | null, visitSummaryErrors?: Array<{ __typename?: 'H_EntityError', code: Types.H_EntityErrorCode, field?: string | null, message?: string | null }> | null } | null };


export const VisitSummaryUpdateDocument = gql`
    mutation visitSummaryUpdate($input: VisitSummaryInput!, $id: ID) {
  visitSummaryUpdate(input: $input, id: $id) {
    visitSummary {
      doctorNotes
      visit {
        id
        status
      }
      doctorNotes
      isProblemSolved
    }
    visitSummaryErrors {
      code
      field
      message
    }
  }
}
    `;
export type VisitSummaryUpdateMutationFn = Apollo.MutationFunction<VisitSummaryUpdateMutation, VisitSummaryUpdateMutationVariables>;

/**
 * __useVisitSummaryUpdateMutation__
 *
 * To run a mutation, you first call `useVisitSummaryUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVisitSummaryUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [visitSummaryUpdateMutation, { data, loading, error }] = useVisitSummaryUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVisitSummaryUpdateMutation(baseOptions?: Apollo.MutationHookOptions<VisitSummaryUpdateMutation, VisitSummaryUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VisitSummaryUpdateMutation, VisitSummaryUpdateMutationVariables>(VisitSummaryUpdateDocument, options);
      }
export type VisitSummaryUpdateMutationHookResult = ReturnType<typeof useVisitSummaryUpdateMutation>;
export type VisitSummaryUpdateMutationResult = Apollo.MutationResult<VisitSummaryUpdateMutation>;
export type VisitSummaryUpdateMutationOptions = Apollo.BaseMutationOptions<VisitSummaryUpdateMutation, VisitSummaryUpdateMutationVariables>;