import { AppointmentStatus, Maybe } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import {
  Button,
  CustomDialog,
  CustomIcon,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  useTheme,
} from "@toolkit/ui";
import React, { FC, ReactElement } from "react";
import { NotificationItemAction } from "shared/components";
import { getReasonText } from "utils/reasons";
import { useAppointmentCancel } from "./useAppointmentCancel";

type AppointmentCancelProps = {
  id: string;
  status?: Maybe<AppointmentStatus>;
  buttonEl?: ReactElement;
} & NotificationItemAction;

export const AppointmentCancel: FC<AppointmentCancelProps> = props => {
  const { id, status, buttonEl, handleNotificationActionComplete } = props;

  const { t } = useTranslation("provider");

  const { state, isOpen, reasons, disabled, title, handleSubmit, handleChangeOtherReason, handleChangeReason, handleToggle } =
    useAppointmentCancel({ id, status, handleNotificationActionComplete });
  const theme = useTheme();
  return (
    <CustomDialog
      type='base'
      open={isOpen}
      DialogTitleProps={{
        title: t("Cancel Appointment"),
        onClose: handleToggle,
      }}
      button={
        <Tooltip title={title}>
          <span>
            {buttonEl ? (
              React.cloneElement(buttonEl, { onMouseDown: handleToggle, disabled: title !== "" })
            ) : (
              <IconButton disabled={title !== ""} onClick={handleToggle}>
                <CustomIcon icon='miniClose' height={30} width={30} viewBox='0 5 20 10' color={theme.palette.error.main} />
              </IconButton>
            )}
          </span>
        </Tooltip>
      }
      DialogActionsProps={{
        children: (
          <Button color='error' disabled={disabled} onClick={handleSubmit}>
            {t("Cancel")}
          </Button>
        ),
      }}
    >
      <RadioGroup onChange={handleChangeReason}>
        {reasons?.map(item => (
          <FormControlLabel
            key={item?.id}
            value={item?.text}
            control={<Radio />}
            label={getReasonText(item!) || ""}
            sx={{ fontSize: theme.mixins.fonts.fontSize.sm }}
          />
        ))}
      </RadioGroup>

      {state.isOther && (
        <TextField
          label={t("Other Reason")}
          placeholder={t("Other Reason")}
          multiline
          minRows={3}
          fullWidth
          value={state.other}
          onChange={handleChangeOtherReason}
        />
      )}
    </CustomDialog>
  );
};
