import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetOrderAddressQueryVariables = Types.Exact<{
  orderId: Types.Scalars['ID']['input'];
}>;


export type GetOrderAddressQuery = { __typename?: 'Query', marketplaceOrder?: { __typename?: 'MarketplaceOrder', id: string, address?: { __typename?: 'MarketplaceAddress', area?: string | null, areaAr?: string | null, city?: string | null, cityAr?: string | null, streetAddress1?: string | null, streetAddress1Ar?: string | null, streetAddress2?: string | null, streetAddress2Ar?: string | null, buildingNumber?: string | null } | null } | null };


export const GetOrderAddressDocument = gql`
    query getOrderAddress($orderId: ID!) {
  marketplaceOrder(id: $orderId) {
    id
    address {
      area
      areaAr
      city
      cityAr
      streetAddress1
      streetAddress1Ar
      streetAddress2
      streetAddress2Ar
      buildingNumber
    }
  }
}
    `;

/**
 * __useGetOrderAddressQuery__
 *
 * To run a query within a React component, call `useGetOrderAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderAddressQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useGetOrderAddressQuery(baseOptions: Apollo.QueryHookOptions<GetOrderAddressQuery, GetOrderAddressQueryVariables> & ({ variables: GetOrderAddressQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrderAddressQuery, GetOrderAddressQueryVariables>(GetOrderAddressDocument, options);
      }
export function useGetOrderAddressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderAddressQuery, GetOrderAddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrderAddressQuery, GetOrderAddressQueryVariables>(GetOrderAddressDocument, options);
        }
export function useGetOrderAddressSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetOrderAddressQuery, GetOrderAddressQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOrderAddressQuery, GetOrderAddressQueryVariables>(GetOrderAddressDocument, options);
        }
export type GetOrderAddressQueryHookResult = ReturnType<typeof useGetOrderAddressQuery>;
export type GetOrderAddressLazyQueryHookResult = ReturnType<typeof useGetOrderAddressLazyQuery>;
export type GetOrderAddressSuspenseQueryHookResult = ReturnType<typeof useGetOrderAddressSuspenseQuery>;
export type GetOrderAddressQueryResult = Apollo.QueryResult<GetOrderAddressQuery, GetOrderAddressQueryVariables>;