import { Order, OrderStatusEnum, SiteSettings } from "@/schema/types";
import { TFunction } from "@toolkit/i18n";
import { statuses } from "../statuses";
import {
  getSharedOrderData,
  mapOrderToDeliveredColumn,
  mapOrderToDispensingColumn,
  mapOrderToNewColumn,
  mapOrderToPendingColumn,
} from "./mapOrderToColumn";
import { shouldShowCancelButton, shouldShowResubmitButton } from "./utils";

export const mapOrderToColumnByStatus = (
  order: Partial<Order>,
  time: Partial<SiteSettings> | undefined,
  t: TFunction,
  defaultCurrency: string
) => {
  const news = mapOrderToNewColumn(order, time, t);
  const pending = mapOrderToPendingColumn(order, time, t);
  const dispensing = mapOrderToDispensingColumn(order, time, t, defaultCurrency);
  const delivered = mapOrderToDeliveredColumn(order, time, t);
  const status = String(order?.status) as OrderStatusEnum;

  if (statuses.new.includes(status)) {
    return news;
  } else if (statuses.pending.includes(status)) {
    return pending;
  } else if (statuses.dispensing.includes(status)) {
    return dispensing;
  } else if (statuses.delivering.includes(status)) {
    return delivered;
  } else return getSharedOrderData(order);
};

export const mapOrderToActionByStatus = (
  order: Partial<Order>,
  time: Partial<SiteSettings> | undefined,
  t: TFunction,
  defaultCurrency: string
) => {
  const news = mapOrderToNewColumn(order, time, t);
  const pending = mapOrderToPendingColumn(order, time, t);
  const dispensing = mapOrderToDispensingColumn(order, time, t, defaultCurrency);
  const delivered = mapOrderToDeliveredColumn(order, time, t);
  const status = String(order?.status) as OrderStatusEnum;
  if (statuses.new.includes(status)) {
    return {
      ...news,
      referenceNumber: order?.prescription?.referenceNumber,
      patientIdentifier: order?.user?.patient?.nationalIdNumber,
      action: {
        hasAcceptAndViewButton: true,
        hasReject: true,
      },
    };
  } else if (statuses.pending.includes(status)) {
    return {
      ...pending,
      patientMobile: order?.user?.patient?.contactNumber,
      patientName: order?.user?.patient?.firstName + " " + order?.user?.patient?.lastName,
      patientIdentifier: order?.user?.patient?.nationalIdNumber,
      action: {
        hasApproval: status === OrderStatusEnum.Allocated,
        hasResubmitButton: status === OrderStatusEnum.ErxHubAuthSubmitTimedOut,
        hasCancelButton: status === OrderStatusEnum.ErxHubAuthSubmitTimedOut,
      },
    };
  } else if (statuses.dispensing.includes(status)) {
    return {
      ...dispensing,
      patientIdentifier: order?.user?.patient?.nationalIdNumber,
      patientName: order?.user?.patient?.firstName + " " + order?.user?.patient?.lastName,
      patientMobile: order?.user?.patient?.contactNumber,
      action: {
        hasCancelButton: shouldShowCancelButton(status),
        hasViewQRButton: status === OrderStatusEnum.ReadyForCustomerPickup,
        hasReadyForDeliveryButton: status === OrderStatusEnum.InProgress,
        hasResubmitButton: shouldShowResubmitButton(status),
        hasDispenseButton: status === OrderStatusEnum.PaymentSucceeded,
        hasDeliveryButton: status === OrderStatusEnum.Dispensed,
      },
    };
  } else if (statuses.delivering.includes(status)) {
    return {
      ...delivered,
      action: {
        hasDeliveryButton: status === OrderStatusEnum.Dispensed,
        hasDeliveredButton: status === OrderStatusEnum.OutForDelivery,
        hasViewQRWithAWBButton:
          status === OrderStatusEnum.Dispensed || status === OrderStatusEnum.OutForDelivery || status === OrderStatusEnum.Delivered,
      },
    };
  } else return getSharedOrderData(order);
};
