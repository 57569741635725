import { Lab } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";

export const useColumns = (): CustomTableColumnProps<Lab>[] => {
  const { t } = useTranslation("provider");

  return useMemo(() => {
    return [
      {
        key: "code",
        header: t("Code"),
        type: "string",
        accessor: "code",
      },
      {
        key: "display",
        header: t("Display"),
        type: "string",
        accessor: "display",
      },
      {
        key: "result",
        header: t("Result"),
        type: "string",
        accessor: ({ result }) => result?.replaceAll(/\{|\}|"/g, ""),
      },
    ];
  }, []);
};
