import { getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { TextField } from "@toolkit/ui";
import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";

export const NumberField: FC<{ name: string; label?: string; min?: number; isRequired?: boolean }> = ({ name, label, isRequired, min }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation("provider");

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: getRequiredValidation(t, !!isRequired) }}
      render={props => {
        const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          const { value } = event.target;
          // eslint-disable-next-line react/prop-types
          props.field.onChange(value ? +value : undefined);
        };

        return (
          <TextField
            // eslint-disable-next-line react/prop-types
            {...props.field}
            onChange={onChange}
            fullWidth
            label={label}
            type='number'
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*", min }}
            error={Boolean(errors[name]?.message)}
            helperText={String(errors[name]?.message)}
          />
        );
      }}
    />
  );
};
