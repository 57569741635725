import { Maybe, ProviderGuidedCareTeamStatus } from "@/schema/types";
import { getProviderGuidedCareTeamStatusColor, providerGuidedCareTeamStatusOptionsMap } from "@health/enum-options";
import { Box, Typography } from "@toolkit/ui";
import { FC } from "react";
import { useGuidedCareTeamStatusStyle } from "./useGuidedCareTeamStatusStyle";

type GuidedCareTeamStatusProps = {
  teamStatus?: Maybe<ProviderGuidedCareTeamStatus>;
};

export const GuidedCareTeamStatus: FC<GuidedCareTeamStatusProps> = props => {
  const { teamStatus } = props;

  const { classes, theme } = useGuidedCareTeamStatusStyle();

  if (!teamStatus) {
    return <></>;
  }

  const statusColor = getProviderGuidedCareTeamStatusColor(teamStatus, theme);

  return (
    <Box className={classes.root}>
      <Box className={classes.circle} sx={{ backgroundColor: statusColor }} />
      <Typography color={statusColor}>{providerGuidedCareTeamStatusOptionsMap[teamStatus]?.label}</Typography>
    </Box>
  );
};
