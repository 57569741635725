import { combineErrors, getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { OptionalLabel, StandardTextFieldProps, TextField } from "@toolkit/ui";
import { FC } from "react";
import { RegisterOptions, useFormContext } from "react-hook-form";

export interface CustomTextFieldProps extends StandardTextFieldProps {
  name: string;
  isRequired?: boolean;
  defaultValue?: string | null;
  errorsInput?;
  label?;
  registerProps?: RegisterOptions;
}

export const CustomTextField: FC<CustomTextFieldProps> = ({
  name,
  label,
  defaultValue,
  isRequired = true,
  errorsInput,
  registerProps = {},
  ...props
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const formErrors = combineErrors(errors, errorsInput);
  const newName = name.split(".");
  const errorMsg = name.includes(".") ? formErrors?.[newName[0]]?.[newName[1]]?.message : formErrors?.[name]?.message;
  const { t } = useTranslation("provider");
  return (
    <TextField
      fullWidth
      label={isRequired ? label : <OptionalLabel label={String(label)} />}
      defaultValue={defaultValue ?? null}
      error={Boolean(errorMsg)}
      inputProps={{ className: "mobile-phone-rtl-fix" }}
      helperText={t(errorMsg)}
      {...register(name, {
        required: getRequiredValidation(t, isRequired),
        ...registerProps,
      })}
      {...props}
    />
  );
};
