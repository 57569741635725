/* eslint-disable sonarjs/cognitive-complexity */
import { TemplateFieldType } from "@/schema/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, Grid, Typography, useAddToast, useTheme } from "@toolkit/ui";
import _ from "lodash";
import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { VisitStoredDetailsDocument, useSetlRiskTemplateResultMutation } from "../../../gql";
import { useCoPilotContext } from "../../CoPilotContext";
import { ShowInput } from "./ShowInput";

export const ResultsForm: FC = () => {
  const { t } = useTranslation("provider");
  const { failed, succeeded } = useAddToast();
  const { handleSubmit, clearErrors, reset } = useFormContext();

  const { fields, visitId, selectedVisitDiagnosis, storedVisitDetails, handleSelectedVisitDiagnosis } = useCoPilotContext();

  const [fetchSetRiskTemplateResults] = useSetlRiskTemplateResultMutation({
    onCompleted: request => {
      const visitDiagnosisRiskTemplateErrors = request?.setlRiskTemplateResult?.visitDiagnosisRiskTemplateErrors;
      if (visitDiagnosisRiskTemplateErrors?.length === 0) {
        succeeded(t("Set Risk Template Result Successfully"));
      } else {
        formatMessageErrors(visitDiagnosisRiskTemplateErrors);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const _onSubmit = <T extends object>(data: T) => {
    const _data = { ...data };
    const filteredObj = _.pickBy(_data, _.identity);

    const result = Object.keys(filteredObj).reduce((acc, key) => {
      const field = fields?.find(l => l?.code === key);
      if (field?.type === TemplateFieldType.Boolean) {
        const selectedValue: { value: boolean; name: string } = filteredObj?.[key] as unknown as { value: boolean; name: string };
        acc[key] = selectedValue?.value;
      } else if (field?.type === TemplateFieldType.String) {
        const selectedValue: { value: boolean; name: string } = filteredObj?.[key] as unknown as { value: boolean; name: string };
        acc[key] = !field?.allowedValues?.length ? filteredObj[key] : selectedValue?.value;
      } else {
        acc[key] = filteredObj[key];
      }
      return acc;
    }, {});

    fetchSetRiskTemplateResults({
      variables: {
        input: {
          id: selectedVisitDiagnosis?.riskTemplate?.id!,
          result: JSON.stringify(result),
        },
      },
      onCompleted({ setlRiskTemplateResult }) {
        if (setlRiskTemplateResult?.visitDiagnosisRiskTemplateErrors?.length !== 0) {
          failed(formatMessageErrors(setlRiskTemplateResult?.visitDiagnosisRiskTemplateErrors));
        } else {
          succeeded(t("Risk Template results added successfully."));
        }
      },
      refetchQueries: [
        {
          query: VisitStoredDetailsDocument,
          variables: {
            visitId: visitId,
          },
        },
      ],
    });
  };
  const theme = useTheme();
  const resetValue = fields?.reduce((a, v) => ({ ...a, [v?.code!]: null }), {});
  const result = selectedVisitDiagnosis?.riskTemplate?.result ? JSON.parse(String(selectedVisitDiagnosis?.riskTemplate?.result)) : {};
  const isFilled = selectedVisitDiagnosis?.riskTemplate?.isFilled;
  const isGuidedCareProgramSelected = selectedVisitDiagnosis?.riskTemplate?.isGuidedCareProgramSelected;

  useEffect(() => {
    selectedVisitDiagnosis && clearErrors();
    isFilled ? reset(result) : reset(resetValue);
  }, [JSON.stringify(selectedVisitDiagnosis), JSON.stringify(fields), isFilled]);

  useEffect(() => {
    const selectedDiagnosis = storedVisitDetails?.visitDiagnoses?.find(item => selectedVisitDiagnosis?.id === item?.id);
    handleSelectedVisitDiagnosis?.(selectedDiagnosis!);
  }, [JSON.stringify(storedVisitDetails), JSON.stringify(selectedVisitDiagnosis)]);

  return (
    <>
      {selectedVisitDiagnosis && (
        <>
          <Box display='flex' justifyContent='space-between' paddingY={1}>
            {selectedVisitDiagnosis?.riskTemplate?.chronicDiseaseSeverity && (
              <Typography>
                {t("Severity")}:{" "}
                <Box component='span' sx={{ color: theme.palette.error[900] }}>
                  {selectedVisitDiagnosis?.riskTemplate?.chronicDiseaseSeverity}
                </Box>
              </Typography>
            )}
            {selectedVisitDiagnosis?.riskTemplate?.notification && (
              <Typography>
                {t("Alert")}:{" "}
                <Box component='span' sx={{ color: theme.palette.error[900] }}>
                  {selectedVisitDiagnosis?.riskTemplate?.notification}
                </Box>
              </Typography>
            )}
          </Box>
          <form onSubmit={handleSubmit(_onSubmit)}>
            <Grid container spacing={1}>
              {fields?.map(item => (
                <Grid item md={12} key={item?.id}>
                  <ShowInput
                    unit={item?.unit?.display!}
                    isSelected={isGuidedCareProgramSelected!}
                    result={result}
                    isMandatory={item?.isMandatory!}
                    code={item?.code!}
                    type={item?.type!}
                    display={item?.display!}
                    values={item?.allowedValues as string[]}
                    defaultValue={item?.defaultValue!}
                    minValue={item?.minNormalRangeValue!}
                    maxValue={item?.maxNormalRangeValue!}
                  />
                </Grid>
              ))}
            </Grid>
            {selectedVisitDiagnosis?.riskTemplate?.id && !isGuidedCareProgramSelected && (
              <Grid item md={6}>
                <Button
                  type='submit'
                  sx={{
                    margin: 1,
                  }}
                >
                  {t("Submit")}
                </Button>
              </Grid>
            )}
          </form>
        </>
      )}
    </>
  );
};
