import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUserChatGroupMutationVariables = Types.Exact<{
  userIds: Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input'];
}>;


export type GetUserChatGroupMutation = { __typename?: 'Mutation', saveOrFetchExistedAnonymousChatGroup?: { __typename?: 'AnonymousChatGroup', title?: string | null, chatId?: any | null, createdDate?: any | null, failedToCreate?: boolean | null, error?: string | null } | null };


export const GetUserChatGroupDocument = gql`
    mutation getUserChatGroup($userIds: [ID!]!) {
  saveOrFetchExistedAnonymousChatGroup(userIds: $userIds) {
    title
    chatId
    createdDate
    failedToCreate
    error
  }
}
    `;
export type GetUserChatGroupMutationFn = Apollo.MutationFunction<GetUserChatGroupMutation, GetUserChatGroupMutationVariables>;

/**
 * __useGetUserChatGroupMutation__
 *
 * To run a mutation, you first call `useGetUserChatGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetUserChatGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getUserChatGroupMutation, { data, loading, error }] = useGetUserChatGroupMutation({
 *   variables: {
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useGetUserChatGroupMutation(baseOptions?: Apollo.MutationHookOptions<GetUserChatGroupMutation, GetUserChatGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetUserChatGroupMutation, GetUserChatGroupMutationVariables>(GetUserChatGroupDocument, options);
      }
export type GetUserChatGroupMutationHookResult = ReturnType<typeof useGetUserChatGroupMutation>;
export type GetUserChatGroupMutationResult = Apollo.MutationResult<GetUserChatGroupMutation>;
export type GetUserChatGroupMutationOptions = Apollo.BaseMutationOptions<GetUserChatGroupMutation, GetUserChatGroupMutationVariables>;