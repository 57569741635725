import { PatientHealthParameter } from "@/schema/types";
import { CustomTable, Grid } from "@toolkit/ui";
import { FC } from "react";
import { usePatientHealthParametersHistory, usePatientHealthParametersNumber } from "../../hooks";
import { convertPatientHealthParametersToTableData } from "../../others";
import { PatientHealthParameterChart } from "../PatientHealthParameterChart/PatientHealthParameterChart";
import { usePatientHealthParameterHistoryVitalSignColumns } from "./usePatientHealthParameterHistoryVitalSignColumns";

type PatientHealthParameterHistoryNumberProps = {
  patientId: string;
  token: string;
  codes: string[];
};

export const PatientHealthParameterHistoryNumber: FC<PatientHealthParameterHistoryNumberProps> = props => {
  const { patientId, token, codes } = props;

  const { chartData, unit, chartType, loading } = usePatientHealthParametersNumber({
    patientId,
    token,
    codes,
  });

  const { data, tableProps } = usePatientHealthParametersHistory({
    patientId,
    token,
    codes,
  });

  const codesData = codes.map(code => data?.filter(item => item?.hpCode === code)) || [];
  const tableData = convertPatientHealthParametersToTableData(codesData.flat() as PatientHealthParameter[]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PatientHealthParameterChart chartType={chartType!} chartData={chartData} unit={unit} isLoading={loading} />
      </Grid>

      <Grid item xs={12}>
        <CustomTable {...tableProps} data={tableData} columns={usePatientHealthParameterHistoryVitalSignColumns()} />
      </Grid>
    </Grid>
  );
};
