import { User } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomToggleButton, FormControlLabel, Grid } from "@toolkit/ui";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";

export const UserInformationButtonGroup: FC<{ defaultValues: User | undefined }> = ({ defaultValues }) => {
  const { control } = useFormContext();
  const { t } = useTranslation("provider");
  return (
    <Grid container spacing={2} item alignItems='center' sx={{ padding: "8px" }}>
      <Grid item>
        <Controller
          name='isLanguagesPublic'
          defaultValue={defaultValues?.doctor?.isLanguagesPublic}
          control={control}
          render={({ field: { onChange: handleC, value } }) => (
            <FormControlLabel
              control={<CustomToggleButton onChange={e => handleC(e.target.checked)} checked={value} />}
              label={t("Is Languages Public")}
            />
          )}
        />
      </Grid>
      <Grid item>
        <Controller
          name='isYearsOfExperiencePublic'
          defaultValue={defaultValues?.doctor?.isYearsOfExperiencePublic}
          control={control}
          render={({ field: { onChange: handleC, value } }) => (
            <FormControlLabel
              control={<CustomToggleButton onChange={e => handleC(e.target.checked)} checked={value} />}
              label={t("Is Years Of Experience Public")}
            />
          )}
        />
      </Grid>
      <Grid item>
        <Controller
          name='isSocialLinksPublic'
          defaultValue={defaultValues?.doctor?.isSocialLinksPublic}
          control={control}
          render={({ field: { onChange: handleC, value } }) => (
            <FormControlLabel
              control={<CustomToggleButton onChange={e => handleC(e.target.checked)} checked={value} />}
              label={t("Is Links Public")}
            />
          )}
        />
      </Grid>
      <Grid item>
        <Controller
          name='isDateOfBirthPublic'
          defaultValue={defaultValues?.doctor?.isDateOfBirthPublic}
          control={control}
          render={({ field: { onChange: handleC, value } }) => (
            <FormControlLabel
              control={<CustomToggleButton onChange={e => handleC(e.target.checked)} checked={value} />}
              label={t("Is Date Of Birth Public")}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
