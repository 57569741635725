import { DayOfWeekEnum, WorkingHour } from "@/schema/types";
import { TFunction, useTranslation } from "@toolkit/i18n";
import { Box, Card, CardContent, ClockIcon, Divider, Typography, getDayString, useTheme } from "@toolkit/ui";
import { useGetTimeZoneQuery } from "gql/queries";
import moment from "moment-timezone";
import { FC, Fragment } from "react";
import { getWorkingHoursWithTimeZone } from "shared/components";
import { InformationDoctor } from "./InformationDoctor.component";

export const WorkingHourItem: FC<{ dayName: string; time: string | any }> = ({ dayName, time }) => {
  const { t } = useTranslation("consumer");
  const theme = useTheme();
  return (
    <>
      <Box alignItems={"center"} p={0} py={1.25} display='flex' justifyContent={"space-between"}>
        <Typography fontSize={theme.mixins.fonts.fontSize.md}>{t(dayName)}</Typography>
        <Typography fontSize={theme.mixins.fonts.fontSize.md} component='pre'>
          {time}
        </Typography>
      </Box>
    </>
  );
};
const getTimeFormat = (time: string, t: TFunction) => {
  const userTz = moment.tz.guess();
  let timeAsArray = moment(time, "HH:mm:ss").tz(userTz).format("hh:mm a").split(" ");
  timeAsArray[1] = t(timeAsArray[1]);
  return timeAsArray.join(" ");
};
export const WorkingHoursCard: FC<{ schedule: WorkingHour[] }> = ({ schedule }) => {
  const { t } = useTranslation("provider");
  const { data: setting } = useGetTimeZoneQuery();
  const timeZone = setting?.siteSettings?.timeZone! || 3;

  const workingHours = getWorkingHoursWithTimeZone(schedule, timeZone) || [];

  return (
    <Card>
      <CardContent>
        <InformationDoctor title={t("Working Hours")} headerIcon={<ClockIcon />}>
          {workingHours?.map((day, i) => {
            return (
              <Fragment key={day.day}>
                <WorkingHourItem
                  dayName={getDayString(day?.day as DayOfWeekEnum)}
                  time={day.openTimeRanges
                    ?.map(range => `${getTimeFormat(range?.openTime, t) || ""} - ${getTimeFormat(range?.closeTime, t) || ""}`)
                    .join("\n ")}
                />
                {i < workingHours.length - 1 && <Divider />}
              </Fragment>
            );
          })}
        </InformationDoctor>
      </CardContent>
    </Card>
  );
};
