import { userWorkspaceNurseVar } from "@/pages/UserWorkspace/vars";
import { VisitStatus } from "@/schema/types";
import { useReactiveVar } from "@apollo/client";
import { useOnHealthProgramStatusUpdate } from "@health/sse";
import { i18n, useTranslation } from "@toolkit/i18n";
import { useWaitingListAppointmentsQuery, WaitingListAppointmentsQuery } from "pages/dashboard/gql/queries";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";
import { isNurseAssignedToDoctor, isNurseUser } from "utils";
import { useStyles } from "./WaitingRoomsList.styles";

export const useWaitingRoomsList = () => {
  const { t } = useTranslation("provider");
  const { classes, cx } = useStyles();
  const { userInfo } = useOidcUserProfile();
  const currentDir = i18n.dir(i18n.language);
  const currentNurseWorkDetails = useReactiveVar(userWorkspaceNurseVar);
  const doctorsFilterVarValue = isNurseUser(userInfo) ? currentNurseWorkDetails?.doctors?.map(e => e?.id) : null;

  const { data, networkStatus, fetchMore, loading, refetch } = useWaitingListAppointmentsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      first: 5,
      filter: {
        status: [VisitStatus.CallInProgress],
        doctors: doctorsFilterVarValue,
      },
    },
    skip: !isNurseAssignedToDoctor(currentNurseWorkDetails),
  });
  const visits = data?.visits?.edges.map(item => item.node) ?? [];
  const pageInfo = data?.visits?.pageInfo;
  const updateQuery = (prev: WaitingListAppointmentsQuery, { fetchMoreResult }: any) => {
    if (!fetchMoreResult) return prev;

    const newEdges = [...(prev?.visits?.edges || []), ...(fetchMoreResult?.visits?.edges || [])];

    return {
      ...fetchMoreResult,
      visits: {
        ...fetchMoreResult.visits,
        edges: newEdges,
      },
    };
  };

  useOnHealthProgramStatusUpdate(({ data }) => {
    data.graphqlType === "Visit" && data?.id && refetch();
  });
  const fetchMoreData = () => {
    if (!pageInfo?.hasNextPage) {
      return;
    }

    fetchMore({
      variables: {
        first: 5,
        after: pageInfo.endCursor,
      },
      updateQuery,
    });
  };

  return {
    networkStatus: networkStatus,
    loading: loading,
    currentDir,
    pageInfo,
    visits,
    classes,
    fetchMoreData,
    t,
    cx,
  };
};
