import { AnonymousChatGroup, ChatGroup } from "@/schema/types";
import { ChatAvatar, ChatInput, ChatList, ChatTitle } from "@health/chat";
import { Box, CustomDialog, IconButton, SpeechIcon, Typography, useTheme } from "@toolkit/ui";
import { FC } from "react";

type ChatDialogProps = {
  isOpened: boolean;
  chatGroup?: ChatGroup | AnonymousChatGroup;
  startChat?: () => void;
  handleToggle;
  renderButton?: (onClick: () => void, unread) => JSX.Element;
  unread?: JSX.Element | null;
};

export const ChatDialogComponent: FC<ChatDialogProps> = ({ isOpened, chatGroup, startChat, handleToggle, renderButton, unread }) => {
  const handleStartCall = () => {
    startChat?.();
  };
  const custom = renderButton ? renderButton(handleStartCall, unread) : undefined;
  const theme = useTheme();

  return (
    <CustomDialog
      open={isOpened}
      type='base'
      button={
        custom || (
          <IconButton color='primary' onClick={handleStartCall}>
            <SpeechIcon color='inherit' sx={{ "& path": { fill: theme.palette.primary.main } }} />
          </IconButton>
        )
      }
      DialogTitleProps={{
        onClose: handleToggle,
        title: (
          <Box display={"flex"} alignItems={"center"}>
            <ChatAvatar value={chatGroup} size={32} />
            <Typography fontSize={theme.mixins.fonts.fontSize.md} mx={1}>
              <ChatTitle value={chatGroup} />
            </Typography>
          </Box>
        ),
      }}
      DialogActionsProps={{
        hasClose: false,
        closeTitle: "Done",
      }}
      DialogContentProps={{
        sx: {
          height: "100vh",
          padding: 0,
        },
      }}
      onBackdropClick={handleToggle}
    >
      <Box
        sx={{
          display: "flex",
          height: "100%",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <ChatList id={chatGroup?.id} />
        <ChatInput id={chatGroup?.id} />
      </Box>
    </CustomDialog>
  );
};
