import { GuidedCareJourneyIntervention } from "@/schema/types";
import { CustomDialog } from "@toolkit/ui";
import { useOpenState } from "@toolkit/core";
import { FC, useEffect, useState } from "react";
import {
  GuidedCareJourneyInterventionsActivities,
  IGuidedCareJourneyInterventionsActivitiesEvent,
} from "../../components/GuidedCareJourneyInterventions/GuidedCareJourneyInterventionsActivities/GuidedCareJourneyInterventionsActivities";
import { useGuidedCareJourneyInterventionsActivitiesModalStyle } from "./useGuidedCareJourneyInterventionsActivitiesModalStyle";

type IGuidedCareJourneyInterventionsActivitiesModalData = {
  eligibleInterventions?: GuidedCareJourneyIntervention[];
  isComingFromAddPersonalizedItem?: boolean;
};

type IOpen = (data: IGuidedCareJourneyInterventionsActivitiesModalData) => void;
type IClose = () => void;

export const GuidedCareJourneyInterventionsActivitiesModalApi: { open: IOpen; close: IClose } = {
  open: () => {},
  close: () => {},
};

type GuidedCareJourneyInterventionsActivitiesModalProps = {
  isLoading: boolean;
  onChange: (event: IGuidedCareJourneyInterventionsActivitiesEvent) => void;
};

export const GuidedCareJourneyInterventionsActivitiesModal: FC<GuidedCareJourneyInterventionsActivitiesModalProps> = props => {
  const { isLoading, onChange } = props;

  const [data, setData] = useState<IGuidedCareJourneyInterventionsActivitiesModalData>();

  const { open: isOpen, handleOpen, handleClose } = useOpenState();

  const { classes } = useGuidedCareJourneyInterventionsActivitiesModalStyle();

  const open: IOpen = _data => {
    handleOpen();
    setData(_data);
  };

  useEffect(() => {
    GuidedCareJourneyInterventionsActivitiesModalApi.open = open;
    GuidedCareJourneyInterventionsActivitiesModalApi.close = handleClose;
  }, []);

  return (
    <CustomDialog
      type={"base"}
      maxWidth={"sm"}
      open={isOpen}
      DialogTitleProps={{
        hidden: true,
      }}
      DialogContentProps={{
        classes: {
          root: classes.dialogContent,
        },
      }}
    >
      <GuidedCareJourneyInterventionsActivities
        isLoading={isLoading}
        isComingFromAddPersonalizedItem={data?.isComingFromAddPersonalizedItem}
        eligibleInterventions={data?.eligibleInterventions!}
        onDialogClose={handleClose}
        onChange={onChange}
      />
    </CustomDialog>
  );
};
