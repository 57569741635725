import { H_OrderDirection, PatientHealthParameter, PatientHealthParameterSortField } from "@/schema/types";
import { sourceOptionsMap } from "@health/enum-options";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getApolloContextFormToken } from "../../../shared/utils";
import { usePatientHealthParameterListQuery } from "../gql";
import { IPatientHealthParametersHistoryPagination } from "../types";

type PatientHealthParametersHistoryProps = {
  patientId: string;
  token: string;
  codes: string[];
};

export const usePatientHealthParametersHistory = (props: PatientHealthParametersHistoryProps) => {
  const { patientId, token, codes } = props;

  const [selectedItem, setSelectedItem] = useState<PatientHealthParameter>();

  const [pagination, setPagination] = useState<IPatientHealthParametersHistoryPagination>({
    first: codes.length === 1 ? 5 : 10,
  });

  const { data, loading } = usePatientHealthParameterListQuery({
    variables: {
      ...pagination,
      filter: {
        patientId: [patientId],
        codes,
      },
      sortBy: {
        field: PatientHealthParameterSortField.CreatedDate,
        direction: H_OrderDirection.Desc,
      },
    },
    fetchPolicy: "no-cache",
    context: getApolloContextFormToken(token!),
  });

  const patientHealthParameters = useMemo(
    () =>
      (data?.patientHealthParameters?.edges?.map(item => ({
        ...item?.node,
        source: sourceOptionsMap[item?.node?.source!]?.label,
        createdDate: moment(item?.node?.createdDate)?.format("DD MMM YYYY HH:mm"),
      })) || []) as PatientHealthParameter[],
    [data?.patientHealthParameters?.edges]
  );

  const pageInfo = data?.patientHealthParameters?.pageInfo;

  const handleSelectItem = useCallback((value: PatientHealthParameter) => {
    setSelectedItem(value);
  }, []);

  const handleGoPrevious = useCallback(() => {
    setPagination(prev => ({
      first: prev.first,
      after: undefined,
      before: pageInfo?.startCursor!,
    }));
  }, [pageInfo?.startCursor]);

  const handleGoNext = useCallback(() => {
    setPagination(prev => ({
      first: prev.first,
      after: pageInfo?.endCursor!,
      before: undefined,
    }));
  }, [pageInfo?.endCursor]);

  const tableProps = useMemo(() => {
    return {
      data: patientHealthParameters,
      minHeight: 350,
      pageSize: 5,
      isLoading: loading,
      hasPreviousPage: pageInfo?.hasPreviousPage,
      hasNextPage: pageInfo?.hasNextPage,
      onGoToPrevious: handleGoPrevious,
      onGoToNext: handleGoNext,
    };
  }, [handleGoNext, handleGoPrevious, loading, pageInfo?.hasNextPage, pageInfo?.hasPreviousPage, patientHealthParameters]);

  useEffect(() => {
    if (patientHealthParameters?.[0]) {
      handleSelectItem(patientHealthParameters?.[0]);
    }
  }, [handleSelectItem, patientHealthParameters]);

  return {
    data: patientHealthParameters,
    selectedItem,
    tableProps,
    loading,
    handleSelectItem,
  };
};
