/* eslint-disable react/forbid-dom-props */
import { Appointment, AppointmentStatus } from "@/schema/types";
import { Trans, useTranslation } from "@toolkit/i18n";
import { TruncateTypography, Typography, useIsMobileView } from "@toolkit/ui";
import moment from "moment-timezone";
import { FC } from "react";
import { AppointmentDateTime } from "./AppointmentDateTime";
import { useAppointmentNotification } from "./AppointmentNotification.styles";
import { formatTimeFromNow, getAppointmentDate, getTimeFrame } from "./utils";

interface ViewAppointmentProps {
  status: AppointmentStatus | null | undefined;
  item: Appointment;
}
export const ViewAppointment: FC<ViewAppointmentProps> = ({ status, item }) => {
  const isMobile = useIsMobileView();
  const { t } = useTranslation("provider");

  const { classes, theme } = useAppointmentNotification({ isMobile });

  const appointmentDate = getAppointmentDate(item, t);

  const timeframe = getTimeFrame(item);

  const formNowTz = moment.tz.guess();
  const formNowTzItem = formatTimeFromNow(moment.tz(item?.confirmedStartTime, formNowTz), t);
  const visitTypeText = item?.type?.replace("_", " ").toLowerCase();
  const visitType = <TruncateTypography text={t(visitTypeText ?? "")!} sx={{ marginX: "2px" }} />;
  const translatedTimeFrame = timeframe.replace(/(\d+:\d+)([APMapm]+)/g, (_, time, period) => {
    const translatedPeriod = period;
    return `${time}${t(translatedPeriod)}`;
  });
  switch (status) {
    case AppointmentStatus.Request:
    case AppointmentStatus.RescheduledByConsumer:
    case AppointmentStatus.Confirmed:
    case AppointmentStatus.Approved:
    case AppointmentStatus.PendingApproval:
      return <AppointmentDateTime appointmentDate={appointmentDate} timeframe={timeframe} />;

    case AppointmentStatus?.ComingSoon:
      return (
        <Typography variant='body2' className={classes.padding}>
          <span style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
            <Trans t={t} i18nKey='approved-appointment-visit' components={{ 1: visitType }} />
          </span>
          <span className={classes.span}>{t(formNowTzItem)}</span>
        </Typography>
      );
    case AppointmentStatus?.Rejected:
      return (
        <>
          <Typography variant='body2' className={classes.padding}>
            {t("Appointment with")} #{item?.id} {t("is Rejected")}
          </Typography>
          <Typography variant='body2' className={classes.padding}>
            <AppointmentDateTime appointmentDate={appointmentDate} timeframe={timeframe} />
          </Typography>
          <Typography
            fontWeight={theme.mixins.fonts.fontWeight.medium}
            fontSize={theme.mixins.fonts.fontSize.sm}
            className={classes.padding}
          >
            {t("Rejected Reason")}:{" "}
            <Typography component='span' variant='body2'>
              {item?.rejectionReason || "-"}
            </Typography>
          </Typography>
        </>
      );
    case AppointmentStatus.Cancelled:
    case AppointmentStatus.CanceledByConsumer:
    case AppointmentStatus.CanceledByDoctor:
      return (
        <>
          <Typography variant='body2' className={classes.padding}>
            {t("Appointment")} #{item?.id} {t("is Cancelled")}{" "}
          </Typography>
          <Typography variant='body2' className={classes.padding}>
            <AppointmentDateTime appointmentDate={appointmentDate} timeframe={timeframe} />
          </Typography>
          <Typography
            fontWeight={theme.mixins.fonts.fontWeight.medium}
            fontSize={theme.mixins.fonts.fontSize.sm}
            className={classes.padding}
          >
            {t("Cancellation Reason")}:{" "}
            <Typography component='span' variant='body2'>
              {item?.cancellationReason || "-"}
            </Typography>
          </Typography>
        </>
      );
    case AppointmentStatus?.ChatInProgress:
    case AppointmentStatus?.CallInProgress:
      return (
        <>
          <Typography variant='body2' className={classes.padding}>
            {t(visitTypeText!)}
            {item?.visit?.number}
          </Typography>
          <Typography variant='body2' className={classes.padding}>
            {t("Starting at")} <span className={classes.span}>{appointmentDate}</span>,{" "}
            <span className={classes.span}>{translatedTimeFrame}</span>
          </Typography>
        </>
      );

    default:
      return (
        <Typography fontWeight={theme.mixins.fonts.fontWeight.medium} fontSize={theme.mixins.fonts.fontSize.sm}>
          {t("Appointment Date")}:{" "}
          <Typography component='span' variant='body2'>
            {getAppointmentDate(item, t)}
          </Typography>
        </Typography>
      );
  }
};
