import { AppointmentStatus, GuidedCareActivityType, GuidedCareJourneyItem, GuidedCareJourneyItemStatus } from "@/schema/types";
import { guidedCareActivityTypeMapper } from "@health/enum-options";
import { useOpenState } from "@toolkit/core";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { Box, Tooltip } from "@toolkit/ui";
import moment from "moment";
import { AppointmentAccept } from "pages/appointments";
import { FC, PropsWithChildren } from "react";
import { getActivityDisplayName } from "../UpcomingActivities/utils";
import { statusMapper } from "../utils";
import { TimelineItemContent } from "./TimelineItemContent";
import { useTimeLineItemStyles } from "./useTimelineItemStyles";

const JourneyTimelineItem: FC<PropsWithChildren<{ item: GuidedCareJourneyItem }>> = ({ item, children }) => {
  const { t } = useTranslation("provider");

  const { open, handleOpen, handleClose } = useOpenState();

  const { classes, theme } = useTimeLineItemStyles();

  const status = Object.keys(GuidedCareJourneyItemStatus).find(
    statusItem => GuidedCareJourneyItemStatus[statusItem] === item?.guidedCareJourneyItemStatus
  );

  const { title, icon } = guidedCareActivityTypeMapper(item?.guidedCareJourneyItemType!, t, theme.palette);

  return (
    <Tooltip
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      disableFocusListener
      disableTouchListener
      placement={pickLocalizedValue("right", "left")}
      title={
        <>
          {(item?.guidedCareJourneyItemType === GuidedCareActivityType.HomeVisit ||
            item?.guidedCareJourneyItemType === GuidedCareActivityType.OnlineVisit ||
            item?.guidedCareJourneyItemType === GuidedCareActivityType.FacilityVisit) &&
          item?.appointment?.status === AppointmentStatus.Request ? (
            <Box key={item?.id}>
              <TimelineItemContent title={item?.id} icon='calender' />

              <AppointmentAccept
                id={item?.id}
                activeHealthProgramMember={item?.appointment?.consumer?.activeHealthProgramMembers?.[0]}
                healthProgramsByActiveMember={item?.appointment?.consumer?.healthProgramsByActiveMembers?.[0]}
                buttonEl={
                  <Box sx={{ cursor: "pointer" }}>
                    <TimelineItemContent title={moment(item?.dueDate).format("DD MMM YYYY")} icon='calender' />
                    <TimelineItemContent title={title} icon={icon} isBold />

                    <TimelineItemContent title={getActivityDisplayName(item!) || "-"} icon='user2' viewBox='-4 -4 25 25' />
                    <TimelineItemContent
                      title={status}
                      icon={statusMapper(item?.guidedCareJourneyItemStatus!)?.iconStatus}
                      color={statusMapper(item?.guidedCareJourneyItemStatus!)?.color}
                      viewBox={statusMapper(item?.guidedCareJourneyItemStatus!)?.viewBoxStatus}
                    />
                  </Box>
                }
              />
            </Box>
          ) : (
            <Box key={item?.id}>
              <TimelineItemContent title={item?.id} icon='calender' />
              <TimelineItemContent title={moment(item?.dueDate).format("DD MMM YYYY")} icon='date' />
              <TimelineItemContent title={title} icon={icon!} isBold />
              <TimelineItemContent title={getActivityDisplayName(item!) || "-"} icon='user2' viewBox='-4 -4 25 25' />

              <TimelineItemContent
                title={status}
                icon={statusMapper(item?.guidedCareJourneyItemStatus!)?.iconStatus}
                viewBox={statusMapper(item?.guidedCareJourneyItemStatus!)?.viewBoxStatus}
                color={statusMapper(item?.guidedCareJourneyItemStatus!)?.color}
              />
            </Box>
          )}
        </>
      }
      classes={{
        tooltip: classes.tooltip,
        popper: classes.popper,
      }}
    >
      <div>{children}</div>
    </Tooltip>
  );
};
export default JourneyTimelineItem;
