import { ChartType } from "@/schema/types";
import { Box } from "@toolkit/ui";
import { FC } from "react";
import { IHealthParameterChartDataSet } from "../../types";
import { PatientHealthParameterChartBar } from "../PatientHealthParameterChartBar/PatientHealthParameterChartBar";
import { PatientHealthParameterChartHorizontalBar } from "../PatientHealthParameterChartHorizontalBar/PatientHealthParameterChartHorizontalBar";
import { PatientHealthParameterChartLine } from "../PatientHealthParameterChartLine/PatientHealthParameterChartLine";
import { PatientHealthParameterEmpty } from "../PatientHealthParameterEmpty/PatientHealthParameterEmpty";

type PatientHealthParameterChartProps = {
  chartType: ChartType;
  chartData: IHealthParameterChartDataSet[];
  unit?: string;
  isLoading: boolean;
};

export const PatientHealthParameterChart: FC<PatientHealthParameterChartProps> = props => {
  const { chartType, chartData, unit, isLoading } = props;

  return (
    <>
      {chartData?.flat()?.length > 0 ? (
        <>
          {chartType === ChartType.Bar && <PatientHealthParameterChartBar dataSets={chartData} unit={unit} isLoading={isLoading} />}

          {chartType === ChartType.HorizontalBar && (
            <PatientHealthParameterChartHorizontalBar dataSets={chartData} unit={unit} isLoading={isLoading} />
          )}

          {chartType === ChartType.Line && <PatientHealthParameterChartLine dataSets={chartData} unit={unit} isLoading={isLoading} />}
        </>
      ) : (
        <Box marginBlock={1}>
          <PatientHealthParameterEmpty />
        </Box>
      )}
    </>
  );
};
