import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VendorOrderCancelMutationVariables = Types.Exact<{
  cancellationReason: Types.Scalars['String']['input'];
  orderId: Types.Scalars['ID']['input'];
}>;


export type VendorOrderCancelMutation = { __typename?: 'Mutation', vendorOrderCancel?: { __typename?: 'Order', id: string, status: Types.OrderStatus } | null };


export const VendorOrderCancelDocument = gql`
    mutation vendorOrderCancel($cancellationReason: String!, $orderId: ID!) {
  vendorOrderCancel(cancellationReason: $cancellationReason, orderId: $orderId) {
    id
    status
  }
}
    `;
export type VendorOrderCancelMutationFn = Apollo.MutationFunction<VendorOrderCancelMutation, VendorOrderCancelMutationVariables>;

/**
 * __useVendorOrderCancelMutation__
 *
 * To run a mutation, you first call `useVendorOrderCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVendorOrderCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vendorOrderCancelMutation, { data, loading, error }] = useVendorOrderCancelMutation({
 *   variables: {
 *      cancellationReason: // value for 'cancellationReason'
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useVendorOrderCancelMutation(baseOptions?: Apollo.MutationHookOptions<VendorOrderCancelMutation, VendorOrderCancelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VendorOrderCancelMutation, VendorOrderCancelMutationVariables>(VendorOrderCancelDocument, options);
      }
export type VendorOrderCancelMutationHookResult = ReturnType<typeof useVendorOrderCancelMutation>;
export type VendorOrderCancelMutationResult = Apollo.MutationResult<VendorOrderCancelMutation>;
export type VendorOrderCancelMutationOptions = Apollo.BaseMutationOptions<VendorOrderCancelMutation, VendorOrderCancelMutationVariables>;