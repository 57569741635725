import { Maybe } from "@/schema/types";
import { Box, Button, CheckMarkIcon, CustomDialog, Grid, IconButton, Tooltip, Typography, useTheme } from "@toolkit/ui";
import { AppointmentDate, AppointmentDateTime } from "pages/appointments/components";
import { FC } from "react";
import { useTentativeAppointmentConfirm } from "./useTentativeAppointmentConfirm";

type AppointmentTentativeConfirmProps = {
  id: string;
  doctorId: string;
  requestedTimeSlots?: Maybe<Maybe<string>[]>;
};

export const AppointmentTentativeConfirm: FC<AppointmentTentativeConfirmProps> = props => {
  const { id, doctorId, requestedTimeSlots } = props;

  const {
    t,
    dateTimeRange,
    open,
    handleToggle,
    errors,
    isExpiredDate,
    isValidFields,
    handleDateTimeChange,
    handleAcceptNurseForAppointment,
  } = useTentativeAppointmentConfirm({ id, doctorId, requestedTimeSlots });
  const theme = useTheme();
  return (
    <CustomDialog
      type='base'
      open={open}
      DialogTitleProps={{
        title: t("Appointment Confirmation"),
        onClose: handleToggle,
      }}
      button={
        <Tooltip title={isExpiredDate ? t("The Date for this Appointment is Expired") : ""}>
          <IconButton disabled={isExpiredDate} onClick={handleToggle}>
            <CheckMarkIcon color='success' />
          </IconButton>
        </Tooltip>
      }
      DialogActionsProps={{
        children: (
          <Tooltip title={!isValidFields ? t("Please choose date time range") : ""}>
            <span>
              <Button sx={{ background: theme.palette.success[900] }} onClick={handleAcceptNurseForAppointment} disabled={!isValidFields}>
                {t("Confirm")}
              </Button>
            </span>
          </Tooltip>
        ),
      }}
    >
      <Box>
        {errors && (
          <Typography color='error' paragraph>
            {t("Please fill the form")}
          </Typography>
        )}
        {isExpiredDate && (
          <Typography color='error' paragraph>
            {t("The Date for this Appointment is Expired")}
          </Typography>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <AppointmentDate date={dateTimeRange?.["date"]} onChangeDate={date => handleDateTimeChange("date", date)} />
          </Grid>

          <Grid item xs={6}>
            <AppointmentDateTime
              label={t("Start Time")}
              value={dateTimeRange?.["startTime"]}
              onChange={time => handleDateTimeChange("startTime", time)}
            />
          </Grid>

          <Grid item xs={6}>
            <AppointmentDateTime
              label={t("End Time")}
              value={dateTimeRange?.["endTime"]}
              onChange={time => handleDateTimeChange("endTime", time)}
            />
          </Grid>
        </Grid>
      </Box>
    </CustomDialog>
  );
};
