import { User } from "@/schema/types";
import { FC } from "react";
import { UserManagementForm } from "../form/UserManagementForm";
import { useUserManagementEditPageHook } from "./UserManagementEdit.hook";

export const UserManagementEditFormPage: FC = () => {
  const { errors, handleUpdateUser, isUpdateLoading, userDefaultValues, isLoadingData } = useUserManagementEditPageHook();
  return (
    <UserManagementForm
      errors={errors}
      onDone={handleUpdateUser}
      isLoading={isLoadingData}
      defaultValues={userDefaultValues as User}
      isEdit={true}
      isSubmitting={isUpdateLoading}
    />
  );
};
