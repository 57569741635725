import { InputMaybe, TimeRangeInput, WorkingHourInput } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { WorkingDay, getDefaultWeekWorkingDays, getWeekWorkingDays } from "@toolkit/ui";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useSiteSettings } from "shared/siteSettings.var";
import { getWorkingHoursWithTimeZone, requestWorkingHoursWithTimeZone } from "./utils";

export const useWorkingHoursHook = ({ workingHoursDefaultValue, inputName = "" }) => {
  const [workingHours, setWorkingHours] = useState<WorkingDay[]>(getDefaultWeekWorkingDays());
  const { setValue, clearErrors, setError } = useFormContext();
  const { t } = useTranslation();
  const { timeZone } = useSiteSettings();

  const onSetWorkingHours = val => {
    const workingHoursValues = val?.map((item: WorkingHourInput) => ({
      day: item.day,
      openTimeRanges: item.openTimeRanges.map((data: InputMaybe<TimeRangeInput>) => ({
        closeTime: data?.closeTime ? data?.closeTime : null,
        openTime: data?.openTime ? data?.openTime : null,
      })),
    }));

    const requestedWorkingHours = requestWorkingHoursWithTimeZone(workingHoursValues, timeZone || 3);
    setValue(inputName, requestedWorkingHours);
    setWorkingHours(val);
  };

  const onError = (isError: boolean | null) => {
    if (isError) {
      setError(inputName, { message: t("Please check input errors on some days."), type: "validate" });
    } else {
      clearErrors(inputName);
    }
  };

  useEffect(() => {
    const initialWorkHours = (workingHoursDefaultValue && getWeekWorkingDays(workingHoursDefaultValue)) || getDefaultWeekWorkingDays();
    const getWorkingHoursWithTimeZoneData = getWorkingHoursWithTimeZone(initialWorkHours, timeZone);
    onSetWorkingHours(getWorkingHoursWithTimeZoneData);
  }, [workingHoursDefaultValue]);

  return {
    workingHours,
    timeZone,
    onSetWorkingHours,
    onError,
  };
};
