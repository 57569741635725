import { jsx as _jsx } from "react/jsx-runtime";
import { Box, CustomInfiniteScroll } from "@toolkit/ui";
import { useChatGroupsHooks } from "./ChatGroups.hooks";
import { ChatGroupsEmpty } from "./ChatGroupsEmpty";
import { ChatGroupsItem } from "./ChatGroupsItem";
import { useChatGroupsStyles } from "./ChatGroups.styles";
import { ChatGroupsItemSkeleton } from "./ChatGroupsItemSkeleton";
export const ChatGroups = ({ selected, onSelect }) => {
    const { data, loading, hasMore, hasError, isEmpty, keyExtractor, handleFetchMore, handleRetry } = useChatGroupsHooks();
    const { classes } = useChatGroupsStyles();
    if (isEmpty) {
        return _jsx(ChatGroupsEmpty, { isLoading: loading, hasError: hasError, onRetry: handleRetry });
    }
    return (_jsx(Box, { id: 'chat-groups-component', className: classes.root, children: _jsx(CustomInfiniteScroll, { dataLength: data.length, onFetchMore: handleFetchMore, onRefresh: handleRetry, hasMore: hasMore, scrollableTarget: 'chat-groups-component', loader: _jsx(ChatGroupsItemSkeleton, {}), children: data.map(item => (_jsx(ChatGroupsItem, { item: item, isSelected: (selected === null || selected === void 0 ? void 0 : selected.id) === item.id, onClick: onSelect }, keyExtractor(item)))) }) }));
};
