import { PermissionEnum } from "@/schema/types";
import { hasPermission } from "@toolkit/core";
import { TFunction } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import { optimaValidatedRequestsPaths, optimaValidatedRequestsRoute } from "./OptimaValidatedRequestsPaths";
import RouteItem from "@/shared/components/Root/routeTypes";
import { OptimaValidatedRequestsListContainer } from "@/pages/Optima/OptimaValidatedRequests/containers";

export const optimaValidatedRequestsRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }) => {
  return {
    id: "optima-validated-requests-routes",
    text: t("Validated Requests", { ns: "provider" }),
    route: optimaValidatedRequestsRoute,
    isProhibited: !hasPermission(PermissionEnum.ViewTransaction),
    subItems: [
      {
        id: "optima-validated-requests-list-route",
        route: optimaValidatedRequestsPaths.list.route,
        fullPath: optimaValidatedRequestsPaths.list.fullPath,
        element: <OptimaValidatedRequestsListContainer />,
        onClick: (route: string) => navigate(route),
      },
    ],
  };
};
