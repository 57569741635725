import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VisitPrescriptionsQueryVariables = Types.Exact<{
  visitId: Types.Scalars['ID']['input'];
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type VisitPrescriptionsQuery = { __typename?: 'Query', visitPrescriptions?: { __typename?: 'VisitPrescriptionTransactionCountableConnection', totalCount?: number | null, pageInfo: { __typename?: 'H_PageInfo', endCursor?: string | null, hasNextPage: boolean, startCursor?: string | null, hasPreviousPage: boolean }, edges: Array<{ __typename?: 'VisitPrescriptionTransactionCountableEdge', cursor: string, node: { __typename?: 'VisitPrescriptionTransaction', id: string, type?: string | null, referenceId?: string | null, activities?: Array<{ __typename?: 'VisitPrescriptionActivity', code?: string | null, denialCode?: string | null, denialDescription?: string | null, duration?: string | null, frequencyUnitType?: string | null, frequencyValue?: number | null, id: string, instructions?: string | null, quantity?: number | null, observation?: string | null, refills?: number | null } | null> | null } }> } | null };


export const VisitPrescriptionsDocument = gql`
    query VisitPrescriptions($visitId: ID!, $last: Int, $first: Int, $before: String, $after: String) {
  visitPrescriptions(
    visitId: $visitId
    last: $last
    first: $first
    before: $before
    after: $after
  ) {
    pageInfo {
      endCursor
      hasNextPage
      startCursor
      hasPreviousPage
    }
    totalCount
    edges {
      cursor
      node {
        id
        type
        referenceId
        activities {
          code
          denialCode
          denialDescription
          duration
          frequencyUnitType
          frequencyValue
          id
          instructions
          quantity
          observation
          refills
        }
      }
    }
  }
}
    `;

/**
 * __useVisitPrescriptionsQuery__
 *
 * To run a query within a React component, call `useVisitPrescriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitPrescriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitPrescriptionsQuery({
 *   variables: {
 *      visitId: // value for 'visitId'
 *      last: // value for 'last'
 *      first: // value for 'first'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useVisitPrescriptionsQuery(baseOptions: Apollo.QueryHookOptions<VisitPrescriptionsQuery, VisitPrescriptionsQueryVariables> & ({ variables: VisitPrescriptionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VisitPrescriptionsQuery, VisitPrescriptionsQueryVariables>(VisitPrescriptionsDocument, options);
      }
export function useVisitPrescriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VisitPrescriptionsQuery, VisitPrescriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VisitPrescriptionsQuery, VisitPrescriptionsQueryVariables>(VisitPrescriptionsDocument, options);
        }
export function useVisitPrescriptionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<VisitPrescriptionsQuery, VisitPrescriptionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<VisitPrescriptionsQuery, VisitPrescriptionsQueryVariables>(VisitPrescriptionsDocument, options);
        }
export type VisitPrescriptionsQueryHookResult = ReturnType<typeof useVisitPrescriptionsQuery>;
export type VisitPrescriptionsLazyQueryHookResult = ReturnType<typeof useVisitPrescriptionsLazyQuery>;
export type VisitPrescriptionsSuspenseQueryHookResult = ReturnType<typeof useVisitPrescriptionsSuspenseQuery>;
export type VisitPrescriptionsQueryResult = Apollo.QueryResult<VisitPrescriptionsQuery, VisitPrescriptionsQueryVariables>;