import { TaskDefinitionStatus } from "@/schema/types";
import { GridProvider } from "@health/domains";
import { useTranslation } from "@toolkit/i18n";
import { Box, FilterGrid, Typography, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";
import { useGuidedCareTaskListQuery } from "../../gql";
import { GuidedCareTaskListCustomTable } from "../GuidedCareTaskListCustomTable/GuidedCareTaskListCustomTable";
import { useGuidedCareTaskListColumns } from "./useGuidedCareTaskListColumns";

type GuidedCareTaskListProps = {
  patientUserId?: string;
  selectedHealthProgram?: string;
  isComingFromPatientProfile?: boolean;
};

export const GuidedCareTaskList: FC<GuidedCareTaskListProps> = props => {
  const { patientUserId, selectedHealthProgram, isComingFromPatientProfile } = props;

  const { t } = useTranslation("provider");
  const { userInfo } = useOidcUserProfile();
  const theme = useTheme();

  return (
    <GridProvider
      gridName={"guidedCareTaskList"}
      query={useGuidedCareTaskListQuery}
      columns={useGuidedCareTaskListColumns({ isComingFromPatientProfile })}
      hideFilterInput
      filterInput={{
        assigneeUserIds: userInfo?.user_id ? [userInfo?.user_id?.toString()] : [],
        patientUserId: patientUserId ? [patientUserId] : [],
        guidedCareHealthProgramId: selectedHealthProgram,
        statuses: [TaskDefinitionStatus.Pending],
      }}
      skipCall={isComingFromPatientProfile ? !(patientUserId && selectedHealthProgram) : false}
    >
      <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
        <Typography
          variant={"subtitle1"}
          fontSize={theme.mixins.fonts.fontSize.md}
          fontWeight={theme.mixins.fonts.fontWeight.medium}
          mb={1}
        >
          {t("Guided Care Tasks")}
        </Typography>

        <FilterGrid />
      </Box>

      <GuidedCareTaskListCustomTable />
    </GridProvider>
  );
};
