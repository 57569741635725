import { GuidedCareJourneyIntervention, InterventionActivationType, TemplateIntervention } from "@/schema/types";
import { Box, Step, StepLabel, Stepper } from "@toolkit/ui";
import moment from "moment";
import { Moment } from "moment/moment";
import { FC, useEffect, useState } from "react";
import { guidedCareTemplateInterventionStepsOptions } from "../../../enum-options/GuidedCareTemplateInterventionSteps";
import { getInterventionActivitiesItems } from "../../../others";
import { GuidedCareJourneyInterventionsActivitiesStep, ITemplateInterventionActivityItem } from "../../../types/types";
import {
  GuidedCareJourneyInterventionsActivitiesDialogActions,
  IGuidedCareJourneyInterventionsActivitiesDialogActionsEvent,
} from "../GuidedCareJourneyInterventionsActivitiesDialogActions/GuidedCareJourneyInterventionsActivitiesDialogActions";
import {
  GuidedCareJourneyInterventionsActivitiesDialogTitle,
  IGuidedCareJourneyInterventionsActivitiesDialogTitleEvent,
} from "../GuidedCareJourneyInterventionsActivitiesDialogTitle/GuidedCareJourneyInterventionsActivitiesDialogTitle";
import {
  GuidedCareJourneyInterventionsActivitiesTabActivities,
  IGuidedCareJourneyInterventionsActivitiesTabActivitiesEvent,
} from "../GuidedCareJourneyInterventionsActivitiesTabActivities/GuidedCareJourneyInterventionsActivitiesTabActivities";
import {
  GuidedCareJourneyInterventionsActivitiesTabInterventions,
  IGuidedCareJourneyInterventionsActivitiesTabInterventionsEvent,
} from "../GuidedCareJourneyInterventionsActivitiesTabInterventions/GuidedCareJourneyInterventionsActivitiesTabInterventions";
import { useGuidedCareJourneyInterventionsActivitiesStyle } from "./useGuidedCareJourneyInterventionsActivitiesStyle";

export type IGuidedCareJourneyInterventionsActivitiesEvent = {
  type: InterventionActivationType;
  payload: {
    selectedIntervention: TemplateIntervention;
    selectedDate: Moment;
    interventionActivitiesItems: ITemplateInterventionActivityItem[];
  };
};

type GuidedCareJourneyInterventionsActivitiesProps = {
  eligibleInterventions: GuidedCareJourneyIntervention[];
  isLoading: boolean;
  isComingFromAddPersonalizedItem?: boolean;
  onDialogClose: () => void;
  onChange: (event: IGuidedCareJourneyInterventionsActivitiesEvent) => void;
};

export const GuidedCareJourneyInterventionsActivities: FC<GuidedCareJourneyInterventionsActivitiesProps> = props => {
  const { eligibleInterventions, isLoading, isComingFromAddPersonalizedItem = false, onDialogClose, onChange } = props;

  const [activeStep, setActiveStep] = useState(GuidedCareJourneyInterventionsActivitiesStep.INTERVENTIONS);
  const [selectedIntervention, setSelectedIntervention] = useState<GuidedCareJourneyIntervention>();
  const [selectedDate, setSelectedDate] = useState<Moment | null>(null);
  const [interventionActivitiesItems, setInterventionActivitiesItems] = useState<ITemplateInterventionActivityItem[]>([]);

  const { classes } = useGuidedCareJourneyInterventionsActivitiesStyle();

  const onGuidedCareJourneyInterventionsActivitiesTabInterventionsChange = (
    event: IGuidedCareJourneyInterventionsActivitiesTabInterventionsEvent
  ) => {
    if (event.type === "INTERVENTION_SELECT") {
      setSelectedIntervention(event.payload);
    }
  };

  const onGuidedCareJourneyInterventionsActivitiesTabActivitiesChange = (
    event: IGuidedCareJourneyInterventionsActivitiesTabActivitiesEvent
  ) => {
    if (event.type === "CHANGE_DATE") {
      setSelectedDate(event.payload.value);
    } else if (event.type === "DELETE_ACTIVITY_ITEM") {
      const updateActivitiesItems = interventionActivitiesItems?.map(item => {
        if (item?.id === event?.payload?.activityItemId) {
          return {
            ...item,
            isDeleted: !item?.isDeleted,
          };
        }

        return item;
      });

      setInterventionActivitiesItems(updateActivitiesItems);
    }
  };

  const onGuidedCareJourneyInterventionsActivitiesDialogTitleChange = (
    event: IGuidedCareJourneyInterventionsActivitiesDialogTitleEvent
  ) => {
    if (event.type === "BACK_CLICK") {
      setSelectedIntervention(undefined);
    }
  };

  const onGuidedCareJourneyInterventionsActivitiesDialogActionsChange = (
    event: IGuidedCareJourneyInterventionsActivitiesDialogActionsEvent
  ) => {
    onChange({
      type: event.type === "MERGE" ? InterventionActivationType.Merge : InterventionActivationType.Replace,
      payload: {
        selectedIntervention: selectedIntervention?.templateIntervention!,
        selectedDate: selectedDate!,
        interventionActivitiesItems: interventionActivitiesItems?.filter(item => !item?.isDeleted),
      },
    });
  };

  useEffect(() => {
    if (selectedIntervention) {
      setActiveStep(GuidedCareJourneyInterventionsActivitiesStep.ACTIVITIES);
      setSelectedDate(moment());
      setInterventionActivitiesItems(getInterventionActivitiesItems(selectedIntervention?.templateIntervention!));
    } else {
      setActiveStep(GuidedCareJourneyInterventionsActivitiesStep.INTERVENTIONS);
      setInterventionActivitiesItems([]);
      setSelectedDate(null);
    }
  }, [selectedIntervention]);

  return (
    <>
      <GuidedCareJourneyInterventionsActivitiesDialogTitle
        selectedIntervention={selectedIntervention?.templateIntervention!}
        onDialogClose={onDialogClose}
        onChange={onGuidedCareJourneyInterventionsActivitiesDialogTitleChange}
      />

      <Box className={classes.root}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {guidedCareTemplateInterventionStepsOptions?.map(item => (
            <Step key={item?.key} className={classes.step}>
              <StepLabel classes={{ alternativeLabel: classes.alternativeLabel }}>{item?.label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <Box className={classes.infoWrapper}>
          {activeStep === GuidedCareJourneyInterventionsActivitiesStep.INTERVENTIONS && (
            <GuidedCareJourneyInterventionsActivitiesTabInterventions
              eligibleInterventions={eligibleInterventions}
              isComingFromAddPersonalizedItem={isComingFromAddPersonalizedItem}
              onChange={onGuidedCareJourneyInterventionsActivitiesTabInterventionsChange}
            />
          )}

          {activeStep === GuidedCareJourneyInterventionsActivitiesStep.ACTIVITIES && (
            <GuidedCareJourneyInterventionsActivitiesTabActivities
              selectedIntervention={selectedIntervention!}
              interventionActivitiesItems={interventionActivitiesItems}
              selectedDate={selectedDate}
              onChange={onGuidedCareJourneyInterventionsActivitiesTabActivitiesChange}
            />
          )}
        </Box>
      </Box>

      <GuidedCareJourneyInterventionsActivitiesDialogActions
        isSelectedIntervention={!!selectedIntervention}
        isLoading={isLoading}
        onDialogClose={onDialogClose}
        onChange={onGuidedCareJourneyInterventionsActivitiesDialogActionsChange}
      />
    </>
  );
};
