import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TentativeAppointmentConfirmMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.ConsumerAppointmentConfirmInput;
}>;


export type TentativeAppointmentConfirmMutation = { __typename?: 'Mutation', tentativeAppointmentConfirm?: { __typename?: 'AppointmentApprovalAction', appointment?: { __typename?: 'Appointment', id: string, type?: Types.AppointmentType | null } | null, appointmentErrors?: Array<{ __typename?: 'H_EntityError', message?: string | null, field?: string | null, code: Types.H_EntityErrorCode }> | null } | null };


export const TentativeAppointmentConfirmDocument = gql`
    mutation tentativeAppointmentConfirm($id: ID!, $input: ConsumerAppointmentConfirmInput!) {
  tentativeAppointmentConfirm(id: $id, input: $input) {
    appointment {
      id
      type
    }
    appointmentErrors {
      message
      field
      code
    }
  }
}
    `;
export type TentativeAppointmentConfirmMutationFn = Apollo.MutationFunction<TentativeAppointmentConfirmMutation, TentativeAppointmentConfirmMutationVariables>;

/**
 * __useTentativeAppointmentConfirmMutation__
 *
 * To run a mutation, you first call `useTentativeAppointmentConfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTentativeAppointmentConfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tentativeAppointmentConfirmMutation, { data, loading, error }] = useTentativeAppointmentConfirmMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTentativeAppointmentConfirmMutation(baseOptions?: Apollo.MutationHookOptions<TentativeAppointmentConfirmMutation, TentativeAppointmentConfirmMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TentativeAppointmentConfirmMutation, TentativeAppointmentConfirmMutationVariables>(TentativeAppointmentConfirmDocument, options);
      }
export type TentativeAppointmentConfirmMutationHookResult = ReturnType<typeof useTentativeAppointmentConfirmMutation>;
export type TentativeAppointmentConfirmMutationResult = Apollo.MutationResult<TentativeAppointmentConfirmMutation>;
export type TentativeAppointmentConfirmMutationOptions = Apollo.BaseMutationOptions<TentativeAppointmentConfirmMutation, TentativeAppointmentConfirmMutationVariables>;