/* eslint-disable max-lines */
import { userWorkspaceDoctorVar } from "@/pages/UserWorkspace/vars";
import { AppointmentPaymentRequest, AppointmentStatus, Maybe, TimeSlot } from "@/schema/types";
import { useReactiveVar } from "@apollo/client";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import moment from "moment-timezone";
import {
  DoctorAppointmentAcceptMutation,
  DoctorAppointmentRescheduleMutation,
  useAppointmentPatientShareGetQuery,
  useDoctorAppointmentAcceptMutation,
  useDoctorAppointmentRescheduleMutation,
  useDoctorAvailableTimeSlotsGetQuery,
} from "pages/appointments/gql";
import { IFormattedTimeSlot } from "pages/appointments/types/types";
import { convertAppointmentTimeSlotToBackEndValue, groupTimeSlotsByTimeOfDay, isDateExpired } from "pages/appointments/utils";
import { useState } from "react";
import { NotificationItemAction } from "shared/components";

type AppointmentRescheduleType = {
  id: string;
  doctorId?: string;
  status: string;
  paymentInfo?: Maybe<AppointmentPaymentRequest>;
  isAppointmentComingFromGuidedCareTask: boolean;
} & NotificationItemAction;

export const useAppointmentReschedule = ({
  id,
  doctorId,
  paymentInfo,
  status,
  isAppointmentComingFromGuidedCareTask,
  handleNotificationActionComplete,
}: AppointmentRescheduleType) => {
  const [selectedDate, setSelectedDate] = useState(moment().format("YYYY-MM-DD"));
  const [selectedTimeSlots, setSelectedTimeSlots] = useState<IFormattedTimeSlot[]>([]);

  const { t } = useTranslation("provider");
  const { open, handleOpen, handleToggle } = useOpenState();
  const { succeeded, failed } = useAddToast();

  const branchUserPreference = useReactiveVar(userWorkspaceDoctorVar);

  const isExpiredDate = isDateExpired(selectedDate);

  const isDisable =
    status !== AppointmentStatus.RescheduledByConsumer &&
    status !== AppointmentStatus.Request &&
    status !== AppointmentStatus.Approved &&
    status !== AppointmentStatus.Confirmed &&
    status !== AppointmentStatus.ComingSoon &&
    status !== AppointmentStatus.PaymentSucceeded &&
    status !== AppointmentStatus.CallInProgress;

  const { data: appointmentPatientShareData, loading: isAppointmentPatientShareLoading } = useAppointmentPatientShareGetQuery({
    variables: {
      appointmentId: id,
    },
    fetchPolicy: "network-only",
    skip: !id || !open,
  });

  const { data, loading: isLoadingAvailableTimeSlots } = useDoctorAvailableTimeSlotsGetQuery({
    variables: {
      doctorId,
      start: moment(selectedDate).startOf("day"),
      end: moment(selectedDate).endOf("day"),
    },
    skip: !doctorId || !selectedDate || !open,
  });

  const timeSlots = groupTimeSlotsByTimeOfDay(data?.doctor?.availableTimeSlots as TimeSlot[]);

  const [doctorAppointmentReschedule, { loading: isRescheduleSubmitting }] = useDoctorAppointmentRescheduleMutation({
    onCompleted: (mutationData: DoctorAppointmentRescheduleMutation) => {
      const appointmentErrors = mutationData?.doctorAppointmentReschedule?.appointmentErrors!;
      if (appointmentErrors?.length === 0) {
        succeeded(t("Appointment Reschedule Successfully"));
        handleNotificationActionComplete?.();
        handleToggle();
      } else {
        failed(formatMessageErrors(appointmentErrors));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    update: (cache, { data }) => {
      const normalizedId = cache.identify({ id: data?.doctorAppointmentReschedule?.appointment?.id, __typename: "Appointment" });
      cache.evict({ id: normalizedId });
      cache.gc();
    },
  });

  const [doctorAppointmentAccept, { loading: isAppointmentAcceptSubmitting }] = useDoctorAppointmentAcceptMutation({
    onCompleted: (appointmentAcceptData: DoctorAppointmentAcceptMutation) => {
      const appointmentErrors = appointmentAcceptData?.doctorAppointmentAccept?.appointmentErrors;
      if (appointmentErrors?.length === 0) {
        succeeded(t("Appointment Reschedule Successfully"));
        handleNotificationActionComplete?.();
        handleToggle();
      } else {
        failed(formatMessageErrors(appointmentErrors));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    update: (cache, { data: doctorAppointmentAcceptData }) => {
      const normalizedId = cache.identify({
        id: doctorAppointmentAcceptData?.doctorAppointmentAccept?.appointment?.id,
        __typename: "Appointment",
      });
      cache.evict({ id: normalizedId });
      cache.gc();
    },
  });

  const handleSubmit = () => {
    if (selectedTimeSlots?.length) {
      if (isAppointmentComingFromGuidedCareTask) {
        doctorAppointmentAccept({
          variables: {
            id,
            input: {
              suggestedTimeSlots: selectedTimeSlots?.map(item => convertAppointmentTimeSlotToBackEndValue(item)),
              paymentInfo: {
                integrationPatientShare: appointmentPatientShareData?.patientShareForAppointment?.patientShare!,
              },
              branchId: branchUserPreference?.branch?.id,
            },
          },
        });
      } else {
        doctorAppointmentReschedule({
          variables: {
            id,
            input: {
              suggestedTimeSlots: selectedTimeSlots?.map(item => convertAppointmentTimeSlotToBackEndValue(item)),
              branchId: branchUserPreference?.branch?.id,
              paymentInfo: paymentInfo?.type
                ? {
                    totalAmount: paymentInfo?.totalAmount,
                  }
                : null,
            },
          },
        });
      }
    } else {
      failed(t("Please choose a time slot"));
    }
  };

  const handleSelectTimeSlot = (timeSlot: IFormattedTimeSlot) => {
    const existingSlotIndex = selectedTimeSlots.findIndex(item => item.startTime === timeSlot.startTime);

    if (existingSlotIndex !== -1) {
      const filteredSlots = selectedTimeSlots.filter((_, index) => index !== existingSlotIndex);
      setSelectedTimeSlots(filteredSlots);
    } else {
      setSelectedTimeSlots([...selectedTimeSlots, timeSlot]);
    }
  };

  const handleChangeDate = value => {
    setSelectedDate(moment(value).format("YYYY-MM-DD"));
  };

  return {
    t,
    selectedDate,
    selectedTimeSlots,
    timeSlots,
    isLoading: isLoadingAvailableTimeSlots || isAppointmentPatientShareLoading,
    isSubmitting: isRescheduleSubmitting || isAppointmentAcceptSubmitting,
    isDisable,
    isExpiredDate,
    open,
    handleOpen,
    handleToggle,
    handleSubmit,
    handleSelectTimeSlot,
    handleChangeDate,
  };
};
