import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, Typography } from "@toolkit/ui";
import { useMemo } from "react";
import { Diagnose } from "@/schema/types";

export const useDiagnosesListColumns = (): CustomTableColumnProps<Diagnose>[] => {
  const { t } = useTranslation("provider");

  return useMemo(() => {
    return [
      {
        key: "display",
        header: t("Display"),
        accessor: "display",
      },
      {
        key: "code",
        header: t("Code"),
        accessor: "code",
      },
      {
        key: "type",
        header: t("Type"),
        accessor: ({ type }) => <Typography>{type}</Typography>,
      },
    ];
  }, [t]);
};
