import { useAddToast } from "@toolkit/ui";
import { useCallback, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useChatGroupLazyQuery, useGetUserChatGroupMutation } from "../gql";
import { AnonymousChatGroup, ChatGroup } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";

export const useChatHooks = ({ handleToggle, chatId, userId }) => {
  const auth = useAuth();
  const authenticated = auth.isAuthenticated || false;
  const [chatGroup, setChatGroup] = useState<AnonymousChatGroup | ChatGroup>();
  const { failed } = useAddToast();
  const [fetchGetChatGroup] = useChatGroupLazyQuery();
  const [fetchGetChatGroupMutation] = useGetUserChatGroupMutation();
  const { t } = useTranslation("provider");

  // eslint-disable-next-line max-statements
  const startChat = useCallback(async () => {
    try {
      if (!authenticated) {
        auth.signinRedirect();
        return;
      }

      if (chatGroup) {
        handleToggle();
      } else if (chatId) {
        const response = await fetchGetChatGroup({ variables: { chatGroupId: chatId } });
        const value = response?.data?.chatGroup as ChatGroup;
        if (value) {
          setChatGroup(value);
          handleToggle();
        } else {
          failed(t("Failed to get chat info. Please try again."));
        }
      } else if (userId) {
        const response = await fetchGetChatGroupMutation({ variables: { userIds: userId } });
        const value = response?.data?.saveOrFetchExistedAnonymousChatGroup as AnonymousChatGroup;
        if (value) {
          setChatGroup(value);
          handleToggle();
        } else {
          failed(t("Failed to get chat info. Please try again."));
        }
      }
    } catch (error) {
      console.error(error);
      failed(t("Failed to get chat info. Please try again."));
    }
  }, [auth, authenticated, chatGroup, chatId, failed, fetchGetChatGroup, fetchGetChatGroupMutation, handleToggle, t, userId]);

  return { startChat, chatGroup };
};
