import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GuidedCareJourneyItemUpdateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.GuidedCareJourneyItemInput;
}>;


export type GuidedCareJourneyItemUpdateMutation = { __typename?: 'Mutation', guidedCareJourneyItemUpdate?: { __typename?: 'GuidedCareJourneyItemCRUD', guidedCareJourneyItem?: { __typename?: 'GuidedCareJourneyItem', id: string } | null, guidedCareJourneyItemErrors?: Array<{ __typename?: 'H_EntityError', code: Types.H_EntityErrorCode, field?: string | null, message?: string | null }> | null } | null };


export const GuidedCareJourneyItemUpdateDocument = gql`
    mutation GuidedCareJourneyItemUpdate($id: ID!, $input: GuidedCareJourneyItemInput!) {
  guidedCareJourneyItemUpdate(id: $id, input: $input) {
    guidedCareJourneyItem {
      id
    }
    guidedCareJourneyItemErrors {
      code
      field
      message
    }
  }
}
    `;
export type GuidedCareJourneyItemUpdateMutationFn = Apollo.MutationFunction<GuidedCareJourneyItemUpdateMutation, GuidedCareJourneyItemUpdateMutationVariables>;

/**
 * __useGuidedCareJourneyItemUpdateMutation__
 *
 * To run a mutation, you first call `useGuidedCareJourneyItemUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuidedCareJourneyItemUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guidedCareJourneyItemUpdateMutation, { data, loading, error }] = useGuidedCareJourneyItemUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGuidedCareJourneyItemUpdateMutation(baseOptions?: Apollo.MutationHookOptions<GuidedCareJourneyItemUpdateMutation, GuidedCareJourneyItemUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GuidedCareJourneyItemUpdateMutation, GuidedCareJourneyItemUpdateMutationVariables>(GuidedCareJourneyItemUpdateDocument, options);
      }
export type GuidedCareJourneyItemUpdateMutationHookResult = ReturnType<typeof useGuidedCareJourneyItemUpdateMutation>;
export type GuidedCareJourneyItemUpdateMutationResult = Apollo.MutationResult<GuidedCareJourneyItemUpdateMutation>;
export type GuidedCareJourneyItemUpdateMutationOptions = Apollo.BaseMutationOptions<GuidedCareJourneyItemUpdateMutation, GuidedCareJourneyItemUpdateMutationVariables>;