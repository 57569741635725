import { Box, CustomDialog, IconButton, SpeechIcon, Typography, useTheme } from "@toolkit/ui";
import * as React from "react";
import { FC, ReactElement } from "react";
import { useChatHooks } from "./useChatHooks";
import { ChatAvatar, ChatInput, ChatList, ChatTitle } from "@health/chat";

type ChatDialogProps = {
  userId: string;
  buttonEl?: ReactElement;
};

export const ChatDialog: FC<ChatDialogProps> = ({ userId, buttonEl }) => {
  const { chatGroup, handleToggle, open: isOpened, startChat: handleStartCall, isGetChatLinkLoading } = useChatHooks();
  const theme = useTheme();

  return (
    <CustomDialog
      open={isOpened}
      type='base'
      button={
        buttonEl ? (
          React.cloneElement(buttonEl, { onClick: () => handleStartCall(userId), disabled: isGetChatLinkLoading })
        ) : (
          <IconButton color='primary' disabled={isGetChatLinkLoading} onClick={() => handleStartCall(userId)}>
            <SpeechIcon color='inherit' sx={{ "& path": { fill: theme.palette.primary.main } }} />
          </IconButton>
        )
      }
      DialogTitleProps={{
        onClose: handleToggle,
        title: (
          <Box display={"flex"} alignItems={"center"}>
            <ChatAvatar value={chatGroup} size={32} />
            <Typography fontSize={theme.mixins.fonts.fontSize.md} mx={1}>
              <ChatTitle value={chatGroup} />
            </Typography>
          </Box>
        ),
      }}
      DialogActionsProps={{
        hasClose: false,
        closeTitle: "Done",
      }}
      DialogContentProps={{
        sx: {
          height: "100vh",
          padding: 0,
        },
      }}
      onBackdropClick={handleToggle}
    >
      <Box
        sx={{
          display: "flex",
          height: "100%",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <ChatList id={chatGroup?.chatId} />
        <ChatInput id={chatGroup?.chatId} />
      </Box>
    </CustomDialog>
  );
};
