import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AppointmentNotificationSettingsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  filter?: Types.InputMaybe<Types.AppointmentNotificationSettingFilterInput>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type AppointmentNotificationSettingsQuery = { __typename?: 'Query', appointmentNotificationSettings?: { __typename?: 'AppointmentNotificationSettingCountableConnection', edges: Array<{ __typename?: 'AppointmentNotificationSettingCountableEdge', node: { __typename?: 'AppointmentNotificationSetting', sendTimeInMinutes?: number | null, id: string, englishText?: string | null, channels?: Array<Types.NotificationChannel | null> | null, arabicText?: string | null, types?: Array<Types.AppointmentType | null> | null } }>, pageInfo: { __typename?: 'H_PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } | null };


export const AppointmentNotificationSettingsDocument = gql`
    query AppointmentNotificationSettings($first: Int, $filter: AppointmentNotificationSettingFilterInput, $after: String, $before: String, $last: Int) {
  appointmentNotificationSettings(
    first: $first
    filter: $filter
    after: $after
    before: $before
    last: $last
  ) {
    edges {
      node {
        sendTimeInMinutes
        id
        englishText
        channels
        arabicText
        types
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
    `;

/**
 * __useAppointmentNotificationSettingsQuery__
 *
 * To run a query within a React component, call `useAppointmentNotificationSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentNotificationSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentNotificationSettingsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      filter: // value for 'filter'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useAppointmentNotificationSettingsQuery(baseOptions?: Apollo.QueryHookOptions<AppointmentNotificationSettingsQuery, AppointmentNotificationSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppointmentNotificationSettingsQuery, AppointmentNotificationSettingsQueryVariables>(AppointmentNotificationSettingsDocument, options);
      }
export function useAppointmentNotificationSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppointmentNotificationSettingsQuery, AppointmentNotificationSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppointmentNotificationSettingsQuery, AppointmentNotificationSettingsQueryVariables>(AppointmentNotificationSettingsDocument, options);
        }
export function useAppointmentNotificationSettingsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AppointmentNotificationSettingsQuery, AppointmentNotificationSettingsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AppointmentNotificationSettingsQuery, AppointmentNotificationSettingsQueryVariables>(AppointmentNotificationSettingsDocument, options);
        }
export type AppointmentNotificationSettingsQueryHookResult = ReturnType<typeof useAppointmentNotificationSettingsQuery>;
export type AppointmentNotificationSettingsLazyQueryHookResult = ReturnType<typeof useAppointmentNotificationSettingsLazyQuery>;
export type AppointmentNotificationSettingsSuspenseQueryHookResult = ReturnType<typeof useAppointmentNotificationSettingsSuspenseQuery>;
export type AppointmentNotificationSettingsQueryResult = Apollo.QueryResult<AppointmentNotificationSettingsQuery, AppointmentNotificationSettingsQueryVariables>;