import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useOnOptimaHaveNewNotification } from "@health/sse";
import { Box, FilterGrid, PageWrapper, TableGrid, TableSettingComponent, useGridContext } from "@toolkit/ui";
import { ActiveFiltersValues } from "../../../OptimaPriorRequests/components/ActiveFiltersValues/ActiveFiltersValues";
import { RequestListKpis } from "../../../OptimaPriorRequests/components/RequestListKpis/RequestListKpis";
import { useOptimaPriorRequestsWithKpiQuery } from "../../../OptimaPriorRequests/gql";
import { useState } from "react";
import { GridProvider } from "../../../../shared/components";
import { useOptimaPriorRequestListContainerColumns } from "./useOptimaPriorRequestListContainerColumns";
import { useOptimaPriorRequestListKpiController } from "./useOptimaPriorRequestListKpiController";
export const OptimaPriorRequestListContainer = props => {
    const { isProviderAdmin } = props;
    const [activeFilters, setActiveFilters] = useState(undefined);
    const handleOnFiltersChange = value => {
        setActiveFilters(value);
    };
    const { selectedKpiMode, totalCounts, hasFilter, handleKpiModeChange } = useOptimaPriorRequestListKpiController({ activeFilters });
    const { refetch } = useGridContext();
    useOnOptimaHaveNewNotification(() => {
        refetch({ first: 10 });
    });
    return (_jsx(GridProvider, { gridName: "optimaPriorRequestList", query: useOptimaPriorRequestsWithKpiQuery, columns: useOptimaPriorRequestListContainerColumns({
            isProviderAdmin,
            selectedKpiMode: selectedKpiMode === null || selectedKpiMode === void 0 ? void 0 : selectedKpiMode.type,
        }), hasTableSetting: true, variables: Object.assign(Object.assign({}, selectedKpiMode.variables), { filter: activeFilters || {}, after: null, last: null, before: null }), dataAccessor: selectedKpiMode.accessor, hideFilterInput: true, isReadOnlyFilters: true, children: _jsxs(PageWrapper, { contentContainerSX: {
                pt: 0,
            }, filters: hasFilter ? _jsx(FilterGrid, {}) : _jsx(Box, { height: 60 }), actions: _jsx(TableSettingComponent, {}), children: [_jsx(ActiveFiltersValues, { onFiltersChange: handleOnFiltersChange }), _jsx(RequestListKpis, { selectedKpiMode: selectedKpiMode, totalCounts: totalCounts, handleKpiModeChange: handleKpiModeChange }), _jsx(TableGrid, {})] }) }));
};
