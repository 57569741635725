import { PermissionEnum } from "@/schema/types";
import RouteItem from "@/shared/components/Root/routeTypes";
import { hasPermission } from "@toolkit/core";
import { TFunction } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import { ProviderOptimaClaimsActivitiesList } from "../containers/OptimaClaimsActivitiesList/ProviderOptimaClaimsActivitiesList";
import { ProviderOptimaClaimsListContainer } from "../containers/OptimaClaimsList/ProviderOptimaClaimsListContainer";
import { optimaClaimsPaths, optimaClaimsRoute } from "./OptimaClaimsPaths";

export const optimaClaimsRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }) => {
  return {
    id: "optima-claims-routes",
    text: t("Claims", { ns: "provider" }),
    route: optimaClaimsRoute,
    isProhibited: !hasPermission(PermissionEnum.ViewTransaction),
    onClick: (route: string) => navigate(route),
    subItems: [
      {
        id: "optima-claims-list-route",
        route: optimaClaimsPaths.list.route,
        fullPath: optimaClaimsPaths.list.fullPath,
        element: <ProviderOptimaClaimsListContainer />,
        onClick: (route: string) => navigate(route),
        hidden: true,
      },
      {
        id: "optima-claims-activities-list-route",
        route: optimaClaimsPaths.authorizationClaimsActivities.route,
        fullPath: optimaClaimsPaths.authorizationClaimsActivities.fullPath,
        element: <ProviderOptimaClaimsActivitiesList />,
        hidden: true,
        onClick: route => navigate(route),
      },
    ],
  };
};
