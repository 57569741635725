import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { PatientCurrentMedicalStatusFragmentFragmentDoc } from '../../fragments/__generated__/patientCurrentMedicalStatus';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeletePatientNonChronicDiseaseMutationVariables = Types.Exact<{
  deletePatientNonChronicDiseaseId: Types.Scalars['ID']['input'];
  patientId: Types.Scalars['ID']['input'];
  userId: Types.Scalars['ID']['input'];
}>;


export type DeletePatientNonChronicDiseaseMutation = { __typename?: 'Mutation', deletePatientNonChronicDisease?: { __typename?: 'PatientMedicalProfile', id: string, patientCurrentMedicalStatus?: { __typename?: 'PatientCurrentMedicalStatus', id: string, patientChronicDiseases?: Array<{ __typename?: 'PatientChronicDisease', id: string, code: string, display?: string | null, createdDate?: any | null } | null> | null, patientChronicMedications?: Array<{ __typename?: 'PatientChronicMedication', id: string, code: string, display?: string | null, createdDate?: any | null } | null> | null, patientNonChronicDiseases?: Array<{ __typename?: 'PatientNonChronicDisease', id: string, code: string, display?: string | null, createdDate?: any | null } | null> | null } | null } | null };


export const DeletePatientNonChronicDiseaseDocument = gql`
    mutation DeletePatientNonChronicDisease($deletePatientNonChronicDiseaseId: ID!, $patientId: ID!, $userId: ID!) {
  deletePatientNonChronicDisease(
    id: $deletePatientNonChronicDiseaseId
    patientId: $patientId
    userId: $userId
  ) {
    id
    patientCurrentMedicalStatus {
      ...patientCurrentMedicalStatusFragment
    }
  }
}
    ${PatientCurrentMedicalStatusFragmentFragmentDoc}`;
export type DeletePatientNonChronicDiseaseMutationFn = Apollo.MutationFunction<DeletePatientNonChronicDiseaseMutation, DeletePatientNonChronicDiseaseMutationVariables>;

/**
 * __useDeletePatientNonChronicDiseaseMutation__
 *
 * To run a mutation, you first call `useDeletePatientNonChronicDiseaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePatientNonChronicDiseaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePatientNonChronicDiseaseMutation, { data, loading, error }] = useDeletePatientNonChronicDiseaseMutation({
 *   variables: {
 *      deletePatientNonChronicDiseaseId: // value for 'deletePatientNonChronicDiseaseId'
 *      patientId: // value for 'patientId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeletePatientNonChronicDiseaseMutation(baseOptions?: Apollo.MutationHookOptions<DeletePatientNonChronicDiseaseMutation, DeletePatientNonChronicDiseaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePatientNonChronicDiseaseMutation, DeletePatientNonChronicDiseaseMutationVariables>(DeletePatientNonChronicDiseaseDocument, options);
      }
export type DeletePatientNonChronicDiseaseMutationHookResult = ReturnType<typeof useDeletePatientNonChronicDiseaseMutation>;
export type DeletePatientNonChronicDiseaseMutationResult = Apollo.MutationResult<DeletePatientNonChronicDiseaseMutation>;
export type DeletePatientNonChronicDiseaseMutationOptions = Apollo.BaseMutationOptions<DeletePatientNonChronicDiseaseMutation, DeletePatientNonChronicDiseaseMutationVariables>;