import { H_OrderDirection, PatientHealthParameterSortField } from "@/schema/types";
import { useVisitsVitalSignsChanged } from "@health/sse";
import { useTranslation } from "@toolkit/i18n";
import { Loader } from "@toolkit/ui";
import { last } from "lodash";
import { healthParameterCodes } from "pages/PatientHealthParameters/constants";
import { FC } from "react";
import { getApolloContextFormToken } from "../../../../utils";
import { usePatientHealthParametersQuery } from "../../gql";
import BasicLineChart from "../Charts/BasicLineChart";
import { OverviewCard } from "./OverviewCard";
import { IVitalSignChart } from "./types";
import { createPatientHealthParameterItem } from "./utils";

type VitalSignBloodPressureChartProps = IVitalSignChart;

export const VitalSignBloodPressureChart: FC<VitalSignBloodPressureChartProps> = props => {
  const { token, width, percentage, patientId } = props;

  const { t } = useTranslation("provider");

  const { data, loading, refetch } = usePatientHealthParametersQuery({
    variables: {
      first: 10,
      filter: {
        patientId: [patientId],
        codes: [healthParameterCodes.bloodPressureHigh],
      },
      sortBy: {
        field: PatientHealthParameterSortField.CreatedDate,
        direction: H_OrderDirection.Desc,
      },
    },
    fetchPolicy: "no-cache",
    context: getApolloContextFormToken(token!),
  });

  const {
    data: healthParameterData,
    loading: loadingBloodPressureLow,
    refetch: refetchBloodPressureLow,
  } = usePatientHealthParametersQuery({
    variables: {
      first: 10,
      filter: {
        patientId: [patientId],
        codes: [healthParameterCodes.bloodPressureLow],
      },
      sortBy: {
        field: PatientHealthParameterSortField.CreatedDate,
        direction: H_OrderDirection.Desc,
      },
    },
    fetchPolicy: "no-cache",
    context: getApolloContextFormToken(token!),
  });

  const bloodPressuresHighData = data?.patientHealthParameters?.edges?.map(item => item?.node) || [];
  const bloodPressuresLowData = healthParameterData?.patientHealthParameters?.edges?.map(item => item?.node) || [];

  const bloodPressuresHigh = createPatientHealthParameterItem(bloodPressuresHighData, "mg/dL");
  const bloodPressuresLow = createPatientHealthParameterItem(bloodPressuresLowData, "mg/dL");

  const lastItem = last(bloodPressuresLow);
  const value = `${Math.floor(last(bloodPressuresHigh)?.value! || 0)}/${Math.floor(lastItem?.value! || 0)}`;

  useVisitsVitalSignsChanged(({ data: visitsVitalSignsChangedData }) => {
    if (visitsVitalSignsChangedData?.hpCode === healthParameterCodes.bloodPressureHigh) {
      refetch({
        first: 10,
        filter: {
          patientId: [patientId],
          codes: [healthParameterCodes.bloodPressureHigh],
        },
      });
    }
    if (visitsVitalSignsChangedData?.hpCode === healthParameterCodes.bloodPressureLow) {
      refetchBloodPressureLow({
        first: 10,
        filter: {
          patientId: [patientId],
          codes: [healthParameterCodes.bloodPressureLow],
        },
      });
    }
  });

  return (
    <OverviewCard
      width={width}
      title={t("Blood Pressure")}
      value={`${value || 0}`}
      unit={"mmHg"}
      description={t("By") + " " + (lastItem?.source || "-")}
      percentage={percentage}
      date={lastItem?.date!}
    >
      {loading || loadingBloodPressureLow ? (
        <Loader />
      ) : (
        <BasicLineChart title={t("Blood Pressure")} data={bloodPressuresLow} data2={bloodPressuresHigh} />
      )}
    </OverviewCard>
  );
};
