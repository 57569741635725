import { BranchError } from "@/schema/types";
import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import { healthProviderBranchesPaths } from "pages/HealthProviderManagement/constants/HealthProvidersManagementPaths";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { branchListBreadCrumb, branchNewBreadCrumb } from "../../constant";
import { useCreateBranchMutation } from "../../gql";
import { IFormData } from "../BranchesForm.types";

export const useBranchNewPageHook = () => {
  const { t } = useTranslation("provider");
  const navigate = useNavigate();
  const [errors, setError] = useState<BranchError[]>();
  const { succeeded, failed } = useAddToast();
  const { setBreadCrumb } = useBreadCrumbs();
  const translateBranch = t("Branch Create Successfully");
  const [createBranch, { loading: isSaving }] = useCreateBranchMutation({
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      const errors = data?.branchCreate?.branchErrors;
      if (errors?.length === 0) {
        succeeded(translateBranch);
        navigate(healthProviderBranchesPaths.indexPage.fullPath);
      } else {
        const error = t(formatMessageErrors(errors));
        failed(error);
        setError(errors as BranchError[]);
      }
    },
    onError: () => {
      failed(t("Branch Create Failed"));
    },
  });

  const handleCreateBranch = (formData: IFormData) => {
    const cityId = formData?.addressInput?.city?.value?.id;
    createBranch({
      variables: {
        branch: {
          ...formData,
          preferredPharmacies: formData?.preferredPharmacies?.map(item => item?.value?.id),
          addressInput: {
            ...formData?.addressInput,
            city: cityId,
          },
        },
      },
    });
  };

  useEffect(() => {
    setBreadCrumb({ title: "Branches Management", values: [branchListBreadCrumb(t), branchNewBreadCrumb(t)] });
  }, []);

  return {
    errors,
    isSaving: isSaving,
    handleCreateBranch,
  };
};
