/* eslint-disable max-lines */
/* eslint-disable sonarjs/cognitive-complexity */
import { TemplateFieldType } from "@/schema/types";
import { combineErrors, getRequiredValidation, lodashGet } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController, CustomDatePicker, HintComponent, TextField } from "@toolkit/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { replaceDotWithEscapeChar } from "../../constants";
import { IShowInput } from "./types";
import { booleanList } from "./utils";

export const ShowInput: FC<IShowInput> = ({
  code,
  unit,
  type,
  display,
  values,
  defaultValue,
  isMandatory,
  result,
  isSelected,
  maxValue,
  minValue,
  disabled,
}) => {
  const { t } = useTranslation("provider");
  const {
    control,
    register,
    formState: { errors: reactFormErrors },
  } = useFormContext();
  const fieldName = replaceDotWithEscapeChar(code);
  const formErrors = combineErrors(reactFormErrors, []);
  const helperText = lodashGet(formErrors, `${fieldName}.message`);

  const label = unit ? `${display} (${unit})` : display;
  const allowedValues = values.map(item => ({ value: item }));

  switch (type) {
    case TemplateFieldType.Boolean:
      return (
        <AutocompleteController
          ControllerProps={{
            control: control,
            name: fieldName,
            rules: { required: getRequiredValidation(t, isMandatory) },
            defaultValue: booleanList?.find(item => item?.value == result?.[code]) || null,
          }}
          disabled={isSelected || disabled}
          TextFieldProps={{
            label,
            error: Boolean(helperText),
            helperText: t(helperText),
          }}
          getOptionLabel={option => option?.name}
          options={booleanList}
        />
      );
    case TemplateFieldType.Date:
      return (
        <CustomDatePicker
          control={control}
          name={fieldName}
          label={label}
          placeholder={display}
          error={Boolean(helperText)}
          helperText={t(helperText)}
          defaultValue={result?.[code] || null}
          datePickerProps={{
            disabled: isSelected || disabled,
          }}
          controllerProps={{
            rules: { required: getRequiredValidation(t, isMandatory) },
          }}
        />
      );
    case TemplateFieldType.String:
      if (values?.length) {
        return (
          <AutocompleteController
            ControllerProps={{
              control: control,
              name: fieldName,
              rules: { required: getRequiredValidation(t, isMandatory) },
              defaultValue: { value: result?.[code] || defaultValue! },
            }}
            TextFieldProps={{
              label: `${label} List`,
              error: Boolean(helperText),
              helperText: t(helperText),
            }}
            disabled={isSelected || disabled}
            getOptionLabel={option => option?.value}
            options={allowedValues}
          />
        );
      } else {
        return (
          <TextField
            fullWidth
            size='small'
            label={label}
            error={Boolean(helperText)}
            helperText={t(helperText)}
            defaultValue={result?.[code] || null}
            disabled={isSelected || disabled}
            {...register(fieldName, {
              required: getRequiredValidation(t, isMandatory),
            })}
          />
        );
      }
    case TemplateFieldType.Number:
      return (
        <TextField
          fullWidth
          type='number'
          size='small'
          label={label}
          disabled={isSelected || disabled}
          defaultValue={Number(result?.[code]) || null}
          error={Boolean(helperText)}
          helperText={helperText}
          {...register(fieldName, {
            required: getRequiredValidation(t, isMandatory),
          })}
          InputProps={{
            endAdornment: (
              <HintComponent
                title={
                  t("Min Normal Range Value") + ": " + (minValue || "-") + ", " + t("Max Normal Range Value") + ": " + (maxValue || "-")
                }
              />
            ),
          }}
        />
      );
    default:
      return (
        <TextField
          fullWidth
          size='small'
          label={label}
          disabled={isSelected || disabled}
          defaultValue={result?.[code] || null}
          error={Boolean(helperText)}
          helperText={t(helperText)}
          {...register(fieldName, {
            required: getRequiredValidation(t, isMandatory),
          })}
        />
      );
  }
};
