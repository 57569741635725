import React, { FC } from "react";
import { Box, CircularProgress, IconButton, Typography } from "../../base/mui";
import { MuiPauseCircleIcon, MuiPlayCircleIcon } from "../../base/mui-icons";
import { useWaveSurferAudioStyle } from "./useWaveSurferAudioStyle";
import { useWaveSurferAudio, WaveSurferAudioProps } from "./useWaveSurferAudio";

export const WaveSurferAudio: FC<WaveSurferAudioProps> = props => {
  const { classes } = useWaveSurferAudioStyle();
  const { waveformRef, duration, loading, playing, handlePlayPause, formatTime } = useWaveSurferAudio(props);

  return (
    <Box className={classes.root}>
      <IconButton className={classes.iconButton} disabled={loading} onClick={handlePlayPause}>
        {playing ? <MuiPauseCircleIcon color={"primary"} /> : <MuiPlayCircleIcon color={"primary"} />}
      </IconButton>

      {loading && <CircularProgress size={20} />}

      <div id={"waveform"} ref={waveformRef} className={classes.waveformRoot} />

      {!loading && duration && (
        <Typography variant={"body1"} marginLeft={1}>
          {formatTime(duration)}
        </Typography>
      )}
    </Box>
  );
};
