import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { PatientCurrentMedicalStatusFragmentFragmentDoc } from '../../fragments/__generated__/patientCurrentMedicalStatus';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeletePatientChronicDiseaseMutationVariables = Types.Exact<{
  deletePatientChronicDiseaseId: Types.Scalars['ID']['input'];
  patientId: Types.Scalars['ID']['input'];
  userId: Types.Scalars['ID']['input'];
}>;


export type DeletePatientChronicDiseaseMutation = { __typename?: 'Mutation', deletePatientChronicDisease?: { __typename?: 'PatientMedicalProfile', id: string, patientCurrentMedicalStatus?: { __typename?: 'PatientCurrentMedicalStatus', id: string, patientChronicDiseases?: Array<{ __typename?: 'PatientChronicDisease', id: string, code: string, display?: string | null, createdDate?: any | null } | null> | null, patientChronicMedications?: Array<{ __typename?: 'PatientChronicMedication', id: string, code: string, display?: string | null, createdDate?: any | null } | null> | null, patientNonChronicDiseases?: Array<{ __typename?: 'PatientNonChronicDisease', id: string, code: string, display?: string | null, createdDate?: any | null } | null> | null } | null } | null };


export const DeletePatientChronicDiseaseDocument = gql`
    mutation DeletePatientChronicDisease($deletePatientChronicDiseaseId: ID!, $patientId: ID!, $userId: ID!) {
  deletePatientChronicDisease(
    id: $deletePatientChronicDiseaseId
    patientId: $patientId
    userId: $userId
  ) {
    id
    patientCurrentMedicalStatus {
      ...patientCurrentMedicalStatusFragment
    }
  }
}
    ${PatientCurrentMedicalStatusFragmentFragmentDoc}`;
export type DeletePatientChronicDiseaseMutationFn = Apollo.MutationFunction<DeletePatientChronicDiseaseMutation, DeletePatientChronicDiseaseMutationVariables>;

/**
 * __useDeletePatientChronicDiseaseMutation__
 *
 * To run a mutation, you first call `useDeletePatientChronicDiseaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePatientChronicDiseaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePatientChronicDiseaseMutation, { data, loading, error }] = useDeletePatientChronicDiseaseMutation({
 *   variables: {
 *      deletePatientChronicDiseaseId: // value for 'deletePatientChronicDiseaseId'
 *      patientId: // value for 'patientId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeletePatientChronicDiseaseMutation(baseOptions?: Apollo.MutationHookOptions<DeletePatientChronicDiseaseMutation, DeletePatientChronicDiseaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePatientChronicDiseaseMutation, DeletePatientChronicDiseaseMutationVariables>(DeletePatientChronicDiseaseDocument, options);
      }
export type DeletePatientChronicDiseaseMutationHookResult = ReturnType<typeof useDeletePatientChronicDiseaseMutation>;
export type DeletePatientChronicDiseaseMutationResult = Apollo.MutationResult<DeletePatientChronicDiseaseMutation>;
export type DeletePatientChronicDiseaseMutationOptions = Apollo.BaseMutationOptions<DeletePatientChronicDiseaseMutation, DeletePatientChronicDiseaseMutationVariables>;