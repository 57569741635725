import { AppointmentNotificationSetting, AppointmentType } from "@/schema/types";
import { NetworkStatus } from "@apollo/client";
import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { useEffect, useState } from "react";
import { useAppointmentNotificationSettingsLazyQuery } from "../gql";

export const useAppointmentsRemindersSettings = () => {
  const { t } = useTranslation("provider");

  const [appointmentType, setAppointmentType] = useState<AppointmentType>(AppointmentType.Online);
  const [selectedNotificationSetting, setSelectedNotificationSetting] = useState<AppointmentNotificationSetting>();
  const { open: isFormModalOpen, handleClose: handleCloseFormModal, handleOpen: handleOpenFormModal } = useOpenState();

  const onOpenFormModal = (notificationSetting?: AppointmentNotificationSetting) => {
    setSelectedNotificationSetting(notificationSetting);
    handleOpenFormModal();
  };

  const onCloseFormModal = () => {
    setSelectedNotificationSetting(undefined);
    handleCloseFormModal();
  };

  const handleAppointmentTypeChange = (value: AppointmentType) => {
    setAppointmentType(value);
  };

  const handleAddReminder = () => {
    onOpenFormModal();
  };

  const [fetchAppointmentNotificationSettings, { networkStatus, data }] = useAppointmentNotificationSettingsLazyQuery({
    variables: {
      first: 5,
      filter: {
        types: [appointmentType],
      },
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const reminderSettings = data?.appointmentNotificationSettings?.edges.map(edge => edge.node) ?? [];
  const isLoading = networkStatus === NetworkStatus.loading || networkStatus === NetworkStatus.refetch;

  useEffect(() => {
    fetchAppointmentNotificationSettings();
  }, [appointmentType, fetchAppointmentNotificationSettings]);

  return {
    isLoading,
    appointmentType,
    reminderSettings,
    selectedNotificationSetting,
    isFormModalOpen,
    isMaxRemindersReached: reminderSettings.length === 3,
    onCloseFormModal,
    onOpenFormModal,
    handleAppointmentTypeChange,
    handleAddReminder,
    handleRefetchSettings: fetchAppointmentNotificationSettings,
    t,
  };
};
