import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetVisitReferralsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetVisitReferralsQuery = { __typename?: 'Query', getVisitReferrals?: { __typename?: 'PatientReferralConnection', totalCount?: number | null, edges?: Array<{ __typename?: 'ReferralEdge', node?: { __typename?: 'PatientReferral', code?: string | null, startDate?: any | null, endDate?: any | null } | null } | null> | null, pageInfo?: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } | null } | null };


export const GetVisitReferralsDocument = gql`
    query getVisitReferrals($id: ID!, $first: Int) {
  getVisitReferrals(visit: $id, first: $first) {
    edges {
      node {
        code
        startDate
        endDate
      }
    }
    totalCount
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
}
    `;

/**
 * __useGetVisitReferralsQuery__
 *
 * To run a query within a React component, call `useGetVisitReferralsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVisitReferralsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVisitReferralsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetVisitReferralsQuery(baseOptions: Apollo.QueryHookOptions<GetVisitReferralsQuery, GetVisitReferralsQueryVariables> & ({ variables: GetVisitReferralsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVisitReferralsQuery, GetVisitReferralsQueryVariables>(GetVisitReferralsDocument, options);
      }
export function useGetVisitReferralsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVisitReferralsQuery, GetVisitReferralsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVisitReferralsQuery, GetVisitReferralsQueryVariables>(GetVisitReferralsDocument, options);
        }
export function useGetVisitReferralsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetVisitReferralsQuery, GetVisitReferralsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetVisitReferralsQuery, GetVisitReferralsQueryVariables>(GetVisitReferralsDocument, options);
        }
export type GetVisitReferralsQueryHookResult = ReturnType<typeof useGetVisitReferralsQuery>;
export type GetVisitReferralsLazyQueryHookResult = ReturnType<typeof useGetVisitReferralsLazyQuery>;
export type GetVisitReferralsSuspenseQueryHookResult = ReturnType<typeof useGetVisitReferralsSuspenseQuery>;
export type GetVisitReferralsQueryResult = Apollo.QueryResult<GetVisitReferralsQuery, GetVisitReferralsQueryVariables>;