/* eslint-disable max-lines */
import { PermissionEnum } from "@/schema/types";
import { hasPermission } from "@toolkit/core";
import { TFunction } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import { DiscountCreateContainer, DiscountsList, DiscountUpdateContainer } from "../containers";
import { discountsPaths, discountsRoute } from "./DiscountsPaths";
export const discountsRoutes: (props: { navigate: NavigateFunction; t: TFunction; enabled: boolean }) => RouteItem = ({
  navigate,
  t,
  enabled,
}) => {
  return {
    id: "marketplace-product-discounts",
    text: t("Discounts"),
    route: discountsRoute,
    hidden: !enabled || !hasPermission(PermissionEnum.ManageDiscounts),
    isProhibited: !enabled || !hasPermission(PermissionEnum.ManageDiscounts),
    subItems: [
      {
        id: "discounts-list",
        text: t("Discounts"),
        route: discountsPaths.main.route,
        fullPath: discountsPaths.main.fullPath,
        element: <DiscountsList />,
        onClick: route => navigate(route),
      },
      {
        id: "discounts-new",
        text: t("New"),
        element: <DiscountCreateContainer />,
        route: discountsPaths.new.route,
        fullPath: discountsPaths.new.fullPath,
        hidden: true,
        onClick: route => navigate(route),
      },
      {
        id: "discounts-update",
        text: t("Update"),
        element: <DiscountUpdateContainer />,
        route: discountsPaths.update.route,
        fullPath: discountsPaths.update.fullPath,
        hidden: true,
        onClick: route => navigate(route),
      },
    ],
  };
};
