import { GuidedCareJourneyIntervention } from "@/schema/types";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { Box, CircularProgress, Typography } from "@toolkit/ui";
import { FC } from "react";
import { useProfileContext } from "../../../context/ProfileContext";
import { useGuidedCareTemplateInterventionPatientHealthParameterListQuery } from "../../../gql";
import { useGuidedCareJourneyInterventionStatisticsStyle } from "./useGuidedCareJourneyInterventionStatisticsStyle";

type GuidedCareJourneyInterventionStatisticsProps = {
  selectedIntervention: GuidedCareJourneyIntervention;
};

export const GuidedCareJourneyInterventionStatistics: FC<GuidedCareJourneyInterventionStatisticsProps> = props => {
  const { selectedIntervention } = props;

  const { t } = useTranslation("provider");
  const { patientId } = useProfileContext();

  const { classes, theme } = useGuidedCareJourneyInterventionStatisticsStyle();

  const intervention = selectedIntervention?.templateIntervention;
  const healthParameter = selectedIntervention?.healthParameter;

  const { data, loading } = useGuidedCareTemplateInterventionPatientHealthParameterListQuery({
    variables: {
      first: 1,
      filter: {
        codes: [healthParameter?.code!],
        patientId: [patientId!],
      },
    },
    skip: !healthParameter?.code || !patientId,
  });

  const patientHealthParameterValue = data?.patientHealthParameters?.edges?.[0]?.node?.valueNumber || t("N/A");
  const patientHealthParameterUnit = healthParameter?.unit
    ? pickLocalizedValue(healthParameter?.unit?.display, healthParameter?.unit?.arabicDisplay)
    : "";

  return (
    <Box className={classes.root}>
      {loading ? (
        <Box className={classes.loadingWrapper}>
          <CircularProgress color={"inherit"} size={25} />
        </Box>
      ) : (
        <>
          <Typography className={classes.currentValueWrapper}>
            {t("Current Value")}:
            <Typography className={classes.currentValue}>{`${patientHealthParameterValue} ${patientHealthParameterUnit}`}</Typography>
          </Typography>

          <Box className={classes.circlesWrapper}>
            <Box className={classes.circleWrapper}>
              <Box className={classes.circle} color={theme.palette.success.main} borderColor={theme.palette.success.main}>
                <Typography className={classes.circleValue}>-</Typography>
              </Box>

              <Typography className={classes.label}> {t("Goal")} </Typography>
            </Box>

            {intervention?.duration && (
              <Box className={classes.circleWrapper}>
                <Box className={classes.circle} color={theme.palette.primary.main} borderColor={theme.palette.primary.main}>
                  <Typography className={classes.circleValue}>
                    {intervention?.duration} <Typography> {t("Days")} </Typography>
                  </Typography>
                </Box>

                <Typography className={classes.label}> {t("Duration")} </Typography>
              </Box>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};
