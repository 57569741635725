import { getMinValueValidation, getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController, Checkbox, Grid, TextField } from "@toolkit/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";

type AcceptOrderFormProps = {
  index: number;
  id: string | number;
  genericName: string;
  tradeDrugs: any[];
};

export const AcceptOrderForm: FC<AcceptOrderFormProps> = props => {
  const { index, id, genericName, tradeDrugs } = props;
  const { t } = useTranslation("vendor");

  const {
    control,
    watch,
    setValue,
    register,
    formState: { errors },
  } = useFormContext();

  const acceptedMedications =
    watch("acceptedMedications")
      ?.filter(item => item && item.tradeCode && item.quantity !== undefined)
      ?.map(item => ({ ...item, quantity: Number(item.quantity), tradeCode: item?.tradeCode?.code })) || [];

  const defaultTradeDrug = tradeDrugs?.[0];
  const defaultQuantity = defaultTradeDrug?.quantity;

  const isChecked = !!acceptedMedications.find(med => med.id.toString() === id.toString());

  const handleCheckboxChange = e => {
    if (e.target.checked) {
      setValue("acceptedMedications", [
        ...acceptedMedications,
        {
          id: id.toString(),
          quantity: defaultQuantity,
          tradeCode: defaultTradeDrug?.code || "",
        },
      ]);
    } else {
      setValue(
        "acceptedMedications",
        acceptedMedications.filter(med => med.id !== id.toString())
      );
    }
  };

  return (
    <>
      <Grid item xs={1}>
        <Checkbox checked={isChecked} onChange={handleCheckboxChange} />
      </Grid>
      <Grid item xs={3}>
        <TextField disabled fullWidth size='small' label={t("Generic Name")} defaultValue={genericName} />
      </Grid>
      <Grid item xs={5}>
        <AutocompleteController
          ControllerProps={{
            name: `acceptedMedications[${index}].tradeCode`,
            control: control,
            defaultValue: defaultTradeDrug || null,
            rules: { required: getRequiredValidation(t, true) },
          }}
          TextFieldProps={{
            placeholder: t("Trade Name"),
            error: Boolean(errors?.acceptedMedications?.[index].tradeCode?.message),
            helperText: errors?.acceptedMedications?.[index].tradeCode?.message?.message,
          }}
          disabled={!isChecked}
          options={tradeDrugs || []}
          getOptionLabel={(option: { display: string }) => option?.display}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          type='number'
          size='small'
          defaultValue={defaultQuantity}
          label={t("Quantity")}
          helperText={errors?.acceptedMedications?.[index]?.quantity?.message}
          error={Boolean(errors?.acceptedMedications?.[index]?.quantity?.message)}
          InputProps={{
            inputProps: {
              min: 1,
            },
          }}
          disabled={!isChecked}
          {...register(`acceptedMedications[${index}].quantity`, {
            required: getRequiredValidation(t, true),
            min: getMinValueValidation(t, 1),
          })}
        />
      </Grid>
    </>
  );
};
