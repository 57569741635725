import { PermissionEnum } from "@/schema/types";
import { hasPermission } from "@toolkit/core";
import { TFunction } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import { OrdersScreen } from "./index";

const ordersRoute = "/orders";

export const ordersPaths = {
  indexPage: {
    route: "",
    get fullPath() {
      return `${ordersRoute}${this.route}`;
    },
  },
};

export const ordersRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }): RouteItem => {
  return {
    id: "orders",
    route: ordersRoute,
    fullPath: ordersPaths.indexPage.fullPath,
    text: t("Orders", { ns: "provider" }),
    element: <OrdersScreen />,
    isProhibited: !hasPermission(PermissionEnum.ManageOrders),
    onClick: (route: string) => navigate(route),
  };
};
