import { GuidedCareJourneyIntervention } from "@/schema/types";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { Alert, Box, Button, IconButton, MuiArrowBackIcon, MuiArrowForwardIcon, Typography } from "@toolkit/ui";
import { FC, useState } from "react";
import { GuidedCareJourneyInterventionsActivitiesModalApi } from "../../../modals/GuidedCareJourneyInterventionsActivities/GuidedCareJourneyInterventionsActivitiesModal";
import { useGuidedCareJourneyEligibleInterventionsAlertStyle } from "./useGuidedCareJourneyEligibleInterventionsAlertStyle";

type GuidedCareJourneyEligibleInterventionsAlertProps = {
  eligibleInterventions: GuidedCareJourneyIntervention[];
  patientName: string;
};

export const GuidedCareJourneyEligibleInterventionsAlert: FC<GuidedCareJourneyEligibleInterventionsAlertProps> = props => {
  const { eligibleInterventions, patientName } = props;

  const [currentIndex, setCurrentIndex] = useState(0);

  const { t } = useTranslation("provider");

  const { classes, theme } = useGuidedCareJourneyEligibleInterventionsAlertStyle();

  const currentItem = eligibleInterventions?.[currentIndex];

  const onArrowBackIconClick = () => {
    setCurrentIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : eligibleInterventions?.length - 1));
  };

  const onArrowForwardIconClick = () => {
    setCurrentIndex(prevIndex => (prevIndex < eligibleInterventions?.length - 1 ? prevIndex + 1 : 0));
  };

  const onTakeActionClick = () => {
    GuidedCareJourneyInterventionsActivitiesModalApi.open({ eligibleInterventions });
  };

  return (
    <Box className={classes.root}>
      <Alert severity={"warning"} className={classes.alert}>
        <Box className={classes.wrapper}>
          <Box className={classes.messageWrapper}>
            <Typography>
              {t("{{patient}} has {{intervention}}", {
                patient: patientName || "Patient",
                intervention: pickLocalizedValue(currentItem?.templateIntervention?.name, currentItem?.templateIntervention?.nameAr!),
              })}
              .
            </Typography>

            <Typography fontWeight={theme.mixins.fonts.fontWeight.medium}>{t("Immediate intervention recommended")}.</Typography>
          </Box>

          <Box className={classes.actionsWrapper}>
            <Button color={"secondary"} className={classes.button} onClick={onTakeActionClick}>
              {t("Take Action")}
            </Button>

            <Box className={classes.iconButtonsWrapper}>
              <IconButton disabled={eligibleInterventions?.length === 1} className={classes.iconButtonPrev} onClick={onArrowBackIconClick}>
                <MuiArrowBackIcon color={"primary"} className={classes.icon} />
                <Typography className={classes.iconButtonPrevText}>{t("Prev")}</Typography>
              </IconButton>

              <Box
                className={classes.circle}
                bgcolor={eligibleInterventions?.length === 1 ? theme.palette.gray.main : theme.palette.primary.main}
              />

              <IconButton disabled={eligibleInterventions?.length === 1} onClick={onArrowForwardIconClick}>
                <Typography className={classes.iconButtonNextText}>{t("Next")}</Typography>
                <MuiArrowForwardIcon color={"primary"} className={classes.icon} />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Alert>
    </Box>
  );
};
