import { CodeSystemCode } from "@/schema/types";
import { SystemCodeAutocomplete } from "@health/autocompletes";
import { visitDiagnosisTypeOptions } from "@health/enum-options";
import { getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController, Grid } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { getApolloContextFormToken } from "shared/utils";

export const DiagnosisForm: FC = () => {
  const { t } = useTranslation("provider");
  const [params] = useSearchParams();

  const {
    control,
    register,
    formState: { errors },
  } = useFormContext();

  const token = params.get("token");

  // Note: This is temporary and will be removed when we convert the form to use Zod schema.
  useEffect(() => {
    register("code", {
      required: getRequiredValidation(t, true),
    });
  }, [register, t]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <SystemCodeAutocomplete
          name={"code"}
          filter={{ codeSystemCode: CodeSystemCode.Diagnosis }}
          context={getApolloContextFormToken(token!)}
        />
      </Grid>

      <Grid item xs={6}>
        <AutocompleteController
          ControllerProps={{
            control,
            name: "visitDiagnosisType",
            rules: {
              required: getRequiredValidation(t, true),
            },
          }}
          TextFieldProps={{
            placeholder: t("Visit Diagnosis Type"),
            label: t("Visit Diagnosis Type"),
            helperText: String(errors?.visitDiagnosisType?.message),
            error: !!errors?.visitDiagnosisType?.message,
          }}
          options={visitDiagnosisTypeOptions || []}
          getOptionLabel={o => o?.label}
          {...register("visitDiagnosisType")}
        />
      </Grid>
    </Grid>
  );
};
