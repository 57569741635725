import { PermissionEnum } from "@/schema/types";
import { hasPermission } from "@toolkit/core";
import { TFunction } from "@toolkit/i18n";
import { TimeMachineIcon } from "@toolkit/ui";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import { UserInfoType } from "shared/configs/oidc";
import { isDoctorUser, isNurseUser, isVendorAdmin } from "utils";
import { VisitsHistoryListPage } from "./index";

const visitHistoryRoute = "/visit-history";

export const visitHistoryPaths = {
  indexPage: {
    route: "",
    get fullPath() {
      return `${visitHistoryRoute}${this.route}`;
    },
  },
};

export const visitHistoryRoutes: (props: { navigate: NavigateFunction; t: TFunction; userInfo: UserInfoType }) => RouteItem = ({
  navigate,
  t,
  userInfo,
}): RouteItem => {
  return {
    id: "visit history",
    route: visitHistoryRoute,
    fullPath: visitHistoryPaths.indexPage.fullPath,
    text: t("Visit History", { ns: "provider" }),
    icon: <TimeMachineIcon />,
    element: <VisitsHistoryListPage />,
    isProhibited: !(
      isDoctorUser(userInfo) ||
      (isNurseUser(userInfo) && hasPermission(PermissionEnum.ManageVisits)) ||
      (isVendorAdmin(userInfo) && hasPermission(PermissionEnum.ManageVisits))
    ),
    onClick: (route: string) => navigate(route),
  };
};
