import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProviderMedicalFormCreateMutationVariables = Types.Exact<{
  input: Types.MedicalFormInput;
}>;


export type ProviderMedicalFormCreateMutation = { __typename?: 'Mutation', createMedicalForm?: { __typename?: 'MedicalForm', errors?: Array<{ __typename?: 'MedicalFormGraphqlError', errorType?: Types.ErrorType | null, message?: string | null, field?: string | null } | null> | null } | null };


export const ProviderMedicalFormCreateDocument = gql`
    mutation ProviderMedicalFormCreate($input: MedicalFormInput!) {
  createMedicalForm(input: $input) {
    errors {
      errorType
      message
      field
    }
  }
}
    `;
export type ProviderMedicalFormCreateMutationFn = Apollo.MutationFunction<ProviderMedicalFormCreateMutation, ProviderMedicalFormCreateMutationVariables>;

/**
 * __useProviderMedicalFormCreateMutation__
 *
 * To run a mutation, you first call `useProviderMedicalFormCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProviderMedicalFormCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [providerMedicalFormCreateMutation, { data, loading, error }] = useProviderMedicalFormCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProviderMedicalFormCreateMutation(baseOptions?: Apollo.MutationHookOptions<ProviderMedicalFormCreateMutation, ProviderMedicalFormCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProviderMedicalFormCreateMutation, ProviderMedicalFormCreateMutationVariables>(ProviderMedicalFormCreateDocument, options);
      }
export type ProviderMedicalFormCreateMutationHookResult = ReturnType<typeof useProviderMedicalFormCreateMutation>;
export type ProviderMedicalFormCreateMutationResult = Apollo.MutationResult<ProviderMedicalFormCreateMutation>;
export type ProviderMedicalFormCreateMutationOptions = Apollo.BaseMutationOptions<ProviderMedicalFormCreateMutation, ProviderMedicalFormCreateMutationVariables>;