import { ChatGroup } from "@/schema/types";
import { useOpenState } from "@toolkit/core";
import { createContext, FC, PropsWithChildren, useContext, useEffect, useState } from "react";

export interface DashboardContextValue {
  selectedChat?: ChatGroup;
  open: boolean;
  onModalClose: () => void;
  handleSelectChat: (ChatGroup?: ChatGroup) => void;
}

export const DashboardContext = createContext<DashboardContextValue>({} as DashboardContextValue);
export const useDashboardContext = () => useContext(DashboardContext);

export const DashboardContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [selectedChat, setSelectedChat] = useState<ChatGroup | undefined>(undefined);
  const { open, handleOpen, handleClose } = useOpenState();
  useEffect(() => {
    selectedChat && handleOpen();
  }, [selectedChat]);

  const onModalClose = () => {
    setSelectedChat(undefined);
    handleClose();
  };

  const handleSelectChat = (chatGroup?: ChatGroup) => {
    setSelectedChat(chatGroup);
  };

  return (
    <DashboardContext.Provider
      value={{
        selectedChat,
        onModalClose,
        open,
        handleSelectChat,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export {};
