import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VendorUserCreateMutationVariables = Types.Exact<{
  input: Types.AdminUserCreateInput;
}>;


export type VendorUserCreateMutation = { __typename?: 'Mutation', userCreate?: { __typename?: 'UserCreate', user?: { __typename?: 'User', appRole?: Types.AppRoleTypes | null, appType?: Types.AppTypes | null, id: string, email?: string | null, firstName?: string | null, lastName?: string | null, isActive: boolean, mobile?: string | null, departments?: Array<{ __typename?: 'Department', id: string, name: string } | null> | null, doctor?: { __typename?: 'Doctor', id: string, appointmentSlotTimePeriod: number, appointmentTypes?: Array<Types.AppointmentTypeEnum | null> | null, bio?: string | null, seniority?: Types.DoctorSeniorityEnum | null, secondMobileNumber?: string | null, socialLinks?: any | null, yearsOfExperience?: number | null, languages?: Array<{ __typename?: 'Language', code: string, display: string, displayAr?: string | null, id: string } | null> | null, qualifications?: Array<{ __typename?: 'Qualification', code: string, fromDate?: any | null, issuer: string, toDate?: any | null } | null> | null, specializations?: Array<{ __typename?: 'DoctorSpecialization', id: string, code: string } | null> | null, workingHours?: Array<{ __typename?: 'WorkingHour', day?: Types.DayOfWeekEnum | null, openTimeRanges?: Array<{ __typename?: 'TimeRange', closeTime?: any | null, openTime?: any | null } | null> | null } | null> | null } | null } | null, accountErrors: Array<{ __typename?: 'AccountError', code: Types.AccountErrorCode, field?: string | null, message?: string | null }> } | null };


export const VendorUserCreateDocument = gql`
    mutation vendorUserCreate($input: AdminUserCreateInput!) {
  userCreate(input: $input) {
    user {
      appRole
      appType
      id
      email
      firstName
      lastName
      isActive
      mobile
      departments {
        id
        name
      }
      doctor {
        id
        appointmentSlotTimePeriod
        appointmentTypes
        bio
        languages {
          code
          display
          displayAr
          id
        }
        qualifications {
          code
          fromDate
          issuer
          toDate
        }
        seniority
        secondMobileNumber
        socialLinks
        specializations {
          id
          code
        }
        workingHours {
          day
          openTimeRanges {
            closeTime
            openTime
          }
        }
        yearsOfExperience
      }
    }
    accountErrors {
      code
      field
      message
    }
  }
}
    `;
export type VendorUserCreateMutationFn = Apollo.MutationFunction<VendorUserCreateMutation, VendorUserCreateMutationVariables>;

/**
 * __useVendorUserCreateMutation__
 *
 * To run a mutation, you first call `useVendorUserCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVendorUserCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vendorUserCreateMutation, { data, loading, error }] = useVendorUserCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVendorUserCreateMutation(baseOptions?: Apollo.MutationHookOptions<VendorUserCreateMutation, VendorUserCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VendorUserCreateMutation, VendorUserCreateMutationVariables>(VendorUserCreateDocument, options);
      }
export type VendorUserCreateMutationHookResult = ReturnType<typeof useVendorUserCreateMutation>;
export type VendorUserCreateMutationResult = Apollo.MutationResult<VendorUserCreateMutation>;
export type VendorUserCreateMutationOptions = Apollo.BaseMutationOptions<VendorUserCreateMutation, VendorUserCreateMutationVariables>;