import { VisitTranscription } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, Card, Typography } from "@toolkit/ui";
import { FC } from "react";
import { useTranscriptionStyles } from "./Transcription.styles";

export const TranscriptionSegments: FC<{ transcription: VisitTranscription; handleFetchMoreSegments: () => void; hasMore: boolean }> = ({
  transcription,
  handleFetchMoreSegments,
  hasMore,
}) => {
  const { t } = useTranslation("provider");
  const { classes } = useTranscriptionStyles();
  const transcriptionsData = transcription?.segments?.edges?.map(transcriptItem => transcriptItem?.node);
  const firstSpeaker = transcriptionsData?.[0]?.speaker;
  return (
    <>
      <Card sx={{ height: "100%", padding: "10px" }}>
        {transcriptionsData?.map(item => (
          <Box key={item?.id} sx={{ display: "flex", justifyContent: item?.speaker === firstSpeaker ? "flex-end" : "flex-start" }}>
            <Box>
              <Typography className={item?.speaker == firstSpeaker ? classes.firstSpeaker : classes.secondSpeaker}>{item?.text}</Typography>
            </Box>
          </Box>
        ))}
      </Card>

      {hasMore && (
        <Button sx={{ marginTop: 1, width: "100%", alignSelf: "center", marginLeft: "0px !important" }} onClick={handleFetchMoreSegments}>
          {t("See More")}
        </Button>
      )}
    </>
  );
};
