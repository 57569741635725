import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GuidedCareTemplateInterventionListQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.HealthProgramTemplateInterventionFilterInput>;
  sortBy?: Types.InputMaybe<Types.HealthProgramTemplateSortingInput>;
}>;


export type GuidedCareTemplateInterventionListQuery = { __typename?: 'Query', templateInterventions?: { __typename?: 'HealthProgramTemplateInterventionCountableConnection', pageInfo: { __typename?: 'H_PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'HealthProgramTemplateInterventionCountableEdge', node: { __typename?: 'TemplateIntervention', id: string, name?: string | null, nameAr?: string | null, duration?: number | null, interventionTemplateActivities?: Array<{ __typename?: 'TemplateInterventionActivity', templateActivityType?: Types.GuidedCareActivityType | null, interventionActivityEventItems?: Array<{ __typename?: 'TemplateInterventionActivityEventItem', id: string, offsetInDays?: number | null } | null> | null } | null> | null } }> } | null };


export const GuidedCareTemplateInterventionListDocument = gql`
    query GuidedCareTemplateInterventionList($first: Int, $last: Int, $after: String, $before: String, $filter: HealthProgramTemplateInterventionFilterInput, $sortBy: HealthProgramTemplateSortingInput) {
  templateInterventions(
    first: $first
    last: $last
    after: $after
    before: $before
    filter: $filter
    sortBy: $sortBy
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        name
        nameAr
        duration
        interventionTemplateActivities {
          templateActivityType
          interventionActivityEventItems {
            id
            offsetInDays
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGuidedCareTemplateInterventionListQuery__
 *
 * To run a query within a React component, call `useGuidedCareTemplateInterventionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuidedCareTemplateInterventionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuidedCareTemplateInterventionListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useGuidedCareTemplateInterventionListQuery(baseOptions?: Apollo.QueryHookOptions<GuidedCareTemplateInterventionListQuery, GuidedCareTemplateInterventionListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GuidedCareTemplateInterventionListQuery, GuidedCareTemplateInterventionListQueryVariables>(GuidedCareTemplateInterventionListDocument, options);
      }
export function useGuidedCareTemplateInterventionListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GuidedCareTemplateInterventionListQuery, GuidedCareTemplateInterventionListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GuidedCareTemplateInterventionListQuery, GuidedCareTemplateInterventionListQueryVariables>(GuidedCareTemplateInterventionListDocument, options);
        }
export function useGuidedCareTemplateInterventionListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GuidedCareTemplateInterventionListQuery, GuidedCareTemplateInterventionListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GuidedCareTemplateInterventionListQuery, GuidedCareTemplateInterventionListQueryVariables>(GuidedCareTemplateInterventionListDocument, options);
        }
export type GuidedCareTemplateInterventionListQueryHookResult = ReturnType<typeof useGuidedCareTemplateInterventionListQuery>;
export type GuidedCareTemplateInterventionListLazyQueryHookResult = ReturnType<typeof useGuidedCareTemplateInterventionListLazyQuery>;
export type GuidedCareTemplateInterventionListSuspenseQueryHookResult = ReturnType<typeof useGuidedCareTemplateInterventionListSuspenseQuery>;
export type GuidedCareTemplateInterventionListQueryResult = Apollo.QueryResult<GuidedCareTemplateInterventionListQuery, GuidedCareTemplateInterventionListQueryVariables>;