import { useTranslation } from "@toolkit/i18n";
import { GridImageModal } from "@toolkit/ui";
import { HealthPackagesQueryNode } from "pages/HealthPackages/types";
import { FC } from "react";

export const HealthPackageImages: FC<{ row: HealthPackagesQueryNode }> = ({ row }) => {
  const { t } = useTranslation("provider");
  const image = row?.mainImageUrl;

  return (
    <>
      <GridImageModal title={t("Health package Images")} image={image!} altText={"main"}></GridImageModal>
    </>
  );
};
