import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GuidedCareJourneyTemplateInterventionListQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.GuidedCareJourneyFilterInput>;
  sortBy?: Types.InputMaybe<Types.GuidedCareJourneySortingInput>;
}>;


export type GuidedCareJourneyTemplateInterventionListQuery = { __typename?: 'Query', guidedCareJourneys?: { __typename?: 'GuidedCareJourneyCountableConnection', pageInfo: { __typename?: 'H_PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'GuidedCareJourneyCountableEdge', node: { __typename?: 'GuidedCareJourney', id: string, activeInterventions?: Array<{ __typename?: 'GuidedCareJourneyIntervention', templateIntervention?: { __typename?: 'TemplateIntervention', id: string } | null } | null> | null, eligibleInterventions?: Array<{ __typename?: 'GuidedCareJourneyIntervention', templateIntervention?: { __typename?: 'TemplateIntervention', id: string, name?: string | null, nameAr?: string | null, duration?: number | null, interventionTemplateActivities?: Array<{ __typename?: 'TemplateInterventionActivity', templateActivityType?: Types.GuidedCareActivityType | null, interventionActivityEventItems?: Array<{ __typename?: 'TemplateInterventionActivityEventItem', id: string, offsetInDays?: number | null } | null> | null } | null> | null } | null, healthParameter?: { __typename?: 'HealthParameter', code?: string | null, display?: string | null, arabicDisplay?: string | null, unit?: { __typename?: 'FieldUnit', display?: string | null, arabicDisplay?: string | null } | null } | null } | null> | null } }> } | null };


export const GuidedCareJourneyTemplateInterventionListDocument = gql`
    query GuidedCareJourneyTemplateInterventionList($first: Int, $last: Int, $after: String, $before: String, $filter: GuidedCareJourneyFilterInput, $sortBy: GuidedCareJourneySortingInput) {
  guidedCareJourneys(
    first: $first
    last: $last
    after: $after
    before: $before
    filter: $filter
    sortBy: $sortBy
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        activeInterventions {
          templateIntervention {
            id
          }
        }
        eligibleInterventions {
          templateIntervention {
            id
            name
            nameAr
            duration
            interventionTemplateActivities {
              templateActivityType
              interventionActivityEventItems {
                id
                offsetInDays
              }
            }
          }
          healthParameter {
            code
            display
            arabicDisplay
            unit {
              display
              arabicDisplay
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGuidedCareJourneyTemplateInterventionListQuery__
 *
 * To run a query within a React component, call `useGuidedCareJourneyTemplateInterventionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuidedCareJourneyTemplateInterventionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuidedCareJourneyTemplateInterventionListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useGuidedCareJourneyTemplateInterventionListQuery(baseOptions?: Apollo.QueryHookOptions<GuidedCareJourneyTemplateInterventionListQuery, GuidedCareJourneyTemplateInterventionListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GuidedCareJourneyTemplateInterventionListQuery, GuidedCareJourneyTemplateInterventionListQueryVariables>(GuidedCareJourneyTemplateInterventionListDocument, options);
      }
export function useGuidedCareJourneyTemplateInterventionListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GuidedCareJourneyTemplateInterventionListQuery, GuidedCareJourneyTemplateInterventionListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GuidedCareJourneyTemplateInterventionListQuery, GuidedCareJourneyTemplateInterventionListQueryVariables>(GuidedCareJourneyTemplateInterventionListDocument, options);
        }
export function useGuidedCareJourneyTemplateInterventionListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GuidedCareJourneyTemplateInterventionListQuery, GuidedCareJourneyTemplateInterventionListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GuidedCareJourneyTemplateInterventionListQuery, GuidedCareJourneyTemplateInterventionListQueryVariables>(GuidedCareJourneyTemplateInterventionListDocument, options);
        }
export type GuidedCareJourneyTemplateInterventionListQueryHookResult = ReturnType<typeof useGuidedCareJourneyTemplateInterventionListQuery>;
export type GuidedCareJourneyTemplateInterventionListLazyQueryHookResult = ReturnType<typeof useGuidedCareJourneyTemplateInterventionListLazyQuery>;
export type GuidedCareJourneyTemplateInterventionListSuspenseQueryHookResult = ReturnType<typeof useGuidedCareJourneyTemplateInterventionListSuspenseQuery>;
export type GuidedCareJourneyTemplateInterventionListQueryResult = Apollo.QueryResult<GuidedCareJourneyTemplateInterventionListQuery, GuidedCareJourneyTemplateInterventionListQueryVariables>;