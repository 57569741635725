import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VisitStatusUpdateMutationVariables = Types.Exact<{
  visitStatusUpdateId: Types.Scalars['ID']['input'];
}>;


export type VisitStatusUpdateMutation = { __typename?: 'Mutation', doctorVisitComplete?: { __typename?: 'VisitApprovalAction', visit?: { __typename?: 'Visit', id: string } | null, visitErrors?: Array<{ __typename?: 'H_EntityError', code: Types.H_EntityErrorCode, field?: string | null, message?: string | null }> | null } | null };


export const VisitStatusUpdateDocument = gql`
    mutation VisitStatusUpdate($visitStatusUpdateId: ID!) {
  doctorVisitComplete(id: $visitStatusUpdateId) {
    visit {
      id
    }
    visitErrors {
      code
      field
      message
    }
  }
}
    `;
export type VisitStatusUpdateMutationFn = Apollo.MutationFunction<VisitStatusUpdateMutation, VisitStatusUpdateMutationVariables>;

/**
 * __useVisitStatusUpdateMutation__
 *
 * To run a mutation, you first call `useVisitStatusUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVisitStatusUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [visitStatusUpdateMutation, { data, loading, error }] = useVisitStatusUpdateMutation({
 *   variables: {
 *      visitStatusUpdateId: // value for 'visitStatusUpdateId'
 *   },
 * });
 */
export function useVisitStatusUpdateMutation(baseOptions?: Apollo.MutationHookOptions<VisitStatusUpdateMutation, VisitStatusUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VisitStatusUpdateMutation, VisitStatusUpdateMutationVariables>(VisitStatusUpdateDocument, options);
      }
export type VisitStatusUpdateMutationHookResult = ReturnType<typeof useVisitStatusUpdateMutation>;
export type VisitStatusUpdateMutationResult = Apollo.MutationResult<VisitStatusUpdateMutation>;
export type VisitStatusUpdateMutationOptions = Apollo.BaseMutationOptions<VisitStatusUpdateMutation, VisitStatusUpdateMutationVariables>;