import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetDoctorAppointmentCancellationReasonsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetDoctorAppointmentCancellationReasonsQuery = { __typename?: 'Query', cancellationReasons?: Array<{ __typename?: 'H_CancelReason', id: string, text?: string | null, text_ar?: string | null } | null> | null };


export const GetDoctorAppointmentCancellationReasonsDocument = gql`
    query getDoctorAppointmentCancellationReasons {
  cancellationReasons(filter: {actor: DOCTOR, affectedType: APPOINTMENT}) {
    id
    text
    text_ar
  }
}
    `;

/**
 * __useGetDoctorAppointmentCancellationReasonsQuery__
 *
 * To run a query within a React component, call `useGetDoctorAppointmentCancellationReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDoctorAppointmentCancellationReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDoctorAppointmentCancellationReasonsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDoctorAppointmentCancellationReasonsQuery(baseOptions?: Apollo.QueryHookOptions<GetDoctorAppointmentCancellationReasonsQuery, GetDoctorAppointmentCancellationReasonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDoctorAppointmentCancellationReasonsQuery, GetDoctorAppointmentCancellationReasonsQueryVariables>(GetDoctorAppointmentCancellationReasonsDocument, options);
      }
export function useGetDoctorAppointmentCancellationReasonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDoctorAppointmentCancellationReasonsQuery, GetDoctorAppointmentCancellationReasonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDoctorAppointmentCancellationReasonsQuery, GetDoctorAppointmentCancellationReasonsQueryVariables>(GetDoctorAppointmentCancellationReasonsDocument, options);
        }
export function useGetDoctorAppointmentCancellationReasonsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetDoctorAppointmentCancellationReasonsQuery, GetDoctorAppointmentCancellationReasonsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDoctorAppointmentCancellationReasonsQuery, GetDoctorAppointmentCancellationReasonsQueryVariables>(GetDoctorAppointmentCancellationReasonsDocument, options);
        }
export type GetDoctorAppointmentCancellationReasonsQueryHookResult = ReturnType<typeof useGetDoctorAppointmentCancellationReasonsQuery>;
export type GetDoctorAppointmentCancellationReasonsLazyQueryHookResult = ReturnType<typeof useGetDoctorAppointmentCancellationReasonsLazyQuery>;
export type GetDoctorAppointmentCancellationReasonsSuspenseQueryHookResult = ReturnType<typeof useGetDoctorAppointmentCancellationReasonsSuspenseQuery>;
export type GetDoctorAppointmentCancellationReasonsQueryResult = Apollo.QueryResult<GetDoctorAppointmentCancellationReasonsQuery, GetDoctorAppointmentCancellationReasonsQueryVariables>;