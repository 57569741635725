import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketplaceProductSubmitForApprovalMutationVariables = Types.Exact<{
  marketplaceHealthPackageSubmitForApprovalId: Types.Scalars['ID']['input'];
}>;


export type MarketplaceProductSubmitForApprovalMutation = { __typename?: 'Mutation', marketplaceProductSubmitForApproval?: { __typename?: 'MarketplaceProduct', id: string, approvalStatus?: Types.MarketplaceApprovalStatus | null } | null };


export const MarketplaceProductSubmitForApprovalDocument = gql`
    mutation MarketplaceProductSubmitForApproval($marketplaceHealthPackageSubmitForApprovalId: ID!) {
  marketplaceProductSubmitForApproval(
    id: $marketplaceHealthPackageSubmitForApprovalId
  ) {
    id
    approvalStatus
  }
}
    `;
export type MarketplaceProductSubmitForApprovalMutationFn = Apollo.MutationFunction<MarketplaceProductSubmitForApprovalMutation, MarketplaceProductSubmitForApprovalMutationVariables>;

/**
 * __useMarketplaceProductSubmitForApprovalMutation__
 *
 * To run a mutation, you first call `useMarketplaceProductSubmitForApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceProductSubmitForApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [marketplaceProductSubmitForApprovalMutation, { data, loading, error }] = useMarketplaceProductSubmitForApprovalMutation({
 *   variables: {
 *      marketplaceHealthPackageSubmitForApprovalId: // value for 'marketplaceHealthPackageSubmitForApprovalId'
 *   },
 * });
 */
export function useMarketplaceProductSubmitForApprovalMutation(baseOptions?: Apollo.MutationHookOptions<MarketplaceProductSubmitForApprovalMutation, MarketplaceProductSubmitForApprovalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarketplaceProductSubmitForApprovalMutation, MarketplaceProductSubmitForApprovalMutationVariables>(MarketplaceProductSubmitForApprovalDocument, options);
      }
export type MarketplaceProductSubmitForApprovalMutationHookResult = ReturnType<typeof useMarketplaceProductSubmitForApprovalMutation>;
export type MarketplaceProductSubmitForApprovalMutationResult = Apollo.MutationResult<MarketplaceProductSubmitForApprovalMutation>;
export type MarketplaceProductSubmitForApprovalMutationOptions = Apollo.BaseMutationOptions<MarketplaceProductSubmitForApprovalMutation, MarketplaceProductSubmitForApprovalMutationVariables>;