import { Appointment, AppointmentStatus } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, Tooltip, useTheme } from "@toolkit/ui";
import { AppointmentAccept, AppointmentCancel, AppointmentReject, AppointmentReschedule } from "pages/appointments";
import { AppointmentTabsStatus } from "pages/appointments/utils";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { openMeetingPlatformLink } from "shared/components/VisitStartingNowNotification/utils";
import { NotificationItemAction } from "../types";
import PropagationEnd from "./PropagationEnd";

type AppointmentActionProps = {
  item: Appointment;
  status: string;
  onClose?: () => void;
} & NotificationItemAction;

export const AppointmentAction: FC<AppointmentActionProps> = ({ item, status, onClose: handleClose, handleNotificationActionComplete }) => {
  const { t } = useTranslation("provider");
  const navigate = useNavigate();
  const theme = useTheme();
  const handleOpenRequests = () => {
    navigate(`/appointments?appointmentId=${item?.id}&activeTab=${AppointmentTabsStatus.NewRequests}`);
    handleNotificationActionComplete?.();
    handleClose?.();
  };
  const handleJoinCall = () => {
    openMeetingPlatformLink(item?.visit?.id!);
  };

  const hansConfirm = status === AppointmentStatus.Request || status === AppointmentStatus.RescheduledByConsumer;
  const hansReject = status === AppointmentStatus.Request || status === AppointmentStatus.RescheduledByConsumer;
  const hasReschedule =
    hansConfirm ||
    status === AppointmentStatus.Confirmed ||
    status === AppointmentStatus.Approved ||
    status === AppointmentStatus.RescheduledByConsumer;

  const hasCancel =
    status === AppointmentStatus.Confirmed ||
    status === AppointmentStatus.Approved ||
    status === AppointmentStatus.RescheduledByConsumer ||
    status === AppointmentStatus.RescheduledByDoctor;

  const hasJoinCall = status === AppointmentStatus.CallInProgress || status === AppointmentStatus.ChatInProgress;

  return (
    <Box mt={1} display='flex' gap={1} flexWrap='wrap'>
      {hansConfirm && (
        <PropagationEnd>
          <AppointmentAccept
            handleNotificationActionComplete={handleNotificationActionComplete}
            id={item?.id}
            activeHealthProgramMember={item?.consumer?.activeHealthProgramMembers?.[0]}
            healthProgramsByActiveMember={item?.consumer?.healthProgramsByActiveMembers?.[0]}
            requestedTimeSlots={item?.requestedTimeSlots!}
            buttonEl={
              <Button variant='outlined' sx={{ width: 120 }} color='success' onMouseUp={handleClose}>
                {t("Accept")}
              </Button>
            }
          />
        </PropagationEnd>
      )}

      {hansReject && (
        <PropagationEnd>
          <AppointmentReject
            handleNotificationActionComplete={handleNotificationActionComplete}
            id={item?.id}
            requestedTimeSlots={item?.requestedTimeSlots}
            buttonEl={
              <Button variant='outlined' color='error' sx={{ width: 120 }} onMouseUp={handleClose}>
                {t("Reject")}
              </Button>
            }
          />
        </PropagationEnd>
      )}
      {hasReschedule && (
        <PropagationEnd>
          <AppointmentReschedule
            handleNotificationActionComplete={handleNotificationActionComplete}
            id={item?.id!}
            status={status!}
            doctorId={item?.doctor?.id}
            patient={item?.consumer}
            paymentInfo={item?.paymentInfo}
            paymentStatus={item?.paymentStatus}
          />
        </PropagationEnd>
      )}
      {hasCancel && (
        <PropagationEnd>
          <AppointmentCancel
            handleNotificationActionComplete={handleNotificationActionComplete}
            id={item?.id!}
            status={status!}
            buttonEl={
              <Button variant='outlined' color='error' sx={{ width: 120 }}>
                {t("Cancel")}
              </Button>
            }
          />
        </PropagationEnd>
      )}
      {hansConfirm && (
        <PropagationEnd>
          <Button variant='outlined' sx={{ width: 120, p: 2, fontSize: theme.mixins.fonts.fontSize.xs }} onClick={handleOpenRequests}>
            {t("Manage Appointments")}
          </Button>
        </PropagationEnd>
      )}

      {item?.visit?.callId && hasJoinCall && (
        <Tooltip title={!item?.visit?.callId ? t("No meeting link available") : ""}>
          <span>
            <Button disabled={!item?.visit?.callId} onClick={handleJoinCall}>
              {t("join now")}
            </Button>
          </span>
        </Tooltip>
      )}
    </Box>
  );
};
