import { MarketplaceOrderType } from "@/schema/types";
import { Box, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { FC } from "react";
import { GridProvider } from "shared/components";
import { ProductOrdersHeader } from "./Components/ProductOrdersHeader";
import { useGetProductOrdersListColumns } from "./Components/useGetProductOrdersListColumns";
import { useProductOrdersState } from "./Components/useProductOrdersState";
import { useGetProductOrdersQuery } from "./gql";

export const ProductOrdersScreen: FC = () => {
  const { onChangeTab, selectedTab } = useProductOrdersState();

  return (
    <GridProvider
      gridName='GetProductOrdersQuery'
      columns={useGetProductOrdersListColumns()}
      query={useGetProductOrdersQuery}
      dataAccessor='productOrders'
      fetchPolicy='network-only'
      nextFetchPolicy='cache-first'
      filterInput={{
        statuses: selectedTab.statusEnums,
        type: MarketplaceOrderType.Product,
      }}
      hasTableSetting
    >
      <PageWrapper
        filters={
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "50px",
              justifyContent: "end",
            }}
          />
        }
        actions={
          <>
            <TableSettingComponent />
          </>
        }
        headerComponent={<ProductOrdersHeader selectedTab={selectedTab} onChangeTab={onChangeTab} />}
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
