import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthPackageUpdateMutationVariables = Types.Exact<{
  healthPackageUpdateId: Types.Scalars['ID']['input'];
  input: Types.MarketplaceHealthPackageInput;
}>;


export type HealthPackageUpdateMutation = { __typename?: 'Mutation', marketplaceHealthPackageUpdate?: { __typename?: 'MarketplaceHealthPackage', id: string } | null };


export const HealthPackageUpdateDocument = gql`
    mutation healthPackageUpdate($healthPackageUpdateId: ID!, $input: MarketplaceHealthPackageInput!) {
  marketplaceHealthPackageUpdate(id: $healthPackageUpdateId, input: $input) {
    id
  }
}
    `;
export type HealthPackageUpdateMutationFn = Apollo.MutationFunction<HealthPackageUpdateMutation, HealthPackageUpdateMutationVariables>;

/**
 * __useHealthPackageUpdateMutation__
 *
 * To run a mutation, you first call `useHealthPackageUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHealthPackageUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [healthPackageUpdateMutation, { data, loading, error }] = useHealthPackageUpdateMutation({
 *   variables: {
 *      healthPackageUpdateId: // value for 'healthPackageUpdateId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHealthPackageUpdateMutation(baseOptions?: Apollo.MutationHookOptions<HealthPackageUpdateMutation, HealthPackageUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HealthPackageUpdateMutation, HealthPackageUpdateMutationVariables>(HealthPackageUpdateDocument, options);
      }
export type HealthPackageUpdateMutationHookResult = ReturnType<typeof useHealthPackageUpdateMutation>;
export type HealthPackageUpdateMutationResult = Apollo.MutationResult<HealthPackageUpdateMutation>;
export type HealthPackageUpdateMutationOptions = Apollo.BaseMutationOptions<HealthPackageUpdateMutation, HealthPackageUpdateMutationVariables>;