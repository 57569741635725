import { Badge, IconButton, SpeechIcon } from "@toolkit/ui";
import { useNavigate } from "react-router-dom";

export const ChatStatus = () => {
  const unreadMessages = 0;
  const navigate = useNavigate();
  const handleGoToChat = () => {
    navigate("/chat");
  };

  return (
    <Badge badgeContent={unreadMessages} color={"primary"}>
      <IconButton onClick={handleGoToChat}>
        <SpeechIcon />
      </IconButton>
    </Badge>
  );
};
