import { BranchType } from "@/schema/types";
import { formGirdSpacing } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, Grid, PageWrapper } from "@toolkit/ui";
import { FC } from "react";
import { FormProvider } from "react-hook-form";
import { WorkingHours } from "shared/components";
import { BranchFormProps } from "./BranchesForm.types";
import Address from "./components/BranchesAddress.component";
import { GeneralInformation } from "./components/GeneralInformation/BranchesGeneralInfo.component";
import { PreferredPharmaciesCard } from "./components/PreferredPharmacies";
import { useBranchFormHook } from "./useBranchesInfoHook";

const BranchForm: FC<BranchFormProps> = ({ isEditMode, branch, onSubmit: handleOnSubmit, isSaving, loading, errors }) => {
  const { t } = useTranslation("provider");

  const { methods, location, onSubmit, handleLocation, handleSubmit, handleCancel, watch } = useBranchFormHook({
    branch,
    handleOnSubmit,
  });

  const doYouHaveBranch = isEditMode ? branch != undefined : true;
  const formButtonTitle = isEditMode ? t("Update") : t("Create");
  const branchType = watch("type")?.["value"];

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              isLoading={loading}
              newButtonDisabled={isSaving}
              hasCancel
              hasFormButton
              formButtonTitle={formButtonTitle}
              onNavigation={handleCancel}
              loadingIndicators={{
                edit: isEditMode && isSaving,
                save: !isEditMode && isSaving,
              }}
            />
          }
        >
          <Grid container spacing={formGirdSpacing}>
            <Grid item xs={12}>
              <FormCard title={t("General Information")} loading={loading} doYouHaveData={doYouHaveBranch}>
                <GeneralInformation defaultValue={branch} errorsInput={errors} />
              </FormCard>
            </Grid>

            <Grid item xs={12}>
              <WorkingHours
                isLoading={loading}
                inputName='workingHours'
                doYouHaveData={doYouHaveBranch}
                workingHoursDefaultValue={branch?.workingHours}
              />
            </Grid>

            {branchType && branchType !== BranchType.Pharmacy && (
              <Grid item xs={12}>
                <PreferredPharmaciesCard isLoading={!!loading} hasData={doYouHaveBranch} defaultValues={branch} />
              </Grid>
            )}

            <Grid item xs={12}>
              <FormCard title={t("Address And Location")} loading={loading} doYouHaveData={doYouHaveBranch}>
                <Address defaultValues={branch} errorsInput={errors} location={location} onLocationChange={handleLocation} />
              </FormCard>
            </Grid>
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};

export default BranchForm;
