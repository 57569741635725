import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetMeDoctorUserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetMeDoctorUserQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, photo?: string | null, dateOfBirth?: any | null, vendor?: { __typename?: 'Vendor', id: string, backGroundImage?: string | null } | null, doctor?: { __typename?: 'Doctor', id: string, bio?: string | null, socialLinks?: any | null, appointmentSlotTimePeriod: number, appointmentTypes?: Array<Types.AppointmentTypeEnum | null> | null, yearsOfExperience?: number | null, seniority?: Types.DoctorSeniorityEnum | null, isDateOfBirthPublic: boolean, isLanguagesPublic: boolean, isLicenseNumberPublic: boolean, isMobileNumberPublic: boolean, isNationalIdPublic: boolean, isSecondMobileNumberPublic: boolean, isSocialLinksPublic: boolean, isYearsOfExperiencePublic: boolean, languages?: Array<{ __typename?: 'Language', code: string, display: string, displayAr?: string | null, id: string } | null> | null, workingHours?: Array<{ __typename?: 'WorkingHour', day?: Types.DayOfWeekEnum | null, openTimeRanges?: Array<{ __typename?: 'TimeRange', closeTime?: any | null, openTime?: any | null } | null> | null } | null> | null, qualifications?: Array<{ __typename?: 'Qualification', code: string, issuer: string, fromDate?: any | null, toDate?: any | null } | null> | null, specializations?: Array<{ __typename?: 'DoctorSpecialization', id: string, code: string, display?: string | null } | null> | null } | null, addresses?: Array<{ __typename?: 'Address', area: string, buildingName?: string | null, buildingNumber?: string | null, city: { __typename?: 'City', name: string }, coordinates?: { __typename?: 'LocationType', lat?: number | null, lng?: number | null } | null } | null> | null } | null };


export const GetMeDoctorUserDocument = gql`
    query getMeDoctorUser {
  me {
    id
    firstName
    lastName
    photo
    vendor {
      id
      backGroundImage
    }
    doctor {
      id
      bio
      languages {
        code
        display
        displayAr
        id
      }
      socialLinks
      workingHours {
        day
        openTimeRanges {
          closeTime
          openTime
        }
      }
      appointmentSlotTimePeriod
      appointmentTypes
      qualifications {
        code
        issuer
        fromDate
        toDate
      }
      yearsOfExperience
      specializations {
        id
        code
        display
      }
      seniority
      isDateOfBirthPublic
      isLanguagesPublic
      isLicenseNumberPublic
      isMobileNumberPublic
      isNationalIdPublic
      isSecondMobileNumberPublic
      isSocialLinksPublic
      isYearsOfExperiencePublic
    }
    addresses {
      area
      buildingName
      buildingNumber
      city {
        name
      }
      coordinates {
        lat
        lng
      }
    }
    dateOfBirth
  }
}
    `;

/**
 * __useGetMeDoctorUserQuery__
 *
 * To run a query within a React component, call `useGetMeDoctorUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeDoctorUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeDoctorUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeDoctorUserQuery(baseOptions?: Apollo.QueryHookOptions<GetMeDoctorUserQuery, GetMeDoctorUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMeDoctorUserQuery, GetMeDoctorUserQueryVariables>(GetMeDoctorUserDocument, options);
      }
export function useGetMeDoctorUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeDoctorUserQuery, GetMeDoctorUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMeDoctorUserQuery, GetMeDoctorUserQueryVariables>(GetMeDoctorUserDocument, options);
        }
export function useGetMeDoctorUserSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetMeDoctorUserQuery, GetMeDoctorUserQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMeDoctorUserQuery, GetMeDoctorUserQueryVariables>(GetMeDoctorUserDocument, options);
        }
export type GetMeDoctorUserQueryHookResult = ReturnType<typeof useGetMeDoctorUserQuery>;
export type GetMeDoctorUserLazyQueryHookResult = ReturnType<typeof useGetMeDoctorUserLazyQuery>;
export type GetMeDoctorUserSuspenseQueryHookResult = ReturnType<typeof useGetMeDoctorUserSuspenseQuery>;
export type GetMeDoctorUserQueryResult = Apollo.QueryResult<GetMeDoctorUserQuery, GetMeDoctorUserQueryVariables>;