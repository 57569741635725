import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { PatientProfileFragmentFragmentDoc } from '../../fragments/__generated__/patientProfile';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramMemberBodyCompositionUpdateMutationVariables = Types.Exact<{
  input: Types.HealthProgramMemberBodyCompositionInput;
}>;


export type HealthProgramMemberBodyCompositionUpdateMutation = { __typename?: 'Mutation', healthProgramMemberBodyCompositionUpdate?: { __typename?: 'HealthProgramMemberBodyCompositionCRUD', entity?: { __typename?: 'HealthProgramMemberBodyComposition', bmi?: number | null, weightInKG?: number | null, heightInCM?: number | null, patient?: { __typename?: 'Patient', id: string, firstName?: string | null, lastName?: string | null, gender?: string | null, dateOfBirth?: any | null, contactNumber?: string | null, nationality?: string | null, activeHealthProgramMembers?: Array<{ __typename?: 'HealthProgramMember', id: string, nationalId?: string | null, isDefault?: boolean | null, payer?: { __typename?: 'Payer', id: string, name: string } | null } | null> | null, user?: { __typename?: 'User', id: string, photo?: string | null, preferredLanguage?: { __typename?: 'Language', code: string, display: string, displayAr?: string | null, id: string } | null } | null, bodyComposition?: { __typename?: 'HealthProgramMemberBodyCompositionBasic', bmi?: number | null, sleep?: number | null, smoking?: number | null, weightInKG?: number | null, heightInCM?: number | null, exercise?: boolean | null, dietType?: Types.DietType | null, alcohol?: boolean | null } | null, bodyCompositions?: { __typename?: 'HealthProgramMemberBodyCompositionCountableConnection', totalCount?: number | null, edges: Array<{ __typename?: 'HealthProgramMemberBodyCompositionCountableEdge', node: { __typename?: 'HealthProgramMemberBodyComposition', modified?: any | null, bmi?: number | null, sleep?: number | null, smoking?: number | null, weightInKG?: number | null, heightInCM?: number | null, exercise?: boolean | null, dietType?: Types.DietType | null, alcohol?: boolean | null } }> } | null } | null } | null, entityErrors?: Array<{ __typename?: 'H_EntityError', code: Types.H_EntityErrorCode, field?: string | null, message?: string | null }> | null } | null };


export const HealthProgramMemberBodyCompositionUpdateDocument = gql`
    mutation HealthProgramMemberBodyCompositionUpdate($input: HealthProgramMemberBodyCompositionInput!) {
  healthProgramMemberBodyCompositionUpdate(input: $input) {
    entity {
      bmi
      weightInKG
      heightInCM
      patient {
        ...PatientProfileFragment
      }
    }
    entityErrors {
      code
      field
      message
    }
  }
}
    ${PatientProfileFragmentFragmentDoc}`;
export type HealthProgramMemberBodyCompositionUpdateMutationFn = Apollo.MutationFunction<HealthProgramMemberBodyCompositionUpdateMutation, HealthProgramMemberBodyCompositionUpdateMutationVariables>;

/**
 * __useHealthProgramMemberBodyCompositionUpdateMutation__
 *
 * To run a mutation, you first call `useHealthProgramMemberBodyCompositionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramMemberBodyCompositionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [healthProgramMemberBodyCompositionUpdateMutation, { data, loading, error }] = useHealthProgramMemberBodyCompositionUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHealthProgramMemberBodyCompositionUpdateMutation(baseOptions?: Apollo.MutationHookOptions<HealthProgramMemberBodyCompositionUpdateMutation, HealthProgramMemberBodyCompositionUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HealthProgramMemberBodyCompositionUpdateMutation, HealthProgramMemberBodyCompositionUpdateMutationVariables>(HealthProgramMemberBodyCompositionUpdateDocument, options);
      }
export type HealthProgramMemberBodyCompositionUpdateMutationHookResult = ReturnType<typeof useHealthProgramMemberBodyCompositionUpdateMutation>;
export type HealthProgramMemberBodyCompositionUpdateMutationResult = Apollo.MutationResult<HealthProgramMemberBodyCompositionUpdateMutation>;
export type HealthProgramMemberBodyCompositionUpdateMutationOptions = Apollo.BaseMutationOptions<HealthProgramMemberBodyCompositionUpdateMutation, HealthProgramMemberBodyCompositionUpdateMutationVariables>;