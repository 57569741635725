import { Maybe, QuestionAnswerExtended } from "@/schema/types";
import { Button, TruncateTypography } from "@toolkit/ui";
import { FC } from "react";
import { HealthInsuranceSatisfaction } from "./HealthInsuranceSatisfaction";
import { useOpenState } from "@toolkit/core";

type MedicalFormDialogProps = {
  classificationName: string;
  numberOfQuestions: number;
  questionAnswers: Maybe<QuestionAnswerExtended>[];
  date: string;
  label: string;
};

export const MedicalFormDialog: FC<MedicalFormDialogProps> = props => {
  const { label, date, classificationName, numberOfQuestions, questionAnswers } = props;

  const { open, handleToggle } = useOpenState();

  return (
    <>
      <Button variant='text' onClick={handleToggle}>
        <TruncateTypography text={label!} />
      </Button>
      {open && (
        <HealthInsuranceSatisfaction
          isOpen={open}
          date={date}
          label={label!}
          questionAnswers={questionAnswers!}
          onToggle={handleToggle}
          classificationName={classificationName!}
          numberOfQuestions={numberOfQuestions}
        />
      )}
    </>
  );
};
