import { CustomTable, useGridContext } from "@toolkit/ui";
import { FC } from "react";

export const Table: FC = () => {
  const { customTableProps } = useGridContext();

  return (
    <CustomTable
      {...customTableProps!}
      TableContainerProps={{
        sx: {
          height: 500,
        },
      }}
    />
  );
};
