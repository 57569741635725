import { MarketplaceOrderType } from "@/schema/types";
import { Box, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { FC } from "react";
import { GridProvider } from "shared/components";
import { HealthPackageOrdersHeader } from "./Components/HealthPackageOrdersHeader";
import { useGetHealthPackageOrdersListColumns } from "./Components/useGetHealthPackageOrdersListColumns";
import { useHealthPackageOrdersList as useHealthPackageOrdersState } from "./Components/useHealthPackageOrdersState";
import { useGetHealthPackageOrdersQuery } from "./gql";

export const HealthPackageOrdersScreen: FC = () => {
  const { onChangeTab, selectedTab } = useHealthPackageOrdersState();

  return (
    <GridProvider
      gridName='GetHealthPackageOrdersQuery'
      columns={useGetHealthPackageOrdersListColumns()}
      query={useGetHealthPackageOrdersQuery}
      dataAccessor='healthPackageOrders'
      fetchPolicy='network-only'
      nextFetchPolicy='cache-first'
      filterInput={{
        statuses: selectedTab.statusEnums,
        type: MarketplaceOrderType.HealthPackage,
      }}
      hasTableSetting
    >
      <PageWrapper
        filters={
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "50px",
              justifyContent: "end",
            }}
          />
        }
        actions={
          <>
            <TableSettingComponent />
          </>
        }
        headerComponent={<HealthPackageOrdersHeader selectedTab={selectedTab} onChangeTab={onChangeTab} />}
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
