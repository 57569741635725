import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PreviousVisitsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type PreviousVisitsQuery = { __typename?: 'Query', visits?: { __typename?: 'VisitCountableConnection', edges: Array<{ __typename?: 'VisitCountableEdge', node: { __typename?: 'Visit', id: string, type?: Types.VisitType | null, status?: Types.VisitStatus | null, created?: any | null, hasRad?: boolean | null, hasLab?: boolean | null, hasDoctorNote?: boolean | null, doctor?: { __typename?: 'Doctor', user?: { __typename?: 'User', id: string, fullName?: string | null } | null } | null, appointment?: { __typename?: 'Appointment', id: string, type?: Types.AppointmentType | null, status?: Types.AppointmentStatus | null } | null, visitPrescriptions?: Array<{ __typename?: 'VisitPrescriptionTransaction', activities?: Array<{ __typename?: 'VisitPrescriptionActivity', id: string, instructions?: string | null, observation?: string | null, quantity?: number | null, refills?: number | null, code?: string | null, denialCode?: string | null, denialDescription?: string | null, duration?: string | null, frequencyUnitType?: string | null, frequencyValue?: number | null } | null> | null } | null> | null } }> } | null };


export const PreviousVisitsDocument = gql`
    query PreviousVisits($first: Int) {
  visits(first: $first) {
    edges {
      node {
        id
        type
        status
        created
        hasRad
        hasLab
        hasDoctorNote
        doctor {
          user {
            id
            fullName
          }
        }
        appointment {
          id
          type
          status
        }
        visitPrescriptions {
          activities {
            id
            instructions
            observation
            quantity
            refills
            code
            denialCode
            denialDescription
            duration
            frequencyUnitType
            frequencyValue
          }
        }
      }
    }
  }
}
    `;

/**
 * __usePreviousVisitsQuery__
 *
 * To run a query within a React component, call `usePreviousVisitsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreviousVisitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreviousVisitsQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function usePreviousVisitsQuery(baseOptions?: Apollo.QueryHookOptions<PreviousVisitsQuery, PreviousVisitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PreviousVisitsQuery, PreviousVisitsQueryVariables>(PreviousVisitsDocument, options);
      }
export function usePreviousVisitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PreviousVisitsQuery, PreviousVisitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PreviousVisitsQuery, PreviousVisitsQueryVariables>(PreviousVisitsDocument, options);
        }
export function usePreviousVisitsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PreviousVisitsQuery, PreviousVisitsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PreviousVisitsQuery, PreviousVisitsQueryVariables>(PreviousVisitsDocument, options);
        }
export type PreviousVisitsQueryHookResult = ReturnType<typeof usePreviousVisitsQuery>;
export type PreviousVisitsLazyQueryHookResult = ReturnType<typeof usePreviousVisitsLazyQuery>;
export type PreviousVisitsSuspenseQueryHookResult = ReturnType<typeof usePreviousVisitsSuspenseQuery>;
export type PreviousVisitsQueryResult = Apollo.QueryResult<PreviousVisitsQuery, PreviousVisitsQueryVariables>;