import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetTradeDrugQuantityQueryVariables = Types.Exact<{
  prescriptionId: Types.Scalars['ID']['input'];
}>;


export type GetTradeDrugQuantityQuery = { __typename?: 'Query', getTradeDrugQuantity?: Array<{ __typename?: 'MedicationTradeDrugs', code: string, tradeDrugs?: Array<{ __typename?: 'TradeDrug', code: string, display: string, quantity?: number | null, isHazardous?: boolean | null, isHighAlert?: boolean | null, isLASA?: boolean | null } | null> | null } | null> | null };


export const GetTradeDrugQuantityDocument = gql`
    query getTradeDrugQuantity($prescriptionId: ID!) {
  getTradeDrugQuantity(prescriptionId: $prescriptionId) {
    code
    tradeDrugs {
      code
      display
      quantity
      isHazardous
      isHighAlert
      isLASA
    }
  }
}
    `;

/**
 * __useGetTradeDrugQuantityQuery__
 *
 * To run a query within a React component, call `useGetTradeDrugQuantityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTradeDrugQuantityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTradeDrugQuantityQuery({
 *   variables: {
 *      prescriptionId: // value for 'prescriptionId'
 *   },
 * });
 */
export function useGetTradeDrugQuantityQuery(baseOptions: Apollo.QueryHookOptions<GetTradeDrugQuantityQuery, GetTradeDrugQuantityQueryVariables> & ({ variables: GetTradeDrugQuantityQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTradeDrugQuantityQuery, GetTradeDrugQuantityQueryVariables>(GetTradeDrugQuantityDocument, options);
      }
export function useGetTradeDrugQuantityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTradeDrugQuantityQuery, GetTradeDrugQuantityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTradeDrugQuantityQuery, GetTradeDrugQuantityQueryVariables>(GetTradeDrugQuantityDocument, options);
        }
export function useGetTradeDrugQuantitySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetTradeDrugQuantityQuery, GetTradeDrugQuantityQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTradeDrugQuantityQuery, GetTradeDrugQuantityQueryVariables>(GetTradeDrugQuantityDocument, options);
        }
export type GetTradeDrugQuantityQueryHookResult = ReturnType<typeof useGetTradeDrugQuantityQuery>;
export type GetTradeDrugQuantityLazyQueryHookResult = ReturnType<typeof useGetTradeDrugQuantityLazyQuery>;
export type GetTradeDrugQuantitySuspenseQueryHookResult = ReturnType<typeof useGetTradeDrugQuantitySuspenseQuery>;
export type GetTradeDrugQuantityQueryResult = Apollo.QueryResult<GetTradeDrugQuantityQuery, GetTradeDrugQuantityQueryVariables>;