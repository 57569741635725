import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VisitSummaryDeleteMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type VisitSummaryDeleteMutation = { __typename?: 'Mutation', visitSummaryDelete?: { __typename?: 'VisitSummaryCRUD', visitSummary?: { __typename?: 'VisitSummary', id: string, isProblemSolved?: boolean | null, doctorNotes?: string | null } | null, visitSummaryErrors?: Array<{ __typename?: 'H_EntityError', code: Types.H_EntityErrorCode, field?: string | null, message?: string | null }> | null } | null };


export const VisitSummaryDeleteDocument = gql`
    mutation visitSummaryDelete($id: ID!) {
  visitSummaryDelete(id: $id) {
    visitSummary {
      id
      isProblemSolved
      doctorNotes
    }
    visitSummaryErrors {
      code
      field
      message
    }
  }
}
    `;
export type VisitSummaryDeleteMutationFn = Apollo.MutationFunction<VisitSummaryDeleteMutation, VisitSummaryDeleteMutationVariables>;

/**
 * __useVisitSummaryDeleteMutation__
 *
 * To run a mutation, you first call `useVisitSummaryDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVisitSummaryDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [visitSummaryDeleteMutation, { data, loading, error }] = useVisitSummaryDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVisitSummaryDeleteMutation(baseOptions?: Apollo.MutationHookOptions<VisitSummaryDeleteMutation, VisitSummaryDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VisitSummaryDeleteMutation, VisitSummaryDeleteMutationVariables>(VisitSummaryDeleteDocument, options);
      }
export type VisitSummaryDeleteMutationHookResult = ReturnType<typeof useVisitSummaryDeleteMutation>;
export type VisitSummaryDeleteMutationResult = Apollo.MutationResult<VisitSummaryDeleteMutation>;
export type VisitSummaryDeleteMutationOptions = Apollo.BaseMutationOptions<VisitSummaryDeleteMutation, VisitSummaryDeleteMutationVariables>;