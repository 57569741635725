import { AppointmentStatus, NotificationType } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { useTheme } from "@toolkit/ui";
import { GuidedCareTaskNotification } from "pages/GuidedCareTasks/components/GuidedCareTaskNotification/GuidedCareTaskNotification";
import { FC } from "react";
import { AccessResponseNotifications } from "shared/modules/patient";
import { AppointmentNotification } from "./AppointmentNotification/AppointmentNotification";
import { AppointmentReminder } from "./AppointmentNotification/AppointmentReminder";
import { NotificationContent } from "./NotificationContent";
import { VisitNotification } from "./VisitNotification/NotificationVisit";
import { NotificationItemProps } from "./types";

export const NotificationItem: FC<NotificationItemProps> = ({
  type,
  id,
  data,
  isDisplayedInDropDown = false,
  handleNotificationActionComplete,
}) => {
  const { t } = useTranslation("provider");

  const title = type.replaceAll("-", " ");
  const theme = useTheme();

  const status = data && data?.changes?.find((change: { field: string; value: string }) => change.field === "status")?.value;

  switch (type) {
    case NotificationType.HealthProgramAppointmentStatusChanged:
      return (
        <AppointmentNotification
          handleNotificationActionComplete={handleNotificationActionComplete}
          id={id}
          status={status as AppointmentStatus}
          isDisplayedInDropDown={isDisplayedInDropDown}
        />
      );
    case NotificationType.HealthProgramVisitStatusChanged:
      return <VisitNotification id={id} status={status} isDisplayedInDropDown={isDisplayedInDropDown} />;
    case NotificationType.PatientProfileAccessResponse:
      return <AccessResponseNotifications data={data} />;
    case NotificationType.HealthProgramAppointmentReminder:
      return (
        <AppointmentReminder
          id={data?.id!}
          englishText={data?.englishText}
          arabicText={data?.arabicText}
          notificationType={data?.notificationType}
          changes={data?.changes}
          isDisplayedInDropDown={isDisplayedInDropDown}
        />
      );
    case NotificationType.GuidedCareTaskNotification:
      return <GuidedCareTaskNotification data={data} />;
    default:
      return <NotificationContent title={t(title)} color={theme.palette.success.main} />;
  }
};
