import { Box, CustomDialog, CustomTable, ShowButton, Typography, useTheme } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import moment from "moment";
import { OptimaValidationInfo } from "../OptimaValidationInfo";
import { OptimaValidationInfoHeader } from "../OptimaValidationInfoHeader";
import React, { FC } from "react";
import { useDiagnosesListColumns } from "../useDiagnosesListColumns";
import { useItemsListColumns } from "../useItemsListColumns";

type OptimaValidationRequestDialogProps = {
  request: string;
};

export const OptimaValidationRequestDialog: FC<OptimaValidationRequestDialogProps> = ({ request }) => {
  const { t } = useTranslation("provider");
  const theme = useTheme();

  const getRequests = JSON.parse(request);

  return (
    <CustomDialog type={"info"} button={<ShowButton />} title={t("Optima Validation Request")}>
      <Typography fontSize={theme.mixins.fonts.fontSize.lg} fontWeight={theme.mixins.fonts.fontWeight.medium}>
        {t("Patient Info")}
      </Typography>

      <Typography variant={"body1"}>
        <Typography component='span' fontWeight={theme.mixins.fonts.fontWeight.medium}>
          {getRequests.patientName}
        </Typography>
        , {getRequests.patientGender}, {moment().diff(getRequests.patientDoB, "years")} {t("years")}
      </Typography>

      <Typography variant={"body1"} noWrap>
        <Typography component='span' fontWeight={theme.mixins.fonts.fontWeight.medium}>
          {t("Weight")}:
        </Typography>{" "}
        {getRequests.patientWeight || "-"},{" "}
        <Typography component='span' fontWeight={theme.mixins.fonts.fontWeight.medium}>
          {t("Height")}:
        </Typography>{" "}
        {getRequests.patientHeight || "-"}
      </Typography>

      <OptimaValidationInfoHeader title={t("Insurance Info")} />
      <Box m={2}>
        <OptimaValidationInfo title={t("Insurance Payer")} info={getRequests.insurancePayer} />
        <OptimaValidationInfo title={t("Insurance Receiver")} info={getRequests.insuranceReceiver} />
        <OptimaValidationInfo title={t("Insurance MemberId")} info={getRequests.insuranceMemberId} />
        <OptimaValidationInfo title={t("Insurance Policy")} info={getRequests.insurancePolicy} />
        <OptimaValidationInfo title={t("Insurance Policy Location")} info={getRequests.insurancePolicyLocation} />
        <OptimaValidationInfo title={t("Insurance Plan")} info={getRequests.insurancePlan} />
      </Box>

      <OptimaValidationInfoHeader title={t("Diagnoses")} />
      <CustomTable
        data={getRequests.diagnoses}
        columns={useDiagnosesListColumns()}
        scrollable
        hasFooter={false}
        TableContainerProps={{
          sx: {
            minHeight: "inherit !important",
          },
        }}
      />

      <OptimaValidationInfoHeader title={t("Items")} />
      <CustomTable
        data={getRequests.items}
        columns={useItemsListColumns()}
        scrollable
        hasFooter={false}
        TableContainerProps={{
          sx: {
            minHeight: "inherit !important",
          },
        }}
      />
    </CustomDialog>
  );
};
