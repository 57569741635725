import { TemplateIntervention } from "@/schema/types";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { Box, CustomMuiDialogTitle, IconButton, ModalCloseIcon, MuiArrowBackIcon, Typography } from "@toolkit/ui";
import { FC } from "react";
import { useGuidedCareJourneyInterventionsActivitiesDialogTitleStyle } from "./useGuidedCareJourneyInterventionsActivitiesDialogTitleStyle";

export type IGuidedCareJourneyInterventionsActivitiesDialogTitleEvent = {
  type: "BACK_CLICK";
};

type GuidedCareJourneyInterventionsActivitiesDialogTitleProps = {
  selectedIntervention?: TemplateIntervention;
  onDialogClose: () => void;
  onChange: (event: IGuidedCareJourneyInterventionsActivitiesDialogTitleEvent) => void;
};

export const GuidedCareJourneyInterventionsActivitiesDialogTitle: FC<GuidedCareJourneyInterventionsActivitiesDialogTitleProps> = props => {
  const { selectedIntervention, onDialogClose, onChange } = props;

  const { t } = useTranslation("provider");

  const { classes } = useGuidedCareJourneyInterventionsActivitiesDialogTitleStyle();

  const onArrowBackIconClick = () => {
    onChange({
      type: "BACK_CLICK",
    });
  };

  return (
    <CustomMuiDialogTitle classes={{ root: classes.root }} hasCloseButton={false}>
      <Box className={classes.wrapper}>
        <Box className={classes.titleWrapper}>
          {selectedIntervention && (
            <IconButton className={classes.iconWrapper} onClick={onArrowBackIconClick}>
              <MuiArrowBackIcon color={"primary"} className={classes.icon} />
            </IconButton>
          )}

          <Typography className={classes.title}>
            {selectedIntervention
              ? pickLocalizedValue(selectedIntervention?.name, selectedIntervention?.nameAr)
              : t("Immediate Intervention")}
          </Typography>
        </Box>

        <IconButton className={classes.icon} onClick={onDialogClose}>
          <ModalCloseIcon color={"primary"} />
        </IconButton>
      </Box>
    </CustomMuiDialogTitle>
  );
};
