import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GuidedCareProgramMemberRiskFactorParametersEvaluateMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.EvaluateProgramRiskFactorTemplateInput>;
}>;


export type GuidedCareProgramMemberRiskFactorParametersEvaluateMutation = { __typename?: 'Mutation', evaluateGuidedCareProgramRiskFactorTemplate?: { __typename?: 'GuidedCareHealthProgramRiskFactorTemplateEvaluation', level?: string | null, notification?: string | null, enrolledGuidedPrograms?: Array<{ __typename?: 'GuidedCareHealthProgram', id: string, name?: string | null } | null> | null, suggestedGuidedPrograms?: Array<{ __typename?: 'GuidedCareHealthProgram', id: string, name?: string | null } | null> | null } | null };


export const GuidedCareProgramMemberRiskFactorParametersEvaluateDocument = gql`
    mutation GuidedCareProgramMemberRiskFactorParametersEvaluate($input: EvaluateProgramRiskFactorTemplateInput) {
  evaluateGuidedCareProgramRiskFactorTemplate(input: $input) {
    level
    notification
    enrolledGuidedPrograms {
      id
      name
    }
    suggestedGuidedPrograms {
      id
      name
    }
  }
}
    `;
export type GuidedCareProgramMemberRiskFactorParametersEvaluateMutationFn = Apollo.MutationFunction<GuidedCareProgramMemberRiskFactorParametersEvaluateMutation, GuidedCareProgramMemberRiskFactorParametersEvaluateMutationVariables>;

/**
 * __useGuidedCareProgramMemberRiskFactorParametersEvaluateMutation__
 *
 * To run a mutation, you first call `useGuidedCareProgramMemberRiskFactorParametersEvaluateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuidedCareProgramMemberRiskFactorParametersEvaluateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guidedCareProgramMemberRiskFactorParametersEvaluateMutation, { data, loading, error }] = useGuidedCareProgramMemberRiskFactorParametersEvaluateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGuidedCareProgramMemberRiskFactorParametersEvaluateMutation(baseOptions?: Apollo.MutationHookOptions<GuidedCareProgramMemberRiskFactorParametersEvaluateMutation, GuidedCareProgramMemberRiskFactorParametersEvaluateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GuidedCareProgramMemberRiskFactorParametersEvaluateMutation, GuidedCareProgramMemberRiskFactorParametersEvaluateMutationVariables>(GuidedCareProgramMemberRiskFactorParametersEvaluateDocument, options);
      }
export type GuidedCareProgramMemberRiskFactorParametersEvaluateMutationHookResult = ReturnType<typeof useGuidedCareProgramMemberRiskFactorParametersEvaluateMutation>;
export type GuidedCareProgramMemberRiskFactorParametersEvaluateMutationResult = Apollo.MutationResult<GuidedCareProgramMemberRiskFactorParametersEvaluateMutation>;
export type GuidedCareProgramMemberRiskFactorParametersEvaluateMutationOptions = Apollo.BaseMutationOptions<GuidedCareProgramMemberRiskFactorParametersEvaluateMutation, GuidedCareProgramMemberRiskFactorParametersEvaluateMutationVariables>;