import { AdminUserCreateInput, AppRoleTypes, AppTypes, AppointmentType, UserUpdateInput, VendorUserTypes } from "@/schema/types";
import { BACKEND_DATE_FORMAT } from "@toolkit/core";
import { removeEmptyRanges } from "@toolkit/ui";
import { camelCase, merge } from "lodash";
import moment from "moment";

export const getDoctorInfo = doctorInfo => {
  if (!doctorInfo) return;
  const appointmentTypes = doctorInfo?.appointmentTypes?.map(type => (type?.value ? type?.value : type));
  return {
    ...doctorInfo,
    isMobileNumberPublic: doctorInfo?.isMobileNumberPublic,
    isLanguagesPublic: doctorInfo?.isLanguagesPublic,
    isNationalIdPublic: doctorInfo?.isNationalIdPublic,
    isSecondMobileNumberPublic: doctorInfo?.isSecondMobileNumberPublic,
    isSocialLinksPublic: doctorInfo?.isSocialLinksPublic,
    isYearsOfExperiencePublic: doctorInfo?.isYearsOfExperiencePublic,
    bio: doctorInfo?.bio,
    specializations: doctorInfo?.specializations?.map(specialty => specialty?.value?.code ?? specialty),
    languages: doctorInfo?.languages?.map(language => (language?.code ? language?.code : language)),
    appointmentTypes,
    onsiteVisitPrice: (appointmentTypes?.includes(AppointmentType.Onsite) && doctorInfo?.onsiteVisitPrice) || null,
    onlineVisitPrice: (appointmentTypes?.includes(AppointmentType.Online) && doctorInfo?.onlineVisitPrice) || null,
    atHomeVisitPrice: (appointmentTypes?.includes(AppointmentType.AtHome) && doctorInfo?.atHomeVisitPrice) || null,
    seniority: doctorInfo?.seniority?.value,
    appointmentSlotTimePeriod: Number(doctorInfo?.appointmentSlotTimePeriod),
    yearsOfExperience: Number(doctorInfo?.yearsOfExperience),
    workingHours: removeEmptyRanges(doctorInfo?.workingHours),
    qualifications: [
      {
        code: doctorInfo?.qualifications?.code,
        issuer: doctorInfo?.qualifications?.issuer,
        fromDate: doctorInfo?.qualifications?.fromDate,
        toDate: doctorInfo?.qualifications?.toDate,
      },
    ],
  };
};

export const userFormMap = input => {
  const vendorUserType = input?.vendorUserType?.value;
  const vendorUserTypeHealthLicense = camelCase(vendorUserType);
  const includeHealthLicenseInfo = ![VendorUserTypes.Receptionist, VendorUserTypes.Manager].includes(vendorUserType);
  const vendorUserTypeHealthLicenseInformation = includeHealthLicenseInfo
    ? {
        healthLicenseNumber: input?.healthLicenseNumber || null,
        healthLicenseStartDate: input?.healthLicenseStartDate ? moment(input?.healthLicenseStartDate).format(BACKEND_DATE_FORMAT) : null,
        healthLicenseEndDate: input?.healthLicenseEndDate ? moment(input?.healthLicenseEndDate).format(BACKEND_DATE_FORMAT) : null,
      }
    : null;
  delete input?.healthLicenseNumber;
  delete input?.healthLicenseStartDate;
  delete input?.healthLicenseEndDate;
  const requestInput = {
    [`${vendorUserTypeHealthLicense}Info`]: vendorUserTypeHealthLicenseInformation,
    doctorInfo:
      vendorUserType === VendorUserTypes.Doctor
        ? merge({}, getDoctorInfo(input?.doctorInfo), vendorUserTypeHealthLicenseInformation)
        : null,
    appRole: input?.appRole,
    branches: input?.branches ?? undefined,
    defaultBranch: input?.defaultBranch?.value?.id,
    departments: input?.departments?.map(e => e?.id),
  };
  if (!vendorUserTypeHealthLicenseInformation) {
    delete requestInput[`${vendorUserTypeHealthLicense}Info`];
  }
  if (vendorUserType !== VendorUserTypes.Doctor) {
    delete input.doctorInfo;
  }
  if (input.appRole?.value === AppRoleTypes.Admin) {
    delete input.branches;
  }
  return requestInput;
};

export const getUserCreateInfo = (input: AdminUserCreateInput | any, currentUser: string) => {
  const vendorUserType = input?.vendorUserType?.value;

  return {
    ...input,
    ...userFormMap(input),
    appType: AppTypes.Vendor,
    vendor: currentUser,
    vendorUserType: vendorUserType,
    gender: input?.gender?.value,
  };
};

export const getUserUpdateInfo = (input: UserUpdateInput | any) => {
  delete input?.vendor;
  delete input?.appType;

  return {
    ...input,
    ...userFormMap(input),
    gender: input?.gender?.value,
  };
};
