import { Rad } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";

export const useColumns = ({ rads }) => {
  const { t } = useTranslation("provider");
  const Row = code => {
    const rad = rads?.find(r => <>{r?.code === code}</>);
    return <>{rad?.name}</>;
  };

  return useMemo(() => {
    return [
      {
        key: "code",
        header: t("code"),
        accessor: "code",
        hideFromSettings: true,
        disableToggleVisibility: true,
      },
      {
        key: "conceptId",
        header: "Description",
        accessor: ({ code }) => <Row code={code} />,
      },
    ] as CustomTableColumnProps<Rad>[];
  }, []);
};
