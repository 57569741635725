import { H_CancelReason, H_RejectionReason } from "@/schema/types";
import { pickLocalizedValue } from "@toolkit/i18n";
import { get } from "lodash";

export const getReasonText = (ReasonObject: Partial<H_RejectionReason> | Partial<H_CancelReason>) => {
  const englishText = get(ReasonObject, "text", "-");
  const arabicText = get(ReasonObject, "text_ar", get(ReasonObject, "text"));

  return pickLocalizedValue(englishText, arabicText);
};
