import { CustomDialog } from "@toolkit/ui";
import { useEffect } from "react";
import { useTranslation } from "@toolkit/i18n";
import { useOpenState } from "@toolkit/core";
import { GuidedCareProgramMemberAddForm } from "../../forms/GuidedCareProgramMemberAdd/GuidedCareProgramMemberAddForm";

export const GuidedCareProgramMemberAddModalApi: { open: () => void; close: () => void } = {
  open: () => {},
  close: () => {},
};

export const GuidedCareProgramMemberAddModal = () => {
  const { t } = useTranslation("provider");

  const { open, handleOpen, handleClose } = useOpenState();

  useEffect(() => {
    GuidedCareProgramMemberAddModalApi.open = handleOpen;
    GuidedCareProgramMemberAddModalApi.close = handleClose;
  }, []);

  return (
    <CustomDialog
      type={"base"}
      open={open}
      maxWidth={"sm"}
      DialogTitleProps={{
        title: t("Add Patient to Program"),
        onClose: close,
      }}
    >
      <GuidedCareProgramMemberAddForm />
    </CustomDialog>
  );
};
