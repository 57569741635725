import { VisitPrescriptionActivityFrequencyType } from "@/schema/types";

export const frequencyTypeOptions = [
  {
    display: VisitPrescriptionActivityFrequencyType.Day,
    value: VisitPrescriptionActivityFrequencyType.Day,
  },
  {
    display: VisitPrescriptionActivityFrequencyType.Hour,
    value: VisitPrescriptionActivityFrequencyType.Hour,
  },
  {
    display: VisitPrescriptionActivityFrequencyType.Month,
    value: VisitPrescriptionActivityFrequencyType.Month,
  },
  {
    display: VisitPrescriptionActivityFrequencyType.Week,
    value: VisitPrescriptionActivityFrequencyType.Week,
  },
];
