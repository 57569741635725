import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VisitDoctorNoteUpdateMutationVariables = Types.Exact<{
  input: Types.VisitDoctorNoteInput;
}>;


export type VisitDoctorNoteUpdateMutation = { __typename?: 'Mutation', visitDoctorNoteUpdate?: { __typename?: 'VisitAction', visit?: { __typename?: 'Visit', id: string, doctorNote?: string | null, summaries?: { __typename?: 'VisitSummaryCountableConnection', edges: Array<{ __typename?: 'VisitSummaryCountableEdge', node: { __typename?: 'VisitSummary', created?: any | null, doctorNotes?: string | null, id: string } }> } | null } | null, visitErrors?: Array<{ __typename?: 'H_EntityError', code: Types.H_EntityErrorCode, field?: string | null, message?: string | null }> | null } | null };


export const VisitDoctorNoteUpdateDocument = gql`
    mutation visitDoctorNoteUpdate($input: VisitDoctorNoteInput!) {
  visitDoctorNoteUpdate(input: $input) {
    visit {
      id
      doctorNote
      summaries {
        edges {
          node {
            created
            doctorNotes
            id
          }
        }
      }
    }
    visitErrors {
      code
      field
      message
    }
  }
}
    `;
export type VisitDoctorNoteUpdateMutationFn = Apollo.MutationFunction<VisitDoctorNoteUpdateMutation, VisitDoctorNoteUpdateMutationVariables>;

/**
 * __useVisitDoctorNoteUpdateMutation__
 *
 * To run a mutation, you first call `useVisitDoctorNoteUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVisitDoctorNoteUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [visitDoctorNoteUpdateMutation, { data, loading, error }] = useVisitDoctorNoteUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVisitDoctorNoteUpdateMutation(baseOptions?: Apollo.MutationHookOptions<VisitDoctorNoteUpdateMutation, VisitDoctorNoteUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VisitDoctorNoteUpdateMutation, VisitDoctorNoteUpdateMutationVariables>(VisitDoctorNoteUpdateDocument, options);
      }
export type VisitDoctorNoteUpdateMutationHookResult = ReturnType<typeof useVisitDoctorNoteUpdateMutation>;
export type VisitDoctorNoteUpdateMutationResult = Apollo.MutationResult<VisitDoctorNoteUpdateMutation>;
export type VisitDoctorNoteUpdateMutationOptions = Apollo.BaseMutationOptions<VisitDoctorNoteUpdateMutation, VisitDoctorNoteUpdateMutationVariables>;