import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GuidedCareTeamActivationMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GuidedCareTeamActivationMutation = { __typename?: 'Mutation', changeProviderGuidedCareHealthProgramTeamActiveStatus?: { __typename?: 'ProviderGuidedCareHealthProgramTeamCRUD', providerGuidedCareHealthProgramTeam?: { __typename?: 'ProviderGuidedCareHealthProgramTeam', isActive?: boolean | null } | null, providerGuidedCareHealthProgramTeamErrors?: Array<{ __typename?: 'H_EntityError', field?: string | null, message?: string | null }> | null } | null };


export const GuidedCareTeamActivationDocument = gql`
    mutation GuidedCareTeamActivation($id: ID!) {
  changeProviderGuidedCareHealthProgramTeamActiveStatus(id: $id) {
    providerGuidedCareHealthProgramTeam {
      isActive
    }
    providerGuidedCareHealthProgramTeamErrors {
      field
      message
    }
  }
}
    `;
export type GuidedCareTeamActivationMutationFn = Apollo.MutationFunction<GuidedCareTeamActivationMutation, GuidedCareTeamActivationMutationVariables>;

/**
 * __useGuidedCareTeamActivationMutation__
 *
 * To run a mutation, you first call `useGuidedCareTeamActivationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuidedCareTeamActivationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guidedCareTeamActivationMutation, { data, loading, error }] = useGuidedCareTeamActivationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGuidedCareTeamActivationMutation(baseOptions?: Apollo.MutationHookOptions<GuidedCareTeamActivationMutation, GuidedCareTeamActivationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GuidedCareTeamActivationMutation, GuidedCareTeamActivationMutationVariables>(GuidedCareTeamActivationDocument, options);
      }
export type GuidedCareTeamActivationMutationHookResult = ReturnType<typeof useGuidedCareTeamActivationMutation>;
export type GuidedCareTeamActivationMutationResult = Apollo.MutationResult<GuidedCareTeamActivationMutation>;
export type GuidedCareTeamActivationMutationOptions = Apollo.BaseMutationOptions<GuidedCareTeamActivationMutation, GuidedCareTeamActivationMutationVariables>;