import { UserNotification } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, CircularProgress } from "@toolkit/ui";
import { FC } from "react";
import { NotificationsList } from "./NotificationsList";

export const NotificationsContent: FC<{
  loading: boolean;
  notifications: UserNotification[];
  fetchMoreData: () => void;
  hasMore: boolean;
}> = ({ loading, notifications, fetchMoreData, hasMore }) => {
  const { t } = useTranslation("provider");

  return (
    <Box
      id='scrollable-id'
      sx={{
        height: 600,
        overflow: "auto",
        paddingInlineEnd: "15px",
      }}
    >
      {loading ? (
        <Box sx={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
          <CircularProgress size={12} sx={{ mx: 1, my: 1 }} />
        </Box>
      ) : (
        <Box>
          {notifications?.length ? (
            <NotificationsList
              notifications={notifications as UserNotification[]}
              fetchMoreData={fetchMoreData}
              hasMore={hasMore}
              isDisplayedInDropDown={true}
            />
          ) : (
            <Box sx={{ justifyContent: "center", alignItems: "center", display: "flex" }}>{t("No notifications")}</Box>
          )}
        </Box>
      )}
    </Box>
  );
};
