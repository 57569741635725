import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GuidedCareTaskListQueryVariables = Types.Exact<{
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sortBy?: Types.InputMaybe<Types.GuidedCareTaskSortingInput>;
  filter?: Types.InputMaybe<Types.GuidedCareTaskFilterInput>;
}>;


export type GuidedCareTaskListQuery = { __typename?: 'Query', guidedCareTasks?: { __typename?: 'GuidedCareTaskCountableConnection', pageInfo: { __typename?: 'H_PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, endCursor?: string | null, startCursor?: string | null }, edges: Array<{ __typename?: 'GuidedCareTaskCountableEdge', node: { __typename?: 'GuidedCareTask', id: string, displayAr?: string | null, displayEn?: string | null, dueDate?: any | null, status?: Types.TaskDefinitionStatus | null, guidedCareJourneyItemId?: string | null, teamId?: string | null, patient?: { __typename?: 'Patient', id: string, firstName?: string | null, lastName?: string | null } | null, taskDefinition?: { __typename?: 'TaskDefinition', taskWorkflowType?: Types.TaskWorkflowType | null } | null, assignee?: Array<{ __typename?: 'GuidedCareTaskAssignee', id: string, assigneeUserId?: string | null, assigneeType?: Types.AssigneeType | null, teamMember?: { __typename?: 'ProviderGuidedCareProgramTeamMember', id: string, user?: { __typename?: 'User', id: string, fullName?: string | null } | null } | null } | null> | null } }> } | null };


export const GuidedCareTaskListDocument = gql`
    query GuidedCareTaskList($last: Int, $first: Int, $after: String, $before: String, $sortBy: GuidedCareTaskSortingInput, $filter: GuidedCareTaskFilterInput) {
  guidedCareTasks(
    last: $last
    first: $first
    after: $after
    before: $before
    sortBy: $sortBy
    filter: $filter
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
    edges {
      node {
        id
        displayAr
        displayEn
        dueDate
        status
        guidedCareJourneyItemId
        teamId
        patient {
          id
          firstName
          lastName
        }
        taskDefinition {
          taskWorkflowType
        }
        assignee {
          id
          assigneeUserId
          assigneeType
          teamMember {
            id
            user {
              id
              fullName
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGuidedCareTaskListQuery__
 *
 * To run a query within a React component, call `useGuidedCareTaskListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuidedCareTaskListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuidedCareTaskListQuery({
 *   variables: {
 *      last: // value for 'last'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      sortBy: // value for 'sortBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGuidedCareTaskListQuery(baseOptions?: Apollo.QueryHookOptions<GuidedCareTaskListQuery, GuidedCareTaskListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GuidedCareTaskListQuery, GuidedCareTaskListQueryVariables>(GuidedCareTaskListDocument, options);
      }
export function useGuidedCareTaskListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GuidedCareTaskListQuery, GuidedCareTaskListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GuidedCareTaskListQuery, GuidedCareTaskListQueryVariables>(GuidedCareTaskListDocument, options);
        }
export function useGuidedCareTaskListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GuidedCareTaskListQuery, GuidedCareTaskListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GuidedCareTaskListQuery, GuidedCareTaskListQueryVariables>(GuidedCareTaskListDocument, options);
        }
export type GuidedCareTaskListQueryHookResult = ReturnType<typeof useGuidedCareTaskListQuery>;
export type GuidedCareTaskListLazyQueryHookResult = ReturnType<typeof useGuidedCareTaskListLazyQuery>;
export type GuidedCareTaskListSuspenseQueryHookResult = ReturnType<typeof useGuidedCareTaskListSuspenseQuery>;
export type GuidedCareTaskListQueryResult = Apollo.QueryResult<GuidedCareTaskListQuery, GuidedCareTaskListQueryVariables>;