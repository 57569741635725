import { Box, Card, useMediaQuery } from "@toolkit/ui";
import { useChatStyles } from "./Chat.styles";
import { useChatHooks } from "./Chat.hooks";
import { ChatGroups } from "@health/chat";
import { ChatMessages } from "./ChatMessages";

export const Chat = () => {
  const { chatGroup, handleSelect, handleClear } = useChatHooks();
  const { classes, theme } = useChatStyles({ hasSelectedItem: !!chatGroup });
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box className={classes.root}>
      <Card className={classes.sidebar}>
        <ChatGroups selected={chatGroup} onSelect={handleSelect} />
      </Card>

      {(!isSmall || chatGroup) && (
        <Card sx={{ flex: 1 }}>
          <ChatMessages chatGroup={chatGroup} onClose={handleClear} />
        </Card>
      )}
    </Box>
  );
};
