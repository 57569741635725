import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { PatientCurrentMedicalStatusFragmentFragmentDoc } from '../../fragments/__generated__/patientCurrentMedicalStatus';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddPatientChronicMedicationMutationVariables = Types.Exact<{
  input: Types.PatientChronicMedicationInput;
}>;


export type AddPatientChronicMedicationMutation = { __typename?: 'Mutation', addPatientChronicMedication?: { __typename?: 'PatientMedicalProfile', id: string, patientCurrentMedicalStatus?: { __typename?: 'PatientCurrentMedicalStatus', id: string, patientChronicDiseases?: Array<{ __typename?: 'PatientChronicDisease', id: string, code: string, display?: string | null, createdDate?: any | null } | null> | null, patientChronicMedications?: Array<{ __typename?: 'PatientChronicMedication', id: string, code: string, display?: string | null, createdDate?: any | null } | null> | null, patientNonChronicDiseases?: Array<{ __typename?: 'PatientNonChronicDisease', id: string, code: string, display?: string | null, createdDate?: any | null } | null> | null } | null } | null };


export const AddPatientChronicMedicationDocument = gql`
    mutation AddPatientChronicMedication($input: PatientChronicMedicationInput!) {
  addPatientChronicMedication(input: $input) {
    id
    patientCurrentMedicalStatus {
      ...patientCurrentMedicalStatusFragment
    }
  }
}
    ${PatientCurrentMedicalStatusFragmentFragmentDoc}`;
export type AddPatientChronicMedicationMutationFn = Apollo.MutationFunction<AddPatientChronicMedicationMutation, AddPatientChronicMedicationMutationVariables>;

/**
 * __useAddPatientChronicMedicationMutation__
 *
 * To run a mutation, you first call `useAddPatientChronicMedicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPatientChronicMedicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPatientChronicMedicationMutation, { data, loading, error }] = useAddPatientChronicMedicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPatientChronicMedicationMutation(baseOptions?: Apollo.MutationHookOptions<AddPatientChronicMedicationMutation, AddPatientChronicMedicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPatientChronicMedicationMutation, AddPatientChronicMedicationMutationVariables>(AddPatientChronicMedicationDocument, options);
      }
export type AddPatientChronicMedicationMutationHookResult = ReturnType<typeof useAddPatientChronicMedicationMutation>;
export type AddPatientChronicMedicationMutationResult = Apollo.MutationResult<AddPatientChronicMedicationMutation>;
export type AddPatientChronicMedicationMutationOptions = Apollo.BaseMutationOptions<AddPatientChronicMedicationMutation, AddPatientChronicMedicationMutationVariables>;