import { z } from "zod";

const MedicationOrderInputSchema = z.object({
  id: z.string(),
  quantity: z.number().int().min(1),
  tradeCode: z.string(),
});

const AcceptedMedicationsSchema = z.array(MedicationOrderInputSchema);

export type IAcceptOrderFormValues = z.infer<typeof AcceptOrderFormSchema>;

export const AcceptOrderFormSchema = z.object({
  acceptedMedications: AcceptedMedicationsSchema,
});

export const AcceptOrderFormDefaultValues: Partial<IAcceptOrderFormValues> = {
  acceptedMedications: [],
};
