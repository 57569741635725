import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VisitDiagnosesQueryVariables = Types.Exact<{
  visitId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type VisitDiagnosesQuery = { __typename?: 'Query', visit?: { __typename?: 'Visit', visitDiagnoses?: Array<{ __typename?: 'VisitDiagnosis', id: string, code: string, display?: string | null, visitDiagnosisType: Types.VisitDiagnosisType, riskTemplate?: { __typename?: 'VisitDiagnosisRiskTemplate', id: string, code?: string | null, isFilled?: boolean | null, notification?: string | null, result?: string | null, chronicDiseaseSeverity?: string | null, chronicDiseaseCode?: string | null, isGuidedCareProgramSelected?: boolean | null, suggestedGuidedPrograms?: Array<{ __typename?: 'GuidedCareHealthProgram', id: string, name?: string | null } | null> | null, enrolledGuidedPrograms?: Array<{ __typename?: 'GuidedCareHealthProgram', id: string, name?: string | null } | null> | null, selectedGuidedCareProgram?: { __typename?: 'GuidedCareHealthProgram', id: string, name?: string | null } | null } | null } | null> | null } | null };


export const VisitDiagnosesDocument = gql`
    query VisitDiagnoses($visitId: ID) {
  visit(id: $visitId) {
    visitDiagnoses {
      id
      code
      display
      visitDiagnosisType
      riskTemplate {
        id
        code
        suggestedGuidedPrograms {
          id
          name
        }
        enrolledGuidedPrograms {
          id
          name
        }
        isFilled
        notification
        result
        chronicDiseaseSeverity
        chronicDiseaseCode
        isGuidedCareProgramSelected
        selectedGuidedCareProgram {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useVisitDiagnosesQuery__
 *
 * To run a query within a React component, call `useVisitDiagnosesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitDiagnosesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitDiagnosesQuery({
 *   variables: {
 *      visitId: // value for 'visitId'
 *   },
 * });
 */
export function useVisitDiagnosesQuery(baseOptions?: Apollo.QueryHookOptions<VisitDiagnosesQuery, VisitDiagnosesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VisitDiagnosesQuery, VisitDiagnosesQueryVariables>(VisitDiagnosesDocument, options);
      }
export function useVisitDiagnosesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VisitDiagnosesQuery, VisitDiagnosesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VisitDiagnosesQuery, VisitDiagnosesQueryVariables>(VisitDiagnosesDocument, options);
        }
export function useVisitDiagnosesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<VisitDiagnosesQuery, VisitDiagnosesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<VisitDiagnosesQuery, VisitDiagnosesQueryVariables>(VisitDiagnosesDocument, options);
        }
export type VisitDiagnosesQueryHookResult = ReturnType<typeof useVisitDiagnosesQuery>;
export type VisitDiagnosesLazyQueryHookResult = ReturnType<typeof useVisitDiagnosesLazyQuery>;
export type VisitDiagnosesSuspenseQueryHookResult = ReturnType<typeof useVisitDiagnosesSuspenseQuery>;
export type VisitDiagnosesQueryResult = Apollo.QueryResult<VisitDiagnosesQuery, VisitDiagnosesQueryVariables>;