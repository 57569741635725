import { Branch, BranchOrderField } from "@/schema/types";
import { CredentialDialog } from "@health/domains";
import { getAutocompleteEnumFilter, vendorTypeOptionsMap } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, TruncateTypography, format } from "@toolkit/ui";
import { useMemo } from "react";
import { AddressInformation } from "shared/components/AddressModal/AddressInformation.component";
import { ActiveSwitch } from "../components/ActiveSwitch.components";
import { WorkingHoursBranch } from "../components/WorkingHoursBranch.component";
import { UsersList } from "./components/UserLists";

export const useGetBranchesColumns = (): CustomTableColumnProps<Branch>[] => {
  const { t } = useTranslation("provider");

  return useMemo(() => {
    return [
      {
        key: "name",
        header: t("Name"),
        accessor: row => <TruncateTypography text={row?.name!} />,
        isSortable: true,
        sortDirection: undefined,
        sortColumnEnum: BranchOrderField.Name,
        isHidden: false,
        filter: {
          type: "string",
          name: "name_Icontains",
        },
      },
      {
        key: "created",
        header: t("Created"),
        accessor: row => format(row?.created),
        isSortable: true,
        sortDirection: "DESC",
        isHidden: false,
        sortColumnEnum: BranchOrderField.Created,
      },
      {
        key: "description",
        header: t("Description"),
        accessor: row => <TruncateTypography text={row?.description!} />,
        isHidden: false,
      },
      {
        key: "credential",
        header: t("eRxHub Credential"),
        accessor: ({ id, isActive }) => (
          <CredentialDialog isDisabled={!isActive} type='pharmacy' targetId={id} title={t("eRxHub Credential")} />
        ),
        isHidden: false,
      },
      {
        key: "address",
        header: t("Address"),
        accessor: row => <AddressInformation id={row?.id} />,
        isHidden: false,
      },
      {
        key: "contactNumber",
        header: t("Contact Number"),
        accessor: "contactNumber",
        isHidden: false,
        filter: {
          type: "string",
          name: "contactNumber_Icontains",
        },
      },
      {
        key: "healthLicense",
        header: t("Health License"),
        accessor: "healthLicense",
        isHidden: false,
      },
      {
        key: "type",
        header: t("Type"),
        accessor: ({ type }) => vendorTypeOptionsMap[type!]?.label || "-",
        isHidden: false,
        filter: getAutocompleteEnumFilter("VendorType", "type"),
      },
      {
        key: "isActive",
        header: t("Active"),
        accessor: row => <ActiveSwitch id={row?.id} isActive={row?.isActive} vendorActive={row?.vendor?.isActive} />,
        isHidden: false,
        filter: getAutocompleteEnumFilter("YesNo", "isActive"),
      },
      {
        key: "workingHours",
        header: t("Working Hours"),
        accessor: ({ id }) => <WorkingHoursBranch id={id} />,
        isHidden: false,
      },
      {
        key: "users",
        header: t("Users"),
        accessor: row => {
          return <UsersList id={row?.id} />;
        },
        isHidden: false,
      },
      {
        key: "healthLicenseNumber",
        header: t("Health License Number"),
        showOnlyForFilterField: true,
        filter: {
          type: "string",
          name: "healthLicenseNumber",
        },
      },
      {
        key: "acceptsDelivery",
        header: t("Accepts Delivery"),
        showOnlyForFilterField: true,
        filter: getAutocompleteEnumFilter("YesNo", "acceptsDelivery"),
      },
    ];
  }, []);
};
