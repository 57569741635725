import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChatGroupQueryVariables = Types.Exact<{
  chatGroupId: Types.Scalars['ID']['input'];
}>;


export type ChatGroupQuery = { __typename?: 'Query', chatGroup?: { __typename?: 'ChatGroup', createdAt: any, id: string, name?: string | null, participants: Array<{ __typename?: 'User', id: string, photo?: string | null, firstName?: string | null }> } | null };


export const ChatGroupDocument = gql`
    query ChatGroup($chatGroupId: ID!) {
  chatGroup(id: $chatGroupId) {
    createdAt
    id
    name
    participants {
      id
      photo
      firstName
    }
  }
}
    `;

/**
 * __useChatGroupQuery__
 *
 * To run a query within a React component, call `useChatGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatGroupQuery({
 *   variables: {
 *      chatGroupId: // value for 'chatGroupId'
 *   },
 * });
 */
export function useChatGroupQuery(baseOptions: Apollo.QueryHookOptions<ChatGroupQuery, ChatGroupQueryVariables> & ({ variables: ChatGroupQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChatGroupQuery, ChatGroupQueryVariables>(ChatGroupDocument, options);
      }
export function useChatGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChatGroupQuery, ChatGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChatGroupQuery, ChatGroupQueryVariables>(ChatGroupDocument, options);
        }
export function useChatGroupSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ChatGroupQuery, ChatGroupQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ChatGroupQuery, ChatGroupQueryVariables>(ChatGroupDocument, options);
        }
export type ChatGroupQueryHookResult = ReturnType<typeof useChatGroupQuery>;
export type ChatGroupLazyQueryHookResult = ReturnType<typeof useChatGroupLazyQuery>;
export type ChatGroupSuspenseQueryHookResult = ReturnType<typeof useChatGroupSuspenseQuery>;
export type ChatGroupQueryResult = Apollo.QueryResult<ChatGroupQuery, ChatGroupQueryVariables>;