import { FilterGrid, PageWrapper, TableGrid, TableSettingComponent, useBreadCrumbs } from "@toolkit/ui";
import { GridProvider } from "shared/components";
import { useOptimaValidatedRequestsListContainerColumns } from "./useOptimaValidatedRequestsListContainerColumns";
import { FC, useEffect } from "react";
import { useOptimaValidationRequestsListQuery } from "../../gql";
import { OptimaValidatedRequestsRoutesBreadcrumb } from "../../constants";
import { useTranslation } from "@toolkit/i18n";

export const OptimaValidatedRequestsListContainer: FC = () => {
  const { t } = useTranslation("provider");

  const { setBreadCrumb } = useBreadCrumbs();

  useEffect(() => {
    setBreadCrumb({
      title: OptimaValidatedRequestsRoutesBreadcrumb(t),
    });
  }, []);

  return (
    <GridProvider
      gridName={"optimaValidationRequests"}
      query={useOptimaValidationRequestsListQuery}
      columns={useOptimaValidatedRequestsListContainerColumns()}
      variables={{
        filter: {
          hasDislikedEdits: true,
        },
      }}
      hasTableSetting
    >
      <PageWrapper filters={<FilterGrid />} actions={<TableSettingComponent />}>
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
