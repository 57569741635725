import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VisitNoteQueryVariables = Types.Exact<{
  visitId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type VisitNoteQuery = { __typename?: 'Query', visit?: { __typename?: 'Visit', doctorNote?: string | null } | null };


export const VisitNoteDocument = gql`
    query visitNote($visitId: ID) {
  visit(id: $visitId) {
    doctorNote
  }
}
    `;

/**
 * __useVisitNoteQuery__
 *
 * To run a query within a React component, call `useVisitNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitNoteQuery({
 *   variables: {
 *      visitId: // value for 'visitId'
 *   },
 * });
 */
export function useVisitNoteQuery(baseOptions?: Apollo.QueryHookOptions<VisitNoteQuery, VisitNoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VisitNoteQuery, VisitNoteQueryVariables>(VisitNoteDocument, options);
      }
export function useVisitNoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VisitNoteQuery, VisitNoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VisitNoteQuery, VisitNoteQueryVariables>(VisitNoteDocument, options);
        }
export function useVisitNoteSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<VisitNoteQuery, VisitNoteQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<VisitNoteQuery, VisitNoteQueryVariables>(VisitNoteDocument, options);
        }
export type VisitNoteQueryHookResult = ReturnType<typeof useVisitNoteQuery>;
export type VisitNoteLazyQueryHookResult = ReturnType<typeof useVisitNoteLazyQuery>;
export type VisitNoteSuspenseQueryHookResult = ReturnType<typeof useVisitNoteSuspenseQuery>;
export type VisitNoteQueryResult = Apollo.QueryResult<VisitNoteQuery, VisitNoteQueryVariables>;