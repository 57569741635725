import { useAddToast } from "@toolkit/ui";
import { useState } from "react";
import { useAuth } from "react-oidc-context";
import { useGetUserChatGroupMutation } from "./gql";
import { AnonymousChatGroup } from "@/schema/types";

export const useChatHooks = () => {
  const [open, setOpen] = useState(false);
  const auth = useAuth();
  const [chatGroup, setChatGroup] = useState<AnonymousChatGroup>();

  const authenticated = auth.isAuthenticated || false;

  const { failed } = useAddToast();

  const [getChatLinkMutation, { loading: isGetChatLinkLoading }] = useGetUserChatGroupMutation({
    onCompleted: async response => {
      const error = response?.saveOrFetchExistedAnonymousChatGroup?.error;
      if (error?.length) {
        failed(error);
      } else {
        const value = response?.saveOrFetchExistedAnonymousChatGroup as AnonymousChatGroup;
        if (value) {
          setChatGroup(value);
          setOpen(true);
        }
      }
    },
  });

  const startChat = (userId: string) => {
    if (authenticated) {
      userId &&
        getChatLinkMutation({
          variables: {
            userIds: [userId],
          },
        });
    } else {
      auth.signinRedirect();
    }
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  return { startChat, chatGroup, open, handleToggle, isGetChatLinkLoading };
};
