import { MedicalForm, MedicalFormSortingField } from "@/schema/types";
import { getDepartmentsAutocompleteFilter } from "@health/autocompletes";
import { MedicalFormActivation } from "@health/domains";
import { getAutocompleteEnumFilter, medicalFormCategoryOptionsMap, medicalFormTypeOptionsMap } from "@health/enum-options";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, OrderDirection, TruncateTypography } from "@toolkit/ui";
import { useMemo } from "react";

export const useProviderMedicalFormsTableColumns = (): CustomTableColumnProps<MedicalForm>[] => {
  const { t } = useTranslation("provider");

  return useMemo(() => {
    return [
      {
        key: "name",
        header: t("Name", { ns: "provider" }),
        accessor: ({ name, nameAr }) => <TruncateTypography text={pickLocalizedValue(name!, nameAr!)} />,
        type: "string",
        isSortable: true,
        sortDirection: OrderDirection.Desc,
        sortColumnEnum: MedicalFormSortingField.Name,
        filter: {
          type: "string",
          name: "nameContains",
        },
      },
      {
        key: "code",
        header: t("Code", { ns: "provider" }),
        accessor: "code",
        type: "string",
        filter: {
          type: "string",
          name: "codes",
          getValueForBackend: name => (name ? [name] : undefined),
        },
      },
      {
        key: "type",
        header: t("Type", { ns: "provider" }),
        accessor: ({ type }) => medicalFormTypeOptionsMap[type!]?.label,
        isSortable: true,
        sortDirection: OrderDirection.Desc,
        sortColumnEnum: MedicalFormSortingField.Type,
      },
      {
        key: "category",
        header: t("Category", { ns: "provider" }),
        accessor: ({ category }) => medicalFormCategoryOptionsMap[category!]?.label,
        isSortable: true,
        sortDirection: OrderDirection.Desc,
        sortColumnEnum: MedicalFormSortingField.Category,
      },
      {
        key: "department",
        header: t("Department", { ns: "provider" }),
        accessor: ({ department }) => department?.name,
      },
      {
        key: "active",
        isSortable: true,
        sortDirection: OrderDirection.Desc,
        sortColumnEnum: MedicalFormSortingField.Active,
        header: t("Active", { ns: "provider" }),
        accessor: row => <MedicalFormActivation id={row?.id!} isActive={!!row?.active} />,
        filter: getAutocompleteEnumFilter("YesNo", "isActive"),
      },
      {
        key: "Departments",
        header: t("Departments", { ns: "provider" }),
        showOnlyForFilterField: true,
        filter: getDepartmentsAutocompleteFilter({ name: "departments", multiple: true }),
      },
    ];
  }, [t]);
};
