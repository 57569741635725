import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { PatientProfileFragmentFragmentDoc } from '../../fragments/__generated__/patientProfile';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPatientDataQueryVariables = Types.Exact<{
  patientId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type GetPatientDataQuery = { __typename?: 'Query', patient?: { __typename?: 'Patient', id: string, firstName?: string | null, lastName?: string | null, gender?: string | null, dateOfBirth?: any | null, contactNumber?: string | null, nationality?: string | null, activeHealthProgramMembers?: Array<{ __typename?: 'HealthProgramMember', id: string, nationalId?: string | null, isDefault?: boolean | null, payer?: { __typename?: 'Payer', id: string, name: string } | null } | null> | null, user?: { __typename?: 'User', id: string, photo?: string | null, preferredLanguage?: { __typename?: 'Language', code: string, display: string, displayAr?: string | null, id: string } | null } | null, bodyComposition?: { __typename?: 'HealthProgramMemberBodyCompositionBasic', bmi?: number | null, sleep?: number | null, smoking?: number | null, weightInKG?: number | null, heightInCM?: number | null, exercise?: boolean | null, dietType?: Types.DietType | null, alcohol?: boolean | null } | null, bodyCompositions?: { __typename?: 'HealthProgramMemberBodyCompositionCountableConnection', totalCount?: number | null, edges: Array<{ __typename?: 'HealthProgramMemberBodyCompositionCountableEdge', node: { __typename?: 'HealthProgramMemberBodyComposition', modified?: any | null, bmi?: number | null, sleep?: number | null, smoking?: number | null, weightInKG?: number | null, heightInCM?: number | null, exercise?: boolean | null, dietType?: Types.DietType | null, alcohol?: boolean | null } }> } | null } | null };


export const GetPatientDataDocument = gql`
    query getPatientData($patientId: ID) {
  patient(id: $patientId) {
    ...PatientProfileFragment
  }
}
    ${PatientProfileFragmentFragmentDoc}`;

/**
 * __useGetPatientDataQuery__
 *
 * To run a query within a React component, call `useGetPatientDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientDataQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useGetPatientDataQuery(baseOptions?: Apollo.QueryHookOptions<GetPatientDataQuery, GetPatientDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPatientDataQuery, GetPatientDataQueryVariables>(GetPatientDataDocument, options);
      }
export function useGetPatientDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPatientDataQuery, GetPatientDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPatientDataQuery, GetPatientDataQueryVariables>(GetPatientDataDocument, options);
        }
export function useGetPatientDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPatientDataQuery, GetPatientDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPatientDataQuery, GetPatientDataQueryVariables>(GetPatientDataDocument, options);
        }
export type GetPatientDataQueryHookResult = ReturnType<typeof useGetPatientDataQuery>;
export type GetPatientDataLazyQueryHookResult = ReturnType<typeof useGetPatientDataLazyQuery>;
export type GetPatientDataSuspenseQueryHookResult = ReturnType<typeof useGetPatientDataSuspenseQuery>;
export type GetPatientDataQueryResult = Apollo.QueryResult<GetPatientDataQuery, GetPatientDataQueryVariables>;