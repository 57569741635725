import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpcomingPatientCareActivitiesQueryVariables = Types.Exact<{
  filter: Types.GuidedCareJourneyItemFilter;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type UpcomingPatientCareActivitiesQuery = { __typename?: 'Query', upcomingPatientCareActivities?: { __typename?: 'GuidedCareJourneyItemCountableConnection', pageInfo: { __typename?: 'H_PageInfo', endCursor?: string | null, startCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean }, edges: Array<{ __typename?: 'GuidedCareJourneyItemCountableEdge', node: { __typename?: 'GuidedCareJourneyItem', id: string, dueDate?: any | null, guidedCareJourneyItemStatus?: Types.GuidedCareJourneyItemStatus | null, guidedCareJourneyItemType?: Types.GuidedCareActivityType | null, medicalForm?: { __typename?: 'MedicalForm', id: string, name?: string | null, description?: string | null } | null, medicalMessage?: { __typename?: 'MedicalMessage', id: string, name?: string | null, medicalMessageType?: Types.MedicalMessageType | null } | null, appointment?: { __typename?: 'Appointment', id: string, type?: Types.AppointmentType | null, status?: Types.AppointmentStatus | null, paymentStatus?: Types.PaymentStatus | null, paymentInfo?: { __typename?: 'AppointmentPaymentRequest', currency?: string | null, totalAmount?: number | null, type?: Types.AppointmentPaymentType | null } | null, consumer?: { __typename?: 'Patient', id: string } | null, doctor?: { __typename?: 'Doctor', id: string, user?: { __typename?: 'User', id: string, fullName?: string | null, firstName?: string | null, lastName?: string | null } | null } | null } | null } }> } | null };


export const UpcomingPatientCareActivitiesDocument = gql`
    query UpcomingPatientCareActivities($filter: GuidedCareJourneyItemFilter!, $first: Int, $after: String) {
  upcomingPatientCareActivities: guidedCareJourneyItems(
    filter: $filter
    first: $first
    after: $after
  ) {
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        id
        medicalForm {
          id
          name
          description
        }
        medicalMessage {
          id
          name
          medicalMessageType
        }
        dueDate
        guidedCareJourneyItemStatus
        guidedCareJourneyItemType
        appointment {
          id
          type
          status
          paymentStatus
          paymentInfo {
            currency
            totalAmount
            type
          }
          consumer {
            id
          }
          doctor {
            id
            user {
              id
              fullName
              firstName
              lastName
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useUpcomingPatientCareActivitiesQuery__
 *
 * To run a query within a React component, call `useUpcomingPatientCareActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcomingPatientCareActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcomingPatientCareActivitiesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useUpcomingPatientCareActivitiesQuery(baseOptions: Apollo.QueryHookOptions<UpcomingPatientCareActivitiesQuery, UpcomingPatientCareActivitiesQueryVariables> & ({ variables: UpcomingPatientCareActivitiesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UpcomingPatientCareActivitiesQuery, UpcomingPatientCareActivitiesQueryVariables>(UpcomingPatientCareActivitiesDocument, options);
      }
export function useUpcomingPatientCareActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpcomingPatientCareActivitiesQuery, UpcomingPatientCareActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UpcomingPatientCareActivitiesQuery, UpcomingPatientCareActivitiesQueryVariables>(UpcomingPatientCareActivitiesDocument, options);
        }
export function useUpcomingPatientCareActivitiesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UpcomingPatientCareActivitiesQuery, UpcomingPatientCareActivitiesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UpcomingPatientCareActivitiesQuery, UpcomingPatientCareActivitiesQueryVariables>(UpcomingPatientCareActivitiesDocument, options);
        }
export type UpcomingPatientCareActivitiesQueryHookResult = ReturnType<typeof useUpcomingPatientCareActivitiesQuery>;
export type UpcomingPatientCareActivitiesLazyQueryHookResult = ReturnType<typeof useUpcomingPatientCareActivitiesLazyQuery>;
export type UpcomingPatientCareActivitiesSuspenseQueryHookResult = ReturnType<typeof useUpcomingPatientCareActivitiesSuspenseQuery>;
export type UpcomingPatientCareActivitiesQueryResult = Apollo.QueryResult<UpcomingPatientCareActivitiesQuery, UpcomingPatientCareActivitiesQueryVariables>;