import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PatientIdByConsentCodeQueryVariables = Types.Exact<{
  code: Types.Scalars['String']['input'];
}>;


export type PatientIdByConsentCodeQuery = { __typename?: 'Query', patientIdByConsentCode?: { __typename?: 'Patient', id: string, firstName?: string | null, lastName?: string | null } | null };


export const PatientIdByConsentCodeDocument = gql`
    query patientIdByConsentCode($code: String!) {
  patientIdByConsentCode(code: $code) {
    id
    firstName
    lastName
  }
}
    `;

/**
 * __usePatientIdByConsentCodeQuery__
 *
 * To run a query within a React component, call `usePatientIdByConsentCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientIdByConsentCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientIdByConsentCodeQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function usePatientIdByConsentCodeQuery(baseOptions: Apollo.QueryHookOptions<PatientIdByConsentCodeQuery, PatientIdByConsentCodeQueryVariables> & ({ variables: PatientIdByConsentCodeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatientIdByConsentCodeQuery, PatientIdByConsentCodeQueryVariables>(PatientIdByConsentCodeDocument, options);
      }
export function usePatientIdByConsentCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientIdByConsentCodeQuery, PatientIdByConsentCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatientIdByConsentCodeQuery, PatientIdByConsentCodeQueryVariables>(PatientIdByConsentCodeDocument, options);
        }
export function usePatientIdByConsentCodeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PatientIdByConsentCodeQuery, PatientIdByConsentCodeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PatientIdByConsentCodeQuery, PatientIdByConsentCodeQueryVariables>(PatientIdByConsentCodeDocument, options);
        }
export type PatientIdByConsentCodeQueryHookResult = ReturnType<typeof usePatientIdByConsentCodeQuery>;
export type PatientIdByConsentCodeLazyQueryHookResult = ReturnType<typeof usePatientIdByConsentCodeLazyQuery>;
export type PatientIdByConsentCodeSuspenseQueryHookResult = ReturnType<typeof usePatientIdByConsentCodeSuspenseQuery>;
export type PatientIdByConsentCodeQueryResult = Apollo.QueryResult<PatientIdByConsentCodeQuery, PatientIdByConsentCodeQueryVariables>;