import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetClinicianQueryVariables = Types.Exact<{
  nationalId: Types.Scalars['String']['input'];
}>;


export type GetClinicianQuery = { __typename?: 'Query', getClinician?: { __typename?: 'Clinician', englishName?: string | null, firstName?: string | null, lastName?: string | null, email?: string | null, tempPassword?: string | null, phone?: string | null, license?: string | null, licenseStart?: any | null, licenseEnd?: any | null } | null };


export const GetClinicianDocument = gql`
    query getClinician($nationalId: String!) {
  getClinician(nationalId: $nationalId) {
    englishName
    firstName
    lastName
    email
    tempPassword
    phone
    license
    licenseStart
    licenseEnd
  }
}
    `;

/**
 * __useGetClinicianQuery__
 *
 * To run a query within a React component, call `useGetClinicianQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClinicianQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClinicianQuery({
 *   variables: {
 *      nationalId: // value for 'nationalId'
 *   },
 * });
 */
export function useGetClinicianQuery(baseOptions: Apollo.QueryHookOptions<GetClinicianQuery, GetClinicianQueryVariables> & ({ variables: GetClinicianQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClinicianQuery, GetClinicianQueryVariables>(GetClinicianDocument, options);
      }
export function useGetClinicianLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClinicianQuery, GetClinicianQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClinicianQuery, GetClinicianQueryVariables>(GetClinicianDocument, options);
        }
export function useGetClinicianSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetClinicianQuery, GetClinicianQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetClinicianQuery, GetClinicianQueryVariables>(GetClinicianDocument, options);
        }
export type GetClinicianQueryHookResult = ReturnType<typeof useGetClinicianQuery>;
export type GetClinicianLazyQueryHookResult = ReturnType<typeof useGetClinicianLazyQuery>;
export type GetClinicianSuspenseQueryHookResult = ReturnType<typeof useGetClinicianSuspenseQuery>;
export type GetClinicianQueryResult = Apollo.QueryResult<GetClinicianQuery, GetClinicianQueryVariables>;