import { AccountError, AdminUserCreateInput } from "@/schema/types";
import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import { useCurrentUserQuery } from "gql/queries";
import { healthProviderUserManagementPaths } from "pages/HealthProviderManagement/constants/HealthProvidersManagementPaths";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getUserCreateInfo } from "../UserManagementForm.util";
import { userManagementListBreadCrumb, userManagementNewBreadCrumb } from "../Users.constant";
import { VendorUserCreateMutation, useVendorUserCreateMutation } from "../gql";

export const useUserManagementAddHook = () => {
  const { t } = useTranslation("provider");
  const { succeeded, failed } = useAddToast();
  const [errors, setErrors] = React.useState<AccountError[]>([]);
  const navigate = useNavigate();
  const [vendorUserCreateMutation, { loading: isCreateLoading }] = useVendorUserCreateMutation({
    onCompleted: (mutationData: VendorUserCreateMutation) => {
      const mutationErrors = mutationData?.userCreate?.accountErrors;
      if (mutationErrors && mutationErrors.length > 0) {
        failed(t(formatMessageErrors(mutationErrors)));
        setErrors(mutationErrors);
      } else {
        succeeded(t("User created successfully"));
        navigate(healthProviderUserManagementPaths.indexPage.fullPath);
      }
    },
    onError: () => {
      failed(t("Failed to create user"));
    },
  });
  const { data } = useCurrentUserQuery();
  const currentUser = data?.me?.vendor?.id as string;

  const handleCreateUser = (inputData: AdminUserCreateInput | any) => {
    vendorUserCreateMutation({
      variables: {
        input: getUserCreateInfo(inputData, currentUser),
      },
    });
  };
  const { setBreadCrumb } = useBreadCrumbs();

  useEffect(() => {
    setBreadCrumb({
      title: t("Users Management"),
      values: [userManagementListBreadCrumb(t), userManagementNewBreadCrumb(t)],
    });
  }, []);

  return {
    isCreateLoading,
    errors,
    handleCreateUser,
  };
};
