import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GuidedCareTeamPatientListQueryVariables = Types.Exact<{
  teamId: Types.Scalars['ID']['input'];
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GuidedCareTeamPatientListQuery = { __typename?: 'Query', teamEnrolledMembers?: { __typename?: 'HealthProgramMemberCountableConnection', totalCount?: number | null, pageInfo: { __typename?: 'H_PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'HealthProgramMemberCountableEdge', cursor: string, node: { __typename?: 'HealthProgramMember', id: string, gender?: Types.H_Gender | null, firstName?: string | null, chronicDiseases?: Array<string> | null, lastName?: string | null, nationalId?: string | null, sehacityId?: string | null, phone?: string | null, patient?: { __typename?: 'Patient', id: string, firstName?: string | null, lastName?: string | null, user?: { __typename?: 'User', id: string, photo?: string | null } | null } | null } }> } | null };


export const GuidedCareTeamPatientListDocument = gql`
    query GuidedCareTeamPatientList($teamId: ID!, $after: String, $first: Int, $before: String, $last: Int) {
  teamEnrolledMembers(
    teamId: $teamId
    after: $after
    first: $first
    before: $before
    last: $last
  ) {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    totalCount
    edges {
      cursor
      node {
        id
        gender
        firstName
        chronicDiseases
        lastName
        nationalId
        sehacityId
        phone
        patient {
          id
          firstName
          lastName
          user {
            id
            photo
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGuidedCareTeamPatientListQuery__
 *
 * To run a query within a React component, call `useGuidedCareTeamPatientListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuidedCareTeamPatientListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuidedCareTeamPatientListQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useGuidedCareTeamPatientListQuery(baseOptions: Apollo.QueryHookOptions<GuidedCareTeamPatientListQuery, GuidedCareTeamPatientListQueryVariables> & ({ variables: GuidedCareTeamPatientListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GuidedCareTeamPatientListQuery, GuidedCareTeamPatientListQueryVariables>(GuidedCareTeamPatientListDocument, options);
      }
export function useGuidedCareTeamPatientListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GuidedCareTeamPatientListQuery, GuidedCareTeamPatientListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GuidedCareTeamPatientListQuery, GuidedCareTeamPatientListQueryVariables>(GuidedCareTeamPatientListDocument, options);
        }
export function useGuidedCareTeamPatientListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GuidedCareTeamPatientListQuery, GuidedCareTeamPatientListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GuidedCareTeamPatientListQuery, GuidedCareTeamPatientListQueryVariables>(GuidedCareTeamPatientListDocument, options);
        }
export type GuidedCareTeamPatientListQueryHookResult = ReturnType<typeof useGuidedCareTeamPatientListQuery>;
export type GuidedCareTeamPatientListLazyQueryHookResult = ReturnType<typeof useGuidedCareTeamPatientListLazyQuery>;
export type GuidedCareTeamPatientListSuspenseQueryHookResult = ReturnType<typeof useGuidedCareTeamPatientListSuspenseQuery>;
export type GuidedCareTeamPatientListQueryResult = Apollo.QueryResult<GuidedCareTeamPatientListQuery, GuidedCareTeamPatientListQueryVariables>;