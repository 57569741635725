import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { useEffect } from "react";
import { MarketplaceOrdersTrackingPage } from "@health/domains";
import { marketplaceOrdersTrackingTitleBreadcrumb } from "../constants/MarketplaceOrderTrackingBreadcrumbs";

export const ProviderMarketPlaceOrdersTrackingContainer = () => {
  const { t } = useTranslation("provider");
  const { setBreadCrumb } = useBreadCrumbs();

  useEffect(() => {
    setBreadCrumb({
      title: marketplaceOrdersTrackingTitleBreadcrumb(t),
    });
  }, []);

  return <MarketplaceOrdersTrackingPage />;
};
