import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DoctorProfileUpdateMutationVariables = Types.Exact<{
  input: Types.DoctorProfileInput;
}>;


export type DoctorProfileUpdateMutation = { __typename?: 'Mutation', doctorProfileUpdate?: { __typename?: 'DoctorProfileUpdate', doctor?: { __typename?: 'Doctor', id: string, appointmentTypes?: Array<Types.AppointmentTypeEnum | null> | null, appointmentSlotTimePeriod: number, availabilityStatus: Types.DoctorAvailabilityStatusEnum, bio?: string | null, isAddressPublic: boolean, isDateOfBirthPublic: boolean, isLanguagesPublic: boolean, isLicenseNumberPublic: boolean, isMobileNumberPublic: boolean, isNationalIdPublic: boolean, isSecondMobileNumberPublic: boolean, isSocialLinksPublic: boolean, isYearsOfExperiencePublic: boolean, secondMobileNumber?: string | null, seniority?: Types.DoctorSeniorityEnum | null, socialLinks?: any | null, yearsOfExperience?: number | null, languages?: Array<{ __typename?: 'Language', code: string, display: string, displayAr?: string | null, id: string } | null> | null, qualifications?: Array<{ __typename?: 'Qualification', code: string, issuer: string, toDate?: any | null, fromDate?: any | null } | null> | null, specializations?: Array<{ __typename?: 'DoctorSpecialization', code: string, id: string } | null> | null, workingHours?: Array<{ __typename?: 'WorkingHour', day?: Types.DayOfWeekEnum | null, openTimeRanges?: Array<{ __typename?: 'TimeRange', closeTime?: any | null, openTime?: any | null } | null> | null } | null> | null } | null, doctorErrors: Array<{ __typename?: 'DoctorError', code: Types.DoctorErrorCode, field?: string | null, message?: string | null }> } | null };


export const DoctorProfileUpdateDocument = gql`
    mutation doctorProfileUpdate($input: DoctorProfileInput!) {
  doctorProfileUpdate(input: $input) {
    doctor {
      id
      appointmentTypes
      appointmentSlotTimePeriod
      availabilityStatus
      bio
      isAddressPublic
      isDateOfBirthPublic
      isLanguagesPublic
      isLicenseNumberPublic
      isMobileNumberPublic
      isNationalIdPublic
      isSecondMobileNumberPublic
      isSocialLinksPublic
      isYearsOfExperiencePublic
      languages {
        code
        display
        displayAr
        id
      }
      qualifications {
        code
        issuer
        toDate
        fromDate
      }
      secondMobileNumber
      seniority
      socialLinks
      specializations {
        code
        id
      }
      workingHours {
        day
        openTimeRanges {
          closeTime
          openTime
        }
      }
      yearsOfExperience
    }
    doctorErrors {
      code
      field
      message
    }
  }
}
    `;
export type DoctorProfileUpdateMutationFn = Apollo.MutationFunction<DoctorProfileUpdateMutation, DoctorProfileUpdateMutationVariables>;

/**
 * __useDoctorProfileUpdateMutation__
 *
 * To run a mutation, you first call `useDoctorProfileUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDoctorProfileUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [doctorProfileUpdateMutation, { data, loading, error }] = useDoctorProfileUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDoctorProfileUpdateMutation(baseOptions?: Apollo.MutationHookOptions<DoctorProfileUpdateMutation, DoctorProfileUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DoctorProfileUpdateMutation, DoctorProfileUpdateMutationVariables>(DoctorProfileUpdateDocument, options);
      }
export type DoctorProfileUpdateMutationHookResult = ReturnType<typeof useDoctorProfileUpdateMutation>;
export type DoctorProfileUpdateMutationResult = Apollo.MutationResult<DoctorProfileUpdateMutation>;
export type DoctorProfileUpdateMutationOptions = Apollo.BaseMutationOptions<DoctorProfileUpdateMutation, DoctorProfileUpdateMutationVariables>;