/* eslint-disable no-nested-ternary */
import { MarketplaceProductSortingField, SortDirection } from "@/schema/types";
import { getMarketplaceBrandsAutocompleteFilter, getMarketplaceProductCategoriesAutocompleteFilter } from "@health/autocompletes";
import { getAutocompleteEnumFilter, marketplaceApprovalStatusOptionsMap } from "@health/enum-options";
import { DATE_FORMAT_FOR_TABLE_LONG } from "@toolkit/core";
import { i18n, pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { Box, CustomTableColumnProps, format, GridImageModal, TruncateTypography } from "@toolkit/ui";
import { useMemo } from "react";
import { CloneDialog, RequestViewModal, ToggleProductsPublishStatus, UpdateProductQuantity } from "../../components";
import { ProductsQueryNode } from "../../types";

export const useProductsListTableColumns = (): CustomTableColumnProps<ProductsQueryNode>[] => {
  const { t } = useTranslation("provider");

  return useMemo(() => {
    return [
      {
        key: "image",
        header: t("Image"),
        accessor: ({ mainImageUrl }) => <GridImageModal image={mainImageUrl!} title={t("Product Image")} altText={t("Product")} />,
      },
      {
        key: "name",
        header: t("Name"),
        accessor: ({ name, nameAr }) => <TruncateTypography text={pickLocalizedValue(name!, nameAr!) || "-"} />,
        filter: {
          type: "string",
          name: "search",
        },
        isSortable: true,
        sortDirection: SortDirection.Desc,
        sortColumnEnum: pickLocalizedValue(MarketplaceProductSortingField.Name, MarketplaceProductSortingField.NameAr),
      },
      {
        key: "short-description",
        header: t("Short Description"),
        accessor: ({ shortDescription, shortDescriptionAr }) => (
          <TruncateTypography text={pickLocalizedValue(shortDescription!, shortDescriptionAr!) || "-"} />
        ),
      },
      {
        key: "brand",
        header: t("Brand"),
        accessor: ({ brand }) => <TruncateTypography text={pickLocalizedValue(brand?.name!, brand?.nameAr!)} />,
        filter: getMarketplaceBrandsAutocompleteFilter({ name: "brandIds", multiple: true }),
      },
      {
        key: "category",
        header: t("Category"),
        accessor: ({ category }) => <TruncateTypography text={pickLocalizedValue(category?.name!, category?.nameAr!)} />,
        filter: getMarketplaceProductCategoriesAutocompleteFilter({ name: "categoryId" }),
      },
      {
        key: "price",
        header: t("Price"),
        accessor: ({ price }) => price || "-",
      },
      {
        key: "discount",
        header: t("Discount Amount"),
        accessor: ({ activeDiscount }) =>
          activeDiscount?.amount ? activeDiscount.amount : activeDiscount?.percentage ? activeDiscount.percentage + "%" : "-",
      },
      {
        key: "discounted-price",
        header: t("Discounted end Date"),
        accessor: ({ activeDiscount }) => (
          <Box sx={{ minWidth: "max-content" }}>{format(activeDiscount?.endDate, DATE_FORMAT_FOR_TABLE_LONG, i18n.language) || "-"}</Box>
        ),
      },
      {
        key: "Stock Quantity",
        header: t("Stock Quantity"),
        accessor: ({ stockQuantity, id }) => <UpdateProductQuantity id={id} stockQuantity={stockQuantity} />,
      },
      {
        key: "is-published",
        header: t("Is Published"),
        accessor: row => <ToggleProductsPublishStatus row={row} />,
        filter: getAutocompleteEnumFilter("YesNo", "isPublished"),
      },
      {
        key: "Approval Status",
        header: t("Approval Status"),
        accessor: ({ approvalStatus }) => marketplaceApprovalStatusOptionsMap[approvalStatus!]?.label,
        filter: getAutocompleteEnumFilter("MarketplaceApprovalStatus", "approvalStatuses", { multiple: true }),
      },
      {
        key: "actions",
        header: t("Actions"),
        accessor: row => (
          <Box display='flex' flexDirection='row' gap={2}>
            <CloneDialog updatable={row?.updatable} id={row?.id!} name={row?.name} nameAr={row?.nameAr} />
            <RequestViewModal {...row} />
          </Box>
        ),
      },
    ];
  }, []);
};
