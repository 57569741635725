import { AppointmentPaymentRequest, Maybe, PaymentStatus } from "@/schema/types";
import { useSiteSettings } from "@/shared/siteSettings.var";
import { toPascalCase } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, Typography, useTheme } from "@toolkit/ui";
import { SectionTitle } from "pages/appointments/components";
import { FC } from "react";

type AppointmentReschedulePaymentInformationProps = {
  paymentInfo?: Maybe<AppointmentPaymentRequest>;
  paymentStatus?: Maybe<PaymentStatus>;
};

export const AppointmentReschedulePaymentInformation: FC<AppointmentReschedulePaymentInformationProps> = props => {
  const { paymentInfo, paymentStatus } = props;
  const { defaultCurrency } = useSiteSettings();

  const { t } = useTranslation("provider");
  const theme = useTheme();
  return (
    <Box>
      <SectionTitle title={t("Payment Information")} />

      <Box marginTop='8px' marginBottom='8px'>
        <Typography fontWeight='bold' fontSize={theme.mixins.fonts.fontSize.sm}>
          {t("Method")}:
        </Typography>
        <Typography fontSize={theme.mixins.fonts.fontSize.sm}>{t(toPascalCase(paymentInfo?.type?.toLowerCase() || "-"))}</Typography>
      </Box>

      <Box marginTop='8px' marginBottom='8px'>
        <Typography fontWeight='bold' fontSize={theme.mixins.fonts.fontSize.sm}>
          {t("Amount")}:
        </Typography>
        <Typography fontSize={theme.mixins.fonts.fontSize.sm}>
          {paymentInfo?.totalAmount ? `${paymentInfo?.totalAmount} ${t(paymentInfo?.currency || defaultCurrency || "")}` : "-"}
        </Typography>
      </Box>

      <Box marginTop='8px' marginBottom='8px'>
        <Typography fontWeight='bold' fontSize={theme.mixins.fonts.fontSize.sm}>
          {t("Status")}:
        </Typography>

        <Typography fontSize={theme.mixins.fonts.fontSize.sm}>{t(toPascalCase(paymentStatus?.toLowerCase() || "-"))}</Typography>
      </Box>
    </Box>
  );
};
