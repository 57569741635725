import { OrderType } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { FC } from "react";
import { IOrderContainer } from "../actions/types";
import { OrderSection } from "./OrderSection";
import { useERXSections } from "./eRxSections/useeRxSections";

export const OrdersContainer: FC<IOrderContainer> = ({ data, hasAction }) => {
  const { color, type, iseRxOrder = true, status } = data;
  const { t } = useTranslation("provider");
  const { eRxSections } = useERXSections(data);

  return (
    <>
      {!iseRxOrder && <OrderSection status={status} color={color} title={t("Checkout Order")} />}
      {iseRxOrder && type && (
        <OrderSection status={status} color={color} title={type === OrderType.Pickup ? t("Pickup Order") : t("Delivery Order")} />
      )}

      {iseRxOrder && (
        <>
          {eRxSections
            .filter(item => item.hasData)
            .map((item, index) => (
              <OrderSection {...item} key={index} color={item.color} hasBG={index % 2 == 0} hasAction={hasAction} status={status} />
            ))}
        </>
      )}
    </>
  );
};
