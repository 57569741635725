import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GeneratePatientReferralByClinicianMutationVariables = Types.Exact<{
  newReferral?: Types.InputMaybe<Types.ReferralRequestInput>;
}>;


export type GeneratePatientReferralByClinicianMutation = { __typename?: 'Mutation', generatePatientReferralByClinician?: { __typename?: 'PatientReferral', code?: string | null, endDate?: any | null, startDate?: any | null, errors?: Array<{ __typename?: 'ReferralGraphqlError', code?: Types.ReferralErrorCodes | null, field?: string | null, message?: string | null } | null> | null } | null };


export const GeneratePatientReferralByClinicianDocument = gql`
    mutation generatePatientReferralByClinician($newReferral: ReferralRequestInput) {
  generatePatientReferralByClinician(newReferral: $newReferral) {
    code
    endDate
    errors {
      code
      field
      message
    }
    startDate
  }
}
    `;
export type GeneratePatientReferralByClinicianMutationFn = Apollo.MutationFunction<GeneratePatientReferralByClinicianMutation, GeneratePatientReferralByClinicianMutationVariables>;

/**
 * __useGeneratePatientReferralByClinicianMutation__
 *
 * To run a mutation, you first call `useGeneratePatientReferralByClinicianMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGeneratePatientReferralByClinicianMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generatePatientReferralByClinicianMutation, { data, loading, error }] = useGeneratePatientReferralByClinicianMutation({
 *   variables: {
 *      newReferral: // value for 'newReferral'
 *   },
 * });
 */
export function useGeneratePatientReferralByClinicianMutation(baseOptions?: Apollo.MutationHookOptions<GeneratePatientReferralByClinicianMutation, GeneratePatientReferralByClinicianMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GeneratePatientReferralByClinicianMutation, GeneratePatientReferralByClinicianMutationVariables>(GeneratePatientReferralByClinicianDocument, options);
      }
export type GeneratePatientReferralByClinicianMutationHookResult = ReturnType<typeof useGeneratePatientReferralByClinicianMutation>;
export type GeneratePatientReferralByClinicianMutationResult = Apollo.MutationResult<GeneratePatientReferralByClinicianMutation>;
export type GeneratePatientReferralByClinicianMutationOptions = Apollo.BaseMutationOptions<GeneratePatientReferralByClinicianMutation, GeneratePatientReferralByClinicianMutationVariables>;