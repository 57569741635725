import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DoctorAvailabilityStatusUpdateMutationVariables = Types.Exact<{
  availabilityStatus: Types.DoctorAvailabilityStatusEnum;
  period: Types.Scalars['Int']['input'];
}>;


export type DoctorAvailabilityStatusUpdateMutation = { __typename?: 'Mutation', doctorAvailabilityStatusUpdate?: { __typename?: 'DoctorAvailabilityStatusUpdate', result?: string | null, doctorErrors: Array<{ __typename?: 'DoctorError', code: Types.DoctorErrorCode, field?: string | null, message?: string | null }> } | null };


export const DoctorAvailabilityStatusUpdateDocument = gql`
    mutation doctorAvailabilityStatusUpdate($availabilityStatus: DoctorAvailabilityStatusEnum!, $period: Int!) {
  doctorAvailabilityStatusUpdate(
    availabilityStatus: $availabilityStatus
    period: $period
  ) {
    doctorErrors {
      code
      field
      message
    }
    result
  }
}
    `;
export type DoctorAvailabilityStatusUpdateMutationFn = Apollo.MutationFunction<DoctorAvailabilityStatusUpdateMutation, DoctorAvailabilityStatusUpdateMutationVariables>;

/**
 * __useDoctorAvailabilityStatusUpdateMutation__
 *
 * To run a mutation, you first call `useDoctorAvailabilityStatusUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDoctorAvailabilityStatusUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [doctorAvailabilityStatusUpdateMutation, { data, loading, error }] = useDoctorAvailabilityStatusUpdateMutation({
 *   variables: {
 *      availabilityStatus: // value for 'availabilityStatus'
 *      period: // value for 'period'
 *   },
 * });
 */
export function useDoctorAvailabilityStatusUpdateMutation(baseOptions?: Apollo.MutationHookOptions<DoctorAvailabilityStatusUpdateMutation, DoctorAvailabilityStatusUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DoctorAvailabilityStatusUpdateMutation, DoctorAvailabilityStatusUpdateMutationVariables>(DoctorAvailabilityStatusUpdateDocument, options);
      }
export type DoctorAvailabilityStatusUpdateMutationHookResult = ReturnType<typeof useDoctorAvailabilityStatusUpdateMutation>;
export type DoctorAvailabilityStatusUpdateMutationResult = Apollo.MutationResult<DoctorAvailabilityStatusUpdateMutation>;
export type DoctorAvailabilityStatusUpdateMutationOptions = Apollo.BaseMutationOptions<DoctorAvailabilityStatusUpdateMutation, DoctorAvailabilityStatusUpdateMutationVariables>;