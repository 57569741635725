import { DoctorAvailabilityStatusEnum } from "@/schema/types";
import { IEnumToOptionsMap } from "@health/enum-options";
import { i18n } from "@toolkit/i18n";

export const doctorAvailabilityStatusOptionsMap: IEnumToOptionsMap<DoctorAvailabilityStatusEnum> = {
  [DoctorAvailabilityStatusEnum.AvailableLevel_1]: {
    key: DoctorAvailabilityStatusEnum.AvailableLevel_1,
    get label() {
      return i18n.t("Available", { ns: "provider" });
    },
    value: DoctorAvailabilityStatusEnum.AvailableLevel_1,
  },
  [DoctorAvailabilityStatusEnum.AvailableLevel_2]: {
    key: DoctorAvailabilityStatusEnum.AvailableLevel_2,
    get label() {
      return i18n.t("Available", { ns: "provider" });
    },
    value: DoctorAvailabilityStatusEnum.AvailableLevel_2,
  },
  [DoctorAvailabilityStatusEnum.Unavailable]: {
    key: DoctorAvailabilityStatusEnum.Unavailable,
    get label() {
      return i18n.t("Unavailable", { ns: "provider" });
    },
    value: DoctorAvailabilityStatusEnum.Unavailable,
  },
};

export const doctorAvailabilityStatusOptions = [
  {
    ...doctorAvailabilityStatusOptionsMap[DoctorAvailabilityStatusEnum.AvailableLevel_1],
    value: [DoctorAvailabilityStatusEnum.AvailableLevel_1, DoctorAvailabilityStatusEnum.AvailableLevel_2],
  },
  { ...doctorAvailabilityStatusOptionsMap[DoctorAvailabilityStatusEnum.Unavailable], value: [DoctorAvailabilityStatusEnum.Unavailable] },
];
