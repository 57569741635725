import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VisitRejectionReasonsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type VisitRejectionReasonsQuery = { __typename?: 'Query', rejectionReasons?: Array<{ __typename?: 'H_RejectionReason', id: string, text?: string | null, text_ar?: string | null } | null> | null };


export const VisitRejectionReasonsDocument = gql`
    query visitRejectionReasons {
  rejectionReasons(filter: {actor: DOCTOR, affectedType: VISIT}) {
    id
    text
    text_ar
  }
}
    `;

/**
 * __useVisitRejectionReasonsQuery__
 *
 * To run a query within a React component, call `useVisitRejectionReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitRejectionReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitRejectionReasonsQuery({
 *   variables: {
 *   },
 * });
 */
export function useVisitRejectionReasonsQuery(baseOptions?: Apollo.QueryHookOptions<VisitRejectionReasonsQuery, VisitRejectionReasonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VisitRejectionReasonsQuery, VisitRejectionReasonsQueryVariables>(VisitRejectionReasonsDocument, options);
      }
export function useVisitRejectionReasonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VisitRejectionReasonsQuery, VisitRejectionReasonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VisitRejectionReasonsQuery, VisitRejectionReasonsQueryVariables>(VisitRejectionReasonsDocument, options);
        }
export function useVisitRejectionReasonsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<VisitRejectionReasonsQuery, VisitRejectionReasonsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<VisitRejectionReasonsQuery, VisitRejectionReasonsQueryVariables>(VisitRejectionReasonsDocument, options);
        }
export type VisitRejectionReasonsQueryHookResult = ReturnType<typeof useVisitRejectionReasonsQuery>;
export type VisitRejectionReasonsLazyQueryHookResult = ReturnType<typeof useVisitRejectionReasonsLazyQuery>;
export type VisitRejectionReasonsSuspenseQueryHookResult = ReturnType<typeof useVisitRejectionReasonsSuspenseQuery>;
export type VisitRejectionReasonsQueryResult = Apollo.QueryResult<VisitRejectionReasonsQuery, VisitRejectionReasonsQueryVariables>;