import { DoctorSpecialization, Language, Maybe } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, Card, CardContent, NewJob, PasteSpecial, Translate, Typography, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { InformationDoctor } from "./InformationDoctor.component";

export interface Qualification {
  code: string;
  created: Date;
  fromDate?: string;
  id: string;
  issuer: string;
  modified: string;
  toDate?: string;
}

interface SpecialtiesCardProps {
  qualifications: Qualification[];
  languages: Maybe<Language>[] | null | undefined;
  specializations?: DoctorSpecialization[];
}
export const SpecialtiesCard: FC<SpecialtiesCardProps> = ({ qualifications, languages, specializations }) => {
  const { t } = useTranslation("provider");
  const theme = useTheme();
  return (
    <Card>
      <CardContent>
        <InformationDoctor title={t("Specialties")} headerIcon={<PasteSpecial fill={theme.palette.primary.main} />}>
          <>
            {!specializations?.length ? (
              <Typography variant='body1' fontSize={theme.mixins.fonts.fontSize.sm}>
                {t("There is no Specializations")}
              </Typography>
            ) : (
              specializations?.map(item => {
                return (
                  <Typography key={item?.id} variant='body1' fontSize={theme.mixins.fonts.fontSize.sm} fontWeight={500}>
                    {item?.display}
                  </Typography>
                );
              })
            )}
          </>
        </InformationDoctor>

        <InformationDoctor title={t("Languages Spoken")} headerIcon={<Translate fill={theme.palette.primary.main} />}>
          <Typography variant='body1' fontSize={theme.mixins.fonts.fontSize.sm}>
            {!languages?.length ? t("There is no Languages") : languages?.map(language => language?.display).join(", ")}
          </Typography>
        </InformationDoctor>

        <InformationDoctor title={t("Doctor Qualifications")} headerIcon={<NewJob fill={theme.palette.primary.main} />}>
          <>
            {!qualifications?.length ? (
              <Typography variant='body1' fontSize={theme.mixins.fonts.fontSize.sm}>
                {t("There is no Qualifications")}
              </Typography>
            ) : (
              qualifications?.map((qualification, index) => {
                return (
                  <Box key={index} sx={{ padding: 1 }}>
                    <Typography variant='body1' fontSize={theme.mixins.fonts.fontSize.sm} fontWeight={500}>
                      {t("Code")} : {qualification?.code}
                    </Typography>
                    <Typography sx={{ fontSize: theme.mixins.fonts.fontSize.sm, textDecoration: "underline" }}>
                      {t("Issuer")} : {qualification?.issuer}
                    </Typography>
                    <Typography
                      variant='body2'
                      color={theme.palette.stale.main}
                      sx={{ fontSize: theme.mixins.fonts.fontSize.sm, color: theme.palette.stale.main }}
                    >
                      {t("Date")} : {qualification?.fromDate} - {qualification?.toDate}
                    </Typography>
                  </Box>
                );
              })
            )}
          </>
        </InformationDoctor>
      </CardContent>
    </Card>
  );
};
