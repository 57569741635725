import { CustomDialog, CustomTable, ShowButton, Typography, useTheme } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { OptimaValidationInfoHeader } from "../OptimaValidationInfoHeader";
import React, { FC } from "react";
import { useItemsListColumns } from "../useItemsListColumns";
import { useEditsListColumns } from "../useEditsListColumns";

type OptimaValidationResponseDialogProps = {
  response: string;
};

export const OptimaValidationResponseDialog: FC<OptimaValidationResponseDialogProps> = ({ response }) => {
  const { t } = useTranslation("provider");
  const theme = useTheme();

  const getResponse = JSON.parse(response);

  return (
    <CustomDialog type={"info"} button={<ShowButton />} title={t("Optima Validation Response")}>
      <Typography fontSize={theme.mixins.fonts.fontSize.lg}>
        <Typography component='span' fontSize={theme.mixins.fonts.fontSize.lg} fontWeight={theme.mixins.fonts.fontWeight.medium}>
          {t("Status")}:{" "}
        </Typography>
        {getResponse.status}
      </Typography>

      <Typography fontSize={theme.mixins.fonts.fontSize.lg}>
        <Typography component='span' fontSize={theme.mixins.fonts.fontSize.lg} fontWeight={theme.mixins.fonts.fontWeight.medium}>
          {t("Outcome")}:{" "}
        </Typography>
        {getResponse.outcome}
      </Typography>

      <OptimaValidationInfoHeader title={t("Items")} />
      <CustomTable
        data={getResponse.items}
        columns={useItemsListColumns()}
        scrollable
        hasFooter={false}
        TableContainerProps={{
          sx: {
            minHeight: "inherit !important",
          },
        }}
      />

      <OptimaValidationInfoHeader title={t("Edits")} />
      <CustomTable
        data={getResponse.edits}
        columns={useEditsListColumns()}
        scrollable
        hasFooter={false}
        TableContainerProps={{
          sx: {
            minHeight: "inherit !important",
          },
        }}
      />
    </CustomDialog>
  );
};
