import { useTranslation } from "@toolkit/i18n";
import { useOptimaRemittanceActivitiesQuery } from "../../../OptimaClaims/gql";
import { useState } from "react";
import { useRemittanceActivitiesListColumns } from "./useRemittanceActivitiesListColumns";
export const useRemittanceActivitiesList = (id) => {
    var _a, _b, _c, _d;
    const { t } = useTranslation("domains");
    const columns = useRemittanceActivitiesListColumns();
    const pageSize = 3;
    const [input, setInput] = useState({
        first: pageSize,
        after: null,
        before: null,
        last: null,
        filter: {
            remittanceId: id ? id : "",
        },
    });
    const { data: activitiesData, loading: isLoading } = useOptimaRemittanceActivitiesQuery({
        variables: input,
        fetchPolicy: "no-cache",
    });
    const pageInfo = (_a = activitiesData === null || activitiesData === void 0 ? void 0 : activitiesData.optimaRemittanceActivities) === null || _a === void 0 ? void 0 : _a.pageInfo;
    const tableData = (_c = (_b = activitiesData === null || activitiesData === void 0 ? void 0 : activitiesData.optimaRemittanceActivities) === null || _b === void 0 ? void 0 : _b.edges) === null || _c === void 0 ? void 0 : _c.map(e => e === null || e === void 0 ? void 0 : e.node);
    const hasNextPage = pageInfo === null || pageInfo === void 0 ? void 0 : pageInfo.hasNextPage;
    const hasPreviousPage = pageInfo === null || pageInfo === void 0 ? void 0 : pageInfo.hasPreviousPage;
    const handleGotoNext = () => {
        setInput(prev => (Object.assign(Object.assign({}, prev), { first: pageSize, after: (pageInfo === null || pageInfo === void 0 ? void 0 : pageInfo.endCursor) || null, last: null, before: null })));
    };
    const handleGoToPrevious = () => {
        setInput(prev => (Object.assign(Object.assign({}, prev), { last: pageSize, before: (pageInfo === null || pageInfo === void 0 ? void 0 : pageInfo.startCursor) || null, first: null, after: null })));
    };
    const totalCount = ((_d = activitiesData === null || activitiesData === void 0 ? void 0 : activitiesData.optimaRemittanceActivities) === null || _d === void 0 ? void 0 : _d.totalCount) || 0;
    return {
        t,
        tableData,
        hasNextPage,
        hasPreviousPage,
        isLoading,
        handleGotoNext,
        handleGoToPrevious,
        columns,
        pageSize,
        totalCount,
    };
};
