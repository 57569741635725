import { GuidedCareJourneyIntervention, HealthParameter, TemplateIntervention } from "@/schema/types";
import { Box, Button } from "@toolkit/ui";
import { FC, useState } from "react";
import {
  GuidedCareTemplateInterventionType,
  guidedCareTemplateInterventionTypeOptions,
} from "../../../enum-options/GuidedCareTemplateInterventionType";
import { GuidedCareJourneyAllInterventions } from "../GuidedCareJourneyAllInterventions/GuidedCareJourneyAllInterventions";
import {
  GuidedCareJourneyInterventionCard,
  IGuidedCareJourneyInterventionCardEvent,
} from "../GuidedCareJourneyInterventionCard/GuidedCareJourneyInterventionCard";
import { useGuidedCareJourneyInterventionsActivitiesTabInterventionsStyle } from "./useGuidedCareJourneyInterventionsActivitiesTabInterventionsStyle";

export type IGuidedCareJourneyInterventionsActivitiesTabInterventionsEvent = {
  type: "INTERVENTION_SELECT";
  payload: {
    templateIntervention: TemplateIntervention;
    healthParameter?: HealthParameter;
  };
};

type GuidedCareJourneyInterventionsActivitiesTabInterventionsProps = {
  eligibleInterventions: GuidedCareJourneyIntervention[];
  isComingFromAddPersonalizedItem?: boolean;
  onChange: (event: IGuidedCareJourneyInterventionsActivitiesTabInterventionsEvent) => void;
};

export const GuidedCareJourneyInterventionsActivitiesTabInterventions: FC<
  GuidedCareJourneyInterventionsActivitiesTabInterventionsProps
> = props => {
  const { eligibleInterventions, isComingFromAddPersonalizedItem = false, onChange } = props;

  const [selectedTab, setSelectedTab] = useState(GuidedCareTemplateInterventionType.RecommendedInterventions);

  const { classes } = useGuidedCareJourneyInterventionsActivitiesTabInterventionsStyle();

  const onTabClick = (value: GuidedCareTemplateInterventionType) => {
    setSelectedTab(value);
  };

  const onGuidedCareJourneyInterventionCardChange = (event: IGuidedCareJourneyInterventionCardEvent, healthParameter?: HealthParameter) => {
    if (event.type === "CLICK") {
      onChange({
        type: "INTERVENTION_SELECT",
        payload: {
          templateIntervention: event.payload.intervention,
          healthParameter,
        },
      });
    }
  };

  return (
    <Box>
      {!isComingFromAddPersonalizedItem && (
        <Box className={classes.tabs}>
          {guidedCareTemplateInterventionTypeOptions?.map(item => (
            <Button
              key={item?.key}
              variant={item?.value === selectedTab ? "contained" : "outlined"}
              onClick={() => onTabClick(item?.value)}
            >
              {item?.label}
            </Button>
          ))}
        </Box>
      )}

      {isComingFromAddPersonalizedItem ? (
        <GuidedCareJourneyAllInterventions onChange={onGuidedCareJourneyInterventionCardChange} />
      ) : (
        <Box>
          {selectedTab === GuidedCareTemplateInterventionType.RecommendedInterventions && (
            <>
              {eligibleInterventions?.map((intervention, index) => (
                <GuidedCareJourneyInterventionCard
                  key={intervention?.templateIntervention?.id}
                  index={index}
                  intervention={intervention?.templateIntervention!}
                  onChange={event => onGuidedCareJourneyInterventionCardChange(event, intervention?.healthParameter!)}
                />
              ))}
            </>
          )}

          {selectedTab === GuidedCareTemplateInterventionType.AllInterventions && (
            <GuidedCareJourneyAllInterventions onChange={onGuidedCareJourneyInterventionCardChange} />
          )}
        </Box>
      )}
    </Box>
  );
};
