import { combineErrors, formGirdBreakPoints, formGirdSpacing, getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { CustomDatePicker, FormPhoneInput, Grid, ToggleButtonController } from "@toolkit/ui";
import moment from "moment";
import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { CustomTextField } from "shared/components";
import { BranchDefaultData } from "../../BranchesForm.types";
import { BranchesType } from "./BranchesType.component";
import { generalInformationFields } from "./generalInformationUtils";

interface GeneralInfoProps {
  errorsInput: any;
  defaultValue: BranchDefaultData;
}

export const GeneralInformation: FC<GeneralInfoProps> = props => {
  const { t } = useTranslation("provider");
  const { defaultValue, errorsInput } = props;
  const {
    control,
    register,
    setValue,
    formState: { errors },
  } = useFormContext();
  const formErrors = combineErrors(errors, errorsInput);

  useEffect(() => {
    setValue("healthLicenseStartDate", defaultValue?.healthLicenseStartDate || moment());
    setValue("healthLicenseEndDate", defaultValue?.healthLicenseEndDate || moment());
    setValue("contactNumber", defaultValue?.contactNumber || "");
  }, [defaultValue, setValue]);

  return (
    <Grid container spacing={formGirdSpacing}>
      {generalInformationFields(t, defaultValue)?.map(item => (
        <Grid item {...formGirdBreakPoints} key={item.name}>
          <CustomTextField
            name={item.name}
            label={item.label}
            defaultValue={item.defaultValue}
            errorsInput={errorsInput}
            registerProps={{
              pattern: item.pattern,
              max: item?.maxValue,
            }}
          />
        </Grid>
      ))}

      <Grid item {...formGirdBreakPoints}>
        <CustomDatePicker
          control={control}
          name='healthLicenseStartDate'
          label={t("Health License Start Date")}
          placeholder={t("Health License Start Date")}
          error={Boolean(formErrors?.healthLicenseStartDate?.message)}
          helperText={t(formErrors?.healthLicenseStartDate?.message)}
        />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <CustomDatePicker
          control={control}
          name='healthLicenseEndDate'
          label={t("Health License End Date")}
          placeholder={t("Health License End Date")}
          error={Boolean(formErrors?.healthLicenseEndDate?.message)}
          helperText={t(formErrors?.healthLicenseEndDate?.message)}
        />
      </Grid>

      <Grid item {...formGirdBreakPoints}>
        <FormPhoneInput
          {...register("contactNumber", {
            required: getRequiredValidation(t, false),
          })}
          label={t("Contact Number")}
          error={Boolean(errorsInput?.["contactNumber"]?.message)}
          helperText={t(errorsInput?.["contactNumber"]?.message)}
          fullWidth
        />
      </Grid>
      <BranchesType defaultValue={defaultValue} />
      <Grid item>
        <ToggleButtonController control={control} name='isActive' label={t("Is Active")} defaultValue={defaultValue?.isActive ?? false} />
      </Grid>
      <Grid item>
        <ToggleButtonController
          control={control}
          name='acceptsDelivery'
          label={t("Accepts Delivery")}
          defaultValue={defaultValue?.acceptsDelivery ?? false}
        />
      </Grid>
    </Grid>
  );
};
