import { HealthParameter, Visit, VisitDiagnosis } from "@/schema/types";
import { useDebounce } from "@toolkit/core";
import { last } from "lodash";
import { VisitDetailTab } from "pages/e-visit/VitalSigns/types";
import { FC, PropsWithChildren, createContext, useContext, useEffect, useState } from "react";
import { VisitStoredDetailsQuery, useGetRiskFactorTemplatesLazyQuery, useVisitStoredDetailsQuery } from "../gql";

export interface CoPilotContextValue {
  storedVisitDetails: Required<VisitStoredDetailsQuery>["details"] | Visit;
  selectedDetailsTab: VisitDetailTab;
  isLoadingStoredDetails: boolean;
  latestDiagnosisCode: string;
  doctorNotes: string;
  visitId: string;
  loading?: boolean;
  fields?: HealthParameter[];
  selectedVisitDiagnosis?: VisitDiagnosis;
  handleGetTemplates?: (code: string) => void;
  handleSelectedVisitDiagnosis?: (row: VisitDiagnosis) => void;
  isThereHealthProgramMember: boolean;
  noteToPatient?: string | undefined;
}

export const CoPilotContext = createContext<CoPilotContextValue>({} as CoPilotContextValue);

export const useCoPilotContext = () => useContext(CoPilotContext);

export const CoPilotContextProvider: FC<
  PropsWithChildren<
    Omit<CoPilotContextValue, "storedVisitDetails" | "isLoadingStoredDetails" | "latestDiagnosisCode" | "isThereHealthProgramMember">
  >
> = ({ children, selectedDetailsTab, doctorNotes: notes, visitId }) => {
  const [selectedVisitDiagnosis, setSelectedVisitDiagnosis] = useState<VisitDiagnosis | undefined>(undefined);

  const { data: storedVisitInformation, loading: isLoadingStoredDetails } = useVisitStoredDetailsQuery({
    variables: {
      visitId,
    },
    skip: !visitId,
  });
  const [getRiskFactorTemplates, { data, loading }] = useGetRiskFactorTemplatesLazyQuery({
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  const doctorNotes = useDebounce(notes || storedVisitInformation?.details?.doctorNote, 500);
  const latestDiagnosisCode = last(storedVisitInformation?.details?.visitDiagnoses)?.code!;
  const isThereHealthProgramMember = !!storedVisitInformation?.details?.healthProgramMember?.id;
  const noteToPatient = storedVisitInformation?.details?.noteToPatient!;
  const handleGetTemplates = code => {
    code &&
      getRiskFactorTemplates({
        variables: {
          first: 10,
          filter: {
            code,
          },
        },
      });
  };

  const handleSelectedVisitDiagnosis = row => {
    setSelectedVisitDiagnosis(row);
  };

  useEffect(() => {
    selectedVisitDiagnosis && selectedVisitDiagnosis?.riskTemplate?.code && handleGetTemplates(selectedVisitDiagnosis?.riskTemplate?.code);
  }, [selectedVisitDiagnosis]);

  const fields = data?.getRiskFactorTemplates?.edges?.[0]?.node?.fields as HealthParameter[];

  return (
    <CoPilotContext.Provider
      value={{
        fields,
        selectedVisitDiagnosis,
        storedVisitDetails: storedVisitInformation?.details!,
        isLoadingStoredDetails,
        latestDiagnosisCode,
        selectedDetailsTab,
        doctorNotes: doctorNotes || "",
        visitId,
        loading,
        handleGetTemplates,
        handleSelectedVisitDiagnosis,
        isThereHealthProgramMember,
        noteToPatient,
      }}
    >
      {children}
    </CoPilotContext.Provider>
  );
};
