import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteAppointmentNotificationSettingMutationVariables = Types.Exact<{
  deleteAppointmentNotificationSettingId: Types.Scalars['ID']['input'];
}>;


export type DeleteAppointmentNotificationSettingMutation = { __typename?: 'Mutation', deleteAppointmentNotificationSetting?: boolean | null };


export const DeleteAppointmentNotificationSettingDocument = gql`
    mutation DeleteAppointmentNotificationSetting($deleteAppointmentNotificationSettingId: ID!) {
  deleteAppointmentNotificationSetting(
    id: $deleteAppointmentNotificationSettingId
  )
}
    `;
export type DeleteAppointmentNotificationSettingMutationFn = Apollo.MutationFunction<DeleteAppointmentNotificationSettingMutation, DeleteAppointmentNotificationSettingMutationVariables>;

/**
 * __useDeleteAppointmentNotificationSettingMutation__
 *
 * To run a mutation, you first call `useDeleteAppointmentNotificationSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAppointmentNotificationSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAppointmentNotificationSettingMutation, { data, loading, error }] = useDeleteAppointmentNotificationSettingMutation({
 *   variables: {
 *      deleteAppointmentNotificationSettingId: // value for 'deleteAppointmentNotificationSettingId'
 *   },
 * });
 */
export function useDeleteAppointmentNotificationSettingMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAppointmentNotificationSettingMutation, DeleteAppointmentNotificationSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAppointmentNotificationSettingMutation, DeleteAppointmentNotificationSettingMutationVariables>(DeleteAppointmentNotificationSettingDocument, options);
      }
export type DeleteAppointmentNotificationSettingMutationHookResult = ReturnType<typeof useDeleteAppointmentNotificationSettingMutation>;
export type DeleteAppointmentNotificationSettingMutationResult = Apollo.MutationResult<DeleteAppointmentNotificationSettingMutation>;
export type DeleteAppointmentNotificationSettingMutationOptions = Apollo.BaseMutationOptions<DeleteAppointmentNotificationSettingMutation, DeleteAppointmentNotificationSettingMutationVariables>;