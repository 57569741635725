import { MarketplaceProduct } from "@/schema/types";
import { getMediaLink } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, ImageList, ImageListItem, Typography } from "@toolkit/ui";
import React from "react";
import { useStyles } from "./styles";

export const ProductImages: React.FC<Pick<MarketplaceProduct, "subImages" | "mainImageUrl">> = ({ subImages, mainImageUrl }) => {
  const { classes } = useStyles();
  const { t } = useTranslation("provider");
  return (
    <>
      {mainImageUrl && (
        <Box marginTop='8px' marginBottom='8px'>
          <Typography fontWeight='bold' fontSize='16px'>
            {t("Main Image:")}
          </Typography>
          <Box display='flex' justifyContent='center'>
            <img src={getMediaLink(mainImageUrl)} className={classes.mainImage} />
          </Box>
        </Box>
      )}
      {subImages && subImages?.length > 0 && (
        <Box marginTop='8px' marginBottom='8px'>
          <Typography fontWeight='bold' fontSize='16px'>
            {t("Sub Images:")}
          </Typography>
          <Box width={"100%"} className={classes.root}>
            <ImageList>
              {subImages?.map((image, index) => (
                <ImageListItem className={classes.imageListItem} key={index}>
                  <img src={getMediaLink(image)} />
                </ImageListItem>
              ))}
            </ImageList>
          </Box>
        </Box>
      )}
      {!mainImageUrl && !subImages?.length && (
        <Box marginTop='8px' marginBottom='8px'>
          <Typography fontSize='20px'>{t("No images provided")}</Typography>
        </Box>
      )}
    </>
  );
};
