import { EvaluatedTemplateLabField, Lab, TemplateFieldType } from "@/schema/types";
import { GraphQLErrors } from "@apollo/client/errors";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { identity, pickBy } from "lodash";
import { safeJsonParseWithDefault } from "pages/dashboard/utils";
import { useState } from "react";
import { getApolloContextFormToken } from "shared/utils";
import { LabsDocument, useLabRequestCreateMutation, useLabRequestDeleteMutation, useLabsQuery, useSetLabResultMutation } from "../gql";

export const useLabsHook = ({ visitId, token, handleResetForm }) => {
  const { t } = useTranslation("provider");
  const { succeeded, failed } = useAddToast();
  const [selectedRow, setSelectedRow] = useState<Lab | undefined>(undefined);
  const [errors, setErrors] = useState<GraphQLErrors>([]);
  const { data, loading } = useLabsQuery({
    variables: {
      visitId,
    },
    context: getApolloContextFormToken(token),
  });

  const [labRequestDelete] = useLabRequestDeleteMutation({
    onCompleted: data => {
      const labErrors = data?.labRequestDelete?.labErrors;
      if (labErrors?.length === 0) {
        succeeded(t("Lab Delete Successfully"));
      } else {
        failed(t("Deleted Lab Failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    refetchQueries: [LabsDocument],
    context: getApolloContextFormToken(token),
  });

  const handleDelete = data => {
    labRequestDelete({
      variables: {
        labRequestDeleteId: data?.id!,
      },
    });
  };

  const [labRequestCreate, { loading: isSubmittingCreate }] = useLabRequestCreateMutation({
    context: getApolloContextFormToken(token),
    onCompleted: data => {
      const labErrors = data?.labRequestCreate?.labErrors;
      if (labErrors?.length === 0) {
        succeeded(t("Lab Added Successfully"));
        handleResetForm();
      } else {
        failed(t("Added Lab Failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
      setErrors(graphQLErrors!);
    },
    refetchQueries: [LabsDocument],
  });

  const [setLabResult, { loading: isSetResultCreate }] = useSetLabResultMutation({
    context: getApolloContextFormToken(token),
    onCompleted: data => {
      const labErrors = data?.setLabResult?.labErrors;
      if (labErrors?.length === 0) {
        succeeded(t("Lab Result Added Successfully"));
        setSelectedRow(undefined);
      } else {
        failed(t("Added Lab Result Failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
      setErrors(graphQLErrors!);
    },
    refetchQueries: [LabsDocument],
  });

  const handleCreate = input => {
    labRequestCreate({
      variables: {
        input: {
          visitId: visitId,
          code: input?.code?.value?.code,
        },
      },
    });
  };
  const selectedLabFields = safeJsonParseWithDefault(selectedRow?.evaluatedTemplate!, {})?.fields as EvaluatedTemplateLabField[];
  const handleSetLabResult = inputResult => {
    const _data = { ...inputResult };
    delete _data?.code;
    const filteredObj = pickBy(_data, identity);

    const result = Object.keys(filteredObj).reduce((acc, key) => {
      const field = selectedLabFields?.find(l => l?.code === key);
      if (field?.type === TemplateFieldType.Boolean) {
        const selectedValue: { value: boolean; name: string } = filteredObj?.[key] as unknown as { value: boolean; name: string };
        acc[key] = selectedValue?.value;
      } else if (field?.type === TemplateFieldType.String) {
        const selectedValue: { value: string; name: string } = filteredObj?.[key] as unknown as { value: string; name: string };
        acc[key] = !field?.allowedValues?.length ? filteredObj[key] : selectedValue?.value;
      } else {
        acc[key] = filteredObj[key];
      }
      return acc;
    }, {});
    setLabResult({
      variables: {
        input: {
          visitId: visitId!,
          code: selectedRow?.code!,
          result: JSON.stringify(result),
        },
      },
    });
  };
  const handleEdit = row => {
    setSelectedRow(row);
  };

  const labs = data?.visit?.labs as Lab[];

  return {
    labs,
    loading,
    errors,
    selectedRow,
    selectedLabFields,
    isSetResultCreate,
    isSubmittingCreate,
    handleCreate,
    handleDelete,
    handleEdit,
    handleSetLabResult,
  };
};
