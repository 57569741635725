import { H_OrderDirection, PatientHealthParameterSortField } from "@/schema/types";
import { useVisitsVitalSignsChanged } from "@health/sse";
import { useTranslation } from "@toolkit/i18n";
import { Loader, useTheme } from "@toolkit/ui";
import { last } from "lodash";
import { healthParameterCodes } from "pages/PatientHealthParameters/constants";
import { FC } from "react";
import { getApolloContextFormToken } from "../../../../utils";
import { usePatientHealthParametersQuery } from "../../gql";
import BarLineChart from "../Charts/BarLineChart";
import { OverviewCard } from "./OverviewCard";
import { IVitalSignChart } from "./types";
import { createPatientHealthParameterItem } from "./utils";

type VitalSignHeartRateChartProps = IVitalSignChart;

export const VitalSignHeartRateChart: FC<VitalSignHeartRateChartProps> = props => {
  const { width, percentage, token, patientId } = props;

  const { t } = useTranslation("provider");
  const {
    palette: { secondary },
  } = useTheme();

  const { data, loading, refetch } = usePatientHealthParametersQuery({
    variables: {
      first: 10,
      filter: {
        patientId: [patientId],
        codes: [healthParameterCodes.heartRate],
      },
      sortBy: {
        field: PatientHealthParameterSortField.CreatedDate,
        direction: H_OrderDirection.Desc,
      },
    },
    fetchPolicy: "no-cache",
    context: getApolloContextFormToken(token!),
  });

  const patientHealthParameters = data?.patientHealthParameters?.edges?.map(item => item?.node) || [];

  const healthParameters = createPatientHealthParameterItem(patientHealthParameters, "°bpm");

  const lastItem = last(healthParameters);
  useVisitsVitalSignsChanged(({ data: visitsVitalSignsChangedData }) => {
    if (visitsVitalSignsChangedData?.hpCode === healthParameterCodes.heartRate) {
      refetch({
        first: 10,
        filter: {
          patientId: [patientId],
          codes: [healthParameterCodes.heartRate],
        },
      });
    }
  });

  return (
    <OverviewCard
      width={width}
      title={t("Heart Rate")}
      value={`${Math.floor(lastItem?.value! || 0)}`}
      unit={"bpm"}
      description={t("By") + " " + (lastItem?.source || "-")}
      percentage={percentage}
      date={lastItem?.date!}
    >
      {loading ? <Loader /> : <BarLineChart title={t("Heart Rate")} color={secondary.main} data={healthParameters} />}
    </OverviewCard>
  );
};
