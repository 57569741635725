import { ProviderSettingsInput } from "@/schema/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useForm } from "react-hook-form";
import { GENERAL_ERROR_MESSAGE } from "shared/constants";
import { SaveProviderSettingsMutation, useSaveProviderSettingsMutation } from "../../../gql";

const useOutPatientJourneyForm = () => {
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<ProviderSettingsInput>();
  const { t } = useTranslation("provider");
  const { succeeded, failed } = useAddToast();

  const [fetchSaveProviderSettingsMutation, { loading: loadingMutation }] = useSaveProviderSettingsMutation({
    onCompleted: (mutationData: SaveProviderSettingsMutation) => {
      if (mutationData?.saveProviderSettings) {
        succeeded(t("Provider Settings Updated"));
      } else {
        failed(t(GENERAL_ERROR_MESSAGE));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onSubmit = (values: ProviderSettingsInput) => {
    fetchSaveProviderSettingsMutation({
      variables: {
        input: {
          outPatientJourneySupported: values.outPatientJourneySupported,
          paymentAfterConsultation: values.paymentAfterConsultation,
          appointmentConfirmationTimeoutSeconds: values.appointmentConfirmationTimeoutSeconds! * 60,
          appointmentReminderTimeoutSeconds: values.appointmentReminderTimeoutSeconds! * 60,
        },
      },
    });
  };

  return {
    t,
    control,
    errors,
    register,
    loadingMutation,
    onSubmit: handleSubmit(onSubmit),
  };
};

export default useOutPatientJourneyForm;
