import { PatientProfileConsentForm } from "@/schema/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useEffect, useState } from "react";
import { getApolloContextFormToken } from "shared/utils";
import { useProfileContext } from "../../../context/ProfileContext";
import { usePatientProfileConsentFormCreateMutation } from "../../../gql";

export const useRequestAccess = () => {
  const { failed, succeeded } = useAddToast();
  const { t } = useTranslation("provider");

  const { patientId, token, tokenId } = useProfileContext();

  const [requestAccess, { loading, data }] = usePatientProfileConsentFormCreateMutation({
    onCompleted: response => {
      const entityErrors = response?.patientProfileConsentFormCreate?.entityErrors;
      if (entityErrors?.length === 0) {
        succeeded(t("Request Access Sent Successfully"));
      } else {
        failed(t("Request Access Sent Failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(t(formatGraphQLError(graphQLErrors)));
    },
    context: getApolloContextFormToken(token!),
  });

  const patientProfileConsentForm = data?.patientProfileConsentFormCreate?.patientProfileConsentForm as PatientProfileConsentForm;
  const [patientProfileConsentFormData, setPatientProfileConsentForm] = useState<PatientProfileConsentForm | undefined>(undefined);
  useEffect(() => {
    if (patientProfileConsentForm) {
      setPatientProfileConsentForm(patientProfileConsentForm!);
    }
  }, [patientProfileConsentForm]);
  const handleReset = () => {
    setPatientProfileConsentForm(undefined);
  };
  const handleRequestAccess = () => {
    requestAccess({
      variables: {
        input: {
          patientId: patientId!,
          consentCode: tokenId ? tokenId : null,
        },
      },
    });
  };

  return {
    loading,
    patientProfileConsentFormData,
    handleReset,
    handleRequestAccess,
  };
};
