import { useCopyVisitLazyQuery } from "@/pages/e-visit/VitalSigns/gql/queries";
import { formatPatientHealthParametersVitalSigns } from "@/pages/PatientHealthParameters/others";
import { VisitStatus } from "@/schema/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CustomDialog, Grid, IconButton, useAddToast, useIsMobileView, useTheme } from "@toolkit/ui";
import { Copilot } from "pages/e-visit/CoPilot";
import { CoPilotContextProvider } from "pages/e-visit/CoPilot/CoPilotContext";
import { convertVisitResultToPlainText, isVisitDetailsEnabled } from "pages/e-visit/VitalSigns/others";
import { VisitTabsStatus } from "pages/programs-calls/utils";
import { FC, ReactElement, useState } from "react";
import { CompleteVisitButton } from "../CompleteButton/CompleteButton";
import { SideButtons } from "../SideButtons/SideButtons";
import { useVisitDetailsDialogItems } from "./useVisitDetailsDialogItems";

type VisitDetailsDialogProps = {
  visitStatus: VisitStatus;
  visitId: string;
  payerId: string;
  status?: VisitTabsStatus;
  color?: string;
  icon?: ReactElement;
};

export const VisitDetailsDialog: FC<VisitDetailsDialogProps> = props => {
  const { visitId, visitStatus, payerId, status, color, icon } = props;

  const [notes, setVisitNotes] = useState("");

  const { t } = useTranslation("provider");
  const { failed, succeeded } = useAddToast();
  const { open, handleToggle } = useOpenState();

  const isMobile = useIsMobileView();

  const [copyVisit, { loading }] = useCopyVisitLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted: response => {
      const vitalSigns = response?.vitalSigns?.edges?.map(item => item?.node!) || [];
      const visitVitalSigns = response?.visit?.vitalSigns?.map(item => item!) || [];

      const vitalSignsData =
        formatPatientHealthParametersVitalSigns(vitalSigns, visitVitalSigns)
          ?.filter(item => item?.value)
          ?.map(item => ({
            display: item?.display,
            value: item?.value,
          })) || [];

      const visit = {
        ...response?.visit,
        vitalSigns: vitalSignsData,
      };

      const formattedText = convertVisitResultToPlainText(visit);

      try {
        navigator.clipboard.writeText(formattedText).then(() => {
          succeeded("The visit details has been copied to the clipboard!");
        });
      } catch (err) {
        failed("Failed to copy response. Please try again.");
        console.error("Copy failed", err);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const isHidden = status === (VisitTabsStatus.canceled || status === VisitTabsStatus.completed) || false;
  const isButtonDisabled = !isVisitDetailsEnabled(visitStatus);

  const handleVisitNoteChanged = (visitNotes: string) => {
    if (visitNotes) {
      setVisitNotes(visitNotes);
    }
  };

  const visitDetailsTabs = useVisitDetailsDialogItems({
    visitId,
    isHidden,
    handleVisitNoteChanged,
  });

  const [selected, setSelected] = useState(visitDetailsTabs[0]);

  const handleOnClick = el => {
    setSelected(el);
  };

  const handleCopy = () => {
    copyVisit({ variables: { visitId } });
  };

  const theme = useTheme();
  return (
    <CustomDialog
      type='base'
      open={open}
      maxWidth='lg'
      DialogTitleProps={{
        title: "Visit Details",
        onClose: handleToggle,
      }}
      fullScreen={isMobile}
      DialogContentProps={{
        sx: {
          height: "90vh",
        },
      }}
      button={
        icon ? (
          <IconButton color='primary' onClick={handleToggle} disabled={isButtonDisabled}>
            {icon}
          </IconButton>
        ) : (
          <Button
            sx={{
              whiteSpace: "nowrap",
              background: color ? color : theme.palette.warning.main,
              "&:hover": { backgroundColor: theme.palette.warning[1100] },
            }}
            disabled={isButtonDisabled}
            onClick={handleToggle}
          >
            {t("Visit Details")}
          </Button>
        )
      }
      DialogActionsProps={{
        children: (
          <Box display='flex'>
            <CompleteVisitButton visitStatus={visitStatus} visitId={visitId} onToggle={handleToggle} />
            <Button onClick={handleCopy} disabled={loading}>
              {t("Copy Visit Details")}
            </Button>
          </Box>
        ),
      }}
    >
      <CoPilotContextProvider visitId={visitId} selectedDetailsTab={selected.id} doctorNotes={notes}>
        <Grid container spacing={1} flexWrap='nowrap'>
          <Grid item xs={1}>
            <SideButtons items={visitDetailsTabs} onClick={handleOnClick} selected={selected} isHidden={isHidden} />
          </Grid>

          <Grid item xs={6.6}>
            <Box sx={{ background: theme.palette.common.white }}>{selected?.component}</Box>
          </Grid>

          <Grid item xs='auto' md={4.5}>
            <Copilot visitId={visitId} payerId={payerId} />
          </Grid>
        </Grid>
      </CoPilotContextProvider>
    </CustomDialog>
  );
};
