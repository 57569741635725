import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProviderGuidedCareHealthProgramTeamsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.ProviderGuidedCareHealthProgramTeamFilterInput>;
}>;


export type ProviderGuidedCareHealthProgramTeamsQuery = { __typename?: 'Query', providerGuidedCareHealthProgramTeams?: { __typename?: 'ProviderGuidedCareHealthProgramTeamCountableConnection', totalCount?: number | null, pageInfo: { __typename?: 'H_PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'ProviderGuidedCareHealthProgramTeamCountableEdge', node: { __typename?: 'ProviderGuidedCareHealthProgramTeam', id: string, name?: string | null, isActive?: boolean | null, description?: string | null, maxNumberOfPatients?: number | null, teamStatus?: Types.ProviderGuidedCareTeamStatus | null, provider: { __typename?: 'Vendor', id: string, name: string }, guidedCareHealthProgram: { __typename?: 'GuidedCareHealthProgram', id: string, name?: string | null, guidedCareHealthProgramTemplate?: { __typename?: 'HealthProgramTemplate', id: string } | null, payer?: { __typename?: 'Payer', id: string, name: string } | null }, teamMembers?: Array<{ __typename?: 'ProviderGuidedCareProgramTeamMember', id: string, isBackup?: boolean | null, user?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, photo?: string | null } | null, guidedCareProgramTeamMember?: { __typename?: 'GuidedCareProgramTeamMember', id: string, position?: Types.TeamMemberPosition | null, isBackupPersonMandatory?: boolean | null, isKeyTeamMember?: boolean | null, isLicencedHealthProfessional?: boolean | null, specialization?: string | null } | null, doctor?: { __typename?: 'Doctor', id: string, user?: { __typename?: 'User', fullName?: string | null } | null } | null } | null> | null } }> } | null };


export const ProviderGuidedCareHealthProgramTeamsDocument = gql`
    query providerGuidedCareHealthProgramTeams($first: Int, $after: String, $last: Int, $before: String, $filter: ProviderGuidedCareHealthProgramTeamFilterInput) {
  providerGuidedCareHealthProgramTeams(
    first: $first
    after: $after
    last: $last
    before: $before
    filter: $filter
  ) {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    totalCount
    edges {
      node {
        id
        name
        isActive
        description
        provider {
          id
          name
        }
        maxNumberOfPatients
        teamStatus
        guidedCareHealthProgram {
          id
          name
          guidedCareHealthProgramTemplate {
            id
          }
          payer {
            id
            name
          }
        }
        teamMembers {
          id
          isBackup
          user {
            id
            firstName
            lastName
            photo
          }
          guidedCareProgramTeamMember {
            id
            position
            isBackupPersonMandatory
            isKeyTeamMember
            isLicencedHealthProfessional
            specialization
          }
          doctor {
            id
            user {
              fullName
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useProviderGuidedCareHealthProgramTeamsQuery__
 *
 * To run a query within a React component, call `useProviderGuidedCareHealthProgramTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProviderGuidedCareHealthProgramTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProviderGuidedCareHealthProgramTeamsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useProviderGuidedCareHealthProgramTeamsQuery(baseOptions?: Apollo.QueryHookOptions<ProviderGuidedCareHealthProgramTeamsQuery, ProviderGuidedCareHealthProgramTeamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProviderGuidedCareHealthProgramTeamsQuery, ProviderGuidedCareHealthProgramTeamsQueryVariables>(ProviderGuidedCareHealthProgramTeamsDocument, options);
      }
export function useProviderGuidedCareHealthProgramTeamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProviderGuidedCareHealthProgramTeamsQuery, ProviderGuidedCareHealthProgramTeamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProviderGuidedCareHealthProgramTeamsQuery, ProviderGuidedCareHealthProgramTeamsQueryVariables>(ProviderGuidedCareHealthProgramTeamsDocument, options);
        }
export function useProviderGuidedCareHealthProgramTeamsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ProviderGuidedCareHealthProgramTeamsQuery, ProviderGuidedCareHealthProgramTeamsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProviderGuidedCareHealthProgramTeamsQuery, ProviderGuidedCareHealthProgramTeamsQueryVariables>(ProviderGuidedCareHealthProgramTeamsDocument, options);
        }
export type ProviderGuidedCareHealthProgramTeamsQueryHookResult = ReturnType<typeof useProviderGuidedCareHealthProgramTeamsQuery>;
export type ProviderGuidedCareHealthProgramTeamsLazyQueryHookResult = ReturnType<typeof useProviderGuidedCareHealthProgramTeamsLazyQuery>;
export type ProviderGuidedCareHealthProgramTeamsSuspenseQueryHookResult = ReturnType<typeof useProviderGuidedCareHealthProgramTeamsSuspenseQuery>;
export type ProviderGuidedCareHealthProgramTeamsQueryResult = Apollo.QueryResult<ProviderGuidedCareHealthProgramTeamsQuery, ProviderGuidedCareHealthProgramTeamsQueryVariables>;