import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GuidedCareTeamListQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.ProviderGuidedCareHealthProgramTeamFilterInput>;
  sortBy?: Types.InputMaybe<Types.ProviderGuidedCareHealthProgramTeamSortingInput>;
}>;


export type GuidedCareTeamListQuery = { __typename?: 'Query', providerGuidedCareHealthProgramTeams?: { __typename?: 'ProviderGuidedCareHealthProgramTeamCountableConnection', totalCount?: number | null, pageInfo: { __typename?: 'H_PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'ProviderGuidedCareHealthProgramTeamCountableEdge', node: { __typename?: 'ProviderGuidedCareHealthProgramTeam', id: string, name?: string | null, isActive?: boolean | null, description?: string | null, maxNumberOfPatients?: number | null, teamStatus?: Types.ProviderGuidedCareTeamStatus | null, createdDate?: any | null, lastModifiedDate?: any | null, guidedCareHealthProgram: { __typename?: 'GuidedCareHealthProgram', id: string, name?: string | null, payer?: { __typename?: 'Payer', name: string } | null } } }> } | null };


export const GuidedCareTeamListDocument = gql`
    query GuidedCareTeamList($first: Int, $after: String, $last: Int, $before: String, $filter: ProviderGuidedCareHealthProgramTeamFilterInput, $sortBy: ProviderGuidedCareHealthProgramTeamSortingInput) {
  providerGuidedCareHealthProgramTeams(
    first: $first
    after: $after
    last: $last
    before: $before
    filter: $filter
    sortBy: $sortBy
  ) {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    totalCount
    edges {
      node {
        id
        name
        isActive
        description
        maxNumberOfPatients
        teamStatus
        createdDate
        lastModifiedDate
        guidedCareHealthProgram {
          id
          name
          payer {
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGuidedCareTeamListQuery__
 *
 * To run a query within a React component, call `useGuidedCareTeamListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuidedCareTeamListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuidedCareTeamListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useGuidedCareTeamListQuery(baseOptions?: Apollo.QueryHookOptions<GuidedCareTeamListQuery, GuidedCareTeamListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GuidedCareTeamListQuery, GuidedCareTeamListQueryVariables>(GuidedCareTeamListDocument, options);
      }
export function useGuidedCareTeamListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GuidedCareTeamListQuery, GuidedCareTeamListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GuidedCareTeamListQuery, GuidedCareTeamListQueryVariables>(GuidedCareTeamListDocument, options);
        }
export function useGuidedCareTeamListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GuidedCareTeamListQuery, GuidedCareTeamListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GuidedCareTeamListQuery, GuidedCareTeamListQueryVariables>(GuidedCareTeamListDocument, options);
        }
export type GuidedCareTeamListQueryHookResult = ReturnType<typeof useGuidedCareTeamListQuery>;
export type GuidedCareTeamListLazyQueryHookResult = ReturnType<typeof useGuidedCareTeamListLazyQuery>;
export type GuidedCareTeamListSuspenseQueryHookResult = ReturnType<typeof useGuidedCareTeamListSuspenseQuery>;
export type GuidedCareTeamListQueryResult = Apollo.QueryResult<GuidedCareTeamListQuery, GuidedCareTeamListQueryVariables>;