import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from "react";
import moment from "moment";
import { Badge, Typography, ListItem, ListItemAvatar, ListItemText, useTheme, ListItemSecondaryAction, format } from "@toolkit/ui";
import { i18n } from "@toolkit/i18n";
import { ChatAvatar } from "../ChatAvatar";
import { ChatStatus } from "../ChatStatus";
import { ChatTitle } from "../ChatTitle";
export const ChatGroupsItem = ({ item, isSelected, unreadCount, onClick }) => {
    const theme = useTheme();
    const { createdAt } = item;
    const time = useMemo(() => {
        if (!createdAt) {
            return null;
        }
        const value = moment(createdAt);
        return format(value.toISOString(), value.isSame(moment(), "day") ? "hh:mm A" : "DD/MM/YYYY", i18n.language);
    }, [createdAt]);
    const handleOnClick = useCallback(() => onClick === null || onClick === void 0 ? void 0 : onClick(item), [item]);
    return (_jsxs(ListItem, { sx: { cursor: "pointer" }, onClick: handleOnClick, selected: isSelected, children: [_jsx(ListItemAvatar, { children: _jsx(ChatAvatar, { value: item, size: 40 }) }), _jsx(ListItemText, { primary: _jsx(ChatTitle, { value: item }), primaryTypographyProps: {
                    sx: { whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", fontWeight: theme.mixins.fonts.fontWeight.medium },
                }, secondary: _jsxs(_Fragment, { children: [_jsx(ChatStatus, { value: item }), _jsx(Typography, { sx: { position: "absolute", right: 10, bottom: 2 }, color: 'primary', fontSize: theme.mixins.fonts.fontSize.xxs, children: time })] }) }), unreadCount && unreadCount > 0 && (_jsx(ListItemSecondaryAction, { children: _jsx(Badge, { badgeContent: unreadCount, color: 'primary' }) }))] }));
};
