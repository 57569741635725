import { PermissionEnum } from "@/schema/types";
import RouteItem from "@/shared/components/Root/routeTypes";
import { hasPermission } from "@toolkit/core";
import { TFunction } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import { ProviderOptimaPriorRequestsListContainer } from "../containers/OptimaPriorRequestsList/ProviderOptimaPriorRequestsListContainer";
import { optimaPriorRequestsPaths, optimaPriorRequestsRoute } from "./OptimaPriorRequestsPaths";

export const optimaPriorRequestsRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }) => {
  return {
    id: "optima-prior-requests-routes",
    text: t("Prior Requests", { ns: "provider" }),
    route: optimaPriorRequestsRoute,
    isProhibited: !hasPermission(PermissionEnum.ViewTransaction),
    subItems: [
      {
        id: "optima-prior-requests-list-route",
        route: optimaPriorRequestsPaths.list.route,
        fullPath: optimaPriorRequestsPaths.list.fullPath,
        element: <ProviderOptimaPriorRequestsListContainer />,
        onClick: (route: string) => navigate(route),
      },
    ],
  };
};
