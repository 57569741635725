import { useOpenState } from "@toolkit/core";
import { FC } from "react";
import { ChatDialogComponent } from "./components/ChatDialog.component";
import { useChatHooks } from "./components/useChatWithUnreadMessageCountHooks";

type ChatDialogProps = {
  userId: string;
  chatId: string | null;
  renderButton: (onClick: () => void, unread) => JSX.Element;
};

export const ChatDialogWithUnreadMessageCount: FC<ChatDialogProps> = ({ userId, renderButton, chatId }) => {
  const { open: isOpened, handleToggle } = useOpenState();
  const { chatGroup, startChat } = useChatHooks({ handleToggle, chatId, userId });
  return (
    <>
      <ChatDialogComponent
        unread={null}
        startChat={startChat}
        chatGroup={chatGroup}
        isOpened={isOpened}
        handleToggle={handleToggle}
        renderButton={renderButton}
      />
    </>
  );
};
