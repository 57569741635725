import { OrderStatusEnum } from "@/schema/types";
import { OrderColorEnum } from "./cards/OrdersCard";

export enum ORDERS_STATUS {
  NEW = "new",
  PENDING = "pending",
  DISPENSING = "dispensing",
  DELIVERING = "delivering",
}

export const statuses = {
  new: [OrderStatusEnum.NewRequest],
  pending: [OrderStatusEnum.Allocated, OrderStatusEnum.AcceptedByProvider, OrderStatusEnum.ErxHubAuthSubmitTimedOut],
  dispensing: [
    OrderStatusEnum.ReadyForCustomerPickup,
    OrderStatusEnum.WaitingErxHubClaimApproval,
    OrderStatusEnum.ErxHubFullyApproved,
    OrderStatusEnum.ErxHubClaimSubmitTimedOut,
    OrderStatusEnum.ErxHubPartiallyApproved,
    OrderStatusEnum.ErxHubRejected,
    OrderStatusEnum.InProgress,
    OrderStatusEnum.ErxHubTimedOut,
    OrderStatusEnum.ErxHubAuthRequestFailed,
    OrderStatusEnum.WaitingErxHubApproval,
    OrderStatusEnum.ErxHubClaimFailed,
    OrderStatusEnum.ErxHubClaimApproved,
    OrderStatusEnum.ErxHubClaimRejected,
    OrderStatusEnum.PaymentFailed,
    OrderStatusEnum.PaymentPending,
    OrderStatusEnum.PaymentSucceeded,
    OrderStatusEnum.PaymentTimedOut,
    OrderStatusEnum.CanceledByConsumer,
    OrderStatusEnum.PaymentOnArrive,
  ],
  delivering: [OrderStatusEnum.Delivered, OrderStatusEnum.OutForDelivery, OrderStatusEnum.Dispensed],
};

export const mapStatusToColor = {
  [ORDERS_STATUS.NEW]: OrderColorEnum.primary,
  [ORDERS_STATUS.PENDING]: OrderColorEnum.warning,
  [ORDERS_STATUS.DISPENSING]: OrderColorEnum.success,
  [ORDERS_STATUS.DELIVERING]: OrderColorEnum.info,
};

export const mapStatusToName = status => {
  return status?.charAt(0)?.toUpperCase() + status?.slice(1);
};
