import { makeStyles } from "@toolkit/ui";

export const usePatientHealthParameterVitalSignReadingCardStyle = makeStyles()(theme => ({
  root: {
    padding: 16,
  },
  value: {
    fontSize: theme.mixins.fonts.fontSize.xxl,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    color: theme.palette.primary.main,
  },
  unit: {
    fontSize: theme.mixins.fonts.fontSize.md,
    marginLeft: 4,
  },
  title: {
    fontSize: theme.mixins.fonts.fontSize.xs,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  source: {
    fontSize: theme.mixins.fonts.fontSize.xs,
    color: theme.palette.stale.main,
    minHeight: 18,
  },
}));
