import { AccountError, User } from "@/schema/types";
import { combineErrors, formGirdBreakPoints, formGirdSpacing, getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { CustomDatePicker, Grid, TextField } from "@toolkit/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";

export const DoctorQualificationsInformation: FC<{ errors: AccountError[]; defaultValues: User | undefined }> = ({
  errors,
  defaultValues,
}) => {
  const { t } = useTranslation("provider");
  const { register, formState, control } = useFormContext();
  const combinedErrors = combineErrors(formState.errors, errors);
  return (
    <Grid container spacing={formGirdSpacing}>
      <Grid item {...formGirdBreakPoints} md={3}>
        <TextField
          label={t("Code")}
          placeholder={t("Code")}
          fullWidth
          error={Boolean(combinedErrors?.doctorInfo?.qualifications?.code?.message)}
          helperText={t(combinedErrors?.doctorInfo?.qualifications?.code?.message)}
          defaultValue={defaultValues?.doctor?.qualifications?.[0]?.code!}
          {...register("doctorInfo.qualifications.code", {
            required: getRequiredValidation(t, true),
          })}
        />
      </Grid>
      <Grid item {...formGirdBreakPoints} md={3}>
        <TextField
          label={t("Issuer")}
          placeholder={t("Issuer")}
          fullWidth
          defaultValue={defaultValues?.doctor?.qualifications?.[0]?.issuer!}
          error={Boolean(combinedErrors?.doctorInfo?.qualifications?.issuer?.message)}
          helperText={t(combinedErrors?.doctorInfo?.qualifications?.issuer?.message)}
          {...register("doctorInfo.qualifications.issuer", {
            required: getRequiredValidation(t, false),
          })}
        />
      </Grid>
      <Grid item {...formGirdBreakPoints} md={3}>
        <CustomDatePicker
          control={control}
          name='doctorInfo.qualifications.fromDate'
          label={t("From Date")}
          placeholder={t("From Date")}
          defaultValue={defaultValues?.doctor?.qualifications?.[0]?.fromDate!}
          error={Boolean(combinedErrors?.doctorInfo?.qualifications?.fromDate?.message)}
          helperText={t(combinedErrors?.doctorInfo?.qualifications?.fromDate?.message)}
          format='YYYY-MM-DD'
        />
      </Grid>
      <Grid item {...formGirdBreakPoints} md={3}>
        <CustomDatePicker
          control={control}
          name='doctorInfo.qualifications.toDate'
          label={t("To Date")}
          placeholder={t("To Date")}
          defaultValue={defaultValues?.doctor?.qualifications?.[0]?.toDate!}
          error={Boolean(combinedErrors?.doctorInfo?.qualifications?.toDate?.message)}
          helperText={t(combinedErrors?.doctorInfo?.qualifications?.toDate?.message)}
          format='YYYY-MM-DD'
        />
      </Grid>
    </Grid>
  );
};
