import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpcomingPatientAppointmentsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  filter?: Types.InputMaybe<Types.AppointmentFilterInput>;
  sortBy?: Types.InputMaybe<Types.AppointmentSortingInput>;
}>;


export type UpcomingPatientAppointmentsQuery = { __typename?: 'Query', appointments?: { __typename?: 'AppointmentCountableConnection', totalCount?: number | null, pageInfo: { __typename?: 'H_PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'AppointmentCountableEdge', node: { __typename?: 'Appointment', id: string, created?: any | null, type?: Types.AppointmentType | null, status?: Types.AppointmentStatus | null, requestedTimeSlots?: Array<string | null> | null, suggestedTimeSlots?: Array<string | null> | null, confirmedStartTime?: any | null, confirmedEndTime?: any | null, appointmentCreationSource?: Types.AppointmentCreationSource | null, visit?: { __typename?: 'Visit', id: string, number?: string | null, callId?: any | null, chatId?: any | null, patient?: { __typename?: 'Patient', id: string, number?: string | null, firstName?: string | null, lastName?: string | null, email?: string | null } | null } | null, consumer?: { __typename?: 'Patient', id: string } | null, doctor?: { __typename?: 'Doctor', appointmentSlotTimePeriod: number, user?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null } | null } | null, providerGuidedCareHealthProgramTeam?: { __typename?: 'ProviderGuidedCareHealthProgramTeam', id: string, teamMembers?: Array<{ __typename?: 'ProviderGuidedCareProgramTeamMember', isBackup?: boolean | null, id: string, user?: { __typename?: 'User', firstName?: string | null, lastName?: string | null, photo?: string | null } | null, doctor?: { __typename?: 'Doctor', vendor: { __typename?: 'Vendor', id: string, name: string, logo?: string | null } } | null } | null> | null } | null } }> } | null };


export const UpcomingPatientAppointmentsDocument = gql`
    query upcomingPatientAppointments($first: Int, $after: String, $before: String, $last: Int, $filter: AppointmentFilterInput, $sortBy: AppointmentSortingInput) {
  appointments(
    first: $first
    after: $after
    last: $last
    before: $before
    filter: $filter
    sortBy: $sortBy
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    totalCount
    edges {
      node {
        id
        created
        type
        status
        requestedTimeSlots
        suggestedTimeSlots
        confirmedStartTime
        confirmedEndTime
        appointmentCreationSource
        visit {
          id
          number
          callId
          chatId
          patient {
            id
            number
            firstName
            lastName
            email
          }
        }
        consumer {
          id
        }
        doctor {
          user {
            id
            firstName
            lastName
          }
          appointmentSlotTimePeriod
        }
        providerGuidedCareHealthProgramTeam {
          id
          teamMembers {
            isBackup
            id
            user {
              firstName
              lastName
              photo
            }
            doctor {
              vendor {
                id
                name
                logo
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useUpcomingPatientAppointmentsQuery__
 *
 * To run a query within a React component, call `useUpcomingPatientAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcomingPatientAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcomingPatientAppointmentsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useUpcomingPatientAppointmentsQuery(baseOptions?: Apollo.QueryHookOptions<UpcomingPatientAppointmentsQuery, UpcomingPatientAppointmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UpcomingPatientAppointmentsQuery, UpcomingPatientAppointmentsQueryVariables>(UpcomingPatientAppointmentsDocument, options);
      }
export function useUpcomingPatientAppointmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpcomingPatientAppointmentsQuery, UpcomingPatientAppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UpcomingPatientAppointmentsQuery, UpcomingPatientAppointmentsQueryVariables>(UpcomingPatientAppointmentsDocument, options);
        }
export function useUpcomingPatientAppointmentsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UpcomingPatientAppointmentsQuery, UpcomingPatientAppointmentsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UpcomingPatientAppointmentsQuery, UpcomingPatientAppointmentsQueryVariables>(UpcomingPatientAppointmentsDocument, options);
        }
export type UpcomingPatientAppointmentsQueryHookResult = ReturnType<typeof useUpcomingPatientAppointmentsQuery>;
export type UpcomingPatientAppointmentsLazyQueryHookResult = ReturnType<typeof useUpcomingPatientAppointmentsLazyQuery>;
export type UpcomingPatientAppointmentsSuspenseQueryHookResult = ReturnType<typeof useUpcomingPatientAppointmentsSuspenseQuery>;
export type UpcomingPatientAppointmentsQueryResult = Apollo.QueryResult<UpcomingPatientAppointmentsQuery, UpcomingPatientAppointmentsQueryVariables>;