import { MedicationOrderLineInput } from "@/schema/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { FC } from "react";
import { OrderMedicationsMappingProps } from "../cards/types";
import { useVendorOrderAcceptMutation } from "../gql";
import { OrdereRxAcceptDialog } from "@/pages/GlobalOrders/Orders/dialoges/ErxOrderAccept/OrdereRxAcceptDialog";
import { MedicationsMappingDialog } from "@/pages/GlobalOrders/Orders/dialoges/ProductOrderAcceptDialog/MedicationsMappingDialog";

export interface MedicationsProps extends OrderMedicationsMappingProps {
  orderId: string;
}

export const ViewAndAcceptButton: FC<MedicationsProps> = ({ prescriptionId, medications, orderId }) => {
  const { t } = useTranslation("provider");
  const { failed, succeeded } = useAddToast();

  const [orderVendorAccept, { loading }] = useVendorOrderAcceptMutation({
    onCompleted: () => {
      succeeded(t("Order accepted successfully"));
    },
    onError: ({ networkError }) => {
      failed(formatGraphQLError(networkError?.["result"]?.Errors));
    },
  });

  const handleOrderAccepted = (acceptedMedications: MedicationOrderLineInput[]): void => {
    orderVendorAccept({
      variables: {
        orderId: orderId,
        input: {
          acceptedMedications,
        },
      },
    });
  };

  return prescriptionId ? (
    <OrdereRxAcceptDialog isLoading={loading} medications={medications} prescriptionId={prescriptionId} onSubmit={handleOrderAccepted} />
  ) : (
    <MedicationsMappingDialog
      isLoading={loading}
      medications={medications}
      onSubmit={handleOrderAccepted}
      buttonLabel={t("View & Accept")}
    />
  );
};
