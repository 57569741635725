import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DoctorListQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.DoctorFilterInput>;
  sortBy?: Types.InputMaybe<Types.DoctorOrder>;
}>;


export type DoctorListQuery = { __typename?: 'Query', doctors?: { __typename?: 'DoctorCountableConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'DoctorCountableEdge', node: { __typename?: 'Doctor', id: string, bio?: string | null, yearsOfExperience?: number | null, seniority?: Types.DoctorSeniorityEnum | null, specializations?: Array<{ __typename?: 'DoctorSpecialization', id: string, code: string, display?: string | null, arabicDisplay?: string | null } | null> | null, qualifications?: Array<{ __typename?: 'Qualification', id: string, code: string, issuer: string } | null> | null, user?: { __typename?: 'User', id: string, fullName?: string | null, nationalId?: string | null, gender?: Types.UserGender | null, photo?: string | null } | null } }> } | null };


export const DoctorListDocument = gql`
    query DoctorList($first: Int, $last: Int, $before: String, $after: String, $filter: DoctorFilterInput, $sortBy: DoctorOrder) {
  doctors(
    first: $first
    last: $last
    before: $before
    after: $after
    filter: $filter
    sortBy: $sortBy
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        id
        bio
        yearsOfExperience
        seniority
        specializations {
          id
          code
          display
          arabicDisplay
        }
        qualifications {
          id
          code
          issuer
        }
        user {
          id
          fullName
          nationalId
          gender
          photo
        }
      }
    }
  }
}
    `;

/**
 * __useDoctorListQuery__
 *
 * To run a query within a React component, call `useDoctorListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDoctorListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoctorListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useDoctorListQuery(baseOptions?: Apollo.QueryHookOptions<DoctorListQuery, DoctorListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DoctorListQuery, DoctorListQueryVariables>(DoctorListDocument, options);
      }
export function useDoctorListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DoctorListQuery, DoctorListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DoctorListQuery, DoctorListQueryVariables>(DoctorListDocument, options);
        }
export function useDoctorListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DoctorListQuery, DoctorListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DoctorListQuery, DoctorListQueryVariables>(DoctorListDocument, options);
        }
export type DoctorListQueryHookResult = ReturnType<typeof useDoctorListQuery>;
export type DoctorListLazyQueryHookResult = ReturnType<typeof useDoctorListLazyQuery>;
export type DoctorListSuspenseQueryHookResult = ReturnType<typeof useDoctorListSuspenseQuery>;
export type DoctorListQueryResult = Apollo.QueryResult<DoctorListQuery, DoctorListQueryVariables>;