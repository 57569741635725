import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketplaceProductUpdateMutationVariables = Types.Exact<{
  marketplaceProductUpdateId: Types.Scalars['ID']['input'];
  input: Types.MarketplaceProductInput;
}>;


export type MarketplaceProductUpdateMutation = { __typename?: 'Mutation', marketplaceProductUpdate?: { __typename?: 'MarketplaceProduct', id: string } | null };


export const MarketplaceProductUpdateDocument = gql`
    mutation MarketplaceProductUpdate($marketplaceProductUpdateId: ID!, $input: MarketplaceProductInput!) {
  marketplaceProductUpdate(id: $marketplaceProductUpdateId, input: $input) {
    id
  }
}
    `;
export type MarketplaceProductUpdateMutationFn = Apollo.MutationFunction<MarketplaceProductUpdateMutation, MarketplaceProductUpdateMutationVariables>;

/**
 * __useMarketplaceProductUpdateMutation__
 *
 * To run a mutation, you first call `useMarketplaceProductUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceProductUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [marketplaceProductUpdateMutation, { data, loading, error }] = useMarketplaceProductUpdateMutation({
 *   variables: {
 *      marketplaceProductUpdateId: // value for 'marketplaceProductUpdateId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarketplaceProductUpdateMutation(baseOptions?: Apollo.MutationHookOptions<MarketplaceProductUpdateMutation, MarketplaceProductUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarketplaceProductUpdateMutation, MarketplaceProductUpdateMutationVariables>(MarketplaceProductUpdateDocument, options);
      }
export type MarketplaceProductUpdateMutationHookResult = ReturnType<typeof useMarketplaceProductUpdateMutation>;
export type MarketplaceProductUpdateMutationResult = Apollo.MutationResult<MarketplaceProductUpdateMutation>;
export type MarketplaceProductUpdateMutationOptions = Apollo.BaseMutationOptions<MarketplaceProductUpdateMutation, MarketplaceProductUpdateMutationVariables>;