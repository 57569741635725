import { HealthParameter, PatientHealthParameter } from "@/schema/types";
import { useHealthParametersChanged } from "@health/sse";
import { useMemo } from "react";
import { useHealthParameterVitalSignListQuery } from "shared/modules/patient/gql";
import { getApolloContextFormToken } from "shared/utils";
import { healthParameterCodesVitalSigns } from "../constants";
import { useVisitPatientHealthParameterListQuery } from "../gql";
import { formatPatientHealthParametersVitalSigns } from "../others";

type HealthParameterVitalSignsProps = {
  visitId: string;
  token?: string;
};

export const useHealthParameterVitalSigns = (props: HealthParameterVitalSignsProps) => {
  const { visitId, token } = props;

  const { data: healthParameterVitalSignsData, loading: isHealthParametersLoading } = useHealthParameterVitalSignListQuery({
    fetchPolicy: "no-cache",
    context: getApolloContextFormToken(token!),
  });

  const healthParametersVitalSigns = useMemo(
    () => (healthParameterVitalSignsData?.getHealthParameters?.edges?.map(item => item?.node) || []) as HealthParameter[],
    [healthParameterVitalSignsData?.getHealthParameters?.edges]
  );

  const {
    data: visitPatientHealthParametersData,
    loading: isVisitPatientHealthParametersLoading,
    refetch,
  } = useVisitPatientHealthParameterListQuery({
    variables: { visitId },
    fetchPolicy: "no-cache",
    skip: !visitId || !healthParametersVitalSigns?.length,
    context: getApolloContextFormToken(token!),
  });

  const visitVitalSigns = useMemo(
    () => (visitPatientHealthParametersData?.visit?.vitalSigns || []) as PatientHealthParameter[],
    [visitPatientHealthParametersData?.visit?.vitalSigns]
  );

  const formattedVisitVitalSigns = useMemo(
    () => formatPatientHealthParametersVitalSigns(healthParametersVitalSigns, visitVitalSigns),
    [healthParametersVitalSigns, visitVitalSigns]
  );

  useHealthParametersChanged(({ data: healthParametersData }) => {
    if (!healthParametersData?.healthParameters?.length) return;

    const healthParameterItem = healthParametersData.healthParameters.find(item => healthParameterCodesVitalSigns.includes(item?.code));

    if (!healthParameterItem) return;

    refetch({ visitId });
  });

  return {
    data: formattedVisitVitalSigns,
    isLoading: isHealthParametersLoading || isVisitPatientHealthParametersLoading,
  };
};
