import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VisitLabsQueryVariables = Types.Exact<{
  visitId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type VisitLabsQuery = { __typename?: 'Query', visitLabs?: { __typename?: 'Visit', labs?: Array<{ __typename?: 'Lab', id: string, code?: string | null, created?: any | null, modified?: any | null, resultReceived?: boolean | null } | null> | null } | null };


export const VisitLabsDocument = gql`
    query visitLabs($visitId: ID) {
  visitLabs: visit(id: $visitId) {
    labs {
      id
      code
      created
      modified
      resultReceived
    }
  }
}
    `;

/**
 * __useVisitLabsQuery__
 *
 * To run a query within a React component, call `useVisitLabsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitLabsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitLabsQuery({
 *   variables: {
 *      visitId: // value for 'visitId'
 *   },
 * });
 */
export function useVisitLabsQuery(baseOptions?: Apollo.QueryHookOptions<VisitLabsQuery, VisitLabsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VisitLabsQuery, VisitLabsQueryVariables>(VisitLabsDocument, options);
      }
export function useVisitLabsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VisitLabsQuery, VisitLabsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VisitLabsQuery, VisitLabsQueryVariables>(VisitLabsDocument, options);
        }
export function useVisitLabsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<VisitLabsQuery, VisitLabsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<VisitLabsQuery, VisitLabsQueryVariables>(VisitLabsDocument, options);
        }
export type VisitLabsQueryHookResult = ReturnType<typeof useVisitLabsQuery>;
export type VisitLabsLazyQueryHookResult = ReturnType<typeof useVisitLabsLazyQuery>;
export type VisitLabsSuspenseQueryHookResult = ReturnType<typeof useVisitLabsSuspenseQuery>;
export type VisitLabsQueryResult = Apollo.QueryResult<VisitLabsQuery, VisitLabsQueryVariables>;