import { HealthParameter } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { Grid, Typography, useTheme } from "@toolkit/ui";
import { useProfileContext } from "../../context/ProfileContext";
import { HealthParameters } from "./HealthParameters";

export const HealthConditions = () => {
  const { t } = useTranslation("provider");
  const theme = useTheme();
  const { conditions: healthConditions } = useProfileContext();

  return (
    <>
      {!!healthConditions?.length && (
        <Grid container item spacing={2}>
          <Grid item xs={12} marginBottom='-7px'>
            <Typography fontSize={theme.mixins.fonts.fontSize.lg} fontWeight={theme.mixins.fonts.fontWeight.medium}>
              {t("Health Parameters")}
            </Typography>
          </Grid>
          {healthConditions?.map(condition => {
            return (
              <Grid item xs={12} key={condition?.id}>
                <HealthParameters title={condition?.display!} fields={(condition?.fields! as HealthParameter[]) || []} />
              </Grid>
            );
          })}
        </Grid>
      )}
    </>
  );
};
