import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DepartmentGetQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DepartmentGetQuery = { __typename?: 'Query', vendorDepartment?: { __typename?: 'Department', id: string, code: string, name: string, directions?: string | null, description?: string | null, branch: { __typename?: 'Branch', id: string, name: string, nameAr?: string | null }, medicalServices?: { __typename?: 'DepartmentMedicalServiceCountableConnection', edges: Array<{ __typename?: 'DepartmentMedicalServiceCountableEdge', node: { __typename?: 'DepartmentMedicalService', id: string, code: string } }> } | null } | null };


export const DepartmentGetDocument = gql`
    query DepartmentGet($id: ID!) {
  vendorDepartment(id: $id) {
    id
    code
    name
    directions
    description
    branch {
      id
      name
      nameAr
    }
    medicalServices(first: 100) {
      edges {
        node {
          id
          code
        }
      }
    }
  }
}
    `;

/**
 * __useDepartmentGetQuery__
 *
 * To run a query within a React component, call `useDepartmentGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepartmentGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepartmentGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDepartmentGetQuery(baseOptions: Apollo.QueryHookOptions<DepartmentGetQuery, DepartmentGetQueryVariables> & ({ variables: DepartmentGetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DepartmentGetQuery, DepartmentGetQueryVariables>(DepartmentGetDocument, options);
      }
export function useDepartmentGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DepartmentGetQuery, DepartmentGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DepartmentGetQuery, DepartmentGetQueryVariables>(DepartmentGetDocument, options);
        }
export function useDepartmentGetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DepartmentGetQuery, DepartmentGetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DepartmentGetQuery, DepartmentGetQueryVariables>(DepartmentGetDocument, options);
        }
export type DepartmentGetQueryHookResult = ReturnType<typeof useDepartmentGetQuery>;
export type DepartmentGetLazyQueryHookResult = ReturnType<typeof useDepartmentGetLazyQuery>;
export type DepartmentGetSuspenseQueryHookResult = ReturnType<typeof useDepartmentGetSuspenseQuery>;
export type DepartmentGetQueryResult = Apollo.QueryResult<DepartmentGetQuery, DepartmentGetQueryVariables>;