import { Department } from "@/schema/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useNavigate, useParams } from "react-router-dom";
import { departmentsPaths } from "../../constants";
import { DepartmentUpsertForm, IDepartmentUpsertFormEvent } from "../../forms/DepartmentUpsert/DepartmentUpsertForm";
import { useDepartmentGetQuery, useDepartmentUpdateMutation } from "../../gql";
import { useSetDepartmentsBreadcrumbs } from "../../hooks";
import { convertDepartmentUpdateFormValuesToBackEndValues } from "../../others";
import { IDepartmentUpdateContainerParams } from "../../types";

export const DepartmentUpdateContainer = () => {
  const { t } = useTranslation("provider");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const { departmentId } = useParams<IDepartmentUpdateContainerParams>();

  const { data, loading } = useDepartmentGetQuery({
    variables: { id: departmentId! },
    skip: !departmentId,
    fetchPolicy: "no-cache",
  });

  const department = data?.vendorDepartment as Department;

  const [updateDepartment, { loading: isSubmitting }] = useDepartmentUpdateMutation({
    onCompleted: mutationData => {
      if (mutationData?.vendorDepartmentUpdate?.department?.id) {
        succeeded(t("Department updated successfully"));
        navigate(departmentsPaths.list.fullPath);
      } else {
        failed(t("Department update failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onDepartmentUpsertFormChange = (event: IDepartmentUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertDepartmentUpdateFormValuesToBackEndValues(event.payload.values);

      updateDepartment({
        variables: {
          id: departmentId!,
          input: values,
        },
      });
    }
  };

  useSetDepartmentsBreadcrumbs("UPDATE", department?.name);

  return (
    <DepartmentUpsertForm
      buttonLabel={t("Update")}
      department={department}
      isLoading={loading}
      isButtonDisabled={isSubmitting}
      onChange={onDepartmentUpsertFormChange}
    />
  );
};
