import { TransactionTransactionStatus } from "../schema/types";
import { BACKEND_DATE_TIME_FORMAT } from "@toolkit/core";
import { produce } from "immer";
import moment from "moment";
export const getOptimaAuthorizationStatusColor = (status, theme) => {
    switch (status) {
        case TransactionTransactionStatus.FullyApproved:
            return theme.palette.success.main;
        case TransactionTransactionStatus.PartiallyApproved:
            return theme.palette.warning.main;
        case TransactionTransactionStatus.Rejected:
            return theme.palette.error.main;
        default:
            return theme.palette.stale.main;
    }
};
export const formatFilterDateForBackend = (value) => {
    if (!value || (value.gte == null && value.lte == null)) {
        return null;
    }
    return produce(value, draft => {
        if (draft.gte) {
            draft.gte = moment(draft.gte).format(BACKEND_DATE_TIME_FORMAT);
        }
        if (draft.lte) {
            draft.lte = moment(draft.lte).format(BACKEND_DATE_TIME_FORMAT);
        }
    });
};
