import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetVisitSummariesQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetVisitSummariesQuery = { __typename?: 'Query', visit?: { __typename?: 'Visit', summaries?: { __typename?: 'VisitSummaryCountableConnection', totalCount?: number | null, pageInfo: { __typename?: 'H_PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'VisitSummaryCountableEdge', node: { __typename?: 'VisitSummary', id: string, doctorNotes?: string | null, isProblemSolved?: boolean | null } }> } | null } | null };


export const GetVisitSummariesDocument = gql`
    query getVisitSummaries($id: ID!, $before: String, $after: String, $first: Int, $last: Int) {
  visit(id: $id) {
    summaries(before: $before, after: $after, first: $first, last: $last) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          doctorNotes
          isProblemSolved
        }
      }
    }
  }
}
    `;

/**
 * __useGetVisitSummariesQuery__
 *
 * To run a query within a React component, call `useGetVisitSummariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVisitSummariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVisitSummariesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useGetVisitSummariesQuery(baseOptions: Apollo.QueryHookOptions<GetVisitSummariesQuery, GetVisitSummariesQueryVariables> & ({ variables: GetVisitSummariesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVisitSummariesQuery, GetVisitSummariesQueryVariables>(GetVisitSummariesDocument, options);
      }
export function useGetVisitSummariesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVisitSummariesQuery, GetVisitSummariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVisitSummariesQuery, GetVisitSummariesQueryVariables>(GetVisitSummariesDocument, options);
        }
export function useGetVisitSummariesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetVisitSummariesQuery, GetVisitSummariesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetVisitSummariesQuery, GetVisitSummariesQueryVariables>(GetVisitSummariesDocument, options);
        }
export type GetVisitSummariesQueryHookResult = ReturnType<typeof useGetVisitSummariesQuery>;
export type GetVisitSummariesLazyQueryHookResult = ReturnType<typeof useGetVisitSummariesLazyQuery>;
export type GetVisitSummariesSuspenseQueryHookResult = ReturnType<typeof useGetVisitSummariesSuspenseQuery>;
export type GetVisitSummariesQueryResult = Apollo.QueryResult<GetVisitSummariesQuery, GetVisitSummariesQueryVariables>;