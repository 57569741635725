import { TemplateIntervention } from "@/schema/types";
import { useDebounce } from "@toolkit/core";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { Box, CircularProgress, CustomInfiniteScroll, SearchIcon, TextField, Typography } from "@toolkit/ui";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { useProfileContext } from "../../../context/ProfileContext";
import { useGuidedCareTemplateInterventionListQuery } from "../../../gql";
import {
  GuidedCareJourneyInterventionCard,
  IGuidedCareJourneyInterventionCardEvent,
} from "../GuidedCareJourneyInterventionCard/GuidedCareJourneyInterventionCard";
import { useGuidedCareJourneyAllInterventionsStyle } from "./useGuidedCareJourneyAllInterventionsStyle";

type GuidedCareJourneyAllInterventionsProps = {
  onChange: (event: IGuidedCareJourneyInterventionCardEvent) => void;
};

export const GuidedCareJourneyAllInterventions: FC<GuidedCareJourneyAllInterventionsProps> = props => {
  const { onChange } = props;

  const [searchValue, setSearchValue] = useState("");

  const debouncedSearchValue = useDebounce(searchValue, 350);

  const { t } = useTranslation("provider");

  const { guidedCareProgramTemplateId } = useProfileContext();

  const { classes, theme } = useGuidedCareJourneyAllInterventionsStyle();

  const { data, loading, refetch, fetchMore } = useGuidedCareTemplateInterventionListQuery({
    variables: {
      first: 10,
      filter: {
        templateId: guidedCareProgramTemplateId,
      },
    },
    notifyOnNetworkStatusChange: true,
    skip: !guidedCareProgramTemplateId,
  });

  const interventions = data?.templateInterventions?.edges?.map(item => item?.node) as TemplateIntervention[];
  const pageInfo = data?.templateInterventions?.pageInfo;

  const onTextFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const fetchMoreData = () => {
    if (pageInfo?.hasNextPage && !loading) {
      fetchMore({
        variables: {
          first: 5,
          after: pageInfo?.endCursor,
          filter: {
            templateId: guidedCareProgramTemplateId,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;

          return Object.assign({}, prev, {
            ...fetchMoreResult,
            templateInterventions: {
              ...fetchMoreResult.templateInterventions,
              edges: [...(prev?.templateInterventions?.edges || []), ...(fetchMoreResult?.templateInterventions?.edges || [])],
            },
          });
        },
      });
    }
  };

  const onGuidedCareJourneyInterventionCardChange = (event: IGuidedCareJourneyInterventionCardEvent) => {
    if (event.type === "CLICK") {
      onChange({
        type: "CLICK",
        payload: {
          intervention: event.payload.intervention,
        },
      });
    }
  };

  useEffect(() => {
    const searchKey = pickLocalizedValue("name", "nameAr");

    refetch({
      first: 10,
      filter: {
        templateId: guidedCareProgramTemplateId,
        [searchKey]: debouncedSearchValue,
      },
    });
  }, [debouncedSearchValue, guidedCareProgramTemplateId, refetch]);

  return (
    <Box>
      {loading ? (
        <Box display={"flex"} justifyContent={"center"} color={theme.palette.primary.main} m={2}>
          <CircularProgress size={30} color={"inherit"} />
        </Box>
      ) : (
        <>
          <Box>
            {(searchValue || interventions?.length > 0) && (
              <Box className={classes.textFieldWrapper}>
                <TextField
                  value={searchValue}
                  placeholder={t("Search")}
                  fullWidth
                  className={classes.textField}
                  InputProps={{
                    classes: { root: classes.inputProps },
                    startAdornment: <SearchIcon className={classes.searchIcon} />,
                  }}
                  onChange={onTextFieldChange}
                />
              </Box>
            )}

            {interventions?.length > 0 && (
              <CustomInfiniteScroll
                dataLength={interventions?.length || 0}
                onFetchMore={() => fetchMoreData()}
                hasMore={pageInfo?.hasNextPage}
                height={480}
                loader={
                  <Box display={"flex"} justifyContent={"center"} color={theme.palette.primary.main} m={1}>
                    <CircularProgress size={50} color={"inherit"} />
                  </Box>
                }
              >
                {interventions?.map((item, index) => (
                  <GuidedCareJourneyInterventionCard
                    key={item?.id}
                    index={index}
                    intervention={item}
                    onChange={onGuidedCareJourneyInterventionCardChange}
                  />
                ))}
              </CustomInfiniteScroll>
            )}

            {!interventions?.length && <Typography>{t("There are no interventions")}</Typography>}
          </Box>
        </>
      )}
    </Box>
  );
};
