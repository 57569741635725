import { OrderStatus } from "@/schema/types";
import moment from "moment/moment";

export const height = 700;

const erxHubStatuses = [
  OrderStatus.WaitingErxHubApproval,
  OrderStatus.ErxHubFullyApproved,
  OrderStatus.ErxHubPartiallyApproved,
  OrderStatus.ErxHubTimedOut,
  OrderStatus.ErxHubRejected,
  OrderStatus.CanceledByProvider,
  OrderStatus.ErxHubClaimApproved,
  OrderStatus.WaitingErxHubClaimApproval,
  OrderStatus.ErxHubClaimRejected,
  OrderStatus.ErxHubClaimFailed,
  OrderStatus.ErxHubAuthRequestFailed,
  OrderStatus.PaymentSucceeded,
  OrderStatus.PaymentFailed,
  OrderStatus.ErxHubClaimSubmitTimedOut,
  OrderStatus.ErxHubAuthSubmitTimedOut,
  OrderStatus.PaymentOnArrive,
];
export const shouldRefetchPending = (status: OrderStatus | undefined) => {
  const pendingStatuses = [OrderStatus.Allocated, OrderStatus.AcceptedByProvider, OrderStatus.ReadyForCustomerPickup, ...erxHubStatuses];
  return !!status && pendingStatuses.includes(status);
};
export const shouldRefetchNew = (status: OrderStatus | undefined) => {
  return [OrderStatus.NewRequest, OrderStatus.RejectedByProvider, OrderStatus.Allocated, OrderStatus.AcceptedByProvider].includes(status!);
};

export const shouldRefetchDispensing = (status: OrderStatus | undefined) => {
  // i think this must be [OrderStatus.ReadyForCustomerPickup, ...erxHubCliamStatuses] not erxHubStatuses
  return [OrderStatus.ReadyForCustomerPickup, OrderStatus.Delivered, OrderStatus.Dispensed, ...erxHubStatuses].includes(status!);
};
export const shouldRefetchDelivered = (orderStatus: OrderStatus | undefined) => {
  const orderStatuesDelevering = [
    OrderStatus.Delivered,
    OrderStatus.OutForDelivery,
    OrderStatus.Dispensed,
    OrderStatus.CanceledByProvider,
    OrderStatus.ReadyForCustomerPickup,
    OrderStatus.ErxHubClaimApproved,
    OrderStatus.ErxHubFullyApproved,
  ];
  return orderStatuesDelevering.includes(orderStatus!);
};

export const formatDeliveryTimeSlot = (startTime: string, endTime: string, lang: string) => {
  const deliveryStartTime = moment(startTime, "HH:mm:ss");
  const deliveryEndTime = moment(endTime, "HH:mm:ss");
  return `${deliveryStartTime.locale("en").format("hh:mm") + deliveryStartTime.locale(lang).format("A")} -
  ${deliveryEndTime.locale("en").format("hh:mm") + deliveryEndTime.locale(lang).format("A")}`;
};
