import { FC, MouseEvent, PropsWithChildren, useCallback } from "react";
import { CallDuration, useActiveCall } from "@health/meeting";
import {
  Box,
  Button,
  Container,
  CustomDialog,
  Grid,
  MuiCallEndIcon,
  MuiMicIcon,
  MuiMicOffIcon,
  MuiVideocamIcon,
  MuiVideocamOffIcon,
  MuiVolumeOffIcon,
  MuiVolumeUpIcon,
  Typography,
  useTheme,
  VideoConference,
} from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { useNavigate } from "react-router-dom";
import { useVisitCallEndMutation } from "../VisitStartingNowNotification/gql";
import { useOpenState } from "@toolkit/core";

export const VisitCallStatusBar: FC<PropsWithChildren> = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation("provider");
  const { call } = useActiveCall()!;
  const [visitCallEnd] = useVisitCallEndMutation();
  const { handleToggle: handleEndCallToggle, open: endCallOpen } = useOpenState();

  const {
    visitId,
    muteAudio,
    muteVideo,
    muteSpeaker,
    dropCall,
    state: { audioMuted, videoMuted, speakerMuted },
  } = call;
  const handleToggleAudio = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      muteAudio?.(m => !m);
    },
    [muteAudio]
  );
  const handleToggleVideo = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      muteVideo?.(m => !m);
    },
    [muteVideo]
  );
  const handleToggleSpeaker = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      muteSpeaker?.(m => !m);
    },
    [muteSpeaker]
  );
  const handleDropCall = useCallback(() => {
    dropCall?.();
    if (visitId) {
      visitCallEnd({ variables: { visitId } });
    }
  }, [dropCall, visitCallEnd, visitId]);

  const handleEndCallOpen = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      handleEndCallToggle?.();
    },
    [handleEndCallToggle]
  );

  const handleBackToCall = useCallback(() => navigate(`/visit-call?visitId=${visitId}`), [visitId, navigate]);

  if (!call) {
    return null;
  }

  return (
    <>
      <Box display={"flex"} bgcolor={theme.palette.common.white} width='100vw' boxShadow={theme.mixins.shadows.md}>
        <Container fixed>
          <Grid
            container
            spacing={2}
            justifyContent='center'
            alignItems='center'
            height='100px'
            sx={{ cursor: "pointer" }}
            onClick={handleBackToCall}
          >
            <Grid item xs='auto'>
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                width='90px'
                height='85px'
                bgcolor={theme.palette.success.main}
              >
                <VideoConference
                  sx={{
                    width: "40px",
                    height: "40px",
                    "& path": {
                      fill: `${theme.palette.common.white} !important`,
                    },
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs>
              <Box height='100%' display='flex' flexDirection='column' justifyContent='center' alignItems='flex-start'>
                <Typography fontWeight='bold'>{call.title}</Typography>
                <Typography>
                  <CallDuration call={call} />
                </Typography>
              </Box>
            </Grid>
            <Grid item xs='auto'>
              <Button title={!videoMuted ? t("Stop video") : t("Start video")} variant='text' onClick={handleToggleVideo}>
                {!videoMuted ? <MuiVideocamIcon /> : <MuiVideocamOffIcon />}
              </Button>

              <Button title={!audioMuted ? t("Mute") : t("Unmute")} variant='text' onClick={handleToggleAudio}>
                {!audioMuted ? <MuiMicIcon /> : <MuiMicOffIcon />}
              </Button>
              <Button variant='text' onClick={handleToggleSpeaker}>
                {!speakerMuted ? <MuiVolumeUpIcon /> : <MuiVolumeOffIcon />}
              </Button>
              <Button title={t("End")} onClick={handleEndCallOpen} color='error'>
                <MuiCallEndIcon />
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
      {endCallOpen && (
        <CustomDialog
          type='warning'
          isOpen={endCallOpen}
          text={{
            body: t("Are you sure you want to end this call?"),
          }}
          onConfirm={handleDropCall}
          onClose={handleEndCallToggle}
        />
      )}
    </>
  );
};
