import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DepartmentCreateMutationVariables = Types.Exact<{
  input: Types.VendorDepartmentCreateInput;
}>;


export type DepartmentCreateMutation = { __typename?: 'Mutation', vendorDepartmentCreate?: { __typename?: 'VendorDepartmentCreate', department?: { __typename?: 'Department', id: string } | null } | null };


export const DepartmentCreateDocument = gql`
    mutation DepartmentCreate($input: VendorDepartmentCreateInput!) {
  vendorDepartmentCreate(input: $input) {
    department {
      id
    }
  }
}
    `;
export type DepartmentCreateMutationFn = Apollo.MutationFunction<DepartmentCreateMutation, DepartmentCreateMutationVariables>;

/**
 * __useDepartmentCreateMutation__
 *
 * To run a mutation, you first call `useDepartmentCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDepartmentCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [departmentCreateMutation, { data, loading, error }] = useDepartmentCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDepartmentCreateMutation(baseOptions?: Apollo.MutationHookOptions<DepartmentCreateMutation, DepartmentCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DepartmentCreateMutation, DepartmentCreateMutationVariables>(DepartmentCreateDocument, options);
      }
export type DepartmentCreateMutationHookResult = ReturnType<typeof useDepartmentCreateMutation>;
export type DepartmentCreateMutationResult = Apollo.MutationResult<DepartmentCreateMutation>;
export type DepartmentCreateMutationOptions = Apollo.BaseMutationOptions<DepartmentCreateMutation, DepartmentCreateMutationVariables>;