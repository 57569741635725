import { Maybe } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, Typography, useTheme } from "@toolkit/ui";
import { FC } from "react";

type InsuranceInformationSectionProps = {
  label: string;
  value?: Maybe<string>;
};

export const InsuranceInformationSection: FC<InsuranceInformationSectionProps> = props => {
  const { label, value } = props;

  const { t } = useTranslation("provider");
  const theme = useTheme();
  return (
    <Box marginTop='8px' marginBottom='8px'>
      <Typography fontWeight='bold' fontSize={theme.mixins.fonts.fontSize.sm}>
        {t(label)}:
      </Typography>
      <Typography fontSize={theme.mixins.fonts.fontSize.sm}>{value || "-"}</Typography>
    </Box>
  );
};
