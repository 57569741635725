import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SaveProviderSettingsMutationVariables = Types.Exact<{
  input: Types.ProviderSettingsInput;
}>;


export type SaveProviderSettingsMutation = { __typename?: 'Mutation', saveProviderSettings?: { __typename?: 'ProviderSettings', outPatientJourneySupported?: boolean | null, paymentAfterConsultation?: boolean | null, appointmentReminderTimeoutSeconds?: number | null, appointmentConfirmationTimeoutSeconds?: number | null } | null };


export const SaveProviderSettingsDocument = gql`
    mutation SaveProviderSettings($input: ProviderSettingsInput!) {
  saveProviderSettings(input: $input) {
    outPatientJourneySupported
    paymentAfterConsultation
    appointmentReminderTimeoutSeconds
    appointmentConfirmationTimeoutSeconds
  }
}
    `;
export type SaveProviderSettingsMutationFn = Apollo.MutationFunction<SaveProviderSettingsMutation, SaveProviderSettingsMutationVariables>;

/**
 * __useSaveProviderSettingsMutation__
 *
 * To run a mutation, you first call `useSaveProviderSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveProviderSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveProviderSettingsMutation, { data, loading, error }] = useSaveProviderSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveProviderSettingsMutation(baseOptions?: Apollo.MutationHookOptions<SaveProviderSettingsMutation, SaveProviderSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveProviderSettingsMutation, SaveProviderSettingsMutationVariables>(SaveProviderSettingsDocument, options);
      }
export type SaveProviderSettingsMutationHookResult = ReturnType<typeof useSaveProviderSettingsMutation>;
export type SaveProviderSettingsMutationResult = Apollo.MutationResult<SaveProviderSettingsMutation>;
export type SaveProviderSettingsMutationOptions = Apollo.BaseMutationOptions<SaveProviderSettingsMutation, SaveProviderSettingsMutationVariables>;