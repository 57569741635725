import { ActiveFilters, CustomInfiniteScroll, EmptyCard, Grid, PageWrapper, StaticFilterDialog } from "@toolkit/ui";
import React, { FC } from "react";
import { useDoctorsCallsRatings, useFiltersFields } from "./components";
import DoctorRatingCardSkeleton from "./components/DoctorRatingCard/DoctorRatingCardSkeleton";
import { DoctorRatingCard, DoctorRatingCardProps } from "./components/DoctorRatingCard";
import { determineRatingColor, displayStringOfArray } from "./utils";

export const DoctorsCallsRatingsContainer: FC = () => {
  const { isLoading, isRefetching, doctorsCallsRating, pageInfo, classes, activeFiltersProps, staticFiltersProps, fetchMoreData, t } =
    useDoctorsCallsRatings();

  return (
    <PageWrapper
      filters={
        <>
          <ActiveFilters {...activeFiltersProps} />
          <StaticFilterDialog filterFields={useFiltersFields()} {...staticFiltersProps} />
        </>
      }
      contentContainerSX={{ padding: 0 }}
    >
      {doctorsCallsRating?.length && !isRefetching ? (
        <div className={classes.container} id='scrollableDiv'>
          <CustomInfiniteScroll
            dataLength={Number(doctorsCallsRating?.length) || 0}
            onFetchMore={() => fetchMoreData()}
            hasMore={Boolean(pageInfo?.hasNextPage)}
            loader={<DoctorRatingCardSkeleton />}
            height={"100%"}
          >
            {doctorsCallsRating?.map(rating => {
              const ratings =
                rating.ratings
                  ?.filter(item => item?.rating)
                  ?.map(rate => ({
                    color: determineRatingColor(rate?.rating ?? 0),
                    rateCount: rate?.count ?? 0,
                    rateValue: rate?.rating ?? 0,
                  })) ?? [];

              const totalRatings = ratings.reduce((acc, curr) => acc + (curr.rateCount ?? 0), 0);

              ratings.sort((a, b) => b.rateValue - a.rateValue);

              return (
                <div key={rating.doctor?.id} className={classes.item}>
                  <DoctorRatingCard
                    title={rating.doctor?.user?.fullName!}
                    subTitle={rating.doctor?.seniority ?? ""}
                    photo={rating.doctor?.user?.photo}
                    description={displayStringOfArray(rating.doctor?.specializations?.map(e => e?.display || "")) ?? ""}
                    rating={rating.doctor?.averageRating!}
                    totalRatings={totalRatings}
                    ratings={ratings as DoctorRatingCardProps["ratings"]}
                    gender={rating?.doctor?.user?.gender}
                  />
                </div>
              );
            })}
            {isLoading && <DoctorRatingCardSkeleton />}
          </CustomInfiniteScroll>
        </div>
      ) : (
        <>
          {isLoading ? (
            <DoctorRatingCardSkeleton />
          ) : (
            <Grid container alignItems='center' justifyContent='center' sx={{ height: "70vh" }}>
              <EmptyCard title={t("No Ratings Yet!")} message='' />
            </Grid>
          )}
        </>
      )}
    </PageWrapper>
  );
};
