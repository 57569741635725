import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CareJourneyStatisticsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.CareJourneyStatisticsFilterInput>;
}>;


export type CareJourneyStatisticsQuery = { __typename?: 'Query', careJourneyStatistics?: { __typename?: 'CareJourneyStatistics', gaps?: number | null, adherence?: number | null, allActivities?: number | null, allCompletedActivities?: number | null } | null };


export const CareJourneyStatisticsDocument = gql`
    query CareJourneyStatistics($filter: CareJourneyStatisticsFilterInput) {
  careJourneyStatistics(filter: $filter) {
    gaps
    adherence
    allActivities
    allCompletedActivities
  }
}
    `;

/**
 * __useCareJourneyStatisticsQuery__
 *
 * To run a query within a React component, call `useCareJourneyStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareJourneyStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareJourneyStatisticsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCareJourneyStatisticsQuery(baseOptions?: Apollo.QueryHookOptions<CareJourneyStatisticsQuery, CareJourneyStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CareJourneyStatisticsQuery, CareJourneyStatisticsQueryVariables>(CareJourneyStatisticsDocument, options);
      }
export function useCareJourneyStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CareJourneyStatisticsQuery, CareJourneyStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CareJourneyStatisticsQuery, CareJourneyStatisticsQueryVariables>(CareJourneyStatisticsDocument, options);
        }
export function useCareJourneyStatisticsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CareJourneyStatisticsQuery, CareJourneyStatisticsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CareJourneyStatisticsQuery, CareJourneyStatisticsQueryVariables>(CareJourneyStatisticsDocument, options);
        }
export type CareJourneyStatisticsQueryHookResult = ReturnType<typeof useCareJourneyStatisticsQuery>;
export type CareJourneyStatisticsLazyQueryHookResult = ReturnType<typeof useCareJourneyStatisticsLazyQuery>;
export type CareJourneyStatisticsSuspenseQueryHookResult = ReturnType<typeof useCareJourneyStatisticsSuspenseQuery>;
export type CareJourneyStatisticsQueryResult = Apollo.QueryResult<CareJourneyStatisticsQuery, CareJourneyStatisticsQueryVariables>;