import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DoctorAppointmentAcceptMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.DoctorAcceptAppointmentInput;
}>;


export type DoctorAppointmentAcceptMutation = { __typename?: 'Mutation', doctorAppointmentAccept?: { __typename?: 'AppointmentApprovalAction', appointment?: { __typename?: 'Appointment', id: string, status?: Types.AppointmentStatus | null, paymentInfo?: { __typename?: 'AppointmentPaymentRequest', type?: Types.AppointmentPaymentType | null, totalAmount?: number | null } | null } | null, appointmentErrors?: Array<{ __typename?: 'H_EntityError', field?: string | null, message?: string | null, code: Types.H_EntityErrorCode }> | null } | null };


export const DoctorAppointmentAcceptDocument = gql`
    mutation doctorAppointmentAccept($id: ID!, $input: DoctorAcceptAppointmentInput!) {
  doctorAppointmentAccept(id: $id, input: $input) {
    appointment {
      id
      status
      paymentInfo {
        type
        totalAmount
      }
    }
    appointmentErrors {
      field
      message
      code
    }
  }
}
    `;
export type DoctorAppointmentAcceptMutationFn = Apollo.MutationFunction<DoctorAppointmentAcceptMutation, DoctorAppointmentAcceptMutationVariables>;

/**
 * __useDoctorAppointmentAcceptMutation__
 *
 * To run a mutation, you first call `useDoctorAppointmentAcceptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDoctorAppointmentAcceptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [doctorAppointmentAcceptMutation, { data, loading, error }] = useDoctorAppointmentAcceptMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDoctorAppointmentAcceptMutation(baseOptions?: Apollo.MutationHookOptions<DoctorAppointmentAcceptMutation, DoctorAppointmentAcceptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DoctorAppointmentAcceptMutation, DoctorAppointmentAcceptMutationVariables>(DoctorAppointmentAcceptDocument, options);
      }
export type DoctorAppointmentAcceptMutationHookResult = ReturnType<typeof useDoctorAppointmentAcceptMutation>;
export type DoctorAppointmentAcceptMutationResult = Apollo.MutationResult<DoctorAppointmentAcceptMutation>;
export type DoctorAppointmentAcceptMutationOptions = Apollo.BaseMutationOptions<DoctorAppointmentAcceptMutation, DoctorAppointmentAcceptMutationVariables>;