import { MarketplaceApprovalStatus } from "@/schema/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { CustomDialog, CustomToggleButton, useAddToast } from "@toolkit/ui";
import { HealthPackagePublishStatusUpdateMutation, useHealthPackagePublishStatusUpdateMutation } from "pages/HealthPackages/gql";
import { HealthPackagesQueryNode } from "pages/HealthPackages/types";
import { FC, useEffect, useState } from "react";

export const ToggleHealthPackagePublishStatus: FC<{ row: HealthPackagesQueryNode }> = ({ row }) => {
  const [checked, setChecked] = useState<boolean>(row?.isPublished);
  const { t } = useTranslation("provider");
  const { handleToggle, open } = useOpenState();
  const { succeeded, failed } = useAddToast();
  const [healthPackagePublishStatusUpdateMutation, { loading }] = useHealthPackagePublishStatusUpdateMutation({
    onCompleted: (data: HealthPackagePublishStatusUpdateMutation, options) => {
      const responseData = data?.[options?.variables?.isPublish ? "publishHealthPackage" : "unPublishHealthPackage"];
      if (responseData?.id) {
        setChecked(responseData?.isPublished as boolean);
        succeeded(!checked ? t("Health package published successfully!") : t("Health package is now unpublished"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });
  const handlePublishPackage = () => {
    healthPackagePublishStatusUpdateMutation({
      variables: {
        id: row?.id,
        isPublish: !checked,
      },
    });
  };

  useEffect(() => {
    setChecked(row?.isPublished);
  }, [row]);

  const isPublishDisabled = row?.approvalStatus !== MarketplaceApprovalStatus.Approved || !row?.updatable;
  return (
    <>
      <CustomToggleButton disabled={loading || isPublishDisabled} checked={checked} onChange={handleToggle} isLoading={loading} />
      {open && (
        <CustomDialog
          type='warning'
          isOpen={open}
          text={{
            body: checked
              ? t("Are you sure you want to unpublish this health package?")
              : t("Are you sure you want to publish this health package?"),
          }}
          onConfirm={handlePublishPackage}
          onClose={handleToggle}
        />
      )}
    </>
  );
};
