import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthPackageCreateMutationVariables = Types.Exact<{
  input: Types.MarketplaceHealthPackageInput;
}>;


export type HealthPackageCreateMutation = { __typename?: 'Mutation', marketplaceHealthPackageCreate?: { __typename?: 'MarketplaceHealthPackage', id: string } | null };


export const HealthPackageCreateDocument = gql`
    mutation HealthPackageCreate($input: MarketplaceHealthPackageInput!) {
  marketplaceHealthPackageCreate(input: $input) {
    id
  }
}
    `;
export type HealthPackageCreateMutationFn = Apollo.MutationFunction<HealthPackageCreateMutation, HealthPackageCreateMutationVariables>;

/**
 * __useHealthPackageCreateMutation__
 *
 * To run a mutation, you first call `useHealthPackageCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHealthPackageCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [healthPackageCreateMutation, { data, loading, error }] = useHealthPackageCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHealthPackageCreateMutation(baseOptions?: Apollo.MutationHookOptions<HealthPackageCreateMutation, HealthPackageCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HealthPackageCreateMutation, HealthPackageCreateMutationVariables>(HealthPackageCreateDocument, options);
      }
export type HealthPackageCreateMutationHookResult = ReturnType<typeof useHealthPackageCreateMutation>;
export type HealthPackageCreateMutationResult = Apollo.MutationResult<HealthPackageCreateMutation>;
export type HealthPackageCreateMutationOptions = Apollo.BaseMutationOptions<HealthPackageCreateMutation, HealthPackageCreateMutationVariables>;