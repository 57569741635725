import { PatientHpVitalSignsChangePercentage } from "@/schema/types";
import { useVisitsVitalSignsChanged } from "@health/sse";
import { useTranslation } from "@toolkit/i18n";
import { CircularProgress, Grid } from "@toolkit/ui";
import { healthParameterCodes } from "pages/PatientHealthParameters/constants";
import { FC } from "react";
import { getApolloContextFormToken } from "shared/utils";
import { usePatientHealthParameterVitalSignsPercentageListQuery } from "../../../../gql";
import { getPatientHealthParameterVitalSignsChangePercentage } from "../../utils";
import { VitalSignBloodPressureChart } from "../../VitalSignBloodPressureChart";
import { VitalSignDialog } from "../../VitalSignDialog";
import { VitalSignHeartRateChart } from "../../VitalSignHeartRateChart";
import { VitalSignOxygenSaturationChart } from "../../VitalSignOxygenSaturationChart";
import { VitalSignRespirationRateChart } from "../../VitalSignRespirationRateChart";
import { VitalSignTemperatureChart } from "../../VitalSignTemperatureChart";

type VitalSignsChartsProps = {
  patientId: string;
  token: string;
  gridWidth?: number;
};

export const VitalSignsCharts: FC<VitalSignsChartsProps> = props => {
  const { patientId, token, gridWidth = 6 } = props;

  const { t } = useTranslation("provider");

  const { data, loading, refetch } = usePatientHealthParameterVitalSignsPercentageListQuery({
    variables: {
      patientId: patientId!,
    },
    context: getApolloContextFormToken(token!),
  });

  const patientHealthParameterVitalSignsChangePercentage = (data?.patientHealthParameterVitalSignsChangePercentage ||
    []) as PatientHpVitalSignsChangePercentage[];

  const vitalSignsChangePercentage = getPatientHealthParameterVitalSignsChangePercentage(patientHealthParameterVitalSignsChangePercentage);

  useVisitsVitalSignsChanged(({ data: visitsVitalSignsChangedData }) => {
    if (visitsVitalSignsChangedData?.patientId) {
      refetch({ patientId: patientId! });
    }
  });

  return loading ? (
    <CircularProgress size='20px' color='inherit' />
  ) : (
    <>
      <Grid item xs={12} sm={4} md={4} lg={4}>
        <VitalSignDialog display={t("Heart Rate")} codes={[healthParameterCodes.heartRate]} token={token!} patientId={patientId!}>
          <VitalSignHeartRateChart
            width={"100%"}
            token={token!}
            patientId={patientId!}
            percentage={vitalSignsChangePercentage?.heartRate}
          />
        </VitalSignDialog>
      </Grid>

      <Grid item xs={12} sm={4} md={4} lg={4}>
        <VitalSignDialog
          display={t("Blood Pressure")}
          token={token!}
          patientId={patientId!}
          codes={[healthParameterCodes.bloodPressureHigh, healthParameterCodes.bloodPressureLow]}
        >
          <VitalSignBloodPressureChart
            width={"100%"}
            token={token!}
            patientId={patientId!}
            percentage={vitalSignsChangePercentage?.bloodPressureLow || vitalSignsChangePercentage?.bloodPressureHigh}
          />
        </VitalSignDialog>
      </Grid>

      <Grid item xs={12} sm={4} md={4} lg={4}>
        <VitalSignDialog
          display={t("Respiration Rate")}
          token={token!}
          patientId={patientId!}
          codes={[healthParameterCodes.respiratoryRate]}
        >
          <VitalSignRespirationRateChart
            width={"100%"}
            token={token!}
            patientId={patientId!}
            percentage={vitalSignsChangePercentage?.respiratoryRate}
          />
        </VitalSignDialog>
      </Grid>

      <Grid item xs={12} sm={4} md={4} lg={gridWidth}>
        <VitalSignDialog display={t("Temperature")} token={token!} patientId={patientId!} codes={[healthParameterCodes.temperature]}>
          <VitalSignTemperatureChart
            width={"100%"}
            token={token!}
            patientId={patientId!}
            percentage={vitalSignsChangePercentage?.temperature}
          />
        </VitalSignDialog>
      </Grid>

      <Grid item xs={12} sm={4} md={4} lg={gridWidth}>
        <VitalSignDialog
          display={t("Oxygen Saturation")}
          token={token!}
          patientId={patientId!}
          codes={[healthParameterCodes.oxygenSaturation]}
        >
          <VitalSignOxygenSaturationChart
            width={"100%"}
            token={token!}
            patientId={patientId!}
            percentage={vitalSignsChangePercentage?.oxygenSaturation}
          />
        </VitalSignDialog>
      </Grid>
    </>
  );
};
