import { IDoctorListNode } from "@/pages/Doctors/types";
import { Qualification } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomDialog, CustomTable, ShowButton } from "@toolkit/ui";
import { FC } from "react";
import { useDoctorQualificationsColumns } from "./useDoctorQualificationsColumns";

type DoctorQualificationsProps = {
  qualifications: IDoctorListNode["qualifications"];
};

export const DoctorQualifications: FC<DoctorQualificationsProps> = props => {
  const { qualifications } = props;

  const { t } = useTranslation("provider");

  return (
    <CustomDialog
      type={"info"}
      title={t("Doctor's Qualifications")}
      button={<ShowButton disabled={!qualifications || !qualifications?.length} />}
    >
      <CustomTable data={qualifications as Qualification[]} columns={useDoctorQualificationsColumns()} scrollable hasFooter={false} />
    </CustomDialog>
  );
};
