import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../../gql/fragments/__generated__/pageInfoFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetVendorUserProfilesQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.UserFilterInput>;
  sortBy?: Types.InputMaybe<Types.UserSortingInput>;
}>;


export type GetVendorUserProfilesQuery = { __typename?: 'Query', users?: { __typename?: 'UserCountableConnection', edges: Array<{ __typename?: 'UserCountableEdge', node: { __typename?: 'User', firstName?: string | null, lastName?: string | null, email?: string | null, mobile?: string | null, isSuperuser?: boolean | null, photo?: string | null, id: string, dateJoined: any, vendor?: { __typename?: 'Vendor', id: string, logo?: string | null, name: string } | null, doctor?: { __typename?: 'Doctor', seniority?: Types.DoctorSeniorityEnum | null, isYearsOfExperiencePublic: boolean, yearsOfExperience?: number | null, bio?: string | null, appointmentTypes?: Array<Types.AppointmentTypeEnum | null> | null, appointmentSlotTimePeriod: number, specializations?: Array<{ __typename?: 'DoctorSpecialization', code: string, display?: string | null, id: string } | null> | null, languages?: Array<{ __typename?: 'Language', code: string, display: string, displayAr?: string | null, id: string } | null> | null, qualifications?: Array<{ __typename?: 'Qualification', id: string, fromDate?: any | null, toDate?: any | null, code: string, issuer: string } | null> | null, workingHours?: Array<{ __typename?: 'WorkingHour', day?: Types.DayOfWeekEnum | null, openTimeRanges?: Array<{ __typename?: 'TimeRange', openTime?: any | null, closeTime?: any | null } | null> | null } | null> | null } | null, addresses?: Array<{ __typename?: 'Address', coordinates?: { __typename?: 'LocationType', lat?: number | null, lng?: number | null } | null } | null> | null } }>, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } } | null };


export const GetVendorUserProfilesDocument = gql`
    query getVendorUserProfiles($first: Int, $last: Int, $before: String, $after: String, $filter: UserFilterInput, $sortBy: UserSortingInput) {
  users(
    first: $first
    last: $last
    before: $before
    after: $after
    filter: $filter
    sortBy: $sortBy
  ) {
    edges {
      node {
        firstName
        lastName
        email
        mobile
        isSuperuser
        photo
        id
        vendor {
          id
          logo
          name
        }
        doctor {
          seniority
          isYearsOfExperiencePublic
          yearsOfExperience
          bio
          specializations {
            code
            display
            id
          }
          languages {
            code
            display
            displayAr
            id
          }
          qualifications {
            id
            fromDate
            toDate
            code
            issuer
          }
          workingHours {
            day
            openTimeRanges {
              openTime
              closeTime
            }
          }
          appointmentTypes
          appointmentSlotTimePeriod
        }
        addresses {
          coordinates {
            lat
            lng
          }
        }
        dateJoined
      }
    }
    pageInfo {
      ...PageInfoFragment
    }
  }
}
    ${PageInfoFragmentFragmentDoc}`;

/**
 * __useGetVendorUserProfilesQuery__
 *
 * To run a query within a React component, call `useGetVendorUserProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVendorUserProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVendorUserProfilesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useGetVendorUserProfilesQuery(baseOptions?: Apollo.QueryHookOptions<GetVendorUserProfilesQuery, GetVendorUserProfilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVendorUserProfilesQuery, GetVendorUserProfilesQueryVariables>(GetVendorUserProfilesDocument, options);
      }
export function useGetVendorUserProfilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVendorUserProfilesQuery, GetVendorUserProfilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVendorUserProfilesQuery, GetVendorUserProfilesQueryVariables>(GetVendorUserProfilesDocument, options);
        }
export function useGetVendorUserProfilesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetVendorUserProfilesQuery, GetVendorUserProfilesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetVendorUserProfilesQuery, GetVendorUserProfilesQueryVariables>(GetVendorUserProfilesDocument, options);
        }
export type GetVendorUserProfilesQueryHookResult = ReturnType<typeof useGetVendorUserProfilesQuery>;
export type GetVendorUserProfilesLazyQueryHookResult = ReturnType<typeof useGetVendorUserProfilesLazyQuery>;
export type GetVendorUserProfilesSuspenseQueryHookResult = ReturnType<typeof useGetVendorUserProfilesSuspenseQuery>;
export type GetVendorUserProfilesQueryResult = Apollo.QueryResult<GetVendorUserProfilesQuery, GetVendorUserProfilesQueryVariables>;