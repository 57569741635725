import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketplaceProductUpdateStockQuantityMutationVariables = Types.Exact<{
  marketplaceProductUpdateStockQuantityId: Types.Scalars['ID']['input'];
  quantity: Types.Scalars['Int']['input'];
}>;


export type MarketplaceProductUpdateStockQuantityMutation = { __typename?: 'Mutation', marketplaceProductUpdateStockQuantity?: { __typename?: 'MarketplaceProduct', stockQuantity: number, id: string } | null };


export const MarketplaceProductUpdateStockQuantityDocument = gql`
    mutation MarketplaceProductUpdateStockQuantity($marketplaceProductUpdateStockQuantityId: ID!, $quantity: Int!) {
  marketplaceProductUpdateStockQuantity(
    id: $marketplaceProductUpdateStockQuantityId
    quantity: $quantity
  ) {
    stockQuantity
    id
  }
}
    `;
export type MarketplaceProductUpdateStockQuantityMutationFn = Apollo.MutationFunction<MarketplaceProductUpdateStockQuantityMutation, MarketplaceProductUpdateStockQuantityMutationVariables>;

/**
 * __useMarketplaceProductUpdateStockQuantityMutation__
 *
 * To run a mutation, you first call `useMarketplaceProductUpdateStockQuantityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceProductUpdateStockQuantityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [marketplaceProductUpdateStockQuantityMutation, { data, loading, error }] = useMarketplaceProductUpdateStockQuantityMutation({
 *   variables: {
 *      marketplaceProductUpdateStockQuantityId: // value for 'marketplaceProductUpdateStockQuantityId'
 *      quantity: // value for 'quantity'
 *   },
 * });
 */
export function useMarketplaceProductUpdateStockQuantityMutation(baseOptions?: Apollo.MutationHookOptions<MarketplaceProductUpdateStockQuantityMutation, MarketplaceProductUpdateStockQuantityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarketplaceProductUpdateStockQuantityMutation, MarketplaceProductUpdateStockQuantityMutationVariables>(MarketplaceProductUpdateStockQuantityDocument, options);
      }
export type MarketplaceProductUpdateStockQuantityMutationHookResult = ReturnType<typeof useMarketplaceProductUpdateStockQuantityMutation>;
export type MarketplaceProductUpdateStockQuantityMutationResult = Apollo.MutationResult<MarketplaceProductUpdateStockQuantityMutation>;
export type MarketplaceProductUpdateStockQuantityMutationOptions = Apollo.BaseMutationOptions<MarketplaceProductUpdateStockQuantityMutation, MarketplaceProductUpdateStockQuantityMutationVariables>;