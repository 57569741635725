import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketplaceOrderOutForDeliveryUpdateMutationVariables = Types.Exact<{
  marketplaceOrderOutForDeliveryUpdateId: Types.Scalars['ID']['input'];
}>;


export type MarketplaceOrderOutForDeliveryUpdateMutation = { __typename?: 'Mutation', marketplaceOrderOutForDeliveryUpdate?: { __typename?: 'MarketplaceOrder', id: string, status: Types.MarketplaceOrderStatus } | null };


export const MarketplaceOrderOutForDeliveryUpdateDocument = gql`
    mutation MarketplaceOrderOutForDeliveryUpdate($marketplaceOrderOutForDeliveryUpdateId: ID!) {
  marketplaceOrderOutForDeliveryUpdate(
    id: $marketplaceOrderOutForDeliveryUpdateId
  ) {
    id
    status
  }
}
    `;
export type MarketplaceOrderOutForDeliveryUpdateMutationFn = Apollo.MutationFunction<MarketplaceOrderOutForDeliveryUpdateMutation, MarketplaceOrderOutForDeliveryUpdateMutationVariables>;

/**
 * __useMarketplaceOrderOutForDeliveryUpdateMutation__
 *
 * To run a mutation, you first call `useMarketplaceOrderOutForDeliveryUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceOrderOutForDeliveryUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [marketplaceOrderOutForDeliveryUpdateMutation, { data, loading, error }] = useMarketplaceOrderOutForDeliveryUpdateMutation({
 *   variables: {
 *      marketplaceOrderOutForDeliveryUpdateId: // value for 'marketplaceOrderOutForDeliveryUpdateId'
 *   },
 * });
 */
export function useMarketplaceOrderOutForDeliveryUpdateMutation(baseOptions?: Apollo.MutationHookOptions<MarketplaceOrderOutForDeliveryUpdateMutation, MarketplaceOrderOutForDeliveryUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarketplaceOrderOutForDeliveryUpdateMutation, MarketplaceOrderOutForDeliveryUpdateMutationVariables>(MarketplaceOrderOutForDeliveryUpdateDocument, options);
      }
export type MarketplaceOrderOutForDeliveryUpdateMutationHookResult = ReturnType<typeof useMarketplaceOrderOutForDeliveryUpdateMutation>;
export type MarketplaceOrderOutForDeliveryUpdateMutationResult = Apollo.MutationResult<MarketplaceOrderOutForDeliveryUpdateMutation>;
export type MarketplaceOrderOutForDeliveryUpdateMutationOptions = Apollo.BaseMutationOptions<MarketplaceOrderOutForDeliveryUpdateMutation, MarketplaceOrderOutForDeliveryUpdateMutationVariables>;