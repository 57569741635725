/* eslint-disable max-lines */
import { CodeSystemCode } from "@/schema/types";
import { SystemCodeAutocomplete } from "@health/autocompletes";
import { getMinValueValidation, getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController, Box, Button, CustomIcon, Grid, TextField, Typography, useTheme } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { getApolloContextFormToken } from "shared/utils";
import { frequencyTypeOptions } from "./constants";
import { ActivityFormData, PrescriptionActivityFormProps } from "./types";
import { getInstructions } from "./utils";

export const PrescriptionActivityForm: FC<PrescriptionActivityFormProps> = ({ onAddActivity }) => {
  const { t } = useTranslation("provider");
  const [params] = useSearchParams();
  const token = params.get("token");

  const methods = useForm<ActivityFormData>({
    defaultValues: {
      frequencyValue: null,
      code: null,
      frequencyUnitType: null,
      quantity: null,
      frequencyType: null,
      duration: null,
      refills: "0",
      observation: null,
    },
    mode: "all",
  });

  const handleResetForm = () => {
    methods.reset({
      frequencyValue: null,
      code: null,
      frequencyUnitType: null,
      quantity: null,
      frequencyType: null,
      duration: null,
      refills: "0",
      observation: null,
    });

    methods.setValue("code", null);
  };

  const handleAddActivity = (data: ActivityFormData) => {
    onAddActivity({ ...data, instructions: getInstructions(data, t) });
    handleResetForm();
  };

  // Note: This is temporary and will be removed when we convert the form to use Zod schema.
  useEffect(() => {
    methods.register("code", {
      required: getRequiredValidation(t, true),
    });

    methods.register("frequencyUnitType", {
      required: getRequiredValidation(t, true),
    });
  }, [methods, t]);
  const theme = useTheme();
  return (
    <>
      <FormProvider {...methods}>
        <form {...methods} onReset={handleResetForm}>
          <Grid flexDirection='column' container rowSpacing={2}>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={4}>
                <SystemCodeAutocomplete
                  name={"code"}
                  label={t("Medication")}
                  isCodeHidden
                  context={getApolloContextFormToken(token!)}
                  filter={{ codeSystemCode: CodeSystemCode.TradeDrug }}
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  {...methods.register("frequencyValue", {
                    min: getMinValueValidation(t, 1),
                    required: getRequiredValidation(t, true),
                  })}
                  fullWidth
                  type='number'
                  label={t("Amount")}
                  error={Boolean(methods.formState.errors?.frequencyValue?.message)}
                  helperText={methods.formState.errors.frequencyValue?.message}
                />
              </Grid>

              <Grid item xs={4}>
                <SystemCodeAutocomplete
                  name={"frequencyUnitType"}
                  label={t("Dose Unit Type")}
                  isCodeHidden
                  context={getApolloContextFormToken(token!)}
                  filter={{ codeSystemCode: CodeSystemCode.UnitId }}
                />
              </Grid>

              <Grid item xs={2.5}>
                <TextField
                  {...methods.register("quantity", {
                    min: getMinValueValidation(t, 1),
                    required: getRequiredValidation(t, true),
                  })}
                  fullWidth
                  type='number'
                  label={t("frequency")}
                  error={Boolean(methods.formState.errors?.quantity?.message)}
                  helperText={methods.formState.errors.quantity?.message}
                />
              </Grid>

              <Grid item xs={3.5}>
                <AutocompleteController
                  ControllerProps={{
                    control: methods.control,
                    name: "frequencyType",
                    rules: { required: { value: true, message: t("Required") } },
                  }}
                  fullWidth
                  TextFieldProps={{
                    label: t("Frequency Type"),
                    error: Boolean(methods.formState.errors?.frequencyType?.["message"]),
                    helperText: methods.formState.errors.frequencyType?.["message"],
                  }}
                  {...methods.register("frequencyType")}
                  options={frequencyTypeOptions}
                  getOptionLabel={option => option.display}
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  {...methods.register("duration", {
                    min: getMinValueValidation(t, 1),
                    required: getRequiredValidation(t, true),
                  })}
                  fullWidth
                  label={t("Duration (days)")}
                  type='number'
                  error={Boolean(methods.formState.errors?.duration?.message)}
                  helperText={methods.formState.errors.duration?.message}
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  {...methods.register("refills", {
                    min: getMinValueValidation(t, 0),
                  })}
                  fullWidth
                  type='number'
                  label={t("Refill (times)")}
                  error={Boolean(methods.formState.errors?.refills?.message)}
                  helperText={methods.formState.errors.refills?.message}
                />
              </Grid>
            </Grid>

            <Grid item container xs={12} columnSpacing={2}>
              <Grid item xs={12} columnSpacing={2}>
                <TextField
                  {...methods.register("observation")}
                  fullWidth
                  label={t("Notes")}
                  error={Boolean(methods.formState.errors?.observation?.message)}
                  helperText={methods.formState.errors.observation?.message}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} columnSpacing={2}>
              <Grid item xs={12} columnSpacing={2}>
                <Typography variant='body2'>
                  <b>{t("Instructions")}: </b>
                  {methods.formState.isValid && getInstructions(methods.getValues(), t)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Box component={"footer"} sx={{ display: "flex", width: "100%", gap: 2, pt: "10px", justifyContent: "space-between" }}>
            <Button
              disabled={!methods.formState.isValid}
              onClick={methods.handleSubmit(handleAddActivity)}
              type='submit'
              startIcon={<CustomIcon icon='add' color={theme.palette.common.white} />}
            >
              {t("Add Medication")}
            </Button>
            <Button
              type='reset'
              sx={{
                backgroundColor: `${theme.palette.common.white} !important`,
                color: theme.palette.primary.main + "!important",
                ":hover": {
                  backgroundColor: `${theme.palette.common.white} !important`,
                  color: theme.palette.primary.main + "!important",
                },
              }}
            >
              {t("Reset")}
            </Button>
          </Box>
        </form>
      </FormProvider>
    </>
  );
};

export default PrescriptionActivityForm;
