import { DoctorSpecialization, LocationType, Qualification, User, WorkingHour } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, Card, CardContent, CircularProgress, Container, Grid, UserFilled, useBreadCrumbs, useTheme } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { DoctorCard } from "./components/DoctorCard.component";
import { FacilityLocationCard } from "./components/FacilityLocationCard.component";
import { InformationDoctor } from "./components/InformationDoctor.component";
import { SpecialtiesCard } from "./components/SpecialtiesCard";
import { VisitsType } from "./components/VisitsType.component";
import { WorkingHoursCard } from "./components/WorkingHoursCard.component";
import { useGetMeDataQuery } from "./gql";
import { profileBreadCrumb } from "./profile.constant";

interface ProfileScreenProps {
  user?: User;
  isLoadingUser?: boolean;
  isEditable?: boolean;
}

export const ProfileScreen: FC<ProfileScreenProps> = ({ user, isLoadingUser, isEditable = true }) => {
  const { t } = useTranslation("provider");
  const { setBreadCrumb } = useBreadCrumbs();

  const { data, loading } = useGetMeDataQuery();
  const profile = user ? user : (data?.me! as User);
  const doctor = profile?.doctor;
  const coordinates = profile?.addresses?.map(address => address?.coordinates);

  useEffect(() => {
    setBreadCrumb({ title: t("My Profile"), values: [profileBreadCrumb(t)] });
  }, []);
  const theme = useTheme();
  return (
    <>
      {loading || isLoadingUser ? (
        <Box sx={{ display: "flex", flex: 1, justifyContent: "center", m: 3, height: "100%" }}>
          <CircularProgress size={100} color='success' />
        </Box>
      ) : (
        <>
          <Box sx={{ backgroundColor: theme.palette.common.white }}>
            <Container>
              <DoctorCard profile={profile} isEditable={isEditable} />
            </Container>
          </Box>
          <Box sx={{ marginTop: 4, marginBottom: 4 }}>
            <Container>
              <Grid container spacing={2}>
                <Grid item container md={8} direction='column' rowSpacing={2}>
                  <Grid item>
                    <Card>
                      <CardContent>
                        <InformationDoctor
                          title={t("About")}
                          subTitle={doctor?.bio || "-"}
                          headerIcon={<UserFilled fill={theme.palette.primary.main} />}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item>
                    <SpecialtiesCard
                      specializations={(doctor?.specializations as DoctorSpecialization[]) || []}
                      languages={doctor?.languages}
                      qualifications={doctor?.qualifications as Qualification[]}
                    />
                  </Grid>
                </Grid>

                <Grid item container spacing={2} xs={12} md={4}>
                  <Grid item xs={12}>
                    <FacilityLocationCard coordinate={coordinates as LocationType} />
                  </Grid>
                  <Grid item xs={12}>
                    <VisitsType visits={doctor?.appointmentTypes} appointmentSlotTimePeriod={doctor?.appointmentSlotTimePeriod as number} />
                  </Grid>
                  <Grid item xs={12}>
                    <WorkingHoursCard schedule={(doctor?.workingHours as WorkingHour[]) || []} />
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </>
      )}
    </>
  );
};
