import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetVisitDetailsStatusQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetVisitDetailsStatusQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, vendor?: { __typename?: 'Vendor', id: string, enableVisitDetails: boolean } | null } | null };


export const GetVisitDetailsStatusDocument = gql`
    query getVisitDetailsStatus {
  me {
    id
    vendor {
      id
      enableVisitDetails
    }
  }
}
    `;

/**
 * __useGetVisitDetailsStatusQuery__
 *
 * To run a query within a React component, call `useGetVisitDetailsStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVisitDetailsStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVisitDetailsStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVisitDetailsStatusQuery(baseOptions?: Apollo.QueryHookOptions<GetVisitDetailsStatusQuery, GetVisitDetailsStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVisitDetailsStatusQuery, GetVisitDetailsStatusQueryVariables>(GetVisitDetailsStatusDocument, options);
      }
export function useGetVisitDetailsStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVisitDetailsStatusQuery, GetVisitDetailsStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVisitDetailsStatusQuery, GetVisitDetailsStatusQueryVariables>(GetVisitDetailsStatusDocument, options);
        }
export function useGetVisitDetailsStatusSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetVisitDetailsStatusQuery, GetVisitDetailsStatusQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetVisitDetailsStatusQuery, GetVisitDetailsStatusQueryVariables>(GetVisitDetailsStatusDocument, options);
        }
export type GetVisitDetailsStatusQueryHookResult = ReturnType<typeof useGetVisitDetailsStatusQuery>;
export type GetVisitDetailsStatusLazyQueryHookResult = ReturnType<typeof useGetVisitDetailsStatusLazyQuery>;
export type GetVisitDetailsStatusSuspenseQueryHookResult = ReturnType<typeof useGetVisitDetailsStatusSuspenseQuery>;
export type GetVisitDetailsStatusQueryResult = Apollo.QueryResult<GetVisitDetailsStatusQuery, GetVisitDetailsStatusQueryVariables>;