import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MedicalFormAnswersQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.MedicalFormAnswerFilter>;
}>;


export type MedicalFormAnswersQuery = { __typename?: 'Query', medicalFormAnswers?: { __typename?: 'MedicalFormAnswerConnection', pageInfo?: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } | null, edges?: Array<{ __typename?: 'MedicalFormAnswerEdge', node?: { __typename?: 'MedicalFormAnswer', id: string, score?: number | null, classificationName?: string | null, medicalForm?: { __typename?: 'MedicalForm', id: string, name?: string | null, description?: string | null, createdDate?: any | null, updateDate?: any | null, questions?: Array<{ __typename?: 'Question', id?: string | null }> | null } | null, questionAnswers?: Array<{ __typename?: 'QuestionAnswerExtended', id: string, booleanAnswer?: boolean | null, multipleChoiceAnswer?: Array<string | null> | null, singleChoiceAnswer?: string | null, stringAnswer?: string | null, question?: { __typename?: 'Question', createdDate?: any | null, id?: string | null, options?: Array<string | null> | null, position?: number | null, question?: string | null, questionType?: Types.QuestionType | null, required?: boolean | null, updateDate?: any | null } | null } | null> | null } | null } | null> | null } | null };


export const MedicalFormAnswersDocument = gql`
    query MedicalFormAnswers($first: Int, $after: String, $filter: MedicalFormAnswerFilter) {
  medicalFormAnswers(first: $first, after: $after, filter: $filter) {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    edges {
      node {
        id
        score
        classificationName
        medicalForm {
          id
          name
          description
          createdDate
          updateDate
          questions {
            id
          }
        }
        questionAnswers {
          id
          booleanAnswer
          multipleChoiceAnswer
          singleChoiceAnswer
          stringAnswer
          question {
            createdDate
            id
            options
            position
            question
            questionType
            required
            updateDate
          }
        }
      }
    }
  }
}
    `;

/**
 * __useMedicalFormAnswersQuery__
 *
 * To run a query within a React component, call `useMedicalFormAnswersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedicalFormAnswersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicalFormAnswersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMedicalFormAnswersQuery(baseOptions?: Apollo.QueryHookOptions<MedicalFormAnswersQuery, MedicalFormAnswersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MedicalFormAnswersQuery, MedicalFormAnswersQueryVariables>(MedicalFormAnswersDocument, options);
      }
export function useMedicalFormAnswersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MedicalFormAnswersQuery, MedicalFormAnswersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MedicalFormAnswersQuery, MedicalFormAnswersQueryVariables>(MedicalFormAnswersDocument, options);
        }
export function useMedicalFormAnswersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MedicalFormAnswersQuery, MedicalFormAnswersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MedicalFormAnswersQuery, MedicalFormAnswersQueryVariables>(MedicalFormAnswersDocument, options);
        }
export type MedicalFormAnswersQueryHookResult = ReturnType<typeof useMedicalFormAnswersQuery>;
export type MedicalFormAnswersLazyQueryHookResult = ReturnType<typeof useMedicalFormAnswersLazyQuery>;
export type MedicalFormAnswersSuspenseQueryHookResult = ReturnType<typeof useMedicalFormAnswersSuspenseQuery>;
export type MedicalFormAnswersQueryResult = Apollo.QueryResult<MedicalFormAnswersQuery, MedicalFormAnswersQueryVariables>;