import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketplaceDiscountQueryVariables = Types.Exact<{
  marketplaceDiscountId: Types.Scalars['ID']['input'];
}>;


export type MarketplaceDiscountQuery = { __typename?: 'Query', marketplaceDiscount?: { __typename?: 'MarketplaceDiscount', id: string, name?: string | null, isPublished: boolean, startDate: any, endDate: any, percentage?: number | null, type: Types.MarketplaceDiscountType, amount?: any | null, products?: Array<{ __typename?: 'MarketplaceProduct', id: string, nameAr?: string | null, name?: string | null } | null> | null, healthPackages?: Array<{ __typename?: 'MarketplaceHealthPackage', id: string, name?: string | null, nameAr?: string | null } | null> | null } | null };


export const MarketplaceDiscountDocument = gql`
    query MarketplaceDiscount($marketplaceDiscountId: ID!) {
  marketplaceDiscount(id: $marketplaceDiscountId) {
    id
    name
    isPublished
    products {
      id
      nameAr
      name
    }
    healthPackages {
      id
      name
      nameAr
    }
    startDate
    endDate
    percentage
    type
    amount
  }
}
    `;

/**
 * __useMarketplaceDiscountQuery__
 *
 * To run a query within a React component, call `useMarketplaceDiscountQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceDiscountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketplaceDiscountQuery({
 *   variables: {
 *      marketplaceDiscountId: // value for 'marketplaceDiscountId'
 *   },
 * });
 */
export function useMarketplaceDiscountQuery(baseOptions: Apollo.QueryHookOptions<MarketplaceDiscountQuery, MarketplaceDiscountQueryVariables> & ({ variables: MarketplaceDiscountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MarketplaceDiscountQuery, MarketplaceDiscountQueryVariables>(MarketplaceDiscountDocument, options);
      }
export function useMarketplaceDiscountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarketplaceDiscountQuery, MarketplaceDiscountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MarketplaceDiscountQuery, MarketplaceDiscountQueryVariables>(MarketplaceDiscountDocument, options);
        }
export function useMarketplaceDiscountSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MarketplaceDiscountQuery, MarketplaceDiscountQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MarketplaceDiscountQuery, MarketplaceDiscountQueryVariables>(MarketplaceDiscountDocument, options);
        }
export type MarketplaceDiscountQueryHookResult = ReturnType<typeof useMarketplaceDiscountQuery>;
export type MarketplaceDiscountLazyQueryHookResult = ReturnType<typeof useMarketplaceDiscountLazyQuery>;
export type MarketplaceDiscountSuspenseQueryHookResult = ReturnType<typeof useMarketplaceDiscountSuspenseQuery>;
export type MarketplaceDiscountQueryResult = Apollo.QueryResult<MarketplaceDiscountQuery, MarketplaceDiscountQueryVariables>;