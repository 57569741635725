import { Diagnose } from "@/schema/types";
import { makeStyles } from "@toolkit/ui";
import { FC } from "react";

const useDiagnosisInformationStyles = makeStyles()(() => ({
  list: {
    margin: 0,
    marginInline: 12,
    padding: 0,
    fontSize: 13,
  },
}));

export const DiagnosisInformation: FC<{ diagnosis: Diagnose[] }> = ({ diagnosis }) => {
  const { classes } = useDiagnosisInformationStyles();

  return (
    <ul className={classes.list}>
      {diagnosis?.map(data => {
        const display = data?.display ? `- ${data?.display}` : data?.display;
        return (
          <li key={data?.id}>
            {data?.code} {display}
          </li>
        );
      })}
    </ul>
  );
};
