import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VendorRejectProductOrderMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  rejectionReason: Types.Scalars['String']['input'];
}>;


export type VendorRejectProductOrderMutation = { __typename?: 'Mutation', vendorRejectProductOrder?: { __typename?: 'MarketplaceOrder', id: string, status: Types.MarketplaceOrderStatus } | null };


export const VendorRejectProductOrderDocument = gql`
    mutation vendorRejectProductOrder($id: ID!, $rejectionReason: String!) {
  vendorRejectProductOrder: marketplaceOrderReject(
    id: $id
    rejectionReason: $rejectionReason
  ) {
    id
    status
  }
}
    `;
export type VendorRejectProductOrderMutationFn = Apollo.MutationFunction<VendorRejectProductOrderMutation, VendorRejectProductOrderMutationVariables>;

/**
 * __useVendorRejectProductOrderMutation__
 *
 * To run a mutation, you first call `useVendorRejectProductOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVendorRejectProductOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vendorRejectProductOrderMutation, { data, loading, error }] = useVendorRejectProductOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      rejectionReason: // value for 'rejectionReason'
 *   },
 * });
 */
export function useVendorRejectProductOrderMutation(baseOptions?: Apollo.MutationHookOptions<VendorRejectProductOrderMutation, VendorRejectProductOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VendorRejectProductOrderMutation, VendorRejectProductOrderMutationVariables>(VendorRejectProductOrderDocument, options);
      }
export type VendorRejectProductOrderMutationHookResult = ReturnType<typeof useVendorRejectProductOrderMutation>;
export type VendorRejectProductOrderMutationResult = Apollo.MutationResult<VendorRejectProductOrderMutation>;
export type VendorRejectProductOrderMutationOptions = Apollo.BaseMutationOptions<VendorRejectProductOrderMutation, VendorRejectProductOrderMutationVariables>;