import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VendorOrderAcceptMutationVariables = Types.Exact<{
  input: Types.VendorOrderAcceptInput;
  orderId: Types.Scalars['ID']['input'];
}>;


export type VendorOrderAcceptMutation = { __typename?: 'Mutation', vendorOrderAccept?: { __typename?: 'Order', id: string, status: Types.OrderStatus } | null };


export const VendorOrderAcceptDocument = gql`
    mutation vendorOrderAccept($input: VendorOrderAcceptInput!, $orderId: ID!) {
  vendorOrderAccept(input: $input, orderId: $orderId) {
    id
    status
  }
}
    `;
export type VendorOrderAcceptMutationFn = Apollo.MutationFunction<VendorOrderAcceptMutation, VendorOrderAcceptMutationVariables>;

/**
 * __useVendorOrderAcceptMutation__
 *
 * To run a mutation, you first call `useVendorOrderAcceptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVendorOrderAcceptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vendorOrderAcceptMutation, { data, loading, error }] = useVendorOrderAcceptMutation({
 *   variables: {
 *      input: // value for 'input'
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useVendorOrderAcceptMutation(baseOptions?: Apollo.MutationHookOptions<VendorOrderAcceptMutation, VendorOrderAcceptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VendorOrderAcceptMutation, VendorOrderAcceptMutationVariables>(VendorOrderAcceptDocument, options);
      }
export type VendorOrderAcceptMutationHookResult = ReturnType<typeof useVendorOrderAcceptMutation>;
export type VendorOrderAcceptMutationResult = Apollo.MutationResult<VendorOrderAcceptMutation>;
export type VendorOrderAcceptMutationOptions = Apollo.BaseMutationOptions<VendorOrderAcceptMutation, VendorOrderAcceptMutationVariables>;