import { CodeSystemCode, DoctorError, DoctorSeniorityEnum, User } from "@/schema/types";
import { SystemCodeAutocomplete } from "@health/autocompletes";
import { combineErrors, formGirdSpacing, getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController, createAutocompleteOption, CustomDatePicker, Grid, TextField } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { ArrayOfValueAutocomplete } from "shared/components";
import { DoctorLanguages } from "./DoctorLanguages.component";
import { UserInformationButtonGroup } from "./UserInformationButtonGroup.component";

export const UserProfileInformation: FC<{ defaultValues: User | undefined; errors: DoctorError[] }> = ({ defaultValues, errors }) => {
  const { t } = useTranslation("provider");
  const { register, setValue, control, formState } = useFormContext();
  const seniority = Object.keys(DoctorSeniorityEnum).map(key => ({ value: DoctorSeniorityEnum[key], name: key }));

  const combinedErrors = combineErrors(formState.errors, errors);
  const seniorityDefaultData = seniority?.find(level => level?.value == defaultValues?.doctor?.seniority);

  // Note: This is temporary and will be removed when we convert the form to use Zod schema.
  useEffect(() => {
    register("specializations", {
      required: getRequiredValidation(t, true),
    });
  }, [register, t]);

  // Note: This is temporary and will be removed when we use RHF setValue method to update all fields at once.
  useEffect(() => {
    const specializationsDefaultValue =
      defaultValues?.doctor?.specializations?.map(item =>
        createAutocompleteOption(
          {
            id: item?.id!,
            code: item?.code!,
            display: item?.display!,
          },
          "code",
          "display"
        )
      ) || [];

    setValue("specializations", specializationsDefaultValue);
  }, [defaultValues?.doctor?.specializations, setValue]);

  return (
    <Grid container spacing={formGirdSpacing}>
      <Grid item container spacing={2} xs={6}>
        <Grid item xs={12}>
          <CustomDatePicker
            control={control}
            name='dateOfBirth'
            label={t("Date Of Birth")}
            placeholder={t("Date Of Birth")}
            defaultValue={defaultValues?.dateOfBirth!}
            error={Boolean(combinedErrors?.dateOfBirth?.message)}
            helperText={t(combinedErrors?.dateOfBirth?.message)}
          />
        </Grid>

        <Grid item xs={12}>
          <AutocompleteController
            ControllerProps={{
              name: "seniority",
              control: control,
              rules: { required: true },
              defaultValue: seniorityDefaultData || null,
            }}
            TextFieldProps={{
              error: Boolean(combinedErrors?.seniority),
              helperText: combinedErrors?.seniority && t("Required"),
              label: t("Seniority"),
            }}
            getOptionLabel={o => o?.name}
            options={seniority || []}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label={t("Years of Experience")}
            placeholder={t("Years of Experience")}
            defaultValue={defaultValues?.doctor?.yearsOfExperience || null}
            fullWidth
            error={Boolean(combinedErrors?.yearsOfExperience)}
            helperText={t(combinedErrors?.yearsOfExperience && t("Required"))}
            {...register("yearsOfExperience", {
              required: getRequiredValidation(t, false),
              pattern: {
                message: t("The value must be a number"),
                value: /^[0-9]+?\.?[0-9]*?$/,
              },
            })}
          />
        </Grid>

        <Grid item xs={12}>
          <DoctorLanguages errors={errors} defaultValues={defaultValues} />
        </Grid>

        <Grid item xs={12}>
          <SystemCodeAutocomplete
            name={"specializations"}
            label={t("Specializations")}
            multiple
            isCodeHidden
            filter={{ codeSystemCode: CodeSystemCode.Speciality }}
          />
        </Grid>

        <Grid item xs={12}>
          <ArrayOfValueAutocomplete
            isRequired={false}
            defaultValue={defaultValues?.doctor?.socialLinks ? JSON.parse(defaultValues?.doctor?.socialLinks) : []}
            errors={errors}
            name='socialLinks'
            placeholder={t("Social Links")}
          />
        </Grid>
      </Grid>

      <UserInformationButtonGroup defaultValues={defaultValues} />
    </Grid>
  );
};
