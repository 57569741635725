import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DoctorCallsRatingsQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  filterInput?: Types.InputMaybe<Types.DoctorCallsRatingsFilter>;
}>;


export type DoctorCallsRatingsQuery = { __typename?: 'Query', doctorCallsRatings: { __typename?: 'DoctorCallsRatingsConnection', totalCount?: number | null, pageInfo: { __typename?: 'H_PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'DoctorCallsRatingsEdge', node: { __typename?: 'DoctorCallsRatingsResponse', doctor?: { __typename?: 'Doctor', id: string, averageRating: number, totalRatings: number, ratingsSum: number, seniority?: Types.DoctorSeniorityEnum | null, specializations?: Array<{ __typename?: 'DoctorSpecialization', display?: string | null } | null> | null, vendor: { __typename?: 'Vendor', rating?: number | null, description?: string | null }, user?: { __typename?: 'User', gender?: Types.UserGender | null, fullName?: string | null, photo?: string | null } | null } | null, ratings?: Array<{ __typename?: 'RatingAndNumberOfCallsPair', count?: number | null, rating?: number | null } | null> | null } }> } };


export const DoctorCallsRatingsDocument = gql`
    query DoctorCallsRatings($after: String, $before: String, $first: Int, $last: Int, $filterInput: DoctorCallsRatingsFilter) {
  doctorCallsRatings(
    after: $after
    before: $before
    first: $first
    last: $last
    filterInput: $filterInput
  ) {
    totalCount
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    edges {
      node {
        doctor {
          id
          averageRating
          totalRatings
          ratingsSum
          seniority
          specializations {
            display
          }
          vendor {
            rating
            description
          }
          user {
            gender
            fullName
            photo
          }
        }
        ratings {
          count
          rating
        }
      }
    }
  }
}
    `;

/**
 * __useDoctorCallsRatingsQuery__
 *
 * To run a query within a React component, call `useDoctorCallsRatingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDoctorCallsRatingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoctorCallsRatingsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      filterInput: // value for 'filterInput'
 *   },
 * });
 */
export function useDoctorCallsRatingsQuery(baseOptions?: Apollo.QueryHookOptions<DoctorCallsRatingsQuery, DoctorCallsRatingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DoctorCallsRatingsQuery, DoctorCallsRatingsQueryVariables>(DoctorCallsRatingsDocument, options);
      }
export function useDoctorCallsRatingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DoctorCallsRatingsQuery, DoctorCallsRatingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DoctorCallsRatingsQuery, DoctorCallsRatingsQueryVariables>(DoctorCallsRatingsDocument, options);
        }
export function useDoctorCallsRatingsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DoctorCallsRatingsQuery, DoctorCallsRatingsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DoctorCallsRatingsQuery, DoctorCallsRatingsQueryVariables>(DoctorCallsRatingsDocument, options);
        }
export type DoctorCallsRatingsQueryHookResult = ReturnType<typeof useDoctorCallsRatingsQuery>;
export type DoctorCallsRatingsLazyQueryHookResult = ReturnType<typeof useDoctorCallsRatingsLazyQuery>;
export type DoctorCallsRatingsSuspenseQueryHookResult = ReturnType<typeof useDoctorCallsRatingsSuspenseQuery>;
export type DoctorCallsRatingsQueryResult = Apollo.QueryResult<DoctorCallsRatingsQuery, DoctorCallsRatingsQueryVariables>;