import { FC } from "react";
import { useParams } from "react-router-dom";
import { GuestCall } from "@health/meeting";

export const VisitCallGuestPage: FC = () => {
  const params = useParams();

  return <GuestCall token={params["token"]!} />;
};

export default VisitCallGuestPage;
