import { OrderDirection, User, UserSortField } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent, useBreadCrumbs } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { useNavigate, useParams } from "react-router-dom";
import { GridProvider } from "shared/components/GridProvider/GridProvider";
import { useGetAllVendorUsersQuery, useVendorUserDeleteMutation } from "../gql";
import { useUserManagementColumns } from "./columns/useUserManagementColumns";

export const UsersManagementList: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("provider");
  const { setBreadCrumb } = useBreadCrumbs();
  const { branchId } = useParams();
  const auth = useAuth();
  const currentUserId = btoa("User:" + auth?.user?.profile?.user_id);
  const columns = useUserManagementColumns(currentUserId);
  const handleAddNewUser = () => {
    navigate("new");
  };

  const handleEditUser = (user: User) => {
    navigate("edit/" + user?.id);
  };

  useEffect(() => {
    setBreadCrumb({ title: t("Users Management"), values: [] });
  }, []);

  const isCurrentUserRow = user => {
    return currentUserId === user?.id;
  };

  return (
    <GridProvider
      hasTableSetting
      gridName='users'
      columns={columns}
      variables={{
        sortBy: { field: UserSortField.DateJoined, direction: OrderDirection.Desc },
        filter: { branchId: branchId || null },
      }}
      query={useGetAllVendorUsersQuery}
      tableAction={{
        isEditVisible: true,
        onEditRow: handleEditUser,
        isDeleteVisible: true,
        isRowDeletable: user => !isCurrentUserRow(user),
        isRowEditable: user => !isCurrentUserRow(user),
      }}
      deleteItemProps={{
        entityTypeName: "User",
        name: "User",
        useDeleteMutation: useVendorUserDeleteMutation,
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions hasAddNew onAddNewItem={handleAddNewUser} />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
