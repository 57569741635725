import { Appointment, AppointmentStatus } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, CircularProgress } from "@toolkit/ui";
import { FC } from "react";
import { NotificationItemAction } from "../types";
import { AppointmentAction } from "./AppointmentAction";
import { AppointmentLayout } from "./AppointmentLayout";
import { ViewAppointment } from "./ViewAppointment";
import { useAppointmentNotificationHook } from "./useAppointmentNotification";

export const AppointmentNotification: FC<
  {
    id: string;
    status: AppointmentStatus;
    handleClose?: () => void;
    isDisplayedInDropDown: boolean;
  } & NotificationItemAction
> = ({ handleClose, id, status, isDisplayedInDropDown, handleNotificationActionComplete }) => {
  const { t } = useTranslation("provider");
  const { loading, appointment } = useAppointmentNotificationHook(id, isDisplayedInDropDown);

  return (
    <>
      {loading ? (
        <Box sx={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
          <CircularProgress size={12} sx={{ mx: 1, my: 1 }} />
          {t("Loading")}...
        </Box>
      ) : (
        <Box>
          <AppointmentLayout status={status!}>
            <ViewAppointment status={status! as AppointmentStatus} item={appointment! as Appointment} />
            <AppointmentAction
              status={status!}
              item={appointment! as Appointment}
              onClose={handleClose}
              handleNotificationActionComplete={handleNotificationActionComplete}
            />
          </AppointmentLayout>
        </Box>
      )}
    </>
  );
};

AppointmentNotification.defaultProps = {
  handleClose: undefined,
};
