import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthPackageCloneMutationVariables = Types.Exact<{
  healthPackageCloneId: Types.Scalars['ID']['input'];
}>;


export type HealthPackageCloneMutation = { __typename?: 'Mutation', marketplaceHealthPackageClone?: { __typename?: 'MarketplaceHealthPackage', id: string } | null };


export const HealthPackageCloneDocument = gql`
    mutation HealthPackageClone($healthPackageCloneId: ID!) {
  marketplaceHealthPackageClone(id: $healthPackageCloneId) {
    id
  }
}
    `;
export type HealthPackageCloneMutationFn = Apollo.MutationFunction<HealthPackageCloneMutation, HealthPackageCloneMutationVariables>;

/**
 * __useHealthPackageCloneMutation__
 *
 * To run a mutation, you first call `useHealthPackageCloneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHealthPackageCloneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [healthPackageCloneMutation, { data, loading, error }] = useHealthPackageCloneMutation({
 *   variables: {
 *      healthPackageCloneId: // value for 'healthPackageCloneId'
 *   },
 * });
 */
export function useHealthPackageCloneMutation(baseOptions?: Apollo.MutationHookOptions<HealthPackageCloneMutation, HealthPackageCloneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HealthPackageCloneMutation, HealthPackageCloneMutationVariables>(HealthPackageCloneDocument, options);
      }
export type HealthPackageCloneMutationHookResult = ReturnType<typeof useHealthPackageCloneMutation>;
export type HealthPackageCloneMutationResult = Apollo.MutationResult<HealthPackageCloneMutation>;
export type HealthPackageCloneMutationOptions = Apollo.BaseMutationOptions<HealthPackageCloneMutation, HealthPackageCloneMutationVariables>;