import { Typography, useTheme } from "@toolkit/ui";
import { FC } from "react";

type OptimaValidationInfoProps = {
  title: string;
  info: string;
};

export const OptimaValidationInfo: FC<OptimaValidationInfoProps> = props => {
  const { title, info } = props;
  const theme = useTheme();

  return (
    <Typography>
      <Typography component='span' fontWeight={theme.mixins.fonts.fontWeight.medium}>
        {title}:{" "}
      </Typography>
      {info}
    </Typography>
  );
};
