import { Department, OrderDirection, VendorDepartmentOrderField } from "@/schema/types";
import { getBranchesAutocompleteFilter } from "@health/autocompletes";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";
import { DepartmentMedicalServices } from "../../components/DepartmentMedicalServices/DepartmentMedicalServices";
import { DepartmentQRCode } from "../../components/DepartmentQRCode/DepartmentQRCode";

export const useDepartmentListContainerColumns = (): CustomTableColumnProps<Department>[] => {
  const { t } = useTranslation("provider");

  return useMemo(
    () => [
      {
        key: "name",
        header: t("Name"),
        accessor: "name",
        isSortable: true,
        sortDirection: OrderDirection.Desc,
        sortColumnEnum: VendorDepartmentOrderField.Name,
        filter: {
          type: "string",
          name: "name_Icontains",
        },
      },
      {
        key: "code",
        header: t("Code"),
        accessor: "code",
        isSortable: true,
        sortDirection: OrderDirection.Desc,
        sortColumnEnum: VendorDepartmentOrderField.Code,
        filter: {
          type: "string",
          name: "code",
        },
      },
      {
        key: "branch",
        header: t("Branch"),
        accessor: ({ branch }) => pickLocalizedValue(branch?.name, branch?.nameAr),
        isSortable: true,
        sortDirection: OrderDirection.Desc,
        sortColumnEnum: VendorDepartmentOrderField.Branch,
        filter: getBranchesAutocompleteFilter({ name: "branch" }),
      },
      {
        key: "medicalServices",
        header: t("Medical Services"),
        accessor: ({ medicalServices }) => <DepartmentMedicalServices medicalServices={medicalServices?.edges} />,
      },
      {
        key: "qrCode",
        header: t("QR Code"),
        accessor: ({ id, name }) => <DepartmentQRCode id={id} name={name} />,
      },
    ],
    [t]
  );
};
