import { Appointment } from "@/schema/types";
import { useOnAppointmentStatusUpdate } from "@health/sse";
import { useTranslation } from "@toolkit/i18n";
import { StaticFilterDialogProps, useGridContext } from "@toolkit/ui";
import { getFilter } from "pages/appointments/utils";
import { ChangeEvent, useEffect } from "react";
import { PageTabsStatusType } from "shared/components/PageTabs";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";

type AppointmentsPageWrapperType = {
  isSelfOnlyAppointments: boolean;
  onChangeTab: (status: PageTabsStatusType) => void;
  onSelfOnlyAppointmentsChange?: (value: boolean) => void;
  onUpdateCount: (input) => void;
};

export const useAppointmentsPageWrapper = ({
  isSelfOnlyAppointments,
  onChangeTab,
  onSelfOnlyAppointmentsChange,
  onUpdateCount,
}: AppointmentsPageWrapperType) => {
  const { t } = useTranslation("provider");
  const { userInfo } = useOidcUserProfile();
  const isVendorAdmin = userInfo?.app_role?.toLowerCase() === "admin";

  const { customTableProps, staticFiltersProps, activeFiltersProps, fields, isLoading } = useGridContext();

  useOnAppointmentStatusUpdate(({ data }) => {
    if (data.graphqlType === "Appointment" && data?.id) {
      customTableProps?.onUpdateGrid && customTableProps?.onUpdateGrid();
    }
  });

  const handleToggleOnlyMyAppointments = (_: ChangeEvent<HTMLInputElement>, value: boolean) => {
    onSelfOnlyAppointmentsChange?.(value);
  };

  const staticFilterDialogProps: StaticFilterDialogProps<Appointment> = {
    isOpen: staticFiltersProps?.isOpen!,
    onApplyFilters: staticFiltersProps?.onApplyFilters!,
    onToggleDialog: staticFiltersProps?.onToggleDialog!,
    filterFields: fields!,
  };

  const handleTab = (status: PageTabsStatusType) => {
    onChangeTab(status);
  };

  delete activeFiltersProps?.requestedFilters.status;

  useEffect(() => {
    if (activeFiltersProps?.requestedFilters) {
      onUpdateCount({
        ...getFilter(isSelfOnlyAppointments!, activeFiltersProps && activeFiltersProps?.requestedFilters),
      });
    }
  }, [JSON.stringify(activeFiltersProps?.requestedFilters)]);

  return {
    t,
    isVendorAdmin,
    isLoading,
    activeFiltersProps,
    staticFilterDialogProps,
    handleTab,
    handleToggleOnlyMyAppointments,
  };
};
