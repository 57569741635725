import { ChatGroup } from "@/schema/types";
import { useCallback, useState } from "react";

export const useChatHooks = () => {
  const [chatGroup, setChatGroup] = useState<ChatGroup>();

  const handleSelect = useCallback(setChatGroup, [setChatGroup]);
  const handleClear = useCallback(() => setChatGroup(undefined), []);

  return { chatGroup, handleSelect, handleClear };
};
