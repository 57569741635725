import { Appointment } from "@/schema/types";
import { Box, EditIcon, IconButton, MagicIcon, makeStyles, RemoveIcon } from "@toolkit/ui";
import { AppointmentCancel, AppointmentReschedule } from "pages/appointments";
import { FC } from "react";

type AppointmentAction = {
  selectAppointment: Appointment;
  onFindSimilar: () => void;
};
const useStyle = makeStyles()(theme => ({
  iconButton: {
    width: 45,
    height: 45,
    borderRadius: "7px",
    background: "white",
    boxShadow: theme.mixins.shadows.main,
  },
}));
export const AppointmentAction: FC<AppointmentAction> = ({ selectAppointment, onFindSimilar: handleFindSimilar }) => {
  const { classes } = useStyle();
  return (
    <Box display='flex' gap={2}>
      <IconButton onClick={handleFindSimilar} className={classes.iconButton}>
        <MagicIcon />
      </IconButton>
      {selectAppointment?.id && (
        <>
          <AppointmentCancel
            id={selectAppointment?.id!}
            status={selectAppointment?.status!}
            buttonEl={
              <IconButton className={classes.iconButton}>
                <RemoveIcon height={6} width={6} viewBox='0 0 20 20' />
              </IconButton>
            }
          />
          <AppointmentReschedule
            id={selectAppointment?.id!}
            doctorId={selectAppointment?.doctor?.id}
            status={selectAppointment?.status!}
            patient={selectAppointment?.consumer}
            paymentInfo={selectAppointment?.paymentInfo}
            paymentStatus={selectAppointment?.paymentStatus}
            buttonEl={
              <IconButton className={classes.iconButton}>
                <EditIcon height={6} width={6} viewBox='0 0 26 26' />
              </IconButton>
            }
          />
        </>
      )}
    </Box>
  );
};
