import { Visit, VisitStatus } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { Button, CustomTableColumnProps } from "@toolkit/ui";
import { RejoinCallButton } from "pages/programs-calls/components/ActiveCall/RejoinCallButton";
import { useMemo } from "react";
import { openMeetingPlatformLink } from "shared/components/VisitStartingNowNotification/utils";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";
import { isDoctorUser, isVendorAdmin } from "utils";

export const useGetVisitActionColumn = (selfOnly: boolean): CustomTableColumnProps<Visit>[] => {
  const { t } = useTranslation("provider");
  const { userInfo } = useOidcUserProfile();
  const showColumn = isDoctorUser(userInfo) && (isVendorAdmin(userInfo) ? selfOnly : true);
  const handleJoinCall = (visitId: string) => () => {
    openMeetingPlatformLink(visitId!);
  };

  return useMemo(
    () => [
      {
        key: "join-call",
        header: t("Join Call"),
        accessor: ({ id, status }) =>
          status !== VisitStatus.CallInProgress ? (
            <RejoinCallButton visitId={id!} isDisabled={status !== VisitStatus.CallRejoinable} />
          ) : (
            <Button color={"success"} sx={{ width: "max-content" }} onClick={handleJoinCall(id!)}>
              {t("Join Now")}
            </Button>
          ),
        hideFromPreferencesAndTable: !showColumn,
      },
    ],
    [t, showColumn]
  );
};
