import { Department } from "@/schema/types";
import { BranchesAutocomplete } from "@health/autocompletes";
import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, FormChipsInput, FormTextField, Grid, PageWrapper } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { departmentsPaths } from "../../constants";
import { convertDepartmentToFormValues } from "../../others";
import { departmentUpsertFormDefaultValues, departmentUpsertFormSchema, IDepartmentUpsertFormValues } from "./DepartmentUpsertFormSchema";

export type IDepartmentUpsertFormEvent = {
  type: "SUBMIT";
  payload: {
    values: IDepartmentUpsertFormValues;
  };
};

type DepartmentUpsertFormProps = {
  department?: Department;
  buttonLabel: string;
  isLoading?: boolean;
  isButtonDisabled?: boolean;
  onChange: (event: IDepartmentUpsertFormEvent) => void;
};

export const DepartmentUpsertForm: FC<DepartmentUpsertFormProps> = props => {
  const { department, buttonLabel, isLoading, isButtonDisabled, onChange } = props;

  const { t } = useTranslation("provider");
  const navigate = useNavigate();

  const form = useCustomForm<IDepartmentUpsertFormValues>({
    defaultValues: departmentUpsertFormDefaultValues,
    schema: departmentUpsertFormSchema,
  });

  const { setValues, handleSubmit } = form;

  const onSubmit = (values: IDepartmentUpsertFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  const onCancelClick = () => {
    navigate(departmentsPaths.list.fullPath);
  };

  useEffect(() => {
    if (department) {
      const _department = convertDepartmentToFormValues(department);
      setValues(_department);
    }
  }, [department, setValues]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton
              isLoading={isLoading}
              formButtonTitle={buttonLabel}
              onNavigation={onCancelClick}
              newButtonDisabled={isButtonDisabled}
            />
          }
        >
          <FormCard title={t("Department Information")} loading={isLoading} doYouHaveData>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <FormTextField name={"name"} label={t("Name")} placeholder={t("Name")} />
              </Grid>

              <Grid item xs={4}>
                <FormTextField name={"code"} label={t("Code")} placeholder={t("Code")} />
              </Grid>

              <Grid item xs={4}>
                <FormTextField name={"directions"} label={t("Directions")} placeholder={t("Directions")} />
              </Grid>

              <Grid item xs={4}>
                <BranchesAutocomplete name={"branch"} />
              </Grid>

              <Grid item xs={4}>
                <FormChipsInput name={"medicalServices"} placeholder={t("Medical Services")} />
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <FormTextField name={"description"} label={t("Description")} placeholder={t("Description")} multiline rows={4} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </FormCard>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
