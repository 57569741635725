import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GuidedCareTeamGetNameQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GuidedCareTeamGetNameQuery = { __typename?: 'Query', providerGuidedCareHealthProgramTeam?: { __typename?: 'ProviderGuidedCareHealthProgramTeam', id: string, name?: string | null } | null };


export const GuidedCareTeamGetNameDocument = gql`
    query GuidedCareTeamGetName($id: ID!) {
  providerGuidedCareHealthProgramTeam(id: $id) {
    id
    name
  }
}
    `;

/**
 * __useGuidedCareTeamGetNameQuery__
 *
 * To run a query within a React component, call `useGuidedCareTeamGetNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuidedCareTeamGetNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuidedCareTeamGetNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGuidedCareTeamGetNameQuery(baseOptions: Apollo.QueryHookOptions<GuidedCareTeamGetNameQuery, GuidedCareTeamGetNameQueryVariables> & ({ variables: GuidedCareTeamGetNameQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GuidedCareTeamGetNameQuery, GuidedCareTeamGetNameQueryVariables>(GuidedCareTeamGetNameDocument, options);
      }
export function useGuidedCareTeamGetNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GuidedCareTeamGetNameQuery, GuidedCareTeamGetNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GuidedCareTeamGetNameQuery, GuidedCareTeamGetNameQueryVariables>(GuidedCareTeamGetNameDocument, options);
        }
export function useGuidedCareTeamGetNameSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GuidedCareTeamGetNameQuery, GuidedCareTeamGetNameQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GuidedCareTeamGetNameQuery, GuidedCareTeamGetNameQueryVariables>(GuidedCareTeamGetNameDocument, options);
        }
export type GuidedCareTeamGetNameQueryHookResult = ReturnType<typeof useGuidedCareTeamGetNameQuery>;
export type GuidedCareTeamGetNameLazyQueryHookResult = ReturnType<typeof useGuidedCareTeamGetNameLazyQuery>;
export type GuidedCareTeamGetNameSuspenseQueryHookResult = ReturnType<typeof useGuidedCareTeamGetNameSuspenseQuery>;
export type GuidedCareTeamGetNameQueryResult = Apollo.QueryResult<GuidedCareTeamGetNameQuery, GuidedCareTeamGetNameQueryVariables>;