import { PermissionEnum } from "@/schema/types";
import { hasPermission } from "@toolkit/core";
import { TFunction } from "@toolkit/i18n";
import { AppointmentSchedulingIcon } from "@toolkit/ui";
import { appointmentsPaths, appointmentsRoute } from "pages/appointments/constants";
import { AppointmentsContainer } from "pages/appointments/containers";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import { UserInfoType } from "shared/configs/oidc";

export const appointmentsRoutes: (props: { navigate: NavigateFunction; t: TFunction; userInfo: UserInfoType }) => RouteItem = ({
  navigate,
  t,
  userInfo,
}) => {
  return {
    id: "appointments",
    route: appointmentsRoute,
    fullPath: appointmentsPaths.main.fullPath,
    text: t("Appointments", { ns: "provider" }),
    icon: <AppointmentSchedulingIcon />,
    element: <AppointmentsContainer />,
    isProhibited: !hasPermission(PermissionEnum.ManageAppointments) && !userInfo?.doctor_id,
    hidden: !hasPermission(PermissionEnum.ManageAppointments) && !userInfo?.doctor_id,
    onClick: (route: string) => navigate(route),
  };
};
