import {
  GuidedCareActivityType,
  GuidedCareHealthProgramRiskFactorTemplateEvaluation,
  HealthParameter,
  Maybe,
  TemplateFieldType,
  TemplateIntervention,
} from "@/schema/types";
import { YesNo } from "@health/enum-options";
import { mapToAutocompleteOptions } from "@toolkit/ui";
import { flatMap, isNil, sortBy } from "lodash";
import moment from "moment/moment";
import { IGuidedCareProgramMemberRiskFactorParametersFormValues } from "../forms/GuidedCareProgramMemberRiskFactorParameters/GuidedCareProgramMemberRiskFactorParametersFormSchema";
import { ITemplateInterventionActivityItem } from "../types/types";

export const getInterventionActivityIcon = (activityType: GuidedCareActivityType) => {
  switch (activityType) {
    case GuidedCareActivityType.HomeVisit:
      return { icon: "home3", viewBox: "1 1 22 22" };
    case GuidedCareActivityType.OnlineVisit:
      return { icon: "video", viewBox: "-2 -2 25 25" };
    case GuidedCareActivityType.FacilityVisit:
      return { icon: "inPerson", viewBox: "-1.5 -1.5 26 26" };
    case GuidedCareActivityType.MedicalForm:
      return { icon: "medical-form", viewBox: "0 -2 30 30" };
    case GuidedCareActivityType.MedicalMessageInstruction:
    case GuidedCareActivityType.MedicalMessageGeneral:
    case GuidedCareActivityType.MedicalMessageReminder:
    case GuidedCareActivityType.MedicalMessageMedical:
    case GuidedCareActivityType.MedicalMessageMonitoring:
      return { icon: "medical-massage", viewBox: "0 2 22 22" };
    case GuidedCareActivityType.Lab:
      return { icon: "blood", viewBox: "-2 -4 30 30" };
    case GuidedCareActivityType.Rad:
      return { icon: "job", viewBox: "1 0 25 25" };
    default:
      return { icon: "pills" };
  }
};

export const getInterventionActivitiesItems = (intervention?: Maybe<TemplateIntervention>): ITemplateInterventionActivityItem[] => {
  if (!intervention) return [];

  const interventionActivityItems = flatMap(
    intervention?.interventionTemplateActivities,
    activity =>
      activity?.interventionActivityEventItems?.map(item => ({
        ...item!,
        templateActivityType: activity?.templateActivityType!,
        isDeleted: false,
      })) || []
  );

  return sortBy(interventionActivityItems, "offsetInDays");
};

export const convertRiskFactorTemplateEvaluateToFormValues = (
  evaluateRiskFactorTemplate?: GuidedCareHealthProgramRiskFactorTemplateEvaluation | null
) => {
  const suggestedPrograms =
    evaluateRiskFactorTemplate?.suggestedGuidedPrograms?.map(item => ({
      id: item?.id!,
      name: item?.name!,
    })) || [];

  return {
    level: evaluateRiskFactorTemplate?.level!,
    suggestedPrograms: mapToAutocompleteOptions(suggestedPrograms, "id", "name"),
  };
};

export const convertRiskFactorTemplateFieldsToFormValues = (fields: (HealthParameter | null)[]) => {
  return fields?.map(field => ({
    id: field?.id!,
    code: field?.code!,
    display: field?.display!,
    arabicDisplay: field?.arabicDisplay,
    type: field?.type!,
    allowedValues: (field?.allowedValues || []) as string[],
    unit: {
      display: field?.unit?.display!,
      arabicDisplay: field?.unit?.arabicDisplay,
    },
    defaultValue: field?.defaultValue,
    isMandatory: !!field?.isMandatory,
    minNormalRangeValue: field?.minNormalRangeValue,
    maxNormalRangeValue: field?.maxNormalRangeValue,
  }));
};

export const convertGuidedCareProgramRiskFactorTemplateFieldsValuesToBackEndValues = (
  fields: IGuidedCareProgramMemberRiskFactorParametersFormValues[]
) => {
  const result = {};

  for (const field of fields) {
    const { code, type, allowedValues, valueNumber, valueString, valueBoolean, valueDate, valueStringList } = field;

    if (type === TemplateFieldType.Number && !isNil(valueNumber)) {
      result[code] = valueNumber;
    } else if (type === TemplateFieldType.Date && valueDate) {
      result[code] = moment(valueDate).format();
    } else if (type === TemplateFieldType.Boolean && !isNil(valueBoolean)) {
      result[code] = valueBoolean?.value === YesNo.Yes;
    } else if (type === TemplateFieldType.String) {
      if (allowedValues?.length && valueStringList) {
        result[code] = valueStringList?.value;
      } else if (valueString) {
        result[code] = valueString;
      }
    } else if (valueString) {
      result[code] = valueString;
    }
  }

  return JSON.stringify(result);
};
