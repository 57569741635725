import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useVisitCallHook } from "./useVisitCallHook";
import { Call } from "@health/meeting";
import { visitCallGuestBaseRoute } from "@/pages/VisitCallGuest/route";

type VisitCallProps = {
  visitId: string;
};

export const VisitCall: FC<VisitCallProps> = ({ visitId }) => {
  const { renderDoctorConsole } = useVisitCallHook({ visitId });
  const invitationLink = `${location.origin}/${visitCallGuestBaseRoute}`;
  const navigate = useNavigate();
  const onGoBack = useCallback(() => navigate("/"), [navigate]);
  const onRequestClose = useCallback(() => navigate("/"), [navigate]);

  return (
    <Call
      invitationLink={invitationLink}
      visitId={visitId}
      onClose={onRequestClose}
      onGoBack={onGoBack}
      renderDoctorConsole={renderDoctorConsole}
    />
  );
};
