import { Box } from "@toolkit/ui";
import { FC } from "react";
import { useDashboardContext } from "pages/dashboard/context/UserDoctorDashboardContextProvider";
import { ChatGroups } from "@health/chat";

export const CareTeamChats: FC = () => {
  const { handleSelectChat } = useDashboardContext();

  return (
    <Box width={"100%"} height={`330px`} display='block'>
      <Box width={"calc(100% - 2)"} height={"100%"} marginX={2}>
        <ChatGroups onSelect={handleSelectChat} />
      </Box>
    </Box>
  );
};
