import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VisitStoredDetailsQueryVariables = Types.Exact<{
  visitId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type VisitStoredDetailsQuery = { __typename?: 'Query', details?: { __typename?: 'Visit', doctorNote?: string | null, noteToPatient?: string | null, visitDiagnoses?: Array<{ __typename?: 'VisitDiagnosis', id: string, display?: string | null, code: string, visitDiagnosisType: Types.VisitDiagnosisType, riskTemplate?: { __typename?: 'VisitDiagnosisRiskTemplate', id: string, code?: string | null, isGuidedCareProgramSelected?: boolean | null, isFilled?: boolean | null, notification?: string | null, result?: string | null, chronicDiseaseSeverity?: string | null, chronicDiseaseCode?: string | null, selectedGuidedCareProgram?: { __typename?: 'GuidedCareHealthProgram', id: string, name?: string | null } | null, enrolledGuidedPrograms?: Array<{ __typename?: 'GuidedCareHealthProgram', id: string, name?: string | null } | null> | null, suggestedGuidedPrograms?: Array<{ __typename?: 'GuidedCareHealthProgram', id: string, name?: string | null } | null> | null } | null } | null> | null, labs?: Array<{ __typename?: 'Lab', id: string, code?: string | null, created?: any | null, modified?: any | null, result?: string | null, resultReceived?: boolean | null } | null> | null, healthProgramMember?: { __typename?: 'HealthProgramMember', id: string } | null } | null };


export const VisitStoredDetailsDocument = gql`
    query visitStoredDetails($visitId: ID) {
  details: visit(id: $visitId) {
    visitDiagnoses {
      id
      display
      code
      riskTemplate {
        id
        code
        isGuidedCareProgramSelected
        selectedGuidedCareProgram {
          id
          name
        }
        enrolledGuidedPrograms {
          id
          name
        }
        suggestedGuidedPrograms {
          id
          name
        }
        isFilled
        notification
        result
        chronicDiseaseSeverity
        chronicDiseaseCode
      }
      visitDiagnosisType
    }
    doctorNote
    labs {
      id
      code
      created
      modified
      result
      resultReceived
    }
    healthProgramMember {
      id
    }
    noteToPatient
  }
}
    `;

/**
 * __useVisitStoredDetailsQuery__
 *
 * To run a query within a React component, call `useVisitStoredDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitStoredDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitStoredDetailsQuery({
 *   variables: {
 *      visitId: // value for 'visitId'
 *   },
 * });
 */
export function useVisitStoredDetailsQuery(baseOptions?: Apollo.QueryHookOptions<VisitStoredDetailsQuery, VisitStoredDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VisitStoredDetailsQuery, VisitStoredDetailsQueryVariables>(VisitStoredDetailsDocument, options);
      }
export function useVisitStoredDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VisitStoredDetailsQuery, VisitStoredDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VisitStoredDetailsQuery, VisitStoredDetailsQueryVariables>(VisitStoredDetailsDocument, options);
        }
export function useVisitStoredDetailsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<VisitStoredDetailsQuery, VisitStoredDetailsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<VisitStoredDetailsQuery, VisitStoredDetailsQueryVariables>(VisitStoredDetailsDocument, options);
        }
export type VisitStoredDetailsQueryHookResult = ReturnType<typeof useVisitStoredDetailsQuery>;
export type VisitStoredDetailsLazyQueryHookResult = ReturnType<typeof useVisitStoredDetailsLazyQuery>;
export type VisitStoredDetailsSuspenseQueryHookResult = ReturnType<typeof useVisitStoredDetailsSuspenseQuery>;
export type VisitStoredDetailsQueryResult = Apollo.QueryResult<VisitStoredDetailsQuery, VisitStoredDetailsQueryVariables>;