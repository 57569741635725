import { UserNotification } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, CircularProgress } from "@toolkit/ui";
import { FC } from "react";
import { useNotificationsHooks } from "shared/components/Notifications/Notifications.hook";
import { NotificationsList } from "shared/components/Notifications/NotificationsList";

export const Notifications: FC = () => {
  const { t } = useTranslation("provider");
  const { notifications, pageInfo, loading, fetchMoreData } = useNotificationsHooks({});

  return (
    <Box id='scrollableBranchesId' sx={{ height: "330px", overflowY: "scroll" }}>
      <Box
        id='scrollable-id'
        sx={{
          height: "330px",
          overflow: "auto",
        }}
      >
        {loading ? (
          <Box sx={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
            <CircularProgress size={12} sx={{ mx: 1, my: 1 }} />
          </Box>
        ) : (
          <Box sx={{ marginX: 2 }}>
            {notifications?.length ? (
              <NotificationsList
                notifications={notifications as UserNotification[]}
                fetchMoreData={fetchMoreData}
                hasMore={pageInfo?.hasNextPage!}
                isDisplayedInDropDown={false}
              />
            ) : (
              <Box sx={{ justifyContent: "center", alignItems: "center", display: "flex" }}>{t("No notifications")}</Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};
