import { InputMaybe, MarketplaceProduct, MarketplaceProductInput } from "@/schema/types";
import { pickLocalizedValue } from "@toolkit/i18n";
import { createAutocompleteOption } from "@toolkit/ui";
import { IProductUpsertFormValues } from "../forms/ProductFormUpsert/ProductUpsertFormSchema";
import { ProductData } from "../types";

export const convertProductFormValuesToBackEndValues = (values: IProductUpsertFormValues): MarketplaceProductInput => {
  return {
    name: values.name,
    nameAr: values.nameAr,
    description: values.description,
    descriptionAr: values.descriptionAr,
    howToUse: values.howToUse,
    howToUseAr: values.howToUseAr,
    mainImageUrl: values.mainImageUrl,
    subImages: (values.subImages as InputMaybe<string>[]) || undefined,
    price: values.price,
    sku: values.sku,
    shortDescription: values.shortDescription,
    shortDescriptionAr: values.shortDescriptionAr,
    stockQuantity: values.stockQuantity!,
    tags: (values.tags as InputMaybe<string>[]) || undefined,
    categoryId: values.productCategory?.value?.id!,
    brandId: values.productBrand?.value?.id!,
    returnPolicy: values.returnPolicy,
    returnPolicyAr: values.returnPolicyAr,
    returnPolicyDays: values.returnPolicyDays,
    returnable: values.returnable!,
  };
};

export const convertProductToFormValues = (product: ProductData): IProductUpsertFormValues => {
  return {
    name: product?.name!,
    nameAr: product?.nameAr!,
    description: product?.description!,
    descriptionAr: product?.descriptionAr!,
    howToUse: product?.howToUse,
    howToUseAr: product?.howToUseAr,
    mainImageUrl: product?.mainImageUrl!,
    subImages: product?.subImages?.filter((image): image is string => image !== null) || [],
    price: product?.price,
    sku: product?.sku!,
    shortDescription: product?.shortDescription!,
    shortDescriptionAr: product?.shortDescriptionAr!,
    stockQuantity: product?.stockQuantity!,
    tags: product?.tags?.filter((tag): tag is string => tag !== null) || [],
    productCategory: createCategoryAutocompleteObject(product?.category),
    productBrand: createBrandAutocompleteObject(product?.brand),
    returnPolicy: product?.returnPolicy,
    returnPolicyAr: product?.returnPolicyAr,
    returnPolicyDays: product?.returnPolicyDays,
    returnable: product?.returnable || false,
  };
};

export const createCategoryAutocompleteObject = (category: MarketplaceProduct["category"]) => {
  return createAutocompleteOption(
    {
      id: category?.id!,
      name: category?.name!,
      nameAr: category?.nameAr!,
    },
    "id",
    item => pickLocalizedValue(item?.name, item?.nameAr!)
  );
};

export const createBrandAutocompleteObject = (product: MarketplaceProduct["brand"]) => {
  return createAutocompleteOption(
    {
      id: product?.id!,
      name: product?.name!,
      nameAr: product?.nameAr,
    },
    "id",
    item => pickLocalizedValue(item?.name, item?.nameAr!)
  );
};
