import { AccountError, DoctorError, User } from "@/schema/types";
import { combineErrors, getMediaLink, getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Grid, ImageUploadController } from "@toolkit/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";

interface PhotoUploaderProps {
  defaultValues?: Partial<User> | undefined;
  errors?: DoctorError[] | AccountError[];
  isRequired?: boolean;
}

export const PhotoUploader: FC<PhotoUploaderProps> = ({ defaultValues, errors, isRequired = true }) => {
  const { control, formState } = useFormContext();
  const { t } = useTranslation("provider");
  const combinedErrors = combineErrors(formState.errors, errors);
  const { accessToken } = useOidcUserProfile();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ImageUploadController
          control={control}
          name='photo'
          defaultValue={defaultValues?.photo && getMediaLink(defaultValues?.photo)}
          validationRation={true}
          error={Boolean(combinedErrors?.photo?.message)}
          helperText={combinedErrors?.photo?.message}
          rules={{
            required: getRequiredValidation(t, isRequired),
          }}
          token={accessToken!}
        />
      </Grid>
    </Grid>
  );
};
