import { OrdereRxAcceptDialog } from "@/pages/GlobalOrders/Orders/dialoges/ErxOrderAccept/OrdereRxAcceptDialog";
import { MedicationsMappingDialog } from "@/pages/GlobalOrders/Orders/dialoges/ProductOrderAcceptDialog/MedicationsMappingDialog";
import { OrderStatus } from "@/schema/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { FC } from "react";
import { OrderMedicationsMappingProps } from "../cards/types";
import { useResubmitOrderGetErxHubApprovalMutation } from "../gql";

type ResubmitProps = OrderMedicationsMappingProps & {
  orderId: string;
  status: OrderStatus;
};

export const ResubmitButton: FC<ResubmitProps> = ({ prescriptionId, orderId, referenceNumber, patientIdentifier, medications, status }) => {
  const { t } = useTranslation("provider");
  const { failed, succeeded } = useAddToast();

  const [resubmitOrderGetErxHubApprovalMutation, { loading: isGetErxApprovalLoading }] = useResubmitOrderGetErxHubApprovalMutation({
    onCompleted: () => {
      succeeded(t("Your order has been resubmitted successfully"));
    },
    onError: ({ graphQLErrors }) => {
      const formattedErrors = formatGraphQLError(graphQLErrors);
      failed(formattedErrors);
      console.error("Resubmit", formattedErrors);
    },
  });

  const handleSubmit = value => {
    resubmitOrderGetErxHubApprovalMutation({
      variables: {
        orderId: orderId,
        input: {
          acceptedMedications: value,
        },
      },
    });
  };
  const getTitleFromStatus = (): string => {
    if (status === OrderStatus.ErxHubAuthSubmitTimedOut) {
      return t("Get IO health Approval");
    }
    return t("Resubmit");
  };
  return prescriptionId ? (
    <OrdereRxAcceptDialog
      isLoading={isGetErxApprovalLoading}
      medications={medications}
      prescriptionId={prescriptionId}
      onSubmit={handleSubmit}
    />
  ) : (
    <MedicationsMappingDialog
      isLoading={isGetErxApprovalLoading}
      buttonLabel={getTitleFromStatus()}
      medications={medications?.filter(el => Boolean(el?.id))}
      patientIdentifier={patientIdentifier}
      referenceNumber={referenceNumber}
      onSubmit={handleSubmit}
    />
  );
};
