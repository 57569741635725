import { ServiceDetails } from "@/schema/types";
import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Button, CircularProgress, CustomDialog } from "@toolkit/ui";
import { FC, useEffect, useRef, useState } from "react";
import {
  AppointmentServiceDetailsUpsertForm,
  AppointmentServiceDetailsUpsertFormRef,
  IAppointmentServiceDetailsUpsertFormEvent,
} from "../../forms/AppointmentServiceDetailsUpsert/AppointmentServiceDetailsUpsertForm";

type IAppointmentServiceDetailsUpsertModalData = {
  appointmentId: string;
  serviceDetails?: ServiceDetails[];
};

type IOpen = (data: IAppointmentServiceDetailsUpsertModalData) => void;
type IClose = () => void;

export const AppointmentServiceDetailsUpsertModalApi: { open: IOpen; close: IClose } = {
  open: () => {},
  close: () => {},
};

type AppointmentServiceDetailsModalProps = {
  isLoading?: boolean;
  onChange: (event: IAppointmentServiceDetailsUpsertFormEvent) => void;
};

export const AppointmentServiceDetailsUpsertModal: FC<AppointmentServiceDetailsModalProps> = props => {
  const { isLoading, onChange } = props;

  const [data, setData] = useState<IAppointmentServiceDetailsUpsertModalData>();

  const { t } = useTranslation("provider");

  const { open: isOpen, handleOpen, handleClose } = useOpenState();

  const appointmentServiceDetailsUpsertFormRef = useRef<AppointmentServiceDetailsUpsertFormRef>(null);

  const onUpdateClick = () => {
    appointmentServiceDetailsUpsertFormRef.current?.submit();
  };

  const open: IOpen = _data => {
    handleOpen();
    setData(_data);
  };

  const close: IClose = () => {
    handleClose();
  };

  useEffect(() => {
    AppointmentServiceDetailsUpsertModalApi.open = open;
    AppointmentServiceDetailsUpsertModalApi.close = close;
  }, []);

  return (
    <CustomDialog
      type={"base"}
      open={isOpen}
      DialogTitleProps={{
        title: t("Appointment Service Details"),
        onClose: handleClose,
      }}
      DialogActionsProps={{
        children: (
          <>
            <Button
              disabled={isLoading}
              endIcon={isLoading ? <CircularProgress color={"inherit"} size={18} /> : null}
              onClick={onUpdateClick}
            >
              {t("Update")}
            </Button>

            <Button variant={"outlined"} onClick={handleClose}>
              {t("Cancel")}
            </Button>
          </>
        ),
      }}
    >
      <AppointmentServiceDetailsUpsertForm
        appointmentId={data?.appointmentId!}
        serviceDetails={data?.serviceDetails!}
        ref={appointmentServiceDetailsUpsertFormRef}
        onChange={onChange}
      />
    </CustomDialog>
  );
};
