import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../../gql/fragments/__generated__/pageInfoFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketplacePromotionsQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.MarketplacePromotionFilterInput>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  sortBy?: Types.InputMaybe<Types.MarketplacePromotionSortingInput>;
}>;


export type MarketplacePromotionsQuery = { __typename?: 'Query', marketplacePromotions?: { __typename?: 'MarketplacePromotionConnection', pageInfo?: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } | null, edges: Array<{ __typename?: 'MarketplacePromotionEdge', node: { __typename?: 'MarketplacePromotion', id: string, name?: string | null, description?: string | null, isPublished: boolean, photo?: string | null, startDate?: any | null, endDate?: any | null, productCategories?: Array<{ __typename?: 'MarketplaceProductCategory', name?: string | null, nameAr?: string | null, id: string } | null> | null, products?: Array<{ __typename?: 'MarketplaceProduct', id: string, nameAr?: string | null, name?: string | null } | null> | null, healthPackages?: Array<{ __typename?: 'MarketplaceHealthPackage', id: string, name?: string | null, nameAr?: string | null } | null> | null, healthPackageCategories?: Array<{ __typename?: 'MarketplaceHealthPackageCategory', nameAr?: string | null, name?: string | null, id: string } | null> | null } }> } | null };


export const MarketplacePromotionsDocument = gql`
    query MarketplacePromotions($after: String, $before: String, $filter: MarketplacePromotionFilterInput, $first: Int, $last: Int, $sortBy: MarketplacePromotionSortingInput) {
  marketplacePromotions(
    after: $after
    before: $before
    filter: $filter
    first: $first
    last: $last
    sortBy: $sortBy
  ) {
    pageInfo {
      ...PageInfoFragment
    }
    edges {
      node {
        id
        name
        description
        isPublished
        photo
        productCategories {
          name
          nameAr
          id
        }
        products {
          id
          nameAr
          name
        }
        healthPackages {
          id
          name
          nameAr
        }
        healthPackageCategories {
          nameAr
          name
          id
        }
        startDate
        endDate
      }
    }
  }
}
    ${PageInfoFragmentFragmentDoc}`;

/**
 * __useMarketplacePromotionsQuery__
 *
 * To run a query within a React component, call `useMarketplacePromotionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketplacePromotionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketplacePromotionsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useMarketplacePromotionsQuery(baseOptions?: Apollo.QueryHookOptions<MarketplacePromotionsQuery, MarketplacePromotionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MarketplacePromotionsQuery, MarketplacePromotionsQueryVariables>(MarketplacePromotionsDocument, options);
      }
export function useMarketplacePromotionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarketplacePromotionsQuery, MarketplacePromotionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MarketplacePromotionsQuery, MarketplacePromotionsQueryVariables>(MarketplacePromotionsDocument, options);
        }
export function useMarketplacePromotionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MarketplacePromotionsQuery, MarketplacePromotionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MarketplacePromotionsQuery, MarketplacePromotionsQueryVariables>(MarketplacePromotionsDocument, options);
        }
export type MarketplacePromotionsQueryHookResult = ReturnType<typeof useMarketplacePromotionsQuery>;
export type MarketplacePromotionsLazyQueryHookResult = ReturnType<typeof useMarketplacePromotionsLazyQuery>;
export type MarketplacePromotionsSuspenseQueryHookResult = ReturnType<typeof useMarketplacePromotionsSuspenseQuery>;
export type MarketplacePromotionsQueryResult = Apollo.QueryResult<MarketplacePromotionsQuery, MarketplacePromotionsQueryVariables>;