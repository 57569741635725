import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, Typography } from "@toolkit/ui";
import { useMemo } from "react";
import { OptimaEdit } from "@/schema/types";

export const useEditsListColumns = (): CustomTableColumnProps<OptimaEdit>[] => {
  const { t } = useTranslation("provider");

  return useMemo(() => {
    return [
      {
        key: "source",
        header: t("Source"),
        accessor: "source",
      },
      {
        key: "code",
        header: t("Code"),
        accessor: "code",
      },
      {
        key: "message",
        header: t("Message"),
        accessor: ({ message }) => <Typography>{message || "-"}</Typography>,
      },
      {
        key: "priority",
        header: t("Priority"),
        accessor: ({ priority }) => {
          return <Typography>{priority || "-"} </Typography>;
        },
      },
      {
        key: "category",
        header: t("Category"),
        accessor: "category",
      },
      {
        key: "subCategory",
        header: t("Sub Category"),
        accessor: "subCategory",
      },
    ];
  }, [t]);
};
