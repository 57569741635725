import { AccountError, User, VendorUserTypes } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { FormCard, Grid } from "@toolkit/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { WorkingHours } from "shared/components";
import { DoctorAppointmentInformation } from "./DoctorAppointmentInformation";
import { Departments } from "./DoctorDepartments.component";
import { DoctorInformation } from "./DoctorInformation";
import { DoctorQualificationsInformation } from "./DoctorQualificationsInformation";

export const DoctorDetailsComponent: FC<{
  errors: AccountError[];
  isLoading: boolean;
  doYouHaveUser: boolean;
  defaultValues?: User | undefined;
  isEdit: boolean;
}> = ({ errors, isLoading, doYouHaveUser, defaultValues, isEdit }) => {
  const { t } = useTranslation("provider");
  const defaultVendorUserType = defaultValues?.vendorUserType as VendorUserTypes | undefined;
  const { watch } = useFormContext();
  const vendorUserType = watch("vendorUserType");

  return (
    <>
      {(vendorUserType?.value == VendorUserTypes.Doctor || defaultVendorUserType === VendorUserTypes.Doctor) && (
        <>
          <Grid item xs={12}>
            <FormCard title={t("Departments")} loading={isLoading} doYouHaveData={doYouHaveUser}>
              <Departments defaultValues={defaultValues} errors={errors} />
            </FormCard>
          </Grid>
          <Grid item xs={12}>
            <FormCard title={t("Doctor Information")} loading={isLoading} doYouHaveData={doYouHaveUser}>
              <DoctorInformation errors={errors} defaultValues={defaultValues} isEdit={isEdit} />
            </FormCard>
          </Grid>
          <Grid item xs={12}>
            <FormCard title={t("Doctor Appointment Information")} loading={isLoading} doYouHaveData={doYouHaveUser}>
              <DoctorAppointmentInformation errors={errors} defaultValues={defaultValues} />
            </FormCard>
          </Grid>
          <Grid item xs={12}>
            <FormCard title={t("Doctor Qualifications")} loading={isLoading} doYouHaveData={doYouHaveUser}>
              <DoctorQualificationsInformation errors={errors} defaultValues={defaultValues} />
            </FormCard>
          </Grid>
          <Grid item xs={12}>
            <WorkingHours
              isLoading={isLoading}
              doYouHaveData={doYouHaveUser}
              inputName={"doctorInfo.workingHours"}
              workingHoursDefaultValue={defaultValues?.doctor?.workingHours}
            />
          </Grid>
        </>
      )}
    </>
  );
};
