import { Maybe, PatientChronicDisease } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, Typography } from "@toolkit/ui";
import { FC, PropsWithChildren, useState } from "react";
import { usePatientInformationSectionStyle } from "./PatientInformationSectionStyle";

export const PatientInformationSection: FC<
  PropsWithChildren<{
    title: string;
    hasItems?: boolean;
    items?: (string | undefined)[] | Maybe<PatientChronicDisease>[];
  }>
> = ({ title, items, children, hasItems = false }) => {
  const { classes, theme } = usePatientInformationSectionStyle();
  const { t } = useTranslation("provider");

  const [seeAll, setSeeAll] = useState(false);

  const handleSeeAll = (index?: number) => () => {
    index === 2 && setSeeAll(true);
  };
  const handleResetSeeAll = () => {
    setSeeAll(false);
  };

  return (
    <>
      <Typography fontSize={theme.mixins.fonts.fontSize.md} fontWeight={theme.mixins.fonts.fontWeight.medium} maxWidth={160}>
        {title}
      </Typography>
      {children}

      <Box display='flex' alignItems='center' gap={1} width={200} flexWrap='wrap'>
        {!!items?.length &&
          items?.map((item, index) => (
            <Box key={item + index}>
              {(index < 2 || seeAll) && (
                <Box className={classes.box}>
                  <Typography className={classes.text}>{item}</Typography>
                </Box>
              )}
              {index === 2 && !seeAll && (
                <Box className={`${classes.box} ${classes.clickable}`}>
                  <Typography className={classes.text} fontWeight={theme.mixins.fonts.fontWeight.medium} onClick={handleSeeAll(index)}>
                    ...
                  </Typography>
                </Box>
              )}
            </Box>
          ))}
        {seeAll && (
          <Box className={`${classes.box} ${classes.clickable}`}>
            <Typography className={classes.text} onClick={handleResetSeeAll}>
              {t("See Less")}
            </Typography>
          </Box>
        )}

        {hasItems && !items?.length && (
          <Box className={classes.noData}>
            <Typography fontWeight={theme.mixins.fonts.fontWeight.medium} className={classes.text}>
              N/A
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};
