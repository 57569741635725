import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VisitSummariesQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.VisitSummaryFilterInput>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type VisitSummariesQuery = { __typename?: 'Query', visitSummaries: { __typename?: 'VisitSummaryCountableConnection', totalCount?: number | null, edges: Array<{ __typename?: 'VisitSummaryCountableEdge', node: { __typename?: 'VisitSummary', created?: any | null, doctorNotes?: string | null, id: string, isProblemSolved?: boolean | null, visit?: { __typename?: 'Visit', id: string } | null } }>, pageInfo: { __typename?: 'H_PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } } };


export const VisitSummariesDocument = gql`
    query VisitSummaries($after: String, $before: String, $filter: VisitSummaryFilterInput, $first: Int, $last: Int) {
  visitSummaries(
    after: $after
    before: $before
    filter: $filter
    first: $first
    last: $last
  ) {
    edges {
      node {
        created
        doctorNotes
        id
        isProblemSolved
        visit {
          id
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    totalCount
  }
}
    `;

/**
 * __useVisitSummariesQuery__
 *
 * To run a query within a React component, call `useVisitSummariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitSummariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitSummariesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useVisitSummariesQuery(baseOptions?: Apollo.QueryHookOptions<VisitSummariesQuery, VisitSummariesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VisitSummariesQuery, VisitSummariesQueryVariables>(VisitSummariesDocument, options);
      }
export function useVisitSummariesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VisitSummariesQuery, VisitSummariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VisitSummariesQuery, VisitSummariesQueryVariables>(VisitSummariesDocument, options);
        }
export function useVisitSummariesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<VisitSummariesQuery, VisitSummariesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<VisitSummariesQuery, VisitSummariesQueryVariables>(VisitSummariesDocument, options);
        }
export type VisitSummariesQueryHookResult = ReturnType<typeof useVisitSummariesQuery>;
export type VisitSummariesLazyQueryHookResult = ReturnType<typeof useVisitSummariesLazyQuery>;
export type VisitSummariesSuspenseQueryHookResult = ReturnType<typeof useVisitSummariesSuspenseQuery>;
export type VisitSummariesQueryResult = Apollo.QueryResult<VisitSummariesQuery, VisitSummariesQueryVariables>;