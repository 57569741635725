import { VisitDoctorNoteInput } from "@/schema/types";
import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { getApolloContextFormToken } from "shared/utils";
import { useVisitNotToPatientUpdateMutation, VisitNotToPatientUpdateMutation, VisitStoredDetailsDocument } from "../../../gql";
import { useCoPilotContext } from "@/pages/e-visit/CoPilot/CoPilotContext";

export const useToPatientNote = ({ visitId, token }: { visitId?: string; token?: string }) => {
  const { t } = useTranslation("provider");
  const { failed, succeeded } = useAddToast();
  const { noteToPatient } = useCoPilotContext();

  const methods = useForm<VisitDoctorNoteInput>({
    mode: "onChange",
  });

  const [visitDoctorNoteUpdateMutation, { loading: isUpdateSubmitting }] = useVisitNotToPatientUpdateMutation({
    context: getApolloContextFormToken(token),
    notifyOnNetworkStatusChange: true,
    onCompleted: (response: VisitNotToPatientUpdateMutation) => {
      const updateErrors = response?.visitNotToPatientUpdate?.visitErrors;
      if (updateErrors && updateErrors?.length > 0) {
        methods.setError("doctorNote", {
          message: formatMessageErrors(updateErrors),
          type: "value",
        });
      } else {
        methods.setValue("doctorNote", response?.visitNotToPatientUpdate?.visit?.noteToPatient);
        succeeded(t("Visit Patient note updated successfully"));
      }
    },
    onError: () => {
      failed(t("Failed to update visit patient note"));
    },
    refetchQueries: [
      {
        query: VisitStoredDetailsDocument,
        variables: {
          visitId,
        },
      },
    ],
  });

  const handleSubmit = (InputData: VisitDoctorNoteInput) => {
    visitDoctorNoteUpdateMutation({
      variables: {
        input: {
          doctorNote: InputData?.doctorNote,
          visit: String(visitId),
        },
      },
    });
  };
  const isSubmitting = isUpdateSubmitting;
  useEffect(() => {
    if (noteToPatient) {
      methods.setValue("doctorNote", noteToPatient);
    }
  }, [noteToPatient]);

  return {
    errors: methods.formState.errors,
    methods,
    isSubmitting,
    handleSubmit,
    noteToPatient,
  };
};
