import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ResubmitOrderGetErxHubApprovalMutationVariables = Types.Exact<{
  input: Types.VendorOrderAcceptInput;
  orderId: Types.Scalars['ID']['input'];
}>;


export type ResubmitOrderGetErxHubApprovalMutation = { __typename?: 'Mutation', resubmitOrderGetErxHubApproval?: { __typename?: 'Order', id: string, status: Types.OrderStatus } | null };


export const ResubmitOrderGetErxHubApprovalDocument = gql`
    mutation resubmitOrderGetErxHubApproval($input: VendorOrderAcceptInput!, $orderId: ID!) {
  resubmitOrderGetErxHubApproval(input: $input, orderId: $orderId) {
    id
    status
  }
}
    `;
export type ResubmitOrderGetErxHubApprovalMutationFn = Apollo.MutationFunction<ResubmitOrderGetErxHubApprovalMutation, ResubmitOrderGetErxHubApprovalMutationVariables>;

/**
 * __useResubmitOrderGetErxHubApprovalMutation__
 *
 * To run a mutation, you first call `useResubmitOrderGetErxHubApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResubmitOrderGetErxHubApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resubmitOrderGetErxHubApprovalMutation, { data, loading, error }] = useResubmitOrderGetErxHubApprovalMutation({
 *   variables: {
 *      input: // value for 'input'
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useResubmitOrderGetErxHubApprovalMutation(baseOptions?: Apollo.MutationHookOptions<ResubmitOrderGetErxHubApprovalMutation, ResubmitOrderGetErxHubApprovalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResubmitOrderGetErxHubApprovalMutation, ResubmitOrderGetErxHubApprovalMutationVariables>(ResubmitOrderGetErxHubApprovalDocument, options);
      }
export type ResubmitOrderGetErxHubApprovalMutationHookResult = ReturnType<typeof useResubmitOrderGetErxHubApprovalMutation>;
export type ResubmitOrderGetErxHubApprovalMutationResult = Apollo.MutationResult<ResubmitOrderGetErxHubApprovalMutation>;
export type ResubmitOrderGetErxHubApprovalMutationOptions = Apollo.BaseMutationOptions<ResubmitOrderGetErxHubApprovalMutation, ResubmitOrderGetErxHubApprovalMutationVariables>;