import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
export type VendorUserFragment = { __typename?: 'User', appRole?: Types.AppRoleTypes | null, appType?: Types.AppTypes | null, email?: string | null, firstName?: string | null, lastName?: string | null, isActive: boolean, id: string, isStaff?: boolean | null, isSuperuser?: boolean | null, mobile?: string | null, nationalId?: string | null, vendorUserType?: Types.UserVendorUserType | null };

export const VendorUserFragmentDoc = gql`
    fragment VendorUser on User {
  appRole
  appType
  email
  firstName
  lastName
  isActive
  id
  isStaff
  isSuperuser
  mobile
  nationalId
  vendorUserType
}
    `;