import { MarketplaceDiscountType } from "@/schema/types";
import { zodSchema } from "@health/autocompletes";
import { zodEnumSchema } from "@health/enum-options";
import { i18n } from "@toolkit/i18n";
import moment from "moment";
import { providerTranslationNamespace } from "utils";
import { z } from "zod";

export const DiscountUpsertFormSchema = z
  .object({
    name: z.string().min(3).max(255),
    endDate: z.string(),
    startDate: z.string(),
    healthPackage: z.array(zodSchema.marketplaceHealthPackage).optional(),
    product: z.array(zodSchema.marketplaceProduct).optional(),
    type: zodEnumSchema.marketplaceDiscountType,
    amount: z.coerce.number().min(0).optional(),
    percentage: z.coerce.number().min(0).max(100).optional(),
  })
  .superRefine((data, ctx) => {
    const theresNoError = data?.product?.length || data?.healthPackage?.length;
    const targetFields = ["product", "healthPackage"];
    if (!theresNoError) {
      targetFields.forEach(field => {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: i18n.t("Please select at least one product, or health package", providerTranslationNamespace),
          path: [field],
        });
      });
    }

    if (data?.startDate && data?.endDate) {
      const startDate = moment(data.startDate);
      const endDate = moment(data.endDate);
      if (startDate.isAfter(endDate)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: i18n.t("End date must be after start date", providerTranslationNamespace),
          path: ["endDate"],
        });
      } else if (startDate.isBefore(moment().startOf("day"))) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: i18n.t("Start date can't be in the past.", providerTranslationNamespace),
          path: ["startDate"],
        });
      }
    }
    if (data?.type?.value === MarketplaceDiscountType.Fixed && !data?.amount) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: i18n.t("Please enter the amount", providerTranslationNamespace),
        path: ["amount"],
      });
    }
    if (data?.type?.value === MarketplaceDiscountType.Percentage && !data?.percentage) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: i18n.t("Please enter the percentage", providerTranslationNamespace),
        path: ["percentage"],
      });
    }
  });

export type IDiscountUpsertFormValues = z.infer<typeof DiscountUpsertFormSchema>;

export const discountUpsertFormSchemaDefaultValues: Partial<IDiscountUpsertFormValues> = {
  name: undefined,
  endDate: undefined,
  startDate: undefined,
  healthPackage: undefined,
  product: undefined,
  type: undefined,
  amount: undefined,
  percentage: undefined,
};
