import { Patient } from "@/schema/types";
import { IconButton, Show, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { PatientProfile } from "shared/modules/patient";
import { ProfileContentTab } from "@/shared/modules/patient/types";

type PatientCareJourneyProps = {
  patient: Patient;
  selectedTab?: ProfileContentTab;
  defaultProgramId?: string;
};

export const PatientCareJourney: FC<PatientCareJourneyProps> = props => {
  const { patient, selectedTab, defaultProgramId } = props;

  const theme = useTheme();

  return (
    <>
      <PatientProfile
        patientId={patient?.id}
        patientName={{
          firstName: patient?.firstName,
          lastName: patient?.lastName,
        }}
        defaultSelectedTab={selectedTab}
        defaultProgramId={defaultProgramId}
        renderModalButton={modalButtonProps => (
          <IconButton
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
            {...modalButtonProps}
          >
            <Show fill={theme.palette.primary.main} />
          </IconButton>
        )}
      />
    </>
  );
};
