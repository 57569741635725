import { CodeSystemCode } from "@/schema/types";
import { getBranchesAutocompleteFilter, getDepartmentsAutocompleteFilter, getSystemCodeAutocompleteFilter } from "@health/autocompletes";
import { doctorSeniorityOptionsMap, getAutocompleteEnumFilter, userGenderOptionsMap } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { Avatar, CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";
import { DoctorBio } from "../../components/DoctorBio/DoctorBio";
import { DoctorQualifications } from "../../components/DoctorQualifications/DoctorQualifications";
import { DoctorSpecializations } from "../../components/DoctorSpecializations/DoctorSpecializations";
import { doctorAvailabilityStatusOptions } from "../../enum-options";
import { IDoctorListNode } from "../../types";

export const useDoctorListContainerColumns = (): CustomTableColumnProps<IDoctorListNode>[] => {
  const { t } = useTranslation("provider");

  return useMemo(() => {
    return [
      {
        key: "photo",
        header: t("Photo"),
        accessor: ({ user }) => <Avatar src={user?.photo || ""} />,
      },
      {
        key: "name",
        header: t("Name"),
        accessor: ({ user }) => user?.fullName,
        filter: {
          type: "string",
          name: "name",
        },
      },
      {
        key: "gender",
        header: t("Gender"),
        accessor: ({ user }) => userGenderOptionsMap[user?.gender!]?.label || "-",
        filter: getAutocompleteEnumFilter("UserGender", "gender"),
      },
      {
        key: "yearsOfExperience",
        header: t("Years Of Experience"),
        accessor: ({ yearsOfExperience }) => yearsOfExperience || "-",
      },
      {
        key: "nationalId",
        header: t("National Id"),
        accessor: ({ user }) => user?.nationalId || "-",
      },
      {
        key: "seniority",
        header: t("Seniority"),
        accessor: ({ seniority }) => doctorSeniorityOptionsMap[seniority!]?.label,
      },
      {
        key: "bio",
        header: t("Bio"),
        accessor: ({ bio }) => <DoctorBio bio={bio} />,
      },
      {
        key: "qualifications",
        header: t("Qualifications"),
        accessor: ({ qualifications }) => <DoctorQualifications qualifications={qualifications} />,
      },
      {
        key: "specializations",
        header: t("Specializations"),
        accessor: ({ specializations }) => <DoctorSpecializations specializations={specializations} />,
        filter: getSystemCodeAutocompleteFilter({
          name: "specializations",
          multiple: true,
          isCodeHidden: true,
          queryVariables: { codeSystemCode: CodeSystemCode.Speciality },
        }),
      },
      {
        key: "search",
        header: t("Search"),
        showOnlyForFilterField: true,
        filter: {
          type: "string",
          name: "search",
        },
      },
      {
        key: "availabilityStatus",
        header: t("Availability Status"),
        showOnlyForFilterField: true,
        filter: {
          type: "autocomplete",
          name: "availabilityStatus",
          options: doctorAvailabilityStatusOptions,
          getOptionLabel: option => option?.label,
          getValueForBackend: option => option?.value,
        },
      },
      {
        key: "isActive",
        header: t("Is Active"),
        showOnlyForFilterField: true,
        filter: getAutocompleteEnumFilter("YesNo", "isActive"),
      },
      {
        key: "licenseNumber_Icontains",
        header: t("License Number"),
        showOnlyForFilterField: true,
        filter: { type: "string", name: "licenseNumber_Icontains" },
      },
      {
        key: "appointmentType",
        header: t("Appointment Types"),
        showOnlyForFilterField: true,
        filter: getAutocompleteEnumFilter("AppointmentType", "appointmentTypes", { multiple: true }),
      },
      {
        key: "branchId",
        header: t("Branch"),
        showOnlyForFilterField: true,
        filter: getBranchesAutocompleteFilter({ name: "branch" }),
      },
      {
        key: "departmentIds",
        header: t("Departments"),
        showOnlyForFilterField: true,
        filter: getDepartmentsAutocompleteFilter({ name: "departmentIds", multiple: true }),
      },
    ];
  }, [t]);
};
