import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketplacePromotionUpdateMutationVariables = Types.Exact<{
  marketplacePromotionUpdateId: Types.Scalars['ID']['input'];
  input: Types.MarketplacePromotionInput;
}>;


export type MarketplacePromotionUpdateMutation = { __typename?: 'Mutation', marketplacePromotionUpdate?: { __typename?: 'MarketplacePromotion', id: string } | null };


export const MarketplacePromotionUpdateDocument = gql`
    mutation marketplacePromotionUpdate($marketplacePromotionUpdateId: ID!, $input: MarketplacePromotionInput!) {
  marketplacePromotionUpdate(id: $marketplacePromotionUpdateId, input: $input) {
    id
  }
}
    `;
export type MarketplacePromotionUpdateMutationFn = Apollo.MutationFunction<MarketplacePromotionUpdateMutation, MarketplacePromotionUpdateMutationVariables>;

/**
 * __useMarketplacePromotionUpdateMutation__
 *
 * To run a mutation, you first call `useMarketplacePromotionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarketplacePromotionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [marketplacePromotionUpdateMutation, { data, loading, error }] = useMarketplacePromotionUpdateMutation({
 *   variables: {
 *      marketplacePromotionUpdateId: // value for 'marketplacePromotionUpdateId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarketplacePromotionUpdateMutation(baseOptions?: Apollo.MutationHookOptions<MarketplacePromotionUpdateMutation, MarketplacePromotionUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarketplacePromotionUpdateMutation, MarketplacePromotionUpdateMutationVariables>(MarketplacePromotionUpdateDocument, options);
      }
export type MarketplacePromotionUpdateMutationHookResult = ReturnType<typeof useMarketplacePromotionUpdateMutation>;
export type MarketplacePromotionUpdateMutationResult = Apollo.MutationResult<MarketplacePromotionUpdateMutation>;
export type MarketplacePromotionUpdateMutationOptions = Apollo.BaseMutationOptions<MarketplacePromotionUpdateMutation, MarketplacePromotionUpdateMutationVariables>;