import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VendorAcceptProductOrderMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type VendorAcceptProductOrderMutation = { __typename?: 'Mutation', vendorAcceptProductOrder?: { __typename?: 'MarketplaceOrder', id: string, status: Types.MarketplaceOrderStatus } | null };


export const VendorAcceptProductOrderDocument = gql`
    mutation vendorAcceptProductOrder($id: ID!) {
  vendorAcceptProductOrder: marketplaceOrderAccept(id: $id) {
    id
    status
  }
}
    `;
export type VendorAcceptProductOrderMutationFn = Apollo.MutationFunction<VendorAcceptProductOrderMutation, VendorAcceptProductOrderMutationVariables>;

/**
 * __useVendorAcceptProductOrderMutation__
 *
 * To run a mutation, you first call `useVendorAcceptProductOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVendorAcceptProductOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vendorAcceptProductOrderMutation, { data, loading, error }] = useVendorAcceptProductOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVendorAcceptProductOrderMutation(baseOptions?: Apollo.MutationHookOptions<VendorAcceptProductOrderMutation, VendorAcceptProductOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VendorAcceptProductOrderMutation, VendorAcceptProductOrderMutationVariables>(VendorAcceptProductOrderDocument, options);
      }
export type VendorAcceptProductOrderMutationHookResult = ReturnType<typeof useVendorAcceptProductOrderMutation>;
export type VendorAcceptProductOrderMutationResult = Apollo.MutationResult<VendorAcceptProductOrderMutation>;
export type VendorAcceptProductOrderMutationOptions = Apollo.BaseMutationOptions<VendorAcceptProductOrderMutation, VendorAcceptProductOrderMutationVariables>;