import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetGuidedCareHealthProgramQueryVariables = Types.Exact<{
  guidedCareHealthProgramId: Types.Scalars['ID']['input'];
}>;


export type GetGuidedCareHealthProgramQuery = { __typename?: 'Query', guidedCareHealthProgram?: { __typename?: 'GuidedCareHealthProgram', guidedCareHealthProgramTemplate?: { __typename?: 'HealthProgramTemplate', id: string, riskFactorTemplate?: { __typename?: 'RiskFactorTemplate', id?: string | null, display?: string | null, fields?: Array<{ __typename?: 'HealthParameter', createdDate?: string | null, id?: string | null, display?: string | null, code?: string | null, arabicDisplay?: string | null, allowedValues?: Array<string | null> | null, minNormalRangeValue?: number | null, maxNormalRangeValue?: number | null, type?: Types.TemplateFieldType | null, standard?: Types.FieldStandard | null, isMandatory?: boolean | null, defaultValue?: string | null, unit?: { __typename?: 'FieldUnit', id?: string | null, display?: string | null, code?: string | null, arabicDisplay?: string | null } | null, category?: { __typename?: 'FieldCategory', id?: string | null, display?: string | null, code?: string | null, arabicDisplay?: string | null } | null, errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', code?: Types.DecisionMakerErrorCodes | null, errorType?: Types.ErrorType | null, field?: string | null, message?: string | null } | null> | null } | null> | null } | null } | null } | null };


export const GetGuidedCareHealthProgramDocument = gql`
    query getGuidedCareHealthProgram($guidedCareHealthProgramId: ID!) {
  guidedCareHealthProgram(id: $guidedCareHealthProgramId) {
    guidedCareHealthProgramTemplate {
      id
      riskFactorTemplate {
        id
        display
        fields {
          createdDate
          id
          display
          code
          arabicDisplay
          allowedValues
          minNormalRangeValue
          maxNormalRangeValue
          type
          standard
          isMandatory
          allowedValues
          defaultValue
          unit {
            id
            display
            code
            arabicDisplay
          }
          category {
            id
            display
            code
            arabicDisplay
          }
          errors {
            code
            errorType
            field
            message
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetGuidedCareHealthProgramQuery__
 *
 * To run a query within a React component, call `useGetGuidedCareHealthProgramQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGuidedCareHealthProgramQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGuidedCareHealthProgramQuery({
 *   variables: {
 *      guidedCareHealthProgramId: // value for 'guidedCareHealthProgramId'
 *   },
 * });
 */
export function useGetGuidedCareHealthProgramQuery(baseOptions: Apollo.QueryHookOptions<GetGuidedCareHealthProgramQuery, GetGuidedCareHealthProgramQueryVariables> & ({ variables: GetGuidedCareHealthProgramQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGuidedCareHealthProgramQuery, GetGuidedCareHealthProgramQueryVariables>(GetGuidedCareHealthProgramDocument, options);
      }
export function useGetGuidedCareHealthProgramLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGuidedCareHealthProgramQuery, GetGuidedCareHealthProgramQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGuidedCareHealthProgramQuery, GetGuidedCareHealthProgramQueryVariables>(GetGuidedCareHealthProgramDocument, options);
        }
export function useGetGuidedCareHealthProgramSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetGuidedCareHealthProgramQuery, GetGuidedCareHealthProgramQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGuidedCareHealthProgramQuery, GetGuidedCareHealthProgramQueryVariables>(GetGuidedCareHealthProgramDocument, options);
        }
export type GetGuidedCareHealthProgramQueryHookResult = ReturnType<typeof useGetGuidedCareHealthProgramQuery>;
export type GetGuidedCareHealthProgramLazyQueryHookResult = ReturnType<typeof useGetGuidedCareHealthProgramLazyQuery>;
export type GetGuidedCareHealthProgramSuspenseQueryHookResult = ReturnType<typeof useGetGuidedCareHealthProgramSuspenseQuery>;
export type GetGuidedCareHealthProgramQueryResult = Apollo.QueryResult<GetGuidedCareHealthProgramQuery, GetGuidedCareHealthProgramQueryVariables>;