import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketplaceProductCreateMutationVariables = Types.Exact<{
  input: Types.MarketplaceProductInput;
}>;


export type MarketplaceProductCreateMutation = { __typename?: 'Mutation', marketplaceProductCreate?: { __typename?: 'MarketplaceProduct', id: string } | null };


export const MarketplaceProductCreateDocument = gql`
    mutation MarketplaceProductCreate($input: MarketplaceProductInput!) {
  marketplaceProductCreate(input: $input) {
    id
  }
}
    `;
export type MarketplaceProductCreateMutationFn = Apollo.MutationFunction<MarketplaceProductCreateMutation, MarketplaceProductCreateMutationVariables>;

/**
 * __useMarketplaceProductCreateMutation__
 *
 * To run a mutation, you first call `useMarketplaceProductCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceProductCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [marketplaceProductCreateMutation, { data, loading, error }] = useMarketplaceProductCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarketplaceProductCreateMutation(baseOptions?: Apollo.MutationHookOptions<MarketplaceProductCreateMutation, MarketplaceProductCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarketplaceProductCreateMutation, MarketplaceProductCreateMutationVariables>(MarketplaceProductCreateDocument, options);
      }
export type MarketplaceProductCreateMutationHookResult = ReturnType<typeof useMarketplaceProductCreateMutation>;
export type MarketplaceProductCreateMutationResult = Apollo.MutationResult<MarketplaceProductCreateMutation>;
export type MarketplaceProductCreateMutationOptions = Apollo.BaseMutationOptions<MarketplaceProductCreateMutation, MarketplaceProductCreateMutationVariables>;