import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DoctorVisitRejectMutationVariables = Types.Exact<{
  input: Types.DoctorVisitRejectInput;
}>;


export type DoctorVisitRejectMutation = { __typename?: 'Mutation', doctorVisitReject?: { __typename?: 'DoctorVisitReject', doctorVisitRejectionAudit?: { __typename?: 'DoctorVisitRejectionAudit', id: string, reason?: string | null } | null, visitErrors?: Array<{ __typename?: 'H_EntityError', code: Types.H_EntityErrorCode, message?: string | null, field?: string | null }> | null } | null };


export const DoctorVisitRejectDocument = gql`
    mutation doctorVisitReject($input: DoctorVisitRejectInput!) {
  doctorVisitReject(input: $input) {
    doctorVisitRejectionAudit {
      id
      reason
    }
    visitErrors {
      code
      message
      field
    }
  }
}
    `;
export type DoctorVisitRejectMutationFn = Apollo.MutationFunction<DoctorVisitRejectMutation, DoctorVisitRejectMutationVariables>;

/**
 * __useDoctorVisitRejectMutation__
 *
 * To run a mutation, you first call `useDoctorVisitRejectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDoctorVisitRejectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [doctorVisitRejectMutation, { data, loading, error }] = useDoctorVisitRejectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDoctorVisitRejectMutation(baseOptions?: Apollo.MutationHookOptions<DoctorVisitRejectMutation, DoctorVisitRejectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DoctorVisitRejectMutation, DoctorVisitRejectMutationVariables>(DoctorVisitRejectDocument, options);
      }
export type DoctorVisitRejectMutationHookResult = ReturnType<typeof useDoctorVisitRejectMutation>;
export type DoctorVisitRejectMutationResult = Apollo.MutationResult<DoctorVisitRejectMutation>;
export type DoctorVisitRejectMutationOptions = Apollo.BaseMutationOptions<DoctorVisitRejectMutation, DoctorVisitRejectMutationVariables>;