import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateAppointmentNotificationSettingMutationVariables = Types.Exact<{
  updateAppointmentNotificationSettingId: Types.Scalars['ID']['input'];
  input: Types.AppointmentNotificationSettingInput;
}>;


export type UpdateAppointmentNotificationSettingMutation = { __typename?: 'Mutation', updateAppointmentNotificationSetting?: { __typename?: 'AppointmentNotificationSetting', sendTimeInMinutes?: number | null, lastModifiedDate?: any | null, lastModifiedBy?: string | null, id: string, englishText?: string | null, createdDate?: any | null, createdBy?: string | null, channels?: Array<Types.NotificationChannel | null> | null, arabicText?: string | null, types?: Array<Types.AppointmentType | null> | null } | null };


export const UpdateAppointmentNotificationSettingDocument = gql`
    mutation UpdateAppointmentNotificationSetting($updateAppointmentNotificationSettingId: ID!, $input: AppointmentNotificationSettingInput!) {
  updateAppointmentNotificationSetting(
    id: $updateAppointmentNotificationSettingId
    input: $input
  ) {
    sendTimeInMinutes
    lastModifiedDate
    lastModifiedBy
    id
    englishText
    createdDate
    createdBy
    channels
    arabicText
    types
  }
}
    `;
export type UpdateAppointmentNotificationSettingMutationFn = Apollo.MutationFunction<UpdateAppointmentNotificationSettingMutation, UpdateAppointmentNotificationSettingMutationVariables>;

/**
 * __useUpdateAppointmentNotificationSettingMutation__
 *
 * To run a mutation, you first call `useUpdateAppointmentNotificationSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppointmentNotificationSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppointmentNotificationSettingMutation, { data, loading, error }] = useUpdateAppointmentNotificationSettingMutation({
 *   variables: {
 *      updateAppointmentNotificationSettingId: // value for 'updateAppointmentNotificationSettingId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAppointmentNotificationSettingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAppointmentNotificationSettingMutation, UpdateAppointmentNotificationSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAppointmentNotificationSettingMutation, UpdateAppointmentNotificationSettingMutationVariables>(UpdateAppointmentNotificationSettingDocument, options);
      }
export type UpdateAppointmentNotificationSettingMutationHookResult = ReturnType<typeof useUpdateAppointmentNotificationSettingMutation>;
export type UpdateAppointmentNotificationSettingMutationResult = Apollo.MutationResult<UpdateAppointmentNotificationSettingMutation>;
export type UpdateAppointmentNotificationSettingMutationOptions = Apollo.BaseMutationOptions<UpdateAppointmentNotificationSettingMutation, UpdateAppointmentNotificationSettingMutationVariables>;