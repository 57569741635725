import { PatientHealthParameterInput, VisitStatus } from "@/schema/types";
import { IVitalSignsUpdateFormValues } from "pages/e-visit/VitalSigns/forms/VitalSignUpdate/VitalSignUpdateFormSchema";
import { healthParameterCodes } from "../../../PatientHealthParameters/constants";

export const isVisitDetailsEnabled = (status: VisitStatus) => {
  return (
    status === VisitStatus.Completed ||
    status === VisitStatus.CallInProgress ||
    status === VisitStatus.Incompleted ||
    status === VisitStatus.CallRejoinable
  );
};

export const convertVisitVitalSignsFormValuesToBackEndValues = (values: IVitalSignsUpdateFormValues): PatientHealthParameterInput[] => {
  const patientHealthParameters =
    values?.items
      ?.filter(item => item?.value && item?.code !== `${healthParameterCodes?.bloodPressureHigh}/${healthParameterCodes?.bloodPressureLow}`)
      ?.map(item => ({
        hpCode: item?.code!,
        valueNumber: item?.value,
      })) || [];

  const bloodPressure = values?.items?.find(
    item => item?.code === `${healthParameterCodes?.bloodPressureHigh}/${healthParameterCodes?.bloodPressureLow}`
  );

  const patientHealthParametersBloodPressures: PatientHealthParameterInput[] = [];

  if (bloodPressure?.value) {
    const [highValue, lowValue] = (bloodPressure.value as string).split("/");

    if (highValue && lowValue) {
      patientHealthParametersBloodPressures.push(
        {
          hpCode: healthParameterCodes?.bloodPressureHigh,
          valueNumber: Number(highValue),
        },
        {
          hpCode: healthParameterCodes?.bloodPressureLow,
          valueNumber: Number(lowValue),
        }
      );
    }
  }

  return [...patientHealthParameters, ...patientHealthParametersBloodPressures];
};

const formatSection = (title, items, formatter) => {
  if (!items || items.length === 0) return "";
  let sectionText = `${title}:\n`;
  items.forEach(item => {
    sectionText += formatter(item);
  });
  sectionText += "\n";
  return sectionText;
};

export const convertVisitResultToPlainText = visit => {
  let formattedText = "Visit Details:\n\n";

  if (visit?.doctorNote) {
    formattedText += `Doctor Note: ${visit?.doctorNote}\n\n`;
  }

  formattedText += formatSection("Vital Signs", visit?.vitalSigns, sign => `${sign.display}: ${sign.value}\n`);

  formattedText += formatSection("Rads", visit?.rads, rad => `${rad.display}: ${rad.value}\n`);

  formattedText += formatSection("Labs", visit?.labs, lab => `${lab.display} with code: ${lab.code}\n`);

  formattedText += formatSection(
    "Diagnoses",
    visit?.visitDiagnoses,
    diagnosis =>
      `${diagnosis.display} with code: ${diagnosis.code} and type: ${
        diagnosis?.visitDiagnosisType.charAt(0).toUpperCase() + diagnosis?.visitDiagnosisType.slice(1).toLowerCase()
      }\n`
  );

  formattedText += formatSection(
    "Prescriptions",
    visit?.visitPrescriptions,
    prescription =>
      `${prescription.display} with Reference Number: ${prescription.referenceId} and type: ${
        prescription?.type.charAt(0).toUpperCase() + prescription?.type.slice(1).toLowerCase()
      }\n`
  );

  return formattedText;
};
