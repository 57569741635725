import { Appointment, AppointmentStatus } from "@/schema/types";
import { IconButton, RescheduleIcon } from "@toolkit/ui";
import { AppointmentAccept, AppointmentReschedule } from "pages/appointments";
import { FC } from "react";

type GuidedCareTaskActionsAppointmentProps = {
  appointment: Appointment;
};

export const GuidedCareTaskActionsAppointment: FC<GuidedCareTaskActionsAppointmentProps> = props => {
  const { appointment } = props;

  const appointmentStatus = appointment?.status!;

  const showAppointmentAccept =
    appointmentStatus === AppointmentStatus.Request || appointmentStatus === AppointmentStatus.RescheduledByConsumer;

  const showAppointmentReschedule =
    showAppointmentAccept ||
    appointmentStatus === AppointmentStatus.Approved ||
    appointmentStatus === AppointmentStatus.Confirmed ||
    appointmentStatus === AppointmentStatus.Tentative;

  return (
    <>
      {showAppointmentAccept && (
        <AppointmentAccept
          id={appointment?.id!}
          activeHealthProgramMember={appointment?.consumer?.activeHealthProgramMembers?.[0]}
          healthProgramsByActiveMember={appointment?.consumer?.healthProgramsByActiveMembers?.[0]}
          requestedTimeSlots={appointment?.requestedTimeSlots || []}
        />
      )}

      {showAppointmentReschedule && (
        <AppointmentReschedule
          id={appointment?.id!}
          status={appointment?.status!}
          doctorId={appointment?.doctor?.id}
          patient={appointment?.consumer}
          paymentInfo={appointment?.paymentInfo}
          paymentStatus={appointment?.paymentStatus}
          isAppointmentComingFromGuidedCareTask
          buttonEl={
            <IconButton size={"small"} color={"primary"}>
              <RescheduleIcon fontSize={"small"} />
            </IconButton>
          }
        />
      )}
    </>
  );
};
