import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GuidedCareTeamGetQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GuidedCareTeamGetQuery = { __typename?: 'Query', providerGuidedCareHealthProgramTeam?: { __typename?: 'ProviderGuidedCareHealthProgramTeam', id: string, name?: string | null, description?: string | null, maxNumberOfPatients?: number | null, teamStatus?: Types.ProviderGuidedCareTeamStatus | null, isActive?: boolean | null, branch: { __typename?: 'Branch', id: string, name: string, nameAr?: string | null }, guidedCareHealthProgram: { __typename?: 'GuidedCareHealthProgram', id: string, name?: string | null, isLatestVersion?: boolean | null }, teamMembers?: Array<{ __typename?: 'ProviderGuidedCareProgramTeamMember', id: string, isBackup?: boolean | null, user?: { __typename?: 'User', id: string, fullName?: string | null } | null, guidedCareProgramTeamMember?: { __typename?: 'GuidedCareProgramTeamMember', id: string, position?: Types.TeamMemberPosition | null, specialization?: string | null, specializationDisplay?: string | null, isBackupPersonMandatory?: boolean | null, isKeyTeamMember?: boolean | null, isLicencedHealthProfessional?: boolean | null } | null } | null> | null } | null };


export const GuidedCareTeamGetDocument = gql`
    query GuidedCareTeamGet($id: ID!) {
  providerGuidedCareHealthProgramTeam(id: $id) {
    id
    name
    description
    branch {
      id
      name
      nameAr
    }
    maxNumberOfPatients
    teamStatus
    isActive
    guidedCareHealthProgram {
      id
      name
      isLatestVersion
    }
    teamMembers {
      id
      isBackup
      user {
        id
        fullName
      }
      guidedCareProgramTeamMember {
        id
        position
        specialization
        specializationDisplay
        isBackupPersonMandatory
        isKeyTeamMember
        isLicencedHealthProfessional
      }
    }
  }
}
    `;

/**
 * __useGuidedCareTeamGetQuery__
 *
 * To run a query within a React component, call `useGuidedCareTeamGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuidedCareTeamGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuidedCareTeamGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGuidedCareTeamGetQuery(baseOptions: Apollo.QueryHookOptions<GuidedCareTeamGetQuery, GuidedCareTeamGetQueryVariables> & ({ variables: GuidedCareTeamGetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GuidedCareTeamGetQuery, GuidedCareTeamGetQueryVariables>(GuidedCareTeamGetDocument, options);
      }
export function useGuidedCareTeamGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GuidedCareTeamGetQuery, GuidedCareTeamGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GuidedCareTeamGetQuery, GuidedCareTeamGetQueryVariables>(GuidedCareTeamGetDocument, options);
        }
export function useGuidedCareTeamGetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GuidedCareTeamGetQuery, GuidedCareTeamGetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GuidedCareTeamGetQuery, GuidedCareTeamGetQueryVariables>(GuidedCareTeamGetDocument, options);
        }
export type GuidedCareTeamGetQueryHookResult = ReturnType<typeof useGuidedCareTeamGetQuery>;
export type GuidedCareTeamGetLazyQueryHookResult = ReturnType<typeof useGuidedCareTeamGetLazyQuery>;
export type GuidedCareTeamGetSuspenseQueryHookResult = ReturnType<typeof useGuidedCareTeamGetSuspenseQuery>;
export type GuidedCareTeamGetQueryResult = Apollo.QueryResult<GuidedCareTeamGetQuery, GuidedCareTeamGetQueryVariables>;