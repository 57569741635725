import { IUserWorkspaceNurseVar } from "@/pages/UserWorkspace/types";
import { AppRoleTypes, UserVendorUserType } from "@/schema/types";
import { UserInfoType } from "shared/configs/oidc";
import { IsVendorUserType } from "./types";

export const isNurseUser: IsVendorUserType = (userInfo: UserInfoType) => {
  return userInfo?.vendor_user_type?.toLowerCase() === UserVendorUserType.Nurse.toLowerCase();
};
export const isDoctorUser: IsVendorUserType = (userInfo: UserInfoType) => {
  return userInfo?.vendor_user_type?.toLowerCase() === UserVendorUserType.Doctor.toLowerCase();
};
export const isManagerUser: IsVendorUserType = (userInfo: UserInfoType) => {
  return userInfo?.vendor_user_type?.toLowerCase() === UserVendorUserType.Manager.toLowerCase();
};
export const isPharmacistUser: IsVendorUserType = (userInfo: UserInfoType) => {
  return userInfo?.vendor_user_type?.toLowerCase() === UserVendorUserType.Pharmacist.toLowerCase();
};
export const isReceptionistUser: IsVendorUserType = (userInfo: UserInfoType) => {
  return userInfo?.vendor_user_type?.toLowerCase() === UserVendorUserType.Receptionist.toLowerCase();
};
export const isVendorAdmin: IsVendorUserType = (userInfo: UserInfoType) => {
  return userInfo?.app_role?.toLowerCase() === AppRoleTypes.Admin.toLowerCase();
};
export const getCodedUserId = (userInfo: UserInfoType) => {
  return btoa("User:" + userInfo.user_id);
};
export const getCodedDoctorId = (userInfo: UserInfoType) => {
  return userInfo?.doctor_id ? btoa("Doctor:" + userInfo?.doctor_id) : undefined;
};
export const isNurseAssignedToDoctor = (nurseWorkSpace: IUserWorkspaceNurseVar | null) => {
  return !!(nurseWorkSpace && !!nurseWorkSpace?.doctors?.length);
};
