import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddDiagnosisToVisitMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.AddDiagnosisToVisitInput>;
}>;


export type AddDiagnosisToVisitMutation = { __typename?: 'Mutation', addDiagnosisToVisit?: { __typename?: 'VisitDiagnosisCRUD', visitDiagnosis?: { __typename: 'VisitDiagnosis', id: string, display?: string | null, code: string, visitDiagnosisType: Types.VisitDiagnosisType, riskTemplate?: { __typename?: 'VisitDiagnosisRiskTemplate', id: string, code?: string | null, isFilled?: boolean | null, notification?: string | null, result?: string | null, chronicDiseaseSeverity?: string | null, chronicDiseaseCode?: string | null, isGuidedCareProgramSelected?: boolean | null, suggestedGuidedPrograms?: Array<{ __typename?: 'GuidedCareHealthProgram', id: string, name?: string | null } | null> | null, enrolledGuidedPrograms?: Array<{ __typename?: 'GuidedCareHealthProgram', id: string, name?: string | null } | null> | null, selectedGuidedCareProgram?: { __typename?: 'GuidedCareHealthProgram', id: string, name?: string | null } | null } | null } | null, visitDiagnosisErrors?: Array<{ __typename?: 'H_EntityError', message?: string | null, field?: string | null, code: Types.H_EntityErrorCode }> | null } | null };


export const AddDiagnosisToVisitDocument = gql`
    mutation AddDiagnosisToVisit($input: AddDiagnosisToVisitInput) {
  addDiagnosisToVisit(input: $input) {
    visitDiagnosis {
      __typename
      id
      display
      code
      riskTemplate {
        id
        code
        suggestedGuidedPrograms {
          id
          name
        }
        enrolledGuidedPrograms {
          id
          name
        }
        isFilled
        notification
        result
        chronicDiseaseSeverity
        chronicDiseaseCode
        isGuidedCareProgramSelected
        selectedGuidedCareProgram {
          id
          name
        }
      }
      visitDiagnosisType
    }
    visitDiagnosisErrors {
      message
      field
      code
    }
  }
}
    `;
export type AddDiagnosisToVisitMutationFn = Apollo.MutationFunction<AddDiagnosisToVisitMutation, AddDiagnosisToVisitMutationVariables>;

/**
 * __useAddDiagnosisToVisitMutation__
 *
 * To run a mutation, you first call `useAddDiagnosisToVisitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDiagnosisToVisitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDiagnosisToVisitMutation, { data, loading, error }] = useAddDiagnosisToVisitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddDiagnosisToVisitMutation(baseOptions?: Apollo.MutationHookOptions<AddDiagnosisToVisitMutation, AddDiagnosisToVisitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDiagnosisToVisitMutation, AddDiagnosisToVisitMutationVariables>(AddDiagnosisToVisitDocument, options);
      }
export type AddDiagnosisToVisitMutationHookResult = ReturnType<typeof useAddDiagnosisToVisitMutation>;
export type AddDiagnosisToVisitMutationResult = Apollo.MutationResult<AddDiagnosisToVisitMutation>;
export type AddDiagnosisToVisitMutationOptions = Apollo.BaseMutationOptions<AddDiagnosisToVisitMutation, AddDiagnosisToVisitMutationVariables>;