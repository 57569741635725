var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useMemo } from "react";
import { useChatGroupsQuery } from "../../gql/queries";
import { ChatGroupSortingField, CommunicationSortDirection } from "../../schema/types";
export const useChatGroupsHooks = () => {
    var _a;
    const { data, fetchMore, loading, refetch, error } = useChatGroupsQuery({
        variables: {
            first: 10,
            sortBy: {
                field: ChatGroupSortingField.Created,
                direction: CommunicationSortDirection.Desc,
            },
        },
    });
    const chatGroups = useMemo(() => { var _a; return (_a = data === null || data === void 0 ? void 0 : data.chatGroups) === null || _a === void 0 ? void 0 : _a.edges.map(e => e.node); }, [data]);
    const keyExtractor = useCallback((item) => item.id, []);
    const pageInfo = (_a = data === null || data === void 0 ? void 0 : data.chatGroups) === null || _a === void 0 ? void 0 : _a.pageInfo;
    const hasMore = pageInfo === null || pageInfo === void 0 ? void 0 : pageInfo.hasNextPage;
    const hasError = !!error;
    const isEmpty = !chatGroups || chatGroups.length === 0;
    const handleRetry = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        yield refetch();
    }), []);
    const handleFetchMore = useCallback(() => {
        if ((pageInfo === null || pageInfo === void 0 ? void 0 : pageInfo.hasNextPage) && !loading) {
            fetchMore({
                variables: {
                    first: 10,
                    after: pageInfo === null || pageInfo === void 0 ? void 0 : pageInfo.endCursor,
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                    var _a, _b;
                    if (!fetchMoreResult)
                        return prev;
                    return Object.assign({}, prev, Object.assign(Object.assign({}, fetchMoreResult), { chatGroups: Object.assign(Object.assign({}, fetchMoreResult.chatGroups), { edges: [...(((_a = prev === null || prev === void 0 ? void 0 : prev.chatGroups) === null || _a === void 0 ? void 0 : _a.edges) || []), ...(((_b = fetchMoreResult === null || fetchMoreResult === void 0 ? void 0 : fetchMoreResult.chatGroups) === null || _b === void 0 ? void 0 : _b.edges) || [])] }) }));
                },
            });
        }
    }, [fetchMore, pageInfo === null || pageInfo === void 0 ? void 0 : pageInfo.endCursor, pageInfo === null || pageInfo === void 0 ? void 0 : pageInfo.hasNextPage, loading]);
    return {
        data: chatGroups,
        hasMore,
        hasError,
        isEmpty,
        loading,
        keyExtractor,
        handleFetchMore,
        handleRetry,
    };
};
