import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VisitNotToPatientUpdateMutationVariables = Types.Exact<{
  input: Types.VisitDoctorNoteInput;
}>;


export type VisitNotToPatientUpdateMutation = { __typename?: 'Mutation', visitNotToPatientUpdate?: { __typename?: 'VisitAction', visit?: { __typename?: 'Visit', id: string, noteToPatient?: string | null } | null, visitErrors?: Array<{ __typename?: 'H_EntityError', field?: string | null, message?: string | null }> | null } | null };


export const VisitNotToPatientUpdateDocument = gql`
    mutation VisitNotToPatientUpdate($input: VisitDoctorNoteInput!) {
  visitNotToPatientUpdate(input: $input) {
    visit {
      id
      noteToPatient
    }
    visitErrors {
      field
      message
    }
  }
}
    `;
export type VisitNotToPatientUpdateMutationFn = Apollo.MutationFunction<VisitNotToPatientUpdateMutation, VisitNotToPatientUpdateMutationVariables>;

/**
 * __useVisitNotToPatientUpdateMutation__
 *
 * To run a mutation, you first call `useVisitNotToPatientUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVisitNotToPatientUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [visitNotToPatientUpdateMutation, { data, loading, error }] = useVisitNotToPatientUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVisitNotToPatientUpdateMutation(baseOptions?: Apollo.MutationHookOptions<VisitNotToPatientUpdateMutation, VisitNotToPatientUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VisitNotToPatientUpdateMutation, VisitNotToPatientUpdateMutationVariables>(VisitNotToPatientUpdateDocument, options);
      }
export type VisitNotToPatientUpdateMutationHookResult = ReturnType<typeof useVisitNotToPatientUpdateMutation>;
export type VisitNotToPatientUpdateMutationResult = Apollo.MutationResult<VisitNotToPatientUpdateMutation>;
export type VisitNotToPatientUpdateMutationOptions = Apollo.BaseMutationOptions<VisitNotToPatientUpdateMutation, VisitNotToPatientUpdateMutationVariables>;