import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VisitsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  filter?: Types.InputMaybe<Types.VisitFilterInput>;
  sortBy?: Types.InputMaybe<Types.VisitSortingInput>;
}>;


export type VisitsQuery = { __typename?: 'Query', visits?: { __typename?: 'VisitCountableConnection', totalCount?: number | null, pageInfo: { __typename?: 'H_PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'VisitCountableEdge', node: { __typename?: 'Visit', id: string, type?: Types.VisitType | null, number?: string | null, created?: any | null, startTime?: any | null, status?: Types.VisitStatus | null, callId?: any | null, chatId?: any | null, cancellationReason?: string | null, healthProgramMember?: { __typename?: 'HealthProgramMember', id: string, firstName?: string | null, lastName?: string | null, insuranceId?: string | null, insuranceCompanyId?: string | null } | null, branch?: { __typename?: 'Branch', name: string } | null, doctor?: { __typename?: 'Doctor', user?: { __typename?: 'User', fullName?: string | null } | null } | null, patient?: { __typename?: 'Patient', id: string, number?: string | null, firstName?: string | null, lastName?: string | null, contactNumber?: string | null, user?: { __typename?: 'User', id: string } | null } | null } }> } | null };


export const VisitsDocument = gql`
    query visits($first: Int, $after: String, $before: String, $last: Int, $filter: VisitFilterInput, $sortBy: VisitSortingInput) {
  visits(
    first: $first
    filter: $filter
    after: $after
    before: $before
    last: $last
    sortBy: $sortBy
  ) {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    totalCount
    edges {
      node {
        id
        type
        number
        created
        startTime
        status
        callId
        chatId
        cancellationReason
        healthProgramMember {
          id
          firstName
          lastName
          insuranceId
          insuranceCompanyId
        }
        branch {
          name
        }
        doctor {
          user {
            fullName
          }
        }
        patient {
          id
          number
          firstName
          lastName
          contactNumber
          user {
            id
          }
        }
      }
    }
  }
}
    `;

/**
 * __useVisitsQuery__
 *
 * To run a query within a React component, call `useVisitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useVisitsQuery(baseOptions?: Apollo.QueryHookOptions<VisitsQuery, VisitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VisitsQuery, VisitsQueryVariables>(VisitsDocument, options);
      }
export function useVisitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VisitsQuery, VisitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VisitsQuery, VisitsQueryVariables>(VisitsDocument, options);
        }
export function useVisitsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<VisitsQuery, VisitsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<VisitsQuery, VisitsQueryVariables>(VisitsDocument, options);
        }
export type VisitsQueryHookResult = ReturnType<typeof useVisitsQuery>;
export type VisitsLazyQueryHookResult = ReturnType<typeof useVisitsLazyQuery>;
export type VisitsSuspenseQueryHookResult = ReturnType<typeof useVisitsSuspenseQuery>;
export type VisitsQueryResult = Apollo.QueryResult<VisitsQuery, VisitsQueryVariables>;