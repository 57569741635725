import { ServiceDetailsInput } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { Button, CustomDialog, Grid } from "@toolkit/ui";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { CustomTextField } from "shared/components";
import { useStyles } from "./PatientAppointmentForm.styles";

type ServiceDetailEditFormProps = {
  service: ServiceDetailsInput;
  onSubmit: (service: ServiceDetailsInput) => void;
  onClose: () => void;
};

export const ServiceDetailEditForm: FC<ServiceDetailEditFormProps> = ({ service, onSubmit: handleOnSubmit, onClose }) => {
  const { t } = useTranslation("provider");
  const { classes } = useStyles();

  const methods = useForm<ServiceDetailsInput>({
    mode: "onChange",
    defaultValues: service,
  });
  const { handleSubmit } = methods;
  const onSubmit = async (data: ServiceDetailsInput) => {
    handleOnSubmit(data);
  };

  return (
    <CustomDialog
      type='base'
      open={true}
      onClose={onClose}
      classes={{
        paper: classes.paper,
      }}
      maxWidth='sm'
      DialogTitleProps={{
        onClose: onClose,
        title: t("Edit Service Type"),
      }}
      DialogActionsProps={{
        children: (
          <Button onClick={handleSubmit(onSubmit)} variant='contained' color='primary'>
            {t("Save")}
          </Button>
        ),
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...methods}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CustomTextField disabled name='serviceCode' label={t("Code")} />
            </Grid>

            <Grid item xs={12} columnSpacing={2}>
              <CustomTextField isRequired multiline minRows={4} name='standard' label={t("Description")} />
            </Grid>
          </Grid>
        </FormProvider>
      </form>
    </CustomDialog>
  );
};
