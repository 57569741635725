import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllPatientsQueryVariables = Types.Exact<{
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.PatientFilterInput>;
}>;


export type GetAllPatientsQuery = { __typename?: 'Query', patients?: { __typename?: 'PatientCountableConnection', pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'PatientCountableEdge', node: { __typename?: 'Patient', firstName?: string | null, lastName?: string | null, id: string, user?: { __typename?: 'User', photo?: string | null, patient?: { __typename?: 'Patient', firstName?: string | null, lastName?: string | null } | null } | null, activeAppointmentsInPerson?: Array<{ __typename?: 'Appointment', doctorNote?: string | null, department?: { __typename?: 'Department', name: string } | null } | null> | null } }> } | null };


export const GetAllPatientsDocument = gql`
    query getAllPatients($last: Int, $first: Int, $after: String, $before: String, $filter: PatientFilterInput) {
  patients(
    last: $last
    first: $first
    after: $after
    before: $before
    filter: $filter
  ) {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    edges {
      node {
        firstName
        lastName
        id
        user {
          photo
          patient {
            firstName
            lastName
          }
        }
        activeAppointmentsInPerson {
          department {
            name
          }
          doctorNote
        }
      }
    }
  }
}
    `;

/**
 * __useGetAllPatientsQuery__
 *
 * To run a query within a React component, call `useGetAllPatientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPatientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPatientsQuery({
 *   variables: {
 *      last: // value for 'last'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetAllPatientsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllPatientsQuery, GetAllPatientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllPatientsQuery, GetAllPatientsQueryVariables>(GetAllPatientsDocument, options);
      }
export function useGetAllPatientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllPatientsQuery, GetAllPatientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllPatientsQuery, GetAllPatientsQueryVariables>(GetAllPatientsDocument, options);
        }
export function useGetAllPatientsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAllPatientsQuery, GetAllPatientsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllPatientsQuery, GetAllPatientsQueryVariables>(GetAllPatientsDocument, options);
        }
export type GetAllPatientsQueryHookResult = ReturnType<typeof useGetAllPatientsQuery>;
export type GetAllPatientsLazyQueryHookResult = ReturnType<typeof useGetAllPatientsLazyQuery>;
export type GetAllPatientsSuspenseQueryHookResult = ReturnType<typeof useGetAllPatientsSuspenseQuery>;
export type GetAllPatientsQueryResult = Apollo.QueryResult<GetAllPatientsQuery, GetAllPatientsQueryVariables>;